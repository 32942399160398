<template>
    <div>
        <div>
            <div
                    style="padding: 1rem; border-bottom: 1px solid #ddd; text-align: left">
                总部教师列表
            </div>
            <div class="public-global-search">
                <el-form class="demo-ruleForm">
                    <el-form-item>
                        <el-button
                                style="margin-bottom: 20px"
                                size="small"
                                icon="el-icon-delete"
                                type="primary"
                                @click="batchDelete(tableChecked,2)">
                            批量删除
                        </el-button>
                        <el-button
                                size="small"
                                icon="el-icon-edit"
                                type="primary"
                                @click="addcreate()"
                                v-permission="'eduHeadquarters:insert'">
                            添加
                        </el-button>
                        <el-button icon="el-icon-upload2" type="primary" @click="upload"
                                   size="small" v-permission="'eduHeadquarters:import'">
                            导入
                        </el-button>
                        <el-button icon="el-icon-download" type="primary" @click="download"
                                   size="small" v-permission="'eduHeadquarters:export'">
                            导出
                        </el-button>
                    </el-form-item>
                </el-form>
                <el-form :model="search" class="demo-ruleForm" size="small" inline
                         v-permission="'eduHeadquarters:list'">
                    <el-form-item label="姓名/工号" style="margin-bottom: 0">
                        <el-input
                                v-model="queryBo.info"
                                clearable
                                @input="() => (queryBo.info = queryBo.info.trim())"
                                placeholder="请输入内容"
                                style="width: 200px">
                            <el-button icon="el-icon-search" slot="append" @click="queryListVo"></el-button>
                        </el-input>

                    </el-form-item>
                    <el-form-item label="是否在任" style="margin-bottom: 0">
                        <el-select
                                clearable
                                @change="queryListVo"
                                v-model="queryBo.online"
                                placeholder="请选择"
                                class="el-form-input-width">
                            <el-option
                                    v-for="item in onlineOpt"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="教师属性" style="margin-bottom: 0">
                        <el-select
                                clearable
                                @change="queryListVo"
                                v-model="queryBo.IdentityStatus"
                                placeholder="请选择"
                                class="el-form-input-width">
                            <el-option
                                    v-for="item in IdentityStatus"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-form>
            </div>
            <div class="table-wrap">
                <el-table
                        size="medium"
                        height="600px"
                        max-height="600px"
                        :header-cell-style="{'text-align':'center'}"
                        :cell-style="{'text-align':'center'}"
                        border
                        ref="multipleTable"
                        :data="tableData"
                        @selection-change="handleSelectionChange">
                    <el-table-column type="selection" width="55" align="center"></el-table-column>
                    <el-table-column label="序号" width="140" type="index">
                    </el-table-column>
                    <el-table-column  label="姓名" width="180" prop="name" sortable>
                    </el-table-column>
                    <el-table-column label="工号" width="200" prop="jobNo" sortable>
                    </el-table-column>
                    <el-table-column label="身份证号" width="200" prop="idCard" sortable>
                    </el-table-column>
                    <el-table-column label="研究方向" width="140" prop="profDir">
                    </el-table-column>
                    <el-table-column label="职称" width="150" prop="jodTitle">
                    </el-table-column>
                    <el-table-column label="学历" width="150" prop="education">
                    </el-table-column>
                    <el-table-column label="在任" width="140" prop="online" sortable>
                    </el-table-column>
                    <el-table-column label="身份类型" width="160" prop="roleType">
                    </el-table-column>
                    <el-table-column label="教师类型" width="160" prop="teacherType">
                    </el-table-column>
                    <el-table-column label="移动电话" width="200" prop="phoneNum">
                    </el-table-column>
                    <el-table-column label="固定电话" width="200" prop="telNum">
                    </el-table-column>
                    <el-table-column label="电子邮箱" width="200" prop="email">
                    </el-table-column>
                    <el-table-column label="邮政编号" width="200" prop="postCode">
                    </el-table-column>
                    <el-table-column label="QQ" width="160" prop="qqNum">
                    </el-table-column>
                    <el-table-column label="微信" width="160" prop="wechart">
                    </el-table-column>
                    <el-table-column label="联系地址" width="250" prop="contactAddress">
                    </el-table-column>
                    <el-table-column label="操作" width="160" fixed="right">
                        <template slot-scope="scope">
                            <el-button @click="editor(scope.row)" type="primary" size="small"
                                       v-permission="'eduHeadquarters:update'">
                                编辑
                            </el-button>
                            <el-button @click="batchDelete(scope.row,1)" type="danger" size="small"
                                       v-permission="'eduHeadquarters:deleted'">
                                删除
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <div style="padding: 1rem">
                    <el-pagination
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                            :current-page="queryBo.page"
                            :page-sizes="[10, 20, 50, 100]"
                            :page-size="queryBo.pageSize"
                            layout="total, sizes, prev, pager, next, jumper"
                            :total="total"
                    >
                    </el-pagination>
                </div>
            </div>
        </div>
        <!-- 编辑 -->
        <el-dialog
                title="编辑"
                :visible.sync="dialogVisible"
                width="50%"
                style="text-align: left">
            <p style="color:red;font-size:14px;margin-bottom:1rem">（提示：教师工号将作为教师登录帐号,身份证号后6位将作为老师的初始密码）</p>
            <el-form
                    size="small"
                    :model="bo"
                    :rules="boRules"
                    ref="ruleForm"
                    inline
                    label-position="left"
                    class="inlineForm"
                    label-width="100px">
                <el-form-item label="上传照片：">
                    <el-upload
                            ref="uploadss"
                            class="upload-demo"
                            :on-success="onSuccess"
                            :headers="myHeaders"
                            :action="this.TEACHERADD">
                        <el-button size="small" type="primary">点击上传</el-button><span style="color:red;font-size:14px;margin-left:1rem">支持格式jpg/png</span>
                    </el-upload>
                </el-form-item>
                <el-form-item>
                    <img class="portrait_image" :src="bo.headerUrl" style="width:22%;margin-left: 100px" alt/>
                </el-form-item>
                <el-form-item label="工号：" prop="jobNo">
                    <el-input
                            suffix-icon="none"
                            v-model="bo.jobNo"
                            placeholder="请输入"
                            class="el-form-input-width"
                            @input="() => (bo.jobNo = bo.jobNo.trim())">
                    </el-input>
                </el-form-item>
                <el-form-item label="姓名：" prop="name">
                    <el-input
                        style="width: 205px;"
                            v-model="bo.name"
                            placeholder="请输入"
                            class="el-form-input-width"
                            @input="() => (bo.name = bo.name.trim())">
                    </el-input>
                </el-form-item>
                <el-form-item label="性别：" prop="sex">
                    <el-select v-model="bo.sex" placeholder="请选择">
                        <el-option
                                v-for="item in sexOpt"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="身份证号：" prop="idCard">
                    <el-input
                            suffix-icon="none"
                            v-model="bo.idCard"
                            placeholder="请输入"
                            class="el-form-input-width"
                            @input="() => (bo.idCard = bo.idCard.trim())">
                    </el-input>
                </el-form-item>
                <el-form-item label="专业方向：" prop="profDir">
                    <el-input
                            suffix-icon="none"
                            v-model="bo.profDir"
                            placeholder="请输入"
                            class="el-form-input-width"
                            @input="() => (bo.profDir = bo.profDir.trim())">
                    </el-input>
                </el-form-item>
                <el-form-item label="职称：" prop="jodTitle">
                    <el-input
                        style="width: 205px;"
                            v-model="bo.jodTitle"
                            placeholder="请输入"
                            class="el-form-input-width"
                            @input="() => (bo.jodTitle = bo.jodTitle.trim())">
                    </el-input>
                </el-form-item>
                <el-form-item label="学历：" prop="education">
                    <el-input
                            suffix-icon="none"
                            v-model="bo.education"
                            placeholder="请输入"
                            class="el-form-input-width"
                            @input="() => (bo.education = bo.education.trim())">
                    </el-input>
                </el-form-item>
                <el-form-item label="在任：" prop="online">
                    <el-select v-model="bo.online" placeholder="请选择">
                        <el-option
                                v-for="item in onlineOpt"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
<!--                <el-form-item label="列表选择：" prop="online" v-if="bo.id ==null">-->
<!--                    <el-select v-model="bo.IdentityStatus" placeholder="请选择">-->
<!--                        <el-option-->
<!--                                v-for="item in IdentityStatusType"-->
<!--                                :key="item.value"-->
<!--                                :label="item.label"-->
<!--                                :value="item.value">-->
<!--                        </el-option>-->
<!--                    </el-select>-->
<!--                </el-form-item>-->
                <el-form-item label="身份类型：" prop="roleType">
                    <el-select v-model="bo.roleType" placeholder="请选择">
                        <el-option
                                v-for="item in roleTypeOpt"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="移动电话：" prop="phoneNum">
                    <el-input
                            suffix-icon="none"
                            v-model="bo.phoneNum"
                            placeholder="请输入"
                            class="el-form-input-width"
                            @input="() => (bo.phoneNum = bo.phoneNum.trim())">
                    </el-input>
                </el-form-item>
                <el-form-item label="固定电话：" prop="telNum">
                    <el-input
                            suffix-icon="none"
                            v-model="bo.telNum"
                            placeholder="请输入"
                            class="el-form-input-width"
                            @input="() => (bo.telNum = bo.telNum.trim())">
                    </el-input>
                </el-form-item>
                <el-form-item label="电子邮箱：" prop="email">
                    <el-input
                            suffix-icon="none"
                            v-model="bo.email"
                            placeholder="请输入"
                            class="el-form-input-width"
                            @input="() => (bo.email = bo.email.trim())">
                    </el-input>
                </el-form-item>
                <el-form-item label="邮政编码：" prop="postCode">
                    <el-input
                            suffix-icon="none"
                            v-model="bo.postCode"
                            placeholder="请输入"
                            class="el-form-input-width"
                            @input="() => (bo.postCode = bo.postCode.trim())">
                    </el-input>
                </el-form-item>
                <el-form-item label="微信：" prop="wechart">
                    <el-input
                            suffix-icon="none"
                            v-model="bo.wechart"
                            placeholder="请输入"
                            class="el-form-input-width"
                            @input="() => (bo.wechart = bo.wechart.trim())">
                    </el-input>
                </el-form-item>
                <el-form-item label="QQ：" prop="qqNum">
                    <el-input
                            suffix-icon="none"
                            v-model="bo.qqNum"
                            placeholder="请输入"
                            class="el-form-input-width"
                            @input="() => (bo.qqNum = bo.qqNum.trim())">
                    </el-input>
                </el-form-item>
                <el-form-item label="教师类型：" prop="teacherType">
                    <el-select v-model="bo.teacherType" placeholder="请选择">
                        <el-option
                                v-for="item in teacherTypeOpt"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="工作单位：" prop="jobCompany">
                    <el-input
                            suffix-icon="none"
                            v-model="bo.jobCompany"
                            placeholder="请输入"
                            class="el-form-input-width"
                            @input="() => (bo.jobCompany = bo.jobCompany.trim())">
                    </el-input>
                </el-form-item>
                <el-form-item label="联系地址：" prop="contactAddress">
                    <el-input
                        style="width: 205px;"
                            suffix-icon="none"
                            type="textarea"
                            :rows="2"
                            placeholder="请输入内容"
                            v-model="bo.contactAddress">
                    </el-input>
                </el-form-item>
                <el-form-item label="备注：" prop="remark">
                    <el-input
                        style="width: 205px;"
                            suffix-icon="none"
                            type="textarea"
                            :rows="2"
                            placeholder="请输入内容"
                            v-model="bo.remark">
                    </el-input>
                </el-form-item>
              <el-form-item prop="siteId" label="函授站" label-width="100px" v-if="accountType === '4'">
                <el-select
                    style="width: 300px"
                    clearable
                    v-model="bo.siteId"
                    placeholder="请选择"
                    class="el-form-input-width"
                >
                  <el-option
                      v-for="item in site"
                      :key="item.id"
                      :label="item.siteName"
                      :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button size="small" @click="dialogVisible = false">取 消</el-button>
                <el-button size="small" type="primary" @click="handleSave('ruleForm')">
                    确 定
                </el-button>
            </div>
        </el-dialog>
        <!-- 新增 -->
        <!-- 编辑 -->
        <el-dialog
                title="新增"
                :visible.sync="dialogVisibles"
                width="50%"
                style="text-align: left">
            <p style="color:red;font-size:14px;margin-bottom:1rem">（提示：教师工号将作为教师登录帐号,身份证号后6位将作为老师的初始密码）</p>
            <el-form
                    size="small"
                    :model="bo"
                    :rules="boRules"
                    ref="ruleForm"
                    inline
                    label-position="left"
                    class="inlineForm"
                    label-width="100px">
              <el-form-item label="上传照片：">
                <el-upload
                    ref="uploadphoto"
                    class="upload-demo"
                    :on-success="onSuccess"
                    :headers="myHeaders"
                    :action="this.TEACHERADD">
                  <el-button size="small" type="primary">点击上传</el-button><span style="color:red;font-size:14px;margin-left:1rem">支持格式jpg/png</span>
                </el-upload>
                <!--              <label>-->
                <!--                <input type="file" id="file" style="display:none;"/>-->
                <!--                <div class="portrait_Sprite">-->
                <!--                  <span>上传照片</span>-->
                <!--                </div>-->
                <!--              </label>-->
              </el-form-item>
              <el-form-item>
                <img class="portrait_image"  :src="bo.headerUrl" style="width:40%" alt/>
              </el-form-item>
                <el-form-item label="工号：" prop="jobNo">
                    <el-input
                            suffix-icon="none"
                            v-model="bo.jobNo"
                            placeholder="请输入"
                            class="el-form-input-width"
                            @input="() => (bo.jobNo = bo.jobNo.trim())">
                    </el-input>
                </el-form-item>
                <el-form-item label="姓名：" prop="name">
                    <el-input
                            suffix-icon="none"
                            style="width: 100%"
                            v-model="bo.name"
                            placeholder="请输入"
                            class="el-form-input-width"
                            @input="() => (bo.name = bo.name.trim())">
                    </el-input>
                </el-form-item>
                <el-form-item label="性别：" prop="sex">
                    <el-select v-model="bo.sex" placeholder="请选择" style="width: 100% !important">
                        <el-option
                                v-for="item in sexOpt"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="身份证号：" prop="idCard">
                    <el-input
                            suffix-icon="none"
                            v-model="bo.idCard"
                            placeholder="请输入"
                            class="el-form-input-width"
                            @input="() => (bo.idCard = bo.idCard.trim())">
                    </el-input>
                </el-form-item>
                <el-form-item label="专业方向：" prop="profDir">
                    <el-input
                            suffix-icon="none"
                            v-model="bo.profDir"
                            placeholder="请输入"
                            class="el-form-input-width"
                            @input="() => (bo.profDir = bo.profDir.trim())">
                    </el-input>
                </el-form-item>
                <el-form-item label="职称：" prop="jodTitle">
                    <el-input
                            suffix-icon="none"
                            v-model="bo.jodTitle"
                            placeholder="请输入"
                            class="el-form-input-width"
                            @input="() => (bo.jodTitle = bo.jodTitle.trim())">
                    </el-input>
                </el-form-item>
                <el-form-item label="学历：" prop="education">
                    <el-input
                            suffix-icon="none"
                            v-model="bo.education"
                            placeholder="请输入"
                            class="el-form-input-width"
                            @input="() => (bo.education = bo.education.trim())">
                    </el-input>
                </el-form-item>
                <el-form-item label="在任：" prop="online">
                    <el-select v-model="bo.online" placeholder="请选择">
                        <el-option
                                v-for="item in onlineOpt"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
<!--                <el-form-item label="列表选择：" prop="online" v-if="bo.id ==null">-->
<!--                    <el-select v-model="bo.IdentityStatus" placeholder="请选择">-->
<!--                        <el-option-->
<!--                                v-for="item in IdentityStatusType"-->
<!--                                :key="item.value"-->
<!--                                :label="item.label"-->
<!--                                :value="item.value"-->
<!--                        >-->
<!--                        </el-option>-->
<!--                    </el-select>-->
<!--                </el-form-item>-->
                <el-form-item label="身份类型：" prop="roleType">
                    <el-select v-model="bo.roleType" placeholder="请选择">
                        <el-option
                                v-for="item in roleTypeOpt"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="移动电话：" prop="phoneNum">
                    <el-input
                            suffix-icon="none"
                            v-model="bo.phoneNum"
                            placeholder="请输入"
                            class="el-form-input-width"
                            @input="() => (bo.phoneNum = bo.phoneNum.trim())">
                    </el-input>
                </el-form-item>
                <el-form-item label="固定电话：" prop="telNum">
                    <el-input
                            suffix-icon="none"
                            v-model="bo.telNum"
                            placeholder="请输入"
                            class="el-form-input-width"
                            @input="() => (bo.telNum = bo.telNum.trim())">
                    </el-input>
                </el-form-item>
                <el-form-item label="电子邮箱：" prop="email">
                    <el-input
                            suffix-icon="none"
                            v-model="bo.email"
                            placeholder="请输入"
                            class="el-form-input-width"
                            @input="() => (bo.email = bo.email.trim())">
                    </el-input>
                </el-form-item>
                <el-form-item label="邮政编码：" prop="postCode">
                    <el-input
                            suffix-icon="none"
                            v-model="bo.postCode"
                            placeholder="请输入"
                            class="el-form-input-width"
                            @input="() => (bo.postCode = bo.postCode.trim())">
                    </el-input>
                </el-form-item>
                <el-form-item label="微信：" prop="wechart">
                    <el-input
                            suffix-icon="none"
                            v-model="bo.wechart"
                            placeholder="请输入"
                            class="el-form-input-width"
                            @input="() => (bo.wechart = bo.wechart.trim())">
                    </el-input>
                </el-form-item>
                <el-form-item label="QQ：" prop="qqNum">
                    <el-input
                            suffix-icon="none"
                            v-model="bo.qqNum"
                            placeholder="请输入"
                            class="el-form-input-width"
                            @input="() => (bo.qqNum = bo.qqNum.trim())">
                    </el-input>
                </el-form-item>
                <el-form-item label="教师类型：" prop="teacherType">
                    <el-select v-model="bo.teacherType" placeholder="请选择">
                        <el-option
                                v-for="item in teacherTypeOpt"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="工作单位：" prop="jobCompany">
                    <el-input
                            suffix-icon="none"
                            v-model="bo.jobCompany"
                            placeholder="请输入"
                            class="el-form-input-width"
                            @input="() => (bo.jobCompany = bo.jobCompany.trim())">
                    </el-input>
                </el-form-item>
                <el-form-item label="联系地址：" prop="contactAddress">
                    <el-input
                        style="width: 205px;"
                            suffix-icon="none"
                            type="textarea"
                            :rows="2"
                            placeholder="请输入内容"
                            v-model="bo.contactAddress">
                    </el-input>
                </el-form-item>
                <el-form-item label="备注：" prop="remark">
                    <el-input
                        style="width: 205px;"
                            suffix-icon="none"
                            type="textarea"
                            :rows="2"
                            placeholder="请输入内容"
                            v-model="bo.remark">
                    </el-input>
                </el-form-item>
              <el-form-item prop="siteId" label="函授站" label-width="100px" v-if="accountType === '4'">
                <el-select
                    style="width: 300px"
                    clearable
                    v-model="bo.siteId"
                    placeholder="请选择"
                    class="el-form-input-width"
                >
                  <el-option
                      v-for="item in site"
                      :key="item.id"
                      :label="item.siteName"
                      :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button size="small" @click="dialogVisibles = false">取 消</el-button>
                <el-button size="small" type="primary" @click="handleSaves('ruleForm')">
                    确 定
                </el-button>
            </div>
        </el-dialog>


        <!-- 导入 上传文件 -->
        <el-dialog title="上传文件" :visible.sync="dialogVisibleUpload" width="30%">
            <div style="margin-bottom: 20px">
                <el-button type="primary" size="small" @click="exportMB">下载模板</el-button>
                <span style="color: red"
                >提示：导入前请先下载模板，根据模版整理好数据后上传导入.</span
                >
            </div>
            <el-upload
                    :headers="myHeaders"
                    class="upload-demo"
                    drag
                    action="#"
                    :show-file-list="false"
                    :http-request="uploadFile"
                    :before-upload="beforeUpload">
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                <div class="el-upload__tip" slot="tip">
                    只能上传excel文件，且不超过50M
                </div>
            </el-upload>
        </el-dialog>
    </div>
</template>
<script>
    export default {
        data() {
            return {
              site: [],
              accountType: localStorage.getItem('accountType'),
                IdentityStatusType: [
                    {
                        value: 1,
                        label: "管理员列表",
                    }, {
                        value: 2,
                        label: "总部教师列表",
                    }, {
                        value: 3,
                        label: "函授站教师列表",
                    },
                ],
                // fileList: [],
                queryBo: {
                    page: 1,
                    pageSize: 10,
                    status: 2,
                    online:"",
                    IdentityStatus: 2,
                    info: "",
                },
                myHeaders: {
                    'x-token': localStorage.loginStrage ? JSON.parse(localStorage.getItem('loginStrage'))['x-token'] : null,
                    'x-perm': localStorage.loginStrage ? JSON.parse(localStorage.getItem('loginStrage'))['x-perm'] : null
                },
                teacherTypeOpt: [
                    {
                        value: 0,
                        label: "辅导教师",
                    }, {
                        value: 1,
                        label: "主讲教师",
                    },
                ],
                roleTypeOpt: [
                    {
                        value: 0,
                        label: "教师",
                    },
                    {
                        value: 1,
                        label: "管理员",
                    }, {
                        value: 2,
                        label: "教师管理员",
                    },
                ],
                onlineOpt: [
                  {
                    value: "",
                    label: "全部",
                  },
                    {
                        value: 0,
                        label: "是",
                    },
                    {
                        value: 1,
                        label: "否",
                    }
                ],
                IdentityStatus: [
                  {
                    value: "",
                    label: "全部",
                  },
                    {
                        value: 2,
                        label: "总部教师",
                    },
                    {
                        value: 3,
                        label: "函授站点教师",
                    }
                ],
                sexOpt: [
                    {
                        value: 0,
                        label: "男",
                    },
                    {
                        value: 1,
                        label: "女",
                    },
                ],
                bo: {
                  // siteId: null,
                    id: null,
                    jobNo: null,//工号
                    name: null,//姓名
                    sex: 0,//性别 0男 1女
                    idCard: null,//身份证号
                    profDir: null,//专业方向
                    jodTitle: null,//职称
                    education: null,//学历
                    online: 0,//是否在任 0否 1是
                    roleType: 0,//身份类型 0教师 1管理员 2教师管理员
                    phoneNum: null,//电话
                    telNum: null,//固定电话
                    email: null,//邮箱
                    postCode: null,//邮政编码
                    teacherType: 0,//教师类型 0辅导教师 1主讲教师 2批阅教师
                    wechart: null,//微信
                    qqNum: null,//QQ
                    jobCompany: null,//工作地点
                    contactAddress: null,//地址
                    remark: null,//备注
                    headerUrl: null,//头像url
                    status: 0,//是否是指导教师 0是 1否（新增，在毕业大作业指导教师列表体现）
                    IdentityStatus: 2,//1 管理员列表 2总部教师列表 3函授站教师列表
                },
                boRules: {
                    jobNo: [{required: true, message: "请输入工号", trigger: "blur"}],
                    name: [{required: true, message: "请输入姓名", trigger: "blur"}],
                    sex: [{required: true, message: "请选择性别", trigger: "blur"}],
                    profDir: [{required: true, message: "请输入专业方向", trigger: "blur"}],
                    jodTitle: [{required: true, message: "请输入职称", trigger: "blur"}],
                    education: [{required: true, message: "请输入学历", trigger: "blur"}],
                    online: [{required: true, message: "请选择是否在任", trigger: "blur"}],
                    roleType: [{required: true, message: "请选择身份类型", trigger: "blur"}],
                    telNum: [{required: true, message: "请输入固定电话", trigger: "blur"}],
                    postCode: [{required: true, message: "请输入邮政编码", trigger: "blur"}],
                    teacherType: [{required: true, message: "请选择教师类型", trigger: "blur"}],
                    wechart: [{required: true, message: "请输入微信", trigger: "blur"}],
                    qqNum: [{required: true, message: "请输入QQ", trigger: "blur"}],
                    jobCompany: [{required: true, message: "请输入工作地点", trigger: "blur"}],
                    contactAddress: [{required: true, message: "请输入地址", trigger: "blur"}],
                    status: [{required: true, message: "请选择是否是指导老师", trigger: "blur"}],
                    IdentityStatus: [{required: true, message: "请选择列表类型", trigger: "blur"}],
                    phoneNum: [
                        {required: true, message: "请输入手机号码", trigger: "blur"},
                        {pattern: /^1[3456789]\d{9}$/, message: "手机号格式不正确"},
                    ],
                    idCard: [{required: true, message: "请输入身份证号码", trigger: "blur"}, {
                        pattern: /^([1-6][1-9]|50)\d{4}(18|19|20)\d{2}((0[1-9])|10|11|12)(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/,
                        message: "身份证号格式不正确",
                    },
                    ],
                    email: [
                        {required: false, message: "请输入邮箱", trigger: "blur"},
                        {
                            pattern: /^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$/,
                            message: "邮箱格式不正确",
                        },
                    ],
                },
                total: 0,
                pageNum: 1,
                pageSize: 10,
                currentPage4: 1,
                textarea: "",
                search: {
                    name: "",
                },
                teacherForm: {
                    name: "",
                },
                tableChecked: [],
                tableData: [],
                correspondType: [
                    {
                        value: 0,
                        label: "全部",
                    },
                    {
                        value: 1,
                        label: "函授站",
                    },
                    {
                        value: 2,
                        label: "学习中心",
                    },
                    {
                        value: 3,
                        label: "函授站及学习中心",
                    },
                ],
                dialogVisible: false,
                dialogVisibles: false,
                dialogVisible1: false,
                dialogVisibleUpload: false,


                value: "",
            };
        },
        methods: {
            uploadFile(file) {
                let fileDate = file.file;
                let forms = new FormData();
                forms.append("file", fileDate);
                forms.append("tearcherAttr", 0);
                this.openLoadingView();
                this.$axios_supermall({
                  url: "/hnjxjy-core/eduTeacher/importDataTeacher",
                  data: forms,
                  method: 'post',
                  responseType: 'blob'
                }).then((res) => {
                  if (res.data.type === "text/xml") {
                    this.queryListVo();
                    this.$message.success("操作成功");
                  } else if (res.data.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
                    this.$message.error("操作失败");
                    let blob = new Blob([res.data], {
                      type: "application/vnd.ms-excel",
                    });
                    let downloadElement = document.createElement('a');
                    let href = window.URL.createObjectURL(blob);
                    downloadElement.href = href;
                    downloadElement.download = '问题数据.xlsx';　　　　　　　　　　// xxx.xls/xxx.xlsx
                    document.body.appendChild(downloadElement);
                    downloadElement.click();
                    document.body.removeChild(downloadElement);
                    window.URL.revokeObjectURL(href);
                    // let blob = new Blob([res.data], {
                    //     type: "application/vnd.ms-excel",
                    // });
                    // let url = window.URL.createObjectURL(blob);
                    // window.location.href = url;
                    // let url = window.URL.createObjectURL(blob);
                    // window.location.href = url;
                    }
                    this.loadingView.close();
                    this.dialogVisibleUpload = false;
                }).catch((err) => {
                  this.$message.error("操作失败");
                  this.loadingView.close();
                });
            },
            exportMB() {
              this.openLoadingView();
              this.$axios_supermallDwon.get("/hnjxjy-core/eduTeacher/exportMB").then(resp => {
                // 文件导出
                if (!resp.data) {
                  return
                }
                let url = window.URL.createObjectURL(new Blob([resp.data]));
                let link = document.createElement('a');
                link.style.display = 'none';
                link.href = url;
                link.setAttribute('download', '教师列表模板.xlsx');
                document.body.appendChild(link);
                link.click()
                this.$message.success("操作成功")
                this.loadingView.close();
              }).catch((err) => {
                this.$message.error("操作失败")
                this.loadingView.close();
              });
            },
            queryListVo() {
                this.openLoadingView();
                this.$axios_supermall.get("/hnjxjy-core/eduTeacher/listVo?page=" + this.queryBo.page + "&pageSize=" + this.queryBo.pageSize + "&name=" + this.queryBo.info + "&online=" + this.queryBo.online + "&IdentityStatus=" + this.queryBo.IdentityStatus).then(resp => {
                    if (resp.data.code == 2000) {
                        let reqData = resp.data.data;
                        this.tableData = reqData.list;
                        this.total = reqData.total;
                    }
                });
            },
            //文件上传成功时
            onSuccess(response, file, fileList) {
                this.bo.headerUrl = response.data.fileUrl
            },
            handleSave(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        let formData = new FormData();
                        formData.append('id', this.bo.id);
                        formData.append('contactAddress', this.bo.contactAddress);
                        formData.append('education', this.bo.education);
                        formData.append('email', this.bo.email);
                        formData.append('headerUrl', this.bo.headerUrl);
                        formData.append('idCard', this.bo.idCard);
                        // formData.append('IdentityStatus', this.bo.IdentityStatus);
                        formData.append('jobCompany', this.bo.jobCompany);
                        formData.append('jobNo', this.bo.jobNo);
                        formData.append('jodTitle', this.bo.jodTitle);
                        formData.append('name', this.bo.name);
                        formData.append('online', this.bo.online);
                        formData.append('phoneNum', this.bo.phoneNum);
                        formData.append('postCode', this.bo.postCode);
                        formData.append('profDir', this.bo.profDir);
                        formData.append('qqNum', this.bo.qqNum);
                        formData.append('remark', this.bo.remark);
                        formData.append('roleType', this.bo.roleType);
                        formData.append('sex', this.bo.sex);
                        formData.append('wechart', this.bo.wechart);
                        formData.append('teacherType', this.bo.teacherType);
                        formData.append('telNum', this.bo.telNum);
                      if (this.accountType === "4"){
                        formData.append('siteId', this.bo.siteId);
                      }
                        if (this.bo.id) {
                            this.$axios_supermallData.put("/hnjxjy-core/eduTeacher/edit", formData).then(resp => {
                                if (resp.data.code == 2000) {
                                    this.$message.success("编辑成功");
                                    this.queryListVo()
                                } else {
                                    // this.$message.error(resp.data.data)
                                }
                            })
                        }
                        this.$refs[formName].resetFields();
                        this.dialogVisible = false;
                    } else {
                        return false;
                    }
                });
            },

            handleSaves(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        let formData = new FormData();
                        formData.append('contactAddress', this.bo.contactAddress);
                        formData.append('education', this.bo.education);
                        formData.append('email', this.bo.email);
                        formData.append('headerUrl', this.bo.headerUrl);
                        formData.append('idCard', this.bo.idCard);
                        // formData.append('IdentityStatus', this.bo.IdentityStatus);
                        formData.append('jobCompany', this.bo.jobCompany);
                        formData.append('jobNo', this.bo.jobNo);
                        formData.append('jodTitle', this.bo.jodTitle);
                        formData.append('name', this.bo.name);
                        formData.append('online', this.bo.online);
                        formData.append('phoneNum', this.bo.phoneNum);
                        formData.append('postCode', this.bo.postCode);
                        formData.append('profDir', this.bo.profDir);
                        formData.append('qqNum', this.bo.qqNum);
                        formData.append('remark', this.bo.remark);
                        formData.append('roleType', this.bo.roleType);
                        formData.append('sex', this.bo.sex);
                        formData.append('wechart', this.bo.wechart);
                        formData.append('teacherType', this.bo.teacherType);
                        formData.append('telNum', this.bo.telNum);
                        if (this.accountType === "4"){
                          formData.append('siteId', this.bo.siteId);
                        }
                        this.openLoadingView();
                        this.$axios_supermall.post("/hnjxjy-core/eduTeacher/add", this.bo).then(resp => {
                            if (resp.data.code === 2000) {
                                this.$message.success("添加成功");
                                this.queryListVo();
                                this.dialogVisibles = false
                            } else {
                                // this.$message.error(resp.data.data)
                            }
                        })
                        this.$refs[formName].resetFields();
                        this.dialogVisible = false;
                    } else {
                        return false;
                    }
                });
            },

            handleSearch() {
            },
            addcreate() {
                this.bo = {
                    jobNo: null,//工号
                    name: null,//姓名
                    sex: 0,//性别 0男 1女
                    idCard: null,//身份证号
                    profDir: null,//专业方向
                    jodTitle: null,//职称
                    education: null,//学历
                    online: 0,//是否在任 0否 1是
                    roleType: 0,//身份类型 0教师 1管理员 2教师管理员
                    phoneNum: null,//电话
                    telNum: null,//固定电话
                    email: null,//邮箱
                    postCode: null,//邮政编码
                    teacherType: 0,//教师类型 0辅导教师 1主讲教师 2批阅教师
                    wechart: null,//微信
                    qqNum: null,//QQ
                    jobCompany: null,//工作地点
                    contactAddress: null,//地址
                    remark: null,//备注
                    headerUrl: null,//头像url
                    status: 0,//是否是指导教师 0是 1否（新增，在毕业大作业指导教师列表体现）
                    IdentityStatus: 2,//1 管理员列表 2总部教师列表 3函授站教师列表
                };
                this.dialogVisibles = true;
              this.$nextTick(() => {
                this.$refs['uploadphoto'].clearFiles() // 清除历史文件列表
              })

            },
            // 批量删除
            batchDelete(row, type) {
                if (type == 1) {
                    this.$confirm(`您确定要删除名称为"${row.name}"的数据吗?`, "提示", {
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                        type: "warning",
                    }).then(() => {
                        this.$axios_supermall.get("/hnjxjy-core/eduTeacher/remove?ids=" + row.id).then(resp => {
                            if (resp.data.code == 2000) {
                                this.$message({
                                    type: "success",
                                    message: "删除成功!",
                                });
                                this.queryListVo();
                            }
                        })
                    }).catch(() => {
                        this.$message({
                            type: "info",
                            message: "已取消删除",
                        });
                    });

                } else if (type == 2) {
                    if (this.tableChecked == "") {
                        this.$message.error("请至少选择一项记录");
                    } else {
                        this.$confirm(`您确定要删除${row.length}数据吗?`, "提示", {
                            confirmButtonText: "确定",
                            cancelButtonText: "取消",
                            type: "warning",
                        }).then(() => {
                            let ids = "";
                            this.tableChecked.forEach(item => {
                                ids = ids + item.id + ",";
                            });
                            ids = ids.substr(0, ids.length - 1).trim();

                            this.$axios_supermall.get("/hnjxjy-core/eduTeacher/remove?ids=" + ids).then(resp => {
                                if (resp.data.code == 2000) {
                                    this.$message({
                                        type: "success",
                                        message: "删除成功!",
                                    });
                                    this.queryListVo();
                                }
                            })
                        })
                            .catch(() => {
                                this.$message({
                                    type: "info",
                                    message: "已取消删除",
                                });
                            });
                    }
                }


            },
            // 点击导入
            upload() {
                this.dialogVisibleUpload = true;
            },
            // 导入上传文
            // 导入上传文件前判断文件格式
            beforeUpload(file) {

                const isXls = file.type === "application/vnd.ms-excel";
                const isXlsx =
                    file.type ===
                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
                if (!isXls && !isXlsx) {
                    this.$message.error("上传文件只能是XLS/XLSX格式！");
                    return false;
                }
                return isXls || isXlsx;
            },
            // 点击导出
            download() {
              this.openLoadingView();
              this.$axios_supermallDwon.get("/hnjxjy-core/eduTeacher/export?status=2").then(resp => {
                let blob = new Blob([resp.data], {
                  type: "application/vnd.ms-excel",
                });
                let url = window.URL.createObjectURL(blob);
                window.location.href = url;
                this.$message.success("操作成功");
                this.loadingView.close();
                // // 文件导出
                // if (!resp.data) {
                //   return
                // }
                // let url = window.URL.createObjectURL(new Blob([resp.data]));
                // let link = document.createElement('a');
                // link.href = url;
                // link.setAttribute('download', '总部教师列表.xlsx');
                // document.body.appendChild(link);
                // link.click()
              }).catch((err) => {
                this.$message.error("操作失败");
                this.loadingView.close();
              });
            },
            handleSelectionChange(val) {
                this.tableChecked = val;
            },
            // 点击修改
            editor(row) {
                this.fileList = []
                this.$axios_supermall.get("/hnjxjy-core/eduTeacher/getOne?id=" + row.id).then(resp => {
                    if (resp.data.code == 2000) {
                        this.bo = resp.data.data;
                        // if(this.bo.headerUrl!=null&&this.bo.headerUrl!=""&&this.bo.headerUrl!="null"){
                        //   this.fileList.push({name:this.bo.headerUrl,url:this.bo.headerUrl})
                        // }
                    }
                });
                this.dialogVisible = true;
                this.$nextTick(() => {
                    this.$refs['uploadss'].clearFiles() // 清除历史文件列表
                })
            },
            handleSizeChange(val) {
                this.queryBo.pageSize = val;
                this.queryListVo();
            },
            handleCurrentChange(val) {
                this.queryBo.page = val;
                this.queryListVo();
            },
            // 点击删除
            deleteCorrespond() {
                this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                })
                    .then(() => {
                        this.$message({
                            type: "success",
                            message: "删除成功!",
                        });
                    })
                    .catch(() => {
                        this.$message({
                            type: "info",
                            message: "已取消删除",
                        });
                    });
            },
          //查询函授站下拉
          queryEduSite() {
            let formData = new FormData();
            formData.append("isAll","1");
            this.$axios_supermall.post("/hnjxjy-core/eduSite/queryAllSite", formData).then(resp => {
              if (resp.data.code === 2000) {
                this.site = resp.data.data;
              }
            })
          },
        },
        mounted() {
            this.queryListVo();
            this.queryEduSite();
        }
    };
</script>
<style scoped>

    .table-wrap {
        overflow: auto;
        /* margin-left: 1rem; */
        /* margin-bottom: 1rem; */
        padding: 1rem;
    }

    .demo-ruleForm {
        /* width: 90%; */
        height: calc(100% - 125px);
    }

    .el-form-input-width {
        width: 100%;
    }

    /deep/ .el-select {

        width: 100% !important;
    }
</style>
