<template>
  <div>
    <div >
      <div
        style="padding: 1rem; border-bottom: 1px solid #ddd; text-align: left"
      >
        站点设置
      </div>
      <div class="search">
        <el-form class="demo-ruleForm">
          <template slot-scope="scope">
            <el-form-item>
              <el-button type="primary" style="margin-bottom: 10px" @click="selection(scope.row)" v-permission="'eduSite:updatePermitEnrol'"
                >允许招生</el-button
              >
              <el-button type="primary" @click="selectiones(scope.row)" v-permission="'eduSite:updatePermitEnrol'"
                >撤销招生</el-button
              >
            </el-form-item>
          </template>
        </el-form>
        <el-form :model="search" class="demo-ruleForm" inline>
          <el-form-item style="margin-bottom: 0">
            <el-input
              v-model="search.name"
              clearable
              @input="() => (search.name = search.name.trim())"
              @clear="getList"
              placeholder="名称/编号"
              style="width: 300px"
            ></el-input>
            <el-button icon="el-icon-search" @click="getList"></el-button>
          </el-form-item>
        </el-form>
      </div>
      <template>
        <div class="table-wrap">
          <el-table
            border
            ref="multipleTable"
            :data="setList"
            @selection-change="handleSelectionChange"
            size="medium"
            height="600px"
            max-height="600px"
            :header-cell-style="{'text-align':'center'}"
            :cell-style="{'text-align':'center'}"
          >
            <el-table-column type="selection" width="55"> </el-table-column>
            <el-table-column  label="序号" width="140" type="index">
            </el-table-column>
            <el-table-column  label="教学函授站" prop="siteName" sortable>
            </el-table-column>
            <el-table-column  label="函授站编号" prop="siteCode" sortable>
            </el-table-column>
            <el-table-column label="允许招生" prop="permitEnrol" sortable>
              <template slot-scope="scope">
                <span>{{scope.row.permitEnrol?"允许":"不允许"}}</span>
              </template>
            </el-table-column>
          </el-table>

          <div style="padding: 1rem">
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="currentPage"
              :page-sizes="[10, 20, 50, 100]"
              :page-size="pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="total"
            >
            </el-pagination>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      // 储存允许招生的数据
      multipleTable: [],
      total: 0,
      currentPage: 1,
      pageSize: 10,
      currentPage4: 4,
      currentPage2: 4,
      currentPage1: 4,
      textarea: "",
      setList: [],
      permitEnrol: "",
      status: "",
      ids: 0,
      pageNo: 1,
      search: {
        name: "",
      },
      teacherForm: {
        name: "",
      },
      tableChecked: [],

      correspondType: [
        {
          value: 0,
          label: "全部",
        },
        {
          value: 1,
          label: "函授站",
        },
        {
          value: 2,
          label: "学习中心",
        },
        {
          value: 3,
          label: "函授站及学习中心",
        },
      ],
      teacherRules: {
        name: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        fullName: [
          { required: true, message: "请输入真实姓名", trigger: "blur" },
        ],
        sex: [{ required: true, message: "请选择性别", trigger: "blur" }],
        phone: [
          { required: true, message: "请输入手机号码", trigger: "blur" },
          { pattern: /^1[3456789]\d{9}$/, message: "手机号格式不正确" },
        ],
        email: [
          { required: false, message: "请输入邮箱", trigger: "blur" },
          {
            pattern: /^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$/,
            message: "邮箱格式不正确",
          },
        ],
        idCard: [
          { required: false, message: "请输入身份证号码", trigger: "blur" },
          {
            pattern: /^([1-6][1-9]|50)\d{4}(18|19|20)\d{2}((0[1-9])|10|11|12)(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/,
            message: "身份证号格式不正确",
          },
        ],
      },
      options: [
        {
          value: "选项1",
          label: "黄金糕",
        },
        {
          value: "选项2",
          label: "双皮奶",
        },
        {
          value: "选项3",
          label: "蚵仔煎",
        },
        {
          value: "选项4",
          label: "龙须面",
        },
        {
          value: "选项5",
          label: "北京烤鸭",
        },
      ],
      value: "",
      pickerOptions: {
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              picker.$emit("pick", new Date());
            },
          },
          {
            text: "昨天",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24);
              picker.$emit("pick", date);
            },
          },
          {
            text: "一周前",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", date);
            },
          },
        ],
      },
      value1: "",
      outerVisible: false,
      innerVisible: false,
    };
  },
  created() {
    this.getList();
  },
  methods: {
    // 获取后台数据
    getList() {

      this.getPermission('eduSite:queryAllSite', () => {
        this.openLoadingView()

        let fromData = new FormData();
        fromData.append("page",this.currentPage)
        fromData.append("pageSize",this.pageSize)
        fromData.append("info",this.search.name)
        this.$axios_supermallData.post("hnjxjy-core/eduSite/queryAllSite",fromData)
            .then((res) => {
              if (res.data.code !== 2000) {
                this.loadingView.close()
                return;
              }
              this.setList = res.data.data.list;
              this.total = res.data.data.total;
              this.loadingView.close()
            }).catch(err => {
              this.$message.error(err)
          this.loadingView.close()
        })
      });
      },
    //获取表格中的相关信息
    selection() {
      let array = [];
      for (let i = 0; i < this.multipleTable.length; i++) {
        array.push(JSON.stringify(this.multipleTable[i].id));
      }
      let arr = array.toString();
      if (arr.length == 0){
        this.$message.error("请至少选择1条数据进行操作!");
      }else {
        this.$confirm("你将对(" + array.length + ")条数据进行操作!", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          this.$axios({
            url: "hnjxjy-core/eduSite/updatePermitEnrol",
            headers: this.loginList,
            method: "POST",
            params: {
              ids: arr,
              status: 1,
            },
          }).then((res) => {
            if (res.data.code == 2000) {
              this.$message({
                type: "success",
                message: "操作成功!",
              });
            }
            this.getList()
          });
          this.getList()
              .catch(() => {
                this.$message({
                  type: "info",
                  message: "操作失败",
                });
              });
        });
      }
    },
    selectiones() {
      let array = [];
      for (let i = 0; i < this.multipleTable.length; i++) {
        array.push(JSON.stringify(this.multipleTable[i].id));
      }
      let arr = array.toString();
      if (arr.length == 0){
        this.$message.error("请至少选择1条数据进行操作!");
      }else {
        this.$confirm("你将对(" + array.length + ")条数据进行操作!", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          this.$axios({
            url: "hnjxjy-core/eduSite/updatePermitEnrol",
            headers: this.loginList,
            method: "POST",
            params: {
              ids: arr,
              status: 0,
            },
          }).then((res) => {
            if (res.data.code == 2000) {
              this.$message({
                type: "success",
                message: "操作成功!",
              });
            }
            this.getList()
          });
          this.getList()
              .catch(() => {
                this.$message({
                  type: "info",
                  message: "操作失败",
                });
              });
        });
      }
    },

    handleSearch() {},
    handleSave() {},
    // 点击导出
    download() {},
    handleSelectionChange(val) {
      this.multipleTable = val;
    },
    // 点击修改
    editor(row) {
      this.dialogVisible = true;
    },
    //查看专业
    major() {
      this.outerVisible = true;
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getList();
    },
    handleSizeChange1(val) {
    },
    handleCurrentChange1(val) {
    },
    handleSizeChange2(val) {
    },
    handleCurrentChange2(val) {
    },
  },
};
</script>
<style scoped>
.search {
  text-align: left;
  margin-top: 1rem;
  /* margin-left: 1rem; */
  padding: 1rem;
}
.table-wrap {
  overflow: auto;
  /* margin-left: 1rem; */
  /* margin-bottom: 1rem; */
  padding: 1rem;
}
.demo-ruleForm {
  /* width: 90%; */
  height: calc(100% - 125px);
}
.el-form-input-width {
  width: 100%;
}
.rigth {
  width: 93%;
  display: inline-block;
  height: 870px;
  overflow-y: scroll;
  /* background-color: #fff; */
  background-color: rgba(164, 174, 224, 0.11);
  float: right;
}
.left {
  width: 7%;
  display: inline-block;
}
.portrait_Sprite {
  padding: 0.2rem 1rem;
  display: inline-block;
  background-color: skyblue;
  color: #fff;
  border-radius: 10%;
  font-size: 13px;
}
</style>
