<template>
    <div>
        <div>
            <div style="padding: 1rem; border-bottom: 1px solid #ddd; text-align: left">
                年级列表
            </div>
            <div class="search">
                <el-form :model="search" class="demo-ruleForm" inline>
                    <el-form-item style="margin-bottom: 0">
                        <el-input
                                v-permission="'eduGrade:queryAllEduGradeByCodeAndName'"
                                v-model="search.name"
                                clearable
                                @input="() => (search.name = search.name.trim())"
                                placeholder="年级名称/年级编号"
                                style="width: 200px">
                            <i slot="prefix" class="el-input__icon el-icon-search"></i>
                        </el-input>
                    </el-form-item>
                    <el-form-item style="margin-bottom: 0">
                        <el-button
                                v-permission="'eduGrade:queryAllEduGradeByCodeAndName'"
                                icon="el-icon-search"
                                @click="handleSearch"
                                type="primary"
                                plain>
                            搜索
                        </el-button>
                        <el-button icon="el-icon-edit" type="primary" @click="addGrade" v-permission="'eduGrade:addEduGradeBySiteId'">
                            添加
                        </el-button>
                        <el-button icon="el-icon-upload2" type="warning" @click="upload" v-permission="'eduGrade:gradeImportExcel'">
                            导入
                        </el-button>
                        <el-button icon="el-icon-download" type="primary" @click="download" v-permission="'eduGrade:exportGradeExcel'">
                            导出
                        </el-button>
                    </el-form-item>
                </el-form>
            </div>
            <div class="table-wrap">
                <el-table ref="multipleTable" :data="tableData" border
                          size="medium"
                          height="650px"
                          max-height="650px"
                          :header-cell-style="{'text-align':'center'}"
                          :cell-style="{'text-align':'center'}"
                >
                    <el-table-column label="序号" type="index" width="80" >
                    </el-table-column>
                    <el-table-column label="年级名称" prop="gradeName" sortable >
                    </el-table-column>
                    <el-table-column label="年级编号" prop="gradeCode" sortable >
                    </el-table-column>
                    <el-table-column label="开学时间" prop="startTime" sortable >
                    </el-table-column>
                    <el-table-column label="操作" width="180" fixed="right">
                        <template slot-scope="scope">
                            <el-button @click="editor(scope.row)" type="primary" size="small" v-permission="'eduGrade:showModifyGradeById'">
                                编辑
                            </el-button>
                            <el-button
                                    @click="deleteGrade(scope.row)"
                                    type="danger"
                                    size="small" v-permission="'eduGrade:deletedEduGradeById'">
                                删除
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <div style="padding: 1rem">
                    <el-pagination
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                            :current-page="search.page"
                            :page-sizes="[10, 20, 50, 100]"
                            :page-size="search.pageSize"
                            layout="total, sizes, prev, pager, next, jumper"
                            :total="this.total"
                    >
                    </el-pagination>
                </div>
            </div>
        </div>
        <!-- 创建/添加 -->
        <el-dialog :title="title" :visible.sync="dialogVisible" width="30%">
            <el-form
                    :model="teacherForm"
                    :rules="teacherRules"
                    ref="ruleForm"
                    class="demo-ruleForm"
                    label-width="120px"
            >
                <el-form-item label="年级名称：" prop="gradeName">
                    <el-input
                            v-model="teacherForm.gradeName"
                            placeholder="请输入年级名称"
                            class="el-form-input-width"
                            @input="
              () => (teacherForm.gradeName = teacherForm.gradeName.trim())
            "
                    ></el-input>
                </el-form-item>
                <el-form-item label="年级编号：" prop="id">
                    <el-input
                            v-model="teacherForm.gradeCode"
                            placeholder="请输入年级编号"
                            class="el-form-input-width"
                            @input="
              () => (teacherForm.gradeCode = teacherForm.gradeCode.trim())
            "
                    ></el-input>
                </el-form-item>

                <el-form-item label="开学时间：" prop="startTime">
                    <el-date-picker
                            v-model="teacherForm.startTime"
                            type="date"
                            placeholder="选择日期"
                            class="el-form-input-width"
                    >
                    </el-date-picker>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="handleSave('ruleForm')"
        >确 定</el-button
        >
      </span>
        </el-dialog>
        <!-- 创建/编辑 -->
        <el-dialog :title="title" :visible.sync="dialogVisible1" width="30%">
            <el-form
                    :model="teacherForm"
                    :rules="teacherRules"
                    ref="ruleForm"
                    class="demo-ruleForm"
                    label-width="120px">
                <el-form-item label="年级名称：" prop="gradeName">
                    <el-input
                            v-model="teacherForm.gradeName"
                            placeholder="请输入年级名称"
                            class="el-form-input-width"
                            @input="() => (teacherForm.gradeName = teacherForm.gradeName.trim())">
                    </el-input>
                </el-form-item>
                <el-form-item label="年级编号：" prop="id">
                    <el-input
                            v-model="teacherForm.gradeCode"
                            placeholder="请输入年级编号"
                            class="el-form-input-width"
                            @input="() => (teacherForm.gradeCode = teacherForm.gradeCode.trim())">
                    </el-input>
                </el-form-item>
                <el-form-item label="开学时间：" prop="startTime">
                    <el-date-picker
                            v-model="teacherForm.startTime"
                            type="date"
                            format="yyyy-MM-dd"
                            placeholder="选择日期"
                            class="el-form-input-width">
                    </el-date-picker>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible1 = false">取 消</el-button>
        <el-button type="primary" @click="handleSave1('ruleForm')" v-permission="'eduGrade:modifyEduGradeById'">
            确 定
        </el-button>
      </span>
        </el-dialog>

        <!-- 导入 上传文件 -->
        <el-dialog title="上传文件" :visible.sync="dialogVisibleUpload" width="30%">
            <div style="margin-bottom: 20px">
                <el-button type="primary" @click="xiazia" v-permission="'eduGrade:gradeImportTemplate'">下载模板</el-button>
                <span style="color: red"
                >提示：导入前请先下载模板，根据模版整理好数据后上传导入.</span
                >
            </div>
            <el-upload
                    class="upload-demo"
                    drag
                    action="#"
                    :show-file-list="false"
                    :http-request="uploadFile"
                    :before-upload="beforeUpload"
            >
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                <div class="el-upload__tip" slot="tip">
                    只能上传excel文件，且不超过50M
                </div>
            </el-upload>
        </el-dialog>
    </div>
</template>
<script>
    export default {
        created() {
            this.Pagerendering();
        },
        data() {
            return {
                title: "",
                search: {
                    name: "",
                    page: 1,
                    pageSize: 10,
                },
                total: 0,
                teacherForm: {
                    id: "",
                    delFlag: 0,
                    gradeCode: "",
                    gradeName: "",
                    startTime: "",
                },
                tableChecked: [],
                tableData: [],
                versionList: [
                    {
                        value: 0,
                        label: "旧版",
                    },
                    {
                        value: 1,
                        label: "新版",
                    },
                ],
                dialogVisible: false,
                dialogVisible1: false,
                dialogVisibleUpload: false,
                teacherRules: {
                    name: [{required: true, message: "请输入姓名", trigger: "blur"}],
                },
            };
        },
        methods: {
            // 页面渲染
            Pagerendering() {
                this.getPermission('eduGrade:queryAllEduGradeByCodeAndName', () => {
                    this.openLoadingView();
                    this.$axios_supermall.get("/hnjxjy-core/eduGrade/queryAllEduGradeByCodeAndName?page=" + this.search.page + "&pageSize=" + this.search.pageSize).then(resp => {
                        this.tableData = resp.data.data.list; //表格列表
                        this.total = resp.data.data.total;
                    })
                });
            },
            // 查询
            handleSearch() {
                this.getPermission('eduGrade:queryAllEduGradeByCodeAndName', () => {
                    this.openLoadingView();
                    this.$axios_supermall.get("/hnjxjy-core/eduGrade/queryAllEduGradeByCodeAndName?" +
                        "gradeNameOrCode=" + this.search.name + "&page=" + this.search.page + "&pageSize=" + this.search.pageSize).then(resp => {
                        if (resp.data.code == 2000) {
                            this.tableData = resp.data.data.list;
                            this.total = resp.data.data.total;
                        }
                    })
                });
            },
            // 添加按钮
            addGrade() {
                this.title = "创建年级列表";
                this.dialogVisible = true;
                this.teacherForm = {};
            },
            //添加按钮-确定按钮
            handleSave() {
                let objPar = JSON.parse(localStorage.getItem("loginStrage"));
                this.$axios_supermall.post("hnjxjy-core/eduGrade/addEduGradeBySiteId",
                    {
                        gradeName: this.teacherForm.gradeName,
                        gradeCode: this.teacherForm.gradeCode,
                        startTime: this.teacherForm.startTime.formatToLocalDateTime()
                    }
                ).then(resp => {
                    if (resp.data.code == 2000) {
                        this.$message.success("操作完成");
                        this.Pagerendering();
                        this.dialogVisible = false;
                    }
                })
            },
            // 点击导入
            upload() {
                this.dialogVisibleUpload = true;
            },
            // 导入上传文件
            uploadFile(file) {
              let fileDate = file.file;
              let forms = new FormData();
              forms.append("file", fileDate);
              let objPar = JSON.parse(localStorage.getItem("loginStrage"));
              this.openLoadingView();
              this.$axios({
                method: "post",
                url: "hnjxjy-core/eduGrade/gradeImportExcel",
                data: forms,
                headers: objPar,
              }).then((res) => {
                if (res.data.code == 2000) {
                  this.$message({
                    type: "success",
                    message: res.data.data,
                  });
                  this.dialogVisibleUpload = false;
                } else {
                  this.$message({
                    type: "error",
                    message: res.data.data,
                  });
                }
                this.loadingView.close();
              }).catch((err) => {
                this.$message.error("操作失败");
                this.loadingView.close();
              });
            },
            // 导入上传文件前判断文件格式
            beforeUpload(file) {
                const isXls = file.type === "application/vnd.ms-excel";
                const isXlsx =
                    file.type ===
                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
                if (!isXls && !isXlsx) {
                    this.$message.error("上传文件只能是XLS/XLSX格式！");
                    return false;
                }
                return isXls || isXlsx;
            },
            // 点击导出
            download() {
              let objPar = JSON.parse(localStorage.getItem("loginStrage"));
              this.openLoadingView();
              this.$axios({
                method: "get",
                url: "hnjxjy-core/eduGrade/exportGradeExcel",
                responseType: "blob",
                headers: objPar,
              }).then((res) => {
                let blob = new Blob([res.data], {
                  type: "application/vnd.ms-excel",
                });
                let url = window.URL.createObjectURL(blob);
                window.location.href = url;
                this.$message.success("操作成功");
                this.loadingView.close();
              }).catch((err) => {
                this.$message.error("操作失败");
                this.loadingView.close();
              });
            },
            // 编辑前
            editor(row) {

                this.title = "修改年级列表";
                this.dialogVisible1 = true;
                this.$axios_supermall.get("hnjxjy-core/eduGrade/showModifyGradeById?" +
                    "id=" + row.id
                ).then(resp => {
                    this.teacherForm = resp.data.data;
                    this.$set(this.teacherForm, 'startTime', new Date(resp.data.data.startTime));
                })
            },
            // 点击删除
            deleteGrade(row) {

                this.$axios_supermall.post("hnjxjy-core/eduGrade/deletedEduGradeById?" +
                    "id=" + row.id
                ).then(resp => {
                    if (resp.data.code != 2000) {
                        // this.$message({
                        //     type: "warning",
                        //     message: resp.data.message,
                        // });
                        return;
                    }
                    this.$message({
                        type: "success",
                        message: resp.data.message,
                    });
                    this.Pagerendering();
                })
            },
            // 下载模版
            xiazia() {
              let objPar = JSON.parse(localStorage.getItem("loginStrage"));
              this.openLoadingView();
              this.$axios({
                method: "get",
                url: "hnjxjy-core/eduGrade/gradeImportTemplate",
                responseType: "blob",
                headers: objPar,
              }).then((res) => {
                let blob = new Blob([res.data], {
                  type: "application/vnd.ms-excel",
                });
                let downloadElement = document.createElement('a');
                let href = window.URL.createObjectURL(blob);
                downloadElement.href = href;
                downloadElement.download = '年级列表导入模板.xlsx';　　　　　　　　　　// xxx.xls/xxx.xlsx
                document.body.appendChild(downloadElement);
                downloadElement.click();
                document.body.removeChild(downloadElement);
                window.URL.revokeObjectURL(href);
                this.$message.success("操作成功");
                this.loadingView.close();
              }).catch((err) => {
                this.$message.error("操作失败");
                this.loadingView.close();
              });
            },
            //编辑后-按钮
            handleSave1() {
                let fromData = new FormData();
                fromData.append("gradeName", this.teacherForm.gradeName);
                fromData.append("gradeCode", this.teacherForm.gradeCode);
                fromData.append("id", this.teacherForm.id);
                fromData.append("startTime", new Date(this.teacherForm.startTime));
                this.$axios_supermall.post("hnjxjy-core/eduGrade/modifyEduGradeById", fromData).then(resp => {
                    if (resp.data.code == 2000) {
                        this.$message.success("操作完成");
                        this.Pagerendering();
                        this.dialogVisible1 = false;
                    }
                })
            },
            handleSizeChange(val) {
                this.search.pageSize = val;
                this.handleSearch();
            },
            handleCurrentChange(val) {
                this.search.page = val;
                this.handleSearch();
            },
        },
    };
</script>
<style scoped>
    .search {
        text-align: left;
        margin-top: 1rem;
        /* margin-left: 1rem; */
        padding: 1rem;
    }

    .table-wrap {
        overflow: auto;
        /* margin-left: 1rem; */
        /* margin-bottom: 1rem; */
        padding: 1rem;
    }

    .demo-ruleForm {
        /* width: 90%; */
        height: calc(100% - 125px);
    }

    .el-form-input-width {
        width: 100%;
    }

    .rigth {
        width: 93%;
        height: 870px;
        overflow-y: scroll;
        display: inline-block;
        /* background-color: #fff; */
        background-color: rgba(164, 174, 224, 0.11);
        float: right;
    }

    .left {
        width: 7%;
        display: inline-block;
    }
</style>
