<template>
  <div id="contain">
    <div >
      <div style="padding: 1rem; border-bottom: 1px solid #ddd; text-align: left">
        短信签名
      </div>
      <div class="top_btn">
        <el-button type="primary" icon="el-icon-circle-plus-outline" @click="putDialogcreate" v-permission="'eduMsgNew/addEduMsgSign'">新增模板</el-button>
      </div>
      <el-table :data="tableData" border style="width: 100%;" size="medium" height="700px" max-height="700px"
                :header-cell-style="{'text-align':'center'}" :cell-style="{'text-align':'center'}">
        <el-table-column type="index" label="序号" width="50">
        </el-table-column>
        <el-table-column prop="signName" label="签名名称">
        </el-table-column>
        <el-table-column prop="accessKeyId" label="accessKeyId">
        </el-table-column>
        <el-table-column prop="accessKeySecret" label=accessKeySecret">
        </el-table-column>
        <el-table-column label="创建时间" prop="createTime">
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button size="mini" type="danger" @click="del(scope.row)" v-permission="'eduMsgNew:updateEduMsgSign'">删除</el-button>
            <el-button size="mini" @click="updateDialogcreate(scope.row)" v-permission="'eduMsgNew:delEduMsgSign'">修改</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="ye">
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                       :page-sizes="[10, 30, 50, 100]" background
                       :current-page="currentPage"
                       layout="total, sizes, prev, pager, next, jumper" :total="total">
        </el-pagination>
      </div>
    </div>
    <el-dialog class="add" title="新增模板" :visible.sync="putDialog" width="40%" center>
      <div class="top_btn">
        <el-button type="primary" icon="el-icon-circle-close" @click="close()" size="small">关闭</el-button>
        <el-button type="primary" icon="el-icon-folder-add" size="small" @click="add()">保存后关闭</el-button>
      </div>
      <el-form ref="form" :model="bo" label-width="130px" style="width: 30vw" :rules="rules">
        <el-form-item label="签名名称" prop="signName">
          <el-input :rows="4" placeholder="请输入内容" resize="none" v-model="bo.signName">
          </el-input>
        </el-form-item>
        <el-form-item label="accessKeyId" prop="accessKeyId">
          <el-input :rows="4" placeholder="请输入内容" resize="none" v-model="bo.accessKeyId">
          </el-input>
        </el-form-item>
        <el-form-item label="accessKeySecret" prop="accessKeySecret">
          <el-input :rows="4" placeholder="请输入内容" resize="none" v-model="bo.accessKeySecret">
          </el-input>
        </el-form-item>
      </el-form>
    </el-dialog>

  </div>
</template>

<script>

export default {
  data() {
    return {
      model: {

      },
      rules: {
        signName: [
          { required: true, message: '签名名称不能为空', trigger: 'blur' }
        ],
        accessKeyId: [
          { required: true, message: 'accessKeyId不能为空', trigger: 'blur' }
        ],
        accessKeySecret: [
          { required: true, message: 'accessKeySecret不能为空', trigger: 'blur' }
        ]
      },
      putDialog: false,
      tableData: [],
      currentPage: 1,
      total: 0,
      page: 1,
      pageSize: 10,
      bo: {
        id: null,
        signName: null,
        accessKeyId: null,
        accessKeySecret: null,
      },
    }
  },
  mounted() {
    this.query();
  },
  methods: {
    close() {
      this.$refs.form.resetFields()
      this.putDialog = false
    },
    del(row) {
      this.$confirm("你确定要删除此签名吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {

        this.$axios_supermall.get("/hnjxjy-core/eduMsgNew/delEduMsgSign?id="+row.id).then(resp =>{
          if (resp.data.code == 2000){
            this.$message.success("删除成功");
            this.query();
          }
        })
          })

    },
    //查询详情
    deltae(row){

    },
    add() {
      this.$refs.form.validate(async valid => {
        if (!valid) return
        let url = "eduMsgNew/addEduMsgSign";
        if (this.bo.id != null) {
          url = "eduMsgNew/updateEduMsgSign";
        }
        this.$axios_supermall.post("/hnjxjy-core/" + url, this.bo).then(resp => {
          if (resp.data.code == 2000) {
            this.$message.success("处理成功");
            this.putDialog = false;
            this.query();
          }
        });
        this.$refs.form.resetFields()
      })
    },
    updateDialogcreate(row) {
      this.bo = row;
      this.putDialog = true;
    },
    putDialogcreate() {
      this.bo = {
        signName: null,
        accessKeyId: null,
        accessKeySecret: null,
      }
      this.putDialog = true;
    },
    query() {
      this.getPermission('eduMsgNew:queryEduMsgSignList', () => {
        this.openLoadingView();
        this.$axios_supermall.get("/hnjxjy-core/eduMsgNew/queryEduMsgSignList?page=" + this.page + "&pageSize=" + this.pageSize).then(resp => {
          if (resp.data.code == 2000) {
            this.tableData = resp.data.data.list;
            this.total = resp.data.data.total;

          }
          this.loadingView.close();
        }).catch(() => {
          this.loadingView.close();
        })
      });
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.query();

    },
    handleCurrentChange(val) {
      this.page = val;
      this.query();
    }
  },
  name: "smsignature"
}
</script>

<style scoped>
.right {
  float: right;
  width: 93%;
  display: inline-block;
  height: 870px;
  overflow-y: scroll;
  /* background-color: #fff; */
  background-color: rgba(164, 174, 224, 0.11);
}

.left {
  width: 7%;
  display: inline-block;
}

.yingyong {
  font-weight: bold;
  color: #555;
}

#aaaaa {
  color: lightskyblue;
}

.top_btn {
  margin: 10px 10px 10px 10px;
  text-align: left;
}

.search {
  margin: 20px 10px 30px 10px;
  text-align: left;
}

.main_contain {
  margin: 20px 10px 30px 10px;
  text-align: left;
}

.batch {
  margin-right: 100px;
  text-align: left;
}

.left {
  width: 7%;
  display: inline-block;
}

.add {
  text-align: left im !important;
}

.ye {
  background: #fff;


}
</style>
