<template>
  <div>
    <div >
      <div
        style="padding: 1rem; border-bottom: 1px solid #ddd; text-align: left"
      >
        招生批次
      </div>
      <div class="search">
        <el-form class="demo-ruleForm">
          <el-form-item>
            <el-button
              icon="el-icon-circle-check"
              type="primary"
              @click="startUp()"
              size="small"
              v-permission="'eduEnrolBatch:isEnable'"
              >开启</el-button
            >
            <el-button
                icon="el-icon-circle-check"
                type="primary"
                @click="startNo()"
                size="small"
                v-permission="'eduEnrolBatch:isEnable'"
            >关闭</el-button
            >
            <el-button
              icon="el-icon-circle-plus-outline"
              type="primary"
              @click="addcreate()"
              size="small"
              v-permission="'eduEnrolBatch:add'"
              >添加</el-button
            >
            <el-button
              icon="el-icon-remove-outline"
              type="primary"
              @click="batchDelete"
              size="small"
              v-permission="'eduEnrolBatch:remove'"
              >删除</el-button
            >
          </el-form-item>
        </el-form>
        <el-form :model="search" class="demo-ruleForm" inline size="small">
          <el-form-item label="批次代码/批次名称" style="margin-bottom: 0;margin-top: 15px">
            <el-input
              v-model="search.name"
              clearable
              @clear="getList"
              placeholder="请输入批次代码/批次名称"
              style="width: 300px"
            > <el-button icon="el-icon-search" @click="getList" slot="append"></el-button></el-input>

          </el-form-item>
        </el-form>
      </div>
      <div class="table-wrap">
        <span
          style="
            color: rgb(46, 146, 255);
            font-size: 14px;
            display: flex;
            justify-content: flex-start;
          "
          >共选中{{ multipleTable.length }}条数据</span
        >
        <el-table
          border
          ref="multipleTable"
          :data="tableChecked"
          style="width: 100%"
          :model="addForm"
          size="medium"
          height="550px"
          max-height="550px"
          :header-cell-style="{'text-align':'center'}"
          :cell-style="{'text-align':'center'}"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection"> </el-table-column>
          <el-table-column label="序号" type="index" width="50">
          </el-table-column>
          <el-table-column
            prop="enabled"
            label="批次状态"
            :model="this.enabled"
          >
          </el-table-column>
          <el-table-column label="查看专业" show-overflow-tooltip>
            <template slot-scope="scope">
              <div
                style="
                  font-size: 14px;
                  color: rgb(46, 146, 255);
                  cursor: pointer;
                "
                @click="major(scope.row)"
              >
                查看专业
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="batchCode" label="批次代码"> </el-table-column>
          <el-table-column prop="batchName" label="批次名称"> </el-table-column>
          <el-table-column prop="batchStartTime" label="报名开始时间">
          </el-table-column>
          <el-table-column prop="batchEndTime" label="报名结束时间">
          </el-table-column>
          <el-table-column fixed="right" label="操作" width="160">
            <template slot-scope="scope">
              <el-button @click="editor(scope.row)" type="primary" size="small" v-permission="'eduEnrolBatch:queryById'">
                编辑
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <div style="padding: 1rem">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="search.page"
            :page-sizes="[10, 50, 100, 500]"
            :page-size="search.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <!-- 编辑 -->
    <el-dialog
        title="编辑"
        :visible.sync="dialogVisible"
        width="50%"
        style="text-align: left"
        :data="editList"
        :before-close="dialogVisibleBeforeClose"
    >
      <el-form
          :model="editList"
          ref="ruleForm"
          class="demo-ruleForm"
          label-width="120px"
          id="fontWeight"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item label="批次代码：" prop="batchCode">
              <el-input
                  style="width: 15vw"
                  placeholder="请输入"
                  class="el-form-input-width"
                  @input="() => (editList.batchCode = editList.batchCode.trim())"
                  v-model="editList.batchCode"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="批次名称：" prop="batchName">
              <el-input
                  style="width: 15vw"
                  placeholder="请输入"
                  class="el-form-input-width"
                  @input="() => (editList.batchName = editList.batchName.trim())"
                  v-model="editList.batchName"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="报名开始时间：" prop="batchStartTime">
              <el-date-picker
                  style="width: 15vw"
                  type="datetime"
                  placeholder="选择日期时间"
                  format="yyyy 年 MM 月 dd 日 HH 时 mm 分 ss 秒"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  v-model="editList.batchStartTime"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="报名结束时间：" prop="batchEndTime">
              <el-date-picker
                  style="width: 15vw"
                  type="datetime"
                  placeholder="选择日期时间"
                  format="yyyy 年 MM 月 dd 日 HH 时 mm 分 ss 秒"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  v-model="editList.batchEndTime"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="注册时间：" prop="registerTime">
              <el-date-picker
                  style="width: 15vw"
                  type="datetime"
                  placeholder="选择日期时间"
                  format="yyyy 年 MM 月 dd 日 HH 时 mm 分 ss 秒"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  v-model="editList.registerTime"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="注册结束时间：" prop="registerTime">
              <el-date-picker
                  style="width: 15vw"
                  type="datetime"
                  placeholder="选择日期时间"
                  format="yyyy 年 MM 月 dd 日 HH 时 mm 分 ss 秒"
                  value-format="yyyy/MM/dd HH:mm:ss"
                  v-model="editList.registerTimeEnd"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="对应年级：" prop="gradeId">
              <el-select style="width: 15vw" v-model="editList.gradeId" placeholder="请选择">
                <el-option
                    v-for="item in options"
                    :key="item.id"
                    :label="item.gradeName"
                    :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="招生简章：" prop="enrolGuide">
              <el-input
                  style="width: 15vw"
                  type="textarea"
                  :rows="4"
                  placeholder="请输入内容"
                  v-model="editList.enrolGuide"
              >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="报名须知：" prop="registerNotes">
              <el-input
                  style="width: 15vw"
                  type="textarea"
                  :rows="4"
                  placeholder="请输入内容"
                  v-model="editList.registerNotes"
              >
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisibleFalse()">关 闭</el-button>
        <el-button type="primary" @click="handleSave('editList')" v-permission="'eduEnrolBatch:edit'"
        >确 定</el-button
        >
      </span>
    </el-dialog>

    <!-- 新增 -->
    <el-dialog
        title="创建"
        :visible.sync="dialogVisible1"
        width="50%"
        style="text-align: left"
    >
      <el-form
          :model="addForm"
          ref="addFormRef"
          class="demo-ruleForm"
          label-width="120px"
          id="fontWeight"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item label="批次代码：" prop="batchCode">
              <el-input
                  style="width: 15vw;"
                  v-model="addForm.batchCode"
                  placeholder="请输入"
                  class="el-form-input-width"
                  @input="() => (teacherForm.name = teacherForm.name.trim())"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="批次名称：" prop="batchName">
              <el-input
                  style="width: 15vw;"
                  v-model="addForm.batchName"
                  placeholder="请输入"
                  class="el-form-input-width"
                  @input="() => (teacherForm.name = teacherForm.name.trim())"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item  label="报名开始时间：" prop="batchStartTime">
              <el-date-picker
                  style="width: 15vw;"
                  v-model="addForm.batchStartTime"
                  type="datetime"
                  placeholder="选择日期时间"
                  format="yyyy 年 MM 月 dd 日 HH 时 mm 分 ss 秒"
                  value-format="yyyy/MM/dd HH:mm:ss"
              >
                >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="报名结束时间：" prop="batchEndtTime">
              <el-date-picker
                  style="width: 15vw;"
                  v-model="addForm.batchEndTime"
                  type="datetime"
                  placeholder="选择日期时间"
                  format="yyyy 年 MM 月 dd 日 HH 时 mm 分 ss 秒"
                  value-format="yyyy/MM/dd HH:mm:ss"
              >
                >
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="注册时间：" prop="registerTime">
              <el-date-picker
                  style="width: 15vw;"
                  v-model="addForm.registerTime"
                  type="datetime"
                  placeholder="选择日期时间"
                  format="yyyy 年 MM 月 dd 日 HH 时 mm 分 ss 秒"
                  value-format="yyyy/MM/dd HH:mm:ss"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="注册结束时间：" prop="registerTime">
              <el-date-picker
                  style="width: 15vw;"
                  v-model="addForm.registerTimeEnd"
                  type="datetime"
                  placeholder="选择日期时间"
                  format="yyyy 年 MM 月 dd 日 HH 时 mm 分 ss 秒"
                  value-format="yyyy/MM/dd HH:mm:ss"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="对应年级：" prop="gradeId">
              <el-select style="width: 15vw;" v-model="addForm.gradeId" placeholder="请选择">
                <el-option
                    v-for="item in this.options"
                    :key="item.id"
                    :label="item.gradeName"
                    :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="招生简章：" prop="name">
              <el-input
                  style="width: 15vw;"
                  type="textarea"
                  :rows="4"
                  placeholder="请输入内容"
                  v-model="addForm.enrolGuide"
              >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="报名须知：" prop="name">
              <el-input
                  style="width: 15vw;"
                  type="textarea"
                  :rows="4"
                  placeholder="请输入内容"
                  v-model="addForm.registerNotes"
              >
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible1 = false">取 消</el-button>
        <el-button type="primary" @click="addUser()">确 定</el-button>
      </span>
    </el-dialog>


    <!-- 设置专业 -->
    <el-dialog title="设置专业" :visible.sync="outerVisible">
      <div style="margin-top: 1rem">
        <el-table :data="seeList" border ref="seeListTable" @selection-change="handleSeeList" style="width: 100%"
                  size="medium"
                  :header-cell-style="{'text-align':'center'}"
                  :cell-style="{'text-align':'center'}">
          <el-table-column type="selection" width="55"> </el-table-column>
          <el-table-column type="index" width="80px" label="序号"> </el-table-column>
          <el-table-column prop="stageId" label="学制"> </el-table-column>
          <el-table-column prop="profName" label="专业"> </el-table-column>
          <el-table-column prop="profCode" label="专业编号"> </el-table-column>
        </el-table>
      </div>
      <el-dialog
        width="40%"
        title="专业列表"
        :visible.sync="innerVisible"
        append-to-body
      >
<!--        <el-form :model="search" class="demo-ruleForm" inline>-->
<!--          <el-form-item label="层次" style="margin-bottom: 0">-->
<!--            <el-select v-model="profession.stageId" placeholder="请选择"-->
<!--                       @change="selectProfession">-->
<!--              <el-option-->
<!--                v-for="item in this.options"-->
<!--                :key="item.id"-->
<!--                :label="item.stageName"-->
<!--                :value="item.id"-->
<!--              >-->
<!--              </el-option>-->
<!--            </el-select>-->
<!--          </el-form-item>-->
<!--        </el-form>-->
        <div style="margin-top: 1rem">
          <el-table :data="tableData" border ref="professionTable" @selection-change="handleProfession" style="width: 100%"
                    size="medium"
                    :header-cell-style="{'text-align':'center'}"
                    :cell-style="{'text-align':'center'}">
            <el-table-column type="selection" width="55"> </el-table-column>
            <el-table-column type="index" width="80px" label="序号"> </el-table-column>
            <el-table-column prop="profName" label="专业"> </el-table-column>
          </el-table>
          <div style="padding: 1rem; text-align: center">
            <el-pagination
              @size-change="handleSizeChange1"
              @current-change="handleCurrentChange1"
              :current-page="profession.pageNum"
              :page-sizes="[10, 30, 50, 100]"
              :page-size="profession.pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="profession.total"
            >
            </el-pagination>
          </div>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" @click="addBatchProfession()"
            >保存后关闭</el-button
          >
        </div>
      </el-dialog>

      <div slot="footer" class="dialog-footer">
        <el-button @click="outerVisible = false">关闭</el-button>
        <el-button type="primary" @click="selectProfession()" v-permission="'eduEnrolBatch:addBatchProfession'"
          >添加专业</el-button
        >
        <el-button type="primary" @click="delBatchProfession()" v-permission="'eduEnrolBatch:delBatchProfession'"
          >移除专业</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      professionBatchId: 0,
      profession:{
        total: 0,
        pageNum: 1,
        pageSize: 10
      },
      pageNum: 1,
      pageSize: 10,
      // currentPage4: 4,
      currentPage2: 4,
      currentPage1: 4,
      textarea: "",
      // 查看专业数据储存
      seeList:[],
      // 储存数据库中的启用状态
      enableds: "",
      // 添加数据的id
      idPlus: 0,
      // 添加数据的储存
      addForm: {
        batchCode: "",
        batchName: "",
        batchStartTime: "",
        batchEndTime: "",
        gradeId: "",
        registerTime: "",
        registerTimeEnd: "",
        enrolGuide:"",
        registerNotes:""

      },
      // 储存编辑之后调用的接口
      editID:0,
      // 编辑数据储存
      batchCode:'',
      editList: {
        id: 0,
        batchName: "",
        batchStartTime: "",
        batchEndTime: "",
        registerTime: "",
        gradeId: "",
        registerNotes: "",
        registerTimeEnd: "",
        enrolGuide: "",
        batchCode: "",
      },
      // 分页管理器中共多少条数据储存
      total: 0,
      // 分页管理器中的当前页数
      currentPage: 0,
      search: {
        name: "",
        ids: "",
        page: 1,
        pageSize: 10,
      },
      teacherForm: {
        name: "",
      },
      // 储存后台获取到的表格数据
      tableChecked: [],
      array: [],
      tableData: [],
      correspondType: [
        {
          value: 0,
          label: "全部",
        },
        {
          value: 1,
          label: "函授站",
        },
        {
          value: 2,
          label: "学习中心",
        },
        {
          value: 3,
          label: "函授站及学习中心",
        },
      ],
      dialogVisible: false,
      dialogVisible1: false,
      dialogVisibleUpload: false,
      // 储存数据的请求头
      loginList: {},
      enrollmentList: [],
      // 储存后台启用状态
      enabled: "",
      // teacherRules: {
      //   name: [{ required: true, message: "请输入姓名", trigger: "blur" }],
      //   fullName: [
      //     { required: true, message: "请输入真实姓名", trigger: "blur" },
      //   ],
      //   sex: [{ required: true, message: "请选择性别", trigger: "blur" }],
      //   phone: [
      //     { required: true, message: "请输入手机号码", trigger: "blur" },
      //     { pattern: /^1[3456789]\d{9}$/, message: "手机号格式不正确" },
      //   ],
      //   email: [
      //     { required: false, message: "请输入邮箱", trigger: "blur" },
      //     {
      //       pattern: /^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$/,
      //       message: "邮箱格式不正确",
      //     },
      //   ],
      //   idCard: [
      //     { required: false, message: "请输入身份证号码", trigger: "blur" },
      //     {
      //       pattern: /^([1-6][1-9]|50)\d{4}(18|19|20)\d{2}((0[1-9])|10|11|12)(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/,
      //       message: "身份证号格式不正确",
      //     },
      //   ],
      // },
      // 添加功能页面对应年级的下拉菜单
      options: [],
      gradId: 0,
      gradeName: "",
      value: "",
      pickerOptions: {
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              picker.$emit("pick", new Date());
            },
          },
          {
            text: "昨天",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24);
              picker.$emit("pick", date);
            },
          },
          {
            text: "一周前",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", date);
            },
          },
        ],
      },
      value1: "",
      outerVisible: false,
      innerVisible: false,
      // 获取设置专业的数据储存
      restList: [],
      multipleTable: [],
      professionTable: [],
      seeListTable: [],
      ids: 0,
      ides: 0,
    };
  },
  // 更新声明周期钩子函数
  created() {
    this.getList();
    this.gradList();
  },

  methods: {
    // 获取后台表格数据
    getList() {
      this.getPermission('eduEnrolBatch:list', () => {
        this.loginList = JSON.parse(localStorage.getItem("loginStrage"));
        // this.loginList = JSON.parse(localStorage.getItem("loginStrage"));
        let formData = new FormData();
        formData.append("page",this.search.page)
        formData.append("pageSize",this.search.pageSize)
        if (null !== this.search.name && this.search.name !== ""){
          formData.append("info",this.search.name)
        }
        this.openLoadingView();
        this.$axios_supermallData.post('hnjxjy-core/eduEnrolBatch/list',formData).then((res) => {
          if (res.data.code !== 2000) {
            this.loadingView.close();
            return;
          }
          this.tableChecked = res.data.data.list;
          this.total = res.data.data.total;
          this.currentPage = res.data.data.pageNum;
          this.ids = res.data.data.id;
          this.loadingView.close();
        }).catch(()=>{
          this.loadingView.close();
        })
      });

    },
    // 获取添加功能页面对应年级下拉菜单
    gradList() {
      this.loginList = JSON.parse(localStorage.getItem("loginStrage"));
      // this.loginList = JSON.parse(localStorage.getItem("loginStrage"));
      this.$axios_supermall({
        url: "/hnjxjy-core/eduGrade/listGL",
        headers: this.loginList,
        method: "POST",
      }).then((res) => {
        if (res.data.code !== 2000) {
          return;
        }
        this.options = res.data.data;
      });
    },
    setList() {
      this.loginList = JSON.parse(localStorage.getItem("loginStrage"));
      this.$axios({
        url: "hnjxjy-core/eduProfession/queryAllProfessionByBachId",
        headers: this.loginList,
        method: "GET",
        params: {
            batchId: this.professionBatchId
        }
      }).then((res) => {
        this.restList = res.data.data.list;
      });

    },
    // 确认添加用户
    addUser() {
      // 判断信息是否为空
      if (!this.addForm.batchCode) {
        this.dialogVisible1 = true;
        this.$message({
          message: "请输入批次代码",
          type: "warning",
        });
      }
      if (this.addForm.batchCode) {
        this.loginList = JSON.parse(localStorage.getItem("loginStrage"));
        this.$axios({
          url: "hnjxjy-core/eduEnrolBatch/add",
          headers: this.loginList,
          method: "POST",
          data: {
            batchCode: this.addForm.batchCode,
            batchName: this.addForm.batchName,
            batchStartTime: this.addForm.batchStartTime,
            batchEndTime: this.addForm.batchEndTime,
            gradeId: this.addForm.gradeId,
            registerTime: this.addForm.registerTime,
            registerTimeEnd: this.addForm.registerTimeEnd,
            enrolGuide: this.addForm.enrolGuide,
            registerNotes: this.addForm.registerNotes
          },
        }).then((res) => {
          if(res.data.code==2000){
            this.tableChecked.push(this.addForm);
            this.$message.success("操作成功")
            this.dialogVisible1 = false;
            this.addForm = {
              batchCode: "",
              batchName: "",
              batchStartTime: "",
              batchEndTime: "",
            };
            this.getList();
          }else{
            this.$message.error("操作失败")
            this.dialogVisible1 = false;
            this.addForm = {
              batchCode: "",
              batchName: "",
              batchStartTime: "",
              batchEndTime: "",
            };
            this.getList();
          }

        });
      }
    },
    dialogVisibleFalse(){
      this.dialogVisible = false;
      this.editList = {
        id: null,
        batchName: "",
        batchStartTime: "",
        batchEndTime: "",
        registerTime: "",
        gradeId: "",
        registerNotes: "",
        registerTimeEnd: "",
        enrolGuide: "",
        batchCode: "",
      };
    },
    dialogVisibleBeforeClose(done){
      done();
      this.editList = {
        id: null,
        batchName: "",
        batchStartTime: "",
        batchEndTime: "",
        registerTime: "",
        gradeId: "",
        registerNotes: "",
        registerTimeEnd: "",
        enrolGuide: "",
        batchCode: "",
      };
    },
    dataFtt(val){
      if (val != null) {
          var date = new Date(val);
          let h=date.getHours().toString().length==1?"0"+date.getHours():date.getHours();
          let m=date.getMinutes().toString().length==1?"0"+date.getMinutes():date.getMinutes();
          let s=date.getSeconds().toString().length==1?"0"+date.getSeconds():date.getSeconds();
          let datestr=date.getFullYear() + '/' + (date.getMonth() + 1) + '/' + date.getDate()+" "+h+":"+m+":"+s;
          console.log(datestr,"datestr");
          return datestr;
      }
    },
    // 编辑
    handleSave() {
      this.loginList = JSON.parse(localStorage.getItem("loginStrage"));
      this.$axios({
        url: "hnjxjy-core/eduEnrolBatch/edit",
        headers: this.loginList,
        method: "POST",
        data: {
          id: this.editID,
          batchName: this.editList.batchName,
          batchStartTime: this.dataFtt(this.editList.batchStartTime),
          batchEndTime: this.dataFtt(this.editList.batchEndTime),
          registerTime: this.dataFtt(this.editList.registerTime),
          registerTimeEnd: this.dataFtt(this.editList.registerTimeEnd),
          gradeId: this.editList.gradeId,
          registerNotes: this.editList.registerNotes,
          enrolGuide: this.editList.enrolGuide,
          batchCode: this.editList.batchCode
        },
      }).then((res) => {
        if (res.data.code === 2000){
          this.$message.success("操作成功")
          this.dialogVisible = false;
          this.getList()
        }else {
          this.$message.error(res.data.message)
          this.dialogVisible = false;
          this.getList()
        }
      }).catch(err => {
        this.$message.error(err)
        this.dialogVisible = false;
        this.getList()
      })
    },
    addcreate() {
      this.dialogVisible1 = true;
    },
    // 更改批次状态
    startUp() {
      if(this.multipleTable!=null&&this.multipleTable!=""){
        let array = [];
        for (let i = 0; i < this.multipleTable.length; i++) {
          array.push(JSON.stringify(this.multipleTable[i].id));
          this.idPlus = this.multipleTable[i].id;
        }
        if (array.length > 1) {
          return  this.$message.error("只能单个开启");
        }
        let arr = array.toString();
        this.openLoadingView();
        this.$axios({
          url: `hnjxjy-core/eduEnrolBatch/isEnable`,
          headers: this.loginList,
          method: "PUT",
          params: {
            id: arr
          },
        }).then(resp => {
          if (resp.data.code === 2000){
            this.getList();
            this.$message.success(resp.data.message)
          }else {
            this.getList();
          }
        }).catch(err => {
          this.getList();
          this.$message.success(err)
        });
      }else{
        this.$message.error("请至少选择一个")
      }

    },

    startNo(){
      if(this.multipleTable!=null&&this.multipleTable!=""){
        let array = [];
        for (let i = 0; i < this.multipleTable.length; i++) {
          array.push(JSON.stringify(this.multipleTable[i].id));
          this.idPlus = this.multipleTable[i].id;
        }
        if (array.length > 1) {
          return  this.$message.error("只能单个关闭");
        }
        let arr = array.toString();

        let editList = {
          id: 0,
          batchName: "",
          batchStartTime: "",
          batchEndTime: "",
          registerTime: "",
          gradeId: "",
          registerNotes: "",
          enrolGuide: "",
          batchCode: "",
        };
        this.loginList = JSON.parse(localStorage.getItem("loginStrage"));
        this.$axios({
          url: `hnjxjy-core/eduEnrolBatch/queryById`,
          headers: this.loginList,
          method: "GET",
          params: {
            id: arr,
          },
        }).then((res) => {
          if (res.data.code == 2000) {
            editList = res.data.data;
            this.loginList = JSON.parse(localStorage.getItem("loginStrage"));
            this.$axios({
              url: "hnjxjy-core/eduEnrolBatch/edit",
              headers: this.loginList,
              method: "POST",
              data: {
                id: arr,
                batchName: editList.batchName,
                batchStartTime: editList.batchStartTime,
                batchEndTime: editList.batchEndTime,
                registerTime: editList.registerTime,
                registerTimeEnd: editList.registerTimeEnd,
                gradeId: editList.gradeId,
                registerNotes: editList.registerNotes,
                enrolGuide: editList.enrolGuide,
                batchCode: editList.batchCode,
                enabled: 0
              },
            }).then((res) => {
              if (res.data.code === 2000){
                this.$message.success(res.data.message)
                this.getList()
              }else {
                this.$message.error("关闭失败")
              }
            }).catch(err => {
              this.$message.error(err)
            });

          }else {
            this.$message.error("关闭失败")
          }
        }).catch((err) => {
           this.$message.error(err)
        })

      }else{
        this.$message.error("请至少选择一个")
      }
    },
    // 批量删除
    batchDelete() {
      let array = [];
      for (let i = 0; i < this.multipleTable.length; i++) {
        array.push(JSON.stringify(this.multipleTable[i].id));
      }
      let arr = array.toString();
      this.$confirm("确认删除选中的" + array.length + "条数据吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.openLoadingView();
        this.$axios({
          url: `hnjxjy-core/eduEnrolBatch/remove`,
          headers: this.loginList,
          method: "DELETE",
          params: {
            ids: arr,
          },
        })
          .then((res) => {
            if (res.data.code == 2000) {
              this.$message({
                type: "success",
                message: "删除成功!",
              });
              this.getList();
            }else {
              this.$message.error(res.data.message)
              this.getList();
            }
          })
          .catch((err) => {
            this.$message.error(err)
            this.getList();
          });
      }).catch(() => {
        this.$message.info("已取消删除")
      })
    },
    // 点击导入
    upload() {
      this.dialogVisibleUpload = true;
    },
    // 导入上传文件
    uploadFile() {},
    // 导入上传文件前判断文件格式
    beforeUpload(file) {
      const isXls = file.type === "application/vnd.ms-excel";
      const isXlsx =
        file.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      if (!isXls && !isXlsx) {
        this.$message.error("上传文件只能是XLS/XLSX格式！");
        return false;
      }
      return isXls || isXlsx;
    },
    // 点击导出
    download() {},
    handleSelectionChange(val) {
      this.multipleTable = val;
    },
    handleProfession(val) {
      this.professionTable = val;
    },
    handleSeeList(val) {
      this.seeListTable = val;
    },
    // 点击修改
    editor(row) {
      this.editID = row.id
      this.dialogVisible = true;
      this.loginList = JSON.parse(localStorage.getItem("loginStrage"));
      setTimeout(() => {
        this.openLoadingView();
        this.$axios({
          url: `hnjxjy-core/eduEnrolBatch/queryById`,
          headers: this.loginList,
          method: "GET",
          params: {
            id: row.id,
          },
        })
            .then((res) => {
              if (res.data.code == 2000) {
                
                this.editList=res.data.data;
                if (this.editList.batchEndTime) {
                  this.editList.batchEndTime = new Date(this.editList.batchEndTime);
                }
                if (this.editList.batchStartTime) {
                  this.editList.batchStartTime = new Date(this.editList.batchStartTime);
                }
                if (this.editList.registerTime) {
                  this.editList.registerTime = new Date(this.editList.registerTime);
                }
                if (this.editList.registerTimeEnd) {
                  this.editList.registerTimeEnd = new Date(this.editList.registerTimeEnd);
                }
              }
              this.loadingView.close();
            })
            .catch((err) => {
              this.loadingView.close();
            })
      }, 100);

    },
    //查看专业
    major(row) {
      this.professionBatchId = row.id;
      this.outerVisible = true;
      this.loginList = JSON.parse(localStorage.getItem("loginStrage"));
      // this.loginList = JSON.parse(localStorage.getItem("loginStrage"));
      this.$axios({
        url: "hnjxjy-core/eduProfession/queryAllProfessionByBachId",
        headers: this.loginList,
        method: "GET",
        params: {
          batchId: row.id,
        },
      }).then((res) => {
        this.seeList = res.data.data.list
      });
    },
    handleSizeChange(val) {
      this.search.pageSize = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.search.page = val;
      this.getList();
    },
    handleSizeChange1(val) {
      this.profession.pageSize = val;
      this.selectProfession();
    },
    handleCurrentChange1(val) {
      this.profession.pageNum = val;
      this.selectProfession();
    },
    handleSizeChange2(val) {
    },
    handleCurrentChange2(val) {
    },
    selectMajor(){
      this.$axios({
        url: "hnjxjy-core/eduProfession/queryAllProfessionByBachId",
        headers: this.loginList,
        method: "GET",
        params: {
          batchId: this.professionBatchId,
        },
      }).then((res) => {
        this.seeList = res.data.data.list
      });
    },
    addBatchProfession(){
      let array = [];
      for (let i = 0; i < this.professionTable.length; i++) {
        array.push(JSON.stringify(this.professionTable[i].id));
      }
       if (array.length == 0){
        this.$message.error("请至少选择一个专业")
      } else {
        let arr = array.toString();
        this.$axios({
          url: "hnjxjy-core/eduEnrolBatch/addBatchProfession",
          headers: this.loginList,
          method: "POST",
          params: {
            batchId: this.professionBatchId,
            proIds: arr
          },
        }).then((res) => {
          if (res.data.code == 2000) {
            this.$message.success("增加成功")
            this.innerVisible = false;
            this.selectMajor();
          }else {
            this.$message.success("增加失败")
            this.innerVisible = false;
          }
        })
      }
    },
    selectProfession(){
      this.innerVisible = true;
      this.$axios({
        url: "hnjxjy-core/eduProfession/queryAllProfessionBySiteId",
        headers: this.loginList,
        method: "GET",
        params: {
          page: this.profession.pageNum,
          pageSize: this.profession.pageSize,
        },
      }).then((res) => {
        this.tableData = res.data.data.list
        this.profession.total = res.data.data.total;
      });
    },
    delBatchProfession(){
      let array = [];
      for (let i = 0; i < this.seeListTable.length; i++) {
        array.push(JSON.stringify(this.seeListTable[i].id));
      }
      if (array.length == 0){
        this.$message.error("请至少选择一个专业")
      }else {
        let arr = array.toString();
        this.$axios({
          url: "hnjxjy-core/eduEnrolBatch/delBatchProfession",
          headers: this.loginList,
          method: "POST",
          params: {
            batchId: this.professionBatchId,
            proIds: arr
          },
        }).then((res) => {
          if (res.data.code == 2000) {
            this.$message.success("移除成功")
            this.selectMajor();
          }else {
            this.$message.success("操作失败")
          }
        })
      }
    }
  },
};
</script>
<style scoped>
.search {
  text-align: left;
  margin-top: 1rem;
  /* margin-left: 1rem; */
  padding: 1rem;
}
.table-wrap {
  overflow: auto;
  /* margin-left: 1rem; */
  /* margin-bottom: 1rem; */
  padding: 1rem;
}
.demo-ruleForm {
  /* width: 90%; */
  height: calc(100% - 125px);
}
.el-form-input-width {
  width: 100%;
}
.rigth {
  width: 93%;
  display: inline-block;
  height: 870px;
  overflow-y: scroll;
  /* background-color: #fff; */
  background-color: rgba(164, 174, 224, 0.11);
  float: right;
}
.left {
  width: 7%;
  display: inline-block;
}
.portrait_Sprite {
  padding: 0.2rem 1rem;
  display: inline-block;
  background-color: skyblue;
  color: #fff;
  border-radius: 10%;
  font-size: 13px;
}
.table-wrap >>> .el-table__body td,
.table-wrap >>> .el-table th {
  text-align: center;
}

#fontWeight{

}

#fontWeight >>> .el-form-item__label {
  font-weight: bolder;
}
</style>
