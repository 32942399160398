<template>
    <div>
        <div>
            <div style="padding: 1rem; border-bottom: 1px solid #ddd; text-align: left">
                学期管理
            </div>
            <div class="search">
                <!-- <el-form class="demo-ruleForm">
                  <el-form-item>
                    <el-button
                      icon="el-icon-delete"
                      type="primary"
                      @click="batchDelete(tableChecked)"
                      >批量删除</el-button
                    >

                    <el-button icon="el-icon-upload2" type="primary" @click="upload"
                      >导入</el-button
                    >
                    <el-button icon="el-icon-download" type="primary" @click="download"
                      >导出</el-button
                    >
                  </el-form-item>
                </el-form> -->
                <el-form :model="search" class="demo-ruleForm" inline>
                    <el-form-item style="margin-bottom: 0" v-permission="'eduTerm:queryEduTermByStartYearAndGradeName'">
                        <el-input
                                v-model="search.name"
                                clearable
                                @input="() => (search.name = search.name.trim())"
                                placeholder="年级名称/开始年份/最高学期">
                            <el-button icon="el-icon-search" @click="yimianxuanran" slot="append"></el-button>
                        </el-input>
                    </el-form-item>
                    <el-button
                            icon="el-icon-circle-plus-outline"
                            type="primary"
                            @click="addcreate()"
                            v-permission="'eduTerm:addEduTermBySiteId'">
                        添加
                    </el-button>
                    <el-button icon="el-icon-s-tools" type="primary" @click="editor()" v-permission="'eduTerm:setTime'">
                        时间设置
                    </el-button>
                </el-form>
            </div>
            <div class="table-wrap">
                <el-table
                    size="medium"
                    height="650px"
                    max-height="650px"
                    :header-cell-style="{'text-align':'center'}"
                    :cell-style="{'text-align':'center'}"
                    border
                    ref="multipleTable"
                    :data="tableData"
                    @selection-change="handleSelectionChange">
                    <el-table-column type="selection" width="55" ></el-table-column>
                    <el-table-column label="序号" width="50" type="index" >
                    </el-table-column>
                    <el-table-column label="年级名称" prop="gradeName" ></el-table-column>
                    <el-table-column label="开始年份" width="300" prop="startYear" sortable >
                        <template slot-scope="scope">
                            <el-date-picker
                                    style="width: 210px"
                                    v-model="scope.row.startYear"
                                    type="year"
                                    placeholder="选择年"
                                    @change="endStartTime(scope.row)">
                            </el-date-picker>
                        </template>
                    </el-table-column>
                    <el-table-column label="当前学期" prop="currentTerm" ></el-table-column>
                    <el-table-column label="最高学期" prop="maxTerm" ></el-table-column>
                    <el-table-column label="上学期开始时间" prop="lastStartTime" sortable >
                    </el-table-column>
                    <el-table-column label="上学期结束时间" prop="lastEndTime" sortable >
                    </el-table-column>
                    <el-table-column label="下学期开始时间" prop="nextStartTime" sortable >
                    </el-table-column>
                    <el-table-column label="下学期结束时间" prop="nextEndTime" sortable >
                    </el-table-column>
                </el-table>
                <!-- 分页 -->
                <div class="fenye">
                    <el-pagination
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                            :current-page="this.pageNum"
                            :page-sizes="[10, 20, 50, 100]"
                            :page-size="this.pageSize"
                            layout="total, sizes, prev, pager, next, jumper"
                            :total="total"
                    >
                    </el-pagination>
                </div>
            </div>
        </div>
        <!-- 学期时间设置 -->
        <el-dialog
                title="学期时间设置"
                :visible.sync="dialogVisible"
                width="30%"
                style="text-align: left"
        >
            <el-form
                    label-position="left"
                    label-width="150px"
                    :model="updateBo"
                    ref="ruleForms"
                    :rules="teacherRule"
                    class="demo-ruleForm"
            >
                <el-form-item label="上学期开始时间：" prop="lastStartTimes">
                    <el-date-picker
                            v-model="updateBo.lastStartTimes"
                            type="datetime"
                            placeholder="选择日期时间"
                    >
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="上学期结束时间：" prop="lastEndTimes">
                    <el-date-picker
                            v-model="updateBo.lastEndTimes"
                            type="datetime"
                            placeholder="选择日期时间"
                    >
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="下学期开始时间：" prop="nextStartTimes">
                    <el-date-picker
                            v-model="updateBo.nextStartTimes"
                            type="datetime"
                            placeholder="选择日期时间"
                    >
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="下学期结束时间：" prop="nextEndTimes">
                    <el-date-picker
                            type="datetime"
                            placeholder="选择日期时间"
                            v-model="updateBo.nextEndTimes"
                    >
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="最高学期：" prop="maxTerms">
                    <el-input v-model="updateBo.maxTerms" placeholder="请输入最高学期 如 3" type="number" style="width: 220px"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="updateCenter"
        >确 定</el-button
        >
      </span>
        </el-dialog>
        <!-- 新增 -->
        <el-dialog
                title="创建"
                :visible.sync="dialogVisible1"
                width="30%"
        >
            <el-form
                    label-position="right"
                    label-width="150px"
                    :rules="teacherRulees"
                    :model="addObject"
                    ref="ruleForm"
                    class="demo-ruleForm item"
            >
                <el-form-item label="开始年份" prop="startYear">
                    <el-date-picker
                        style="width: 200px;"
                            @change="startYear"
                            v-model="addObject.startYear"
                            type="year"
                            placeholder="选择日期时间"
                    >
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="年级名称" prop="gradeId">
                    <el-select style="width: 200px;" v-model="addObject.gradeId" placeholder="请选择">
                        <el-option
                                v-for="item in option"
                                :key="item.id"
                                :label="item.gradeName"
                                :value="item.id"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="上学期开始时间" prop="lastStartTime">
                    <el-date-picker
                        style="width: 200px;"
                            v-model="addObject.lastStartTime"
                            type="datetime"
                            placeholder="选择日期时间"
                            @change="lastStartTime"
                    >
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="上学期结束时间" prop="lastEndTime">
                    <el-date-picker
                        style="width: 200px;"
                            v-model="addObject.lastEndTime"
                            type="datetime"
                            placeholder="选择日期时间"
                            @change="lastEndTime"
                    >
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="下学期开始时间" prop="nextStartTime">
                    <el-date-picker
                        style="width: 200px;"
                            v-model="addObject.nextStartTime"
                            type="datetime"
                            placeholder="选择日期时间"
                            @change="nextStartTime"
                    >
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="下学期结束时间" prop="nextEndTime">
                    <el-date-picker
                        style="width: 200px;"
                            v-model="addObject.nextEndTime"
                            type="datetime"
                            placeholder="选择日期时间"
                            @change="nextEndTime"
                    >
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="最高学期" prop="maxTerm">
                    <el-input  v-model="addObject.maxTerm" placeholder="请输入最高学期 如 3" type="number" style="width: 200px"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="addcreatebutton"
        >确 定</el-button
        >
      </span>
        </el-dialog>
    </div>
</template>
<script>
    export default {
        created() {
            this.yimianxuanran()//页面表格渲染
            this.gradList()
        },
        data() {
            return {
                year: "",
                value3: '你好',
                total: 0,
                pageNum: 1,
                pageSize: 10,
                currentPage4: 4,
                textarea: "",

                search: {
                    name: "",
                },
                teacherForm: {
                    name: "",
                },

                tableChecked: [],
                tableData: [],
                option: [],
                correspondType: [
                    {
                        value: 0,
                        label: "全部",
                    },
                    {
                        value: 1,
                        label: "函授站",
                    },
                    {
                        value: 2,
                        label: "学习中心",
                    },
                    {
                        value: 3,
                        label: "函授站及学习中心",
                    },
                ],
                dialogVisible: false,
                dialogVisible1: false,
                dialogVisibleUpload: false,
              updateBo: {
                ids: '',
                lastStartTimes: "", //上学期开始时间
                lastEndTimes: "", //上学期结束时间
                nextStartTimes: "", //下学期开始时间
                maxTerms: "",
                nextEndTimes: "", //下学期结束时间
              },
              teacherRule: {
                  lastStartTimes: [{required:true, message: "此项是必填项", trigger: 'blur'}],
                  lastEndTimes: [{required:true, message: "此项是必填项", trigger: 'blur'}],
                  nextStartTimes: [{required:true, message: "此项是必填项", trigger: 'blur'}],
                  nextEndTimes: [{required:true, message: "此项是必填项", trigger: 'blur'}],
                  maxTerms: [{required:true, message: "此项是必填项", trigger: 'blur'}],
                },
              addObject: {
                startYear: "", //开始年份
                gradeId: 1,  //年级名称缩印
                lastStartTime: "", //上学期开始时间
                lastEndTime: "", //上学期结束时间
                nextStartTime: "", //下学期开始时间
                nextEndTime: "", //下学期结束时间
                maxTerm: 0, //最高学期
              }, //添加
              teacherRulees: {
                lastStartTime: [{required:true, message: "此项是必填项", trigger: 'blur'}],
                startYear: [{required:true, message: "此项是必填项", trigger: 'blur'}],
                gradeId: [{required:true, message: "此项是必填项", trigger: 'blur'}],
                lastEndTime: [{required:true, message: "此项是必填项", trigger: 'blur'}],
                nextStartTime: [{required:true, message: "此项是必填项", trigger: 'blur'}],
                nextEndTime: [{required:true, message: "此项是必填项", trigger: 'blur'}],
                maxTerm: [{required:true, message: "此项是必填项", trigger: 'blur'}],
              },
                options: [
                    {
                        value: 0,
                        label: "一年级",
                    },
                    {
                        value: 1,
                        label: "二年级",
                    },
                    {
                        value: 2,
                        label: "三年级",
                    },
                    {
                        value: 3,
                        label: "四年级",
                    },
                    {
                        value: 4,
                        label: "五年级",
                    },
                ],
                value: "",
                pickerOptions: {
                    shortcuts: [
                        {
                            text: "今天",
                            onClick(picker) {
                                picker.$emit("pick", new Date());
                            },
                        },
                        {
                            text: "昨天",
                            onClick(picker) {
                                const date = new Date();
                                date.setTime(date.getTime() - 3600 * 1000 * 24);
                                picker.$emit("pick", date);
                            },
                        },
                        {
                            text: "一周前",
                            onClick(picker) {
                                const date = new Date();
                                date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
                                picker.$emit("pick", date);
                            },
                        },
                    ],
                },
                value1: "",//选择日期时间
            };
        },
        methods: {
            //选择修改日期
            endStartTime(row) {
                this.getPermission('eduTerm:updateTimeYear', () => {
                    if (row.startYear != null) {
                        let year = row.startYear.formatToLocalDateTime()
                        row.startYear = year.substring(0, year.indexOf('-'))
                        row.startYear = parseInt(row.startYear);
                    }
                    this.$axios_supermall.post("/hnjxjy-core/eduTerm/updateTimeYear",
                        {
                            "id": row.id,
                            "startYear": row.startYear
                        }).then(resp => {
                        if (resp.data.code == 2000) {
                            this.$message.success("修改成功");
                            this.yimianxuanran();
                        }
                    });
                });
            },
            // 页面表格渲染
            yimianxuanran() {
                this.getPermission('eduTerm:queryEduTermByStartYearAndGradeName', () => {
                    this.openLoadingView();
                    this.$axios_supermall.get("/hnjxjy-core/eduTerm/queryEduTermByStartYearAndGradeName?page=" + this.pageNum + "&pageSize=" + this.pageSize
                        + "&yearNameOrGradeName=" + this.search.name
                    ).then(resp => {
                        if (resp.data.code == 2000) {
                            this.tableData = resp.data.data.list;
                            this.total = resp.data.data.total;
                            this.tableData.forEach(item => {
                                item.startYear += "-01-01 00:00:00"
                            })
                        }
                    });
                });
            },

            handleSearch() {
            },
            // 开始年份
            startYear(value) {
                this.addObject.startYear = value.formatToLocalDateTime()
            },
            // 年级名称索引
            gradeId(value) {
                this.addObject.gradeId = value

            },
            //上学期开始时间
            lastStartTime(value) {
                this.addObject.lastStartTime = value.formatToLocalDateTime();
            },
            //上学期结束时间
            lastEndTime(value) {
                this.addObject.lastEndTime = value.formatToLocalDateTime();
            },
            //下学期开始时间
            nextStartTime(value) {
                this.addObject.nextStartTime = value.formatToLocalDateTime();
            },
            //下学期结束时间
            nextEndTime(value) {
                this.addObject.nextEndTime = value.formatToLocalDateTime();
            },
            // 最高学期
            maxTerm(value) {
                this.addObject.maxTerm = value
            },
            // 添加-按钮
            addcreate() {
                this.addObject = {};
                this.dialogVisible1 = true;
            },
            //修改信息
            updateCenter() {
                let ids = "";
                this.tableChecked.forEach(item => {
                    ids = ids + item.id + ",";
                })
                ids = ids.substr(0, ids.length - 1);
              this.$refs['ruleForms'].validate((valid) => {
                    if (valid) {
                      this.$axios_supermall.post("hnjxjy-core/eduTerm/setTime", {
                        ids: ids,
                        lastStartTime: this.updateBo.lastStartTimes.formatToLocalDateTime(),
                        lastEndTime: this.updateBo.lastEndTimes.formatToLocalDateTime(),
                        nextStartTime: this.updateBo.nextStartTimes.formatToLocalDateTime(),
                        nextEndTime: this.updateBo.nextEndTimes.formatToLocalDateTime(),
                        maxTerm: this.updateBo.maxTerms,
                      }).then(resp => {
                        if (resp.data.code == 2000) {
                          this.$message.success("操作成功");
                          this.dialogVisible = false;
                          this.yimianxuanran();
                        }
                      });
                      this.$refs['ruleForms'].resetFields();
                    }
              });
            },
            //查询年级
            gradList() {
                this.$axios_supermall.post("/hnjxjy-core/eduGrade/listGL").then(resp => {
                    if (resp.data.code == 2000) {
                        this.option = resp.data.data;
                    }
                })
            },
            //添加-弹窗-确认按钮
            addcreatebutton() {
                if (this.addObject.startYear != null && this.addObject.startYear != "") {
                    this.addObject.startYear = this.addObject.startYear.substring(0, this.addObject.startYear.indexOf('-'))
                }
              this.$refs['ruleForm'].validate((valid) => {
                if (valid) {
                  this.$axios_supermall.post("hnjxjy-core/eduTerm/addEduTermBySiteId", {
                    "startYear": this.addObject.startYear,
                    "gradeId": this.addObject.gradeId,
                    "lastStartTime": this.addObject.lastStartTime,
                    "lastEndTime": this.addObject.lastEndTime,
                    "nextEndTime": this.addObject.nextEndTime,
                    "nextStartTime": this.addObject.nextStartTime,
                    "maxTerm": this.addObject.maxTerm,
                  }).then(resp => {
                    if (resp.data.code == 2000) {
                      this.$message.success("操作完成");
                      this.yimianxuanran();
                      this.dialogVisible1 = false;
                    }
                  })
                }
                });
            },

            // 批量删除
            batchDelete(row) {
                if (this.tableChecked == "") {
                    this.$message.error("请至少选择一项记录");
                } else {
                    this.$confirm(`您确定要删除${row.length}数据吗?`, "提示", {
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                        type: "warning",
                    })
                        .then(() => {
                            this.$message({
                                type: "success",
                                message: "删除成功!",
                            });
                        })
                        .catch(() => {
                            this.$message({
                                type: "info",
                                message: "已取消删除",
                            });
                        });
                }
            },
            handleSelectionChange(val) {
                this.tableChecked = val;
            },
            // 点击修改
            editor() {
                if (this.tableChecked.length <= 0) {
                    this.$message.warning("请选择条目");
                    return;
                }
                this.updateBo = {

                };
                this.dialogVisible = true;
            },
            handleSizeChange(val) {
                this.pageSize = val;
                this.yimianxuanran();
            },
            handleCurrentChange(val) {
                this.pageNum = val;
                this.yimianxuanran();
            },
            // 点击删除
            deleteCorrespond() {
                this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                })
                    .then(() => {
                        this.$message({
                            type: "success",
                            message: "删除成功!",
                        });
                    })
                    .catch(() => {
                        this.$message({
                            type: "info",
                            message: "已取消删除",
                        });
                    });
            },
        },
    };
</script>
<style>
.item .el-form-item__label{
  color: #64666A;
  font-weight: bold;
  text-align: left;
  padding-left: 30px;
}
    .search {
        text-align: left;
        margin-top: 1rem;
        /* margin-left: 1rem; */
        padding: 1rem;
    }

    .table-wrap {
        overflow: auto;
        /* margin-left: 1rem; */
        /* margin-bottom: 1rem; */
        padding: 1rem;
    }

    .demo-ruleForm {
        /* width: 90%; */
        height: calc(100% - 125px);
    }

    .el-form-input-width {
        width: 100%;
    }

    .rigth {
        width: 93%;
        display: inline-block;
        height: 870px;
        overflow-y: scroll;
        /* background-color: #fff; */
        background-color: rgba(164, 174, 224, 0.11);
        float: right;
    }

    .left {
        width: 7%;
        display: inline-block;
    }

    .portrait_Sprite {
        padding: 0.2rem 1rem;
        display: inline-block;
        background-color: skyblue;
        color: #fff;
        border-radius: 10%;
        font-size: 13px;
    }

    /* 分页 */
    .fenye {
        /* overflow: hidden; */
        overflow-x: auto;
    }

    @media (max-width: 750px) {
        .left {
            display: none;
        }

        .rigth {
            width: 100%;
        }

        .search {
            padding: 0;
        }

        .table-wrap {
            padding: 0;
        }
    }
</style>
