<template>
    <div style="height:400px;width: 100%;position:relative;">
        <div id="regionCharts" style="width: 100%;height:400px;float:left;"></div>
        <div style="float:left;position: absolute;right:10px;top:20px" >
            <el-select v-model="areaId" placeholder="请选择" @change="drawregionCharts">
                <el-option
                        v-for="item in list"
                        :key="item.id"
                        :label="item.areaName"
                        :value="item.id">
                </el-option>
            </el-select>
        </div>
    </div>
</template>

<script>
    //region 省市js
    import anhui from 'echarts/map/json/province/anhui.json'
    import aomen from 'echarts/map/json/province/aomen.json'
    import beijing from 'echarts/map/json/province/beijing.json'
    import chongqing from 'echarts/map/json/province/chongqing.json'
    import fujian from 'echarts/map/json/province/fujian.json'
    import gansu from 'echarts/map/json/province/gansu.json'
    import guangdong from 'echarts/map/json/province/guangdong.json'
    import guangxi from 'echarts/map/json/province/guangxi.json'
    import guizhou from 'echarts/map/json/province/guizhou.json'
    import hainan from 'echarts/map/json/province/hainan.json'
    import hebei from 'echarts/map/json/province/hebei.json'
    import heilongjiang from 'echarts/map/json/province/heilongjiang.json'
    import henan from 'echarts/map/json/province/henan.json'
    import hubei from 'echarts/map/json/province/hubei.json'
    import hunan from 'echarts/map/json/province/hunan.json'
    import jiangsu from 'echarts/map/json/province/jiangsu.json'
    import jiangxi from 'echarts/map/json/province/jiangxi.json'
    import jilin from 'echarts/map/json/province/jilin.json'
    import liaoning from 'echarts/map/json/province/liaoning.json'
    import neimenggu from 'echarts/map/json/province/neimenggu.json'
    import ningxia from 'echarts/map/json/province/ningxia.json'
    import qinghai from 'echarts/map/json/province/qinghai.json'
    import shandong from 'echarts/map/json/province/shandong.json'
    import shanghai from 'echarts/map/json/province/shanghai.json'
    import shanxi from 'echarts/map/json/province/shanxi.json'
    import shanxi1 from 'echarts/map/json/province/shanxi1.json'
    import sichuan from 'echarts/map/json/province/sichuan.json'
    import taiwan from 'echarts/map/json/province/taiwan.json'
    import tianjin from 'echarts/map/json/province/tianjin.json'
    import xianggang from 'echarts/map/json/province/xianggang.json'
    import xinjiang from 'echarts/map/json/province/xinjiang.json'
    import xizang from 'echarts/map/json/province/xizang.json'
    import yunnan from 'echarts/map/json/province/yunnan.json'
    //endregion
    export default {
        name: "echarsMap",
        data() {
            return {
                list: [],
                areaId: '',
                chainCity: {
                    anhui: anhui,
                    aomen: aomen,
                    beijing: beijing,
                    chongqing: chongqing,
                    fujian: fujian,
                    gansu: gansu,
                    guangdong: guangdong,
                    guangxi: guangxi,
                    guizhou: guizhou,
                    hainan: hainan,
                    hebei: hebei,
                    heilongjiang: heilongjiang,
                    henan: henan,
                    hubei: hubei,
                    hunan: hunan,
                    jiangsu: jiangsu,
                    jiangxi: jiangxi,
                    jilin: jilin,
                    liaoning: liaoning,
                    neimenggu: neimenggu,
                    ningxia: ningxia,
                    qinghai: qinghai,
                    shandong: shandong,
                    shanghai: shanghai,
                    shanxi: shanxi,
                    shanxi1: shanxi1,
                    sichuan: sichuan,
                    taiwan: taiwan,
                    tianjin: tianjin,
                    xianggang: xianggang,
                    xinjiang: xinjiang,
                    xizang: xizang,
                    yunnan: yunnan
                }
            };
        },
        mounted() {
            this.queryAllEduArea();
        },
        methods: {
            queryAllEduArea() {
                this.$axios_supermall.get('/hnjxjy-core/eduArea/queryAllEduArea').then(response => {
                    if (response.data.code === 2000) {
                        this.list = response.data.data;
                        this.areaId = this.list[0].id;
                        this.drawregionCharts();
                    }
                });
            },
            drawregionCharts() {
                this.openLoadingView();
                this.$axios_supermall.get('/hnjxjy-core/eduIndex/distributionStat?areaId=' + this.areaId).then(response => {
                    if (response.data.code === 2000) {
                        let dataList = []; // 该数据是从服务器获取到的数据
                        let cityName = this.list.find(l => l.id === this.areaId).areaName;
                        cityName = cityName.toString().replace('市', '');
                        cityName = cityName.toString().replace('省', '');
                        this.$pinyin.setOptions({checkPolyphone: false, charCase: 0});
                        let cityPinyin = this.$pinyin.getFullChars(cityName);
                        cityPinyin = cityPinyin.toLowerCase();
                        let mychart = this.$echarts.init(document.getElementById('regionCharts'));
                        this.$echarts.registerMap(cityName, this.chainCity[cityPinyin]);
                        if (response.data.data != null && response.data.data != '此省份无数据' && response.data.data.areaList.length > 0) {
                            response.data.data.areaList.forEach(a => {
                                dataList.push({name: a.areaName, value: a.count})
                            });
                        }
                        let min = response.data.data.minCount != null ? response.data.data.minCount : -2;
                        let max = response.data.data.maxCount != null ? response.data.data.maxCount : -1;
                        let visualMapShow = response.data.data.minCount != null;
                        let option = {
                            tooltip: {
                                trigger: 'item',
                                formatter: function (params) {
                                    if (params.value != null && params.value != '' && !isNaN(params.value)) {
                                        return params.name + " " + params.value;//自行定义formatter格式
                                    } else {
                                        return params.name;
                                    }
                                }
                            },
                            visualMap: {
                                min: min,
                                max: max,
                                left: 'center',
                                top: 'bottom',
                                text: ['高 <= ' + max, '低 >= ' + min],//取值范围的文字
                                inRange: {
                                    color: ['#e0ffff', '#006edd']//取值范围的颜色
                                },
                                show: visualMapShow//图注
                            },
                            geo: {
                                map: cityName,
                                roam: true,//不开启缩放和平移
                                zoom: 1.23,//视角缩放比例
                                label: {
                                    normal: {
                                        show: true,
                                        fontSize: '10',
                                        color: 'rgba(0,0,0,0.7)'
                                    },
                                    emphasis: {
                                        textStyle: {
                                            color: '#fff'
                                        }
                                    }
                                },
                                itemStyle: {
                                    normal: {
                                        borderColor: 'rgba(0, 0, 0, 0.2)',
                                    },
                                    emphasis: {
                                        areaColor: '#F3B329',//鼠标选择区域颜色
                                        shadowOffsetX: 0,
                                        shadowOffsetY: 0,
                                        shadowBlur: 20,
                                        borderWidth: 0,
                                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                                    }
                                }
                            },
                            series: [
                                {
                                    name: '信息量',
                                    type: 'map',
                                    geoIndex: 0,
                                    data: dataList,
                                }
                            ]
                        };
                        mychart.setOption(option);
                        this.$nextTick(() => {
                            mychart.resize() // 这里是为了解决，tab刷新的时候，图表不刷新的问题。
                        })
                    }
                });

            }
        }
    };
</script>

<style scoped>
    .o-echarts {
        min-width: 30px;
        min-height: 30px;
        width: 100%;
        height: 100%;
    }
</style>