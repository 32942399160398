<template>
    <div>
        <div>
            <div style="padding: 1rem; border-bottom: 1px solid #ddd; text-align: left">
                管理员列表
            </div>
            <div class="public-global-search">
                <el-form class="demo-ruleForm">
                    <el-form-item>
                        <el-button
                                icon="el-icon-delete"
                                type="primary"
                                @click="batchDelete(tableChecked)"
                                v-permission="'eduAdminTeacher:deleted'">
                            批量删除
                        </el-button>
                        <el-button icon="el-icon-edit" type="primary" @click="addcreate()"
                                   v-permission="'eduAdminTeacher:insert'">
                            添加
                        </el-button>
                        <el-button icon="el-icon-upload2" type="primary" @click="upload"
                                   v-permission="'eduAdminTeacher:import'">
                            导入
                        </el-button>
                        <el-button icon="el-icon-download" type="primary" @click="download"
                                   v-permission="'eduAdminTeacher：export'">
                            导出
                        </el-button>
                    </el-form-item>
                </el-form>
                <el-form :model="search" class="demo-ruleForm" inline v-permission="'eduAdminTeacher:list'">
                    <el-form-item label="姓名/工号" style="margin-bottom: 0;margin-top: 20px">
                        <el-input
                                v-model="queryBo.name"
                                clearable
                                @input="() => (queryBo.name = queryBo.name.trim())"
                                placeholder="请输入内容"
                                style="width: 200px">
                            <el-button slot="append" icon="el-icon-search" @click="Pagerendering"></el-button>
                        </el-input>

                    </el-form-item>
                    <el-form-item label="是否在任" style="margin-bottom: 0;margin-top: 20px">
                        <el-select
                                clearable
                                v-model="queryBo.online"
                                placeholder="请选择"
                                class="el-form-input-width">
                            <el-option
                                    v-for="item in onlinename"
                                    :key="item.id"
                                    :label="item.label"
                                    :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-form>
            </div>
            <div class="table-wrap">
                <el-table
                    size="medium"
                    height="600px"
                    max-height="600px"
                    :header-cell-style="{'text-align':'center'}"
                    :cell-style="{'text-align':'center'}"
                    border
                    ref="multipleTable"
                    :data="tableData"
                    @selection-change="handleSelectionChange">
                    <el-table-column type="selection" width="55" ></el-table-column>
                    <el-table-column label="序号" width="140" type="index" >
                    </el-table-column>
                    <el-table-column label="姓名" width="180" prop="name" sortable >
                    </el-table-column>
                    <el-table-column label="工号" width="200" prop="jobNo" sortable >
                    </el-table-column>
                    <el-table-column label="身份证号" width="200" prop="idCard" sortable >
                    </el-table-column>
                    <el-table-column label="研究方向" width="140" prop="profDir" >
                    </el-table-column>
                    <el-table-column label="职称" width="150" prop="jodTitle" >
                    </el-table-column>
                    <el-table-column label="学历" width="150" prop="education" >
                    </el-table-column>
                    <el-table-column label="在任" width="140" prop="online" sortable >
                    </el-table-column>
                    <el-table-column label="身份类型" width="160" prop="roleType" >
                    </el-table-column>
                    <el-table-column label="教师类型" width="160" prop="teacherType" >
                    </el-table-column>
                    <el-table-column label="移动电话" width="200" prop="phoneNum" >
                    </el-table-column>
                    <el-table-column label="固定电话" width="200" prop="telNum" >
                    </el-table-column>
                    <el-table-column label="电子邮箱" width="200" prop="email" >
                    </el-table-column>
                    <el-table-column label="邮政编号" width="200" prop="postCode" >
                    </el-table-column>
                    <el-table-column label="QQ" width="160" prop="qqNum" >
                    </el-table-column>
                    <el-table-column label="微信" width="160" prop="wechart" >
                    </el-table-column>
                    <el-table-column label="联系地址" width="250" prop="contactAddress" >
                    </el-table-column>
                    <el-table-column label="操作" width="160" fixed="right" >
                        <template slot-scope="scope">
                            <el-button @click="editor(scope.row)" type="primary" size="small" v-permission="'eduAdminTeacher:update'">
                                编辑
                            </el-button>
                            <el-button
                                    @click="deleteCorrespond(scope.row)"
                                    type="danger"
                                    size="small"
                                    v-permission="'eduAdminTeacher:deleted'">
                                删除
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <!-- 分页页码 -->
                <div style="padding: 1rem">
                    <el-pagination
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                            :current-page="queryBo.page"
                            :page-sizes="[10, 20, 30, 40]"
                            :page-size="queryBo.pageSize"
                            layout="total, sizes, prev, pager, next, jumper"
                            :total="total"
                    >
                    </el-pagination>
                </div>
            </div>
        </div>
        <!-- 编辑 -->
        <el-dialog
                title="编辑"
                :visible.sync="dialogVisible"
                width="40%"
                style="text-align: left"
        >
            <p style="color: red; font-size: 14px; margin-bottom: 1rem">
                （提示：教师工号将作为教师登录帐号,身份证号后6位将作为老师的初始密码）
            </p>
            <el-form
                    :model="add"
                    :rules="teacherRules"
                    ref="ruleForm"
                    class="demo-ruleForm"
                    label-width="120px"
            >
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="上传照片：">
                            <el-upload
                                    class="upload-demo"
                                    :on-success="onSuccess"
                                    :headers="myHeaders"
                                    :action="this.TEACHERADD">
                                <el-button size="small" type="primary">点击上传</el-button><span style="color:red;font-size:14px;margin-left:1rem">支持格式jpg/png</span>
                            </el-upload>
                            <!--              <label>-->
                            <!--                <input type="file" id="file" style="display:none;"/>-->
                            <!--                <div class="portrait_Sprite">-->
                            <!--                  <span>上传照片</span>-->
                            <!--                </div>-->
                            <!--              </label>-->
                        </el-form-item>
                        <el-form-item>
                            <img class="portrait_image" :src="add.headerUrl" style="width:95%;" alt/>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="工号：" prop="jobNo">
                            <el-input
                                style="width: 200px"
                                    v-model="add.jobNo"
                                    placeholder="请输入"
                                    class="el-form-input-width"
                                    @input="() => (add.jobNo = add.jobNo.trim())"
                            ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="姓名：">
                            <el-input
                                style="width: 200px"
                                    v-model="add.name"
                                    placeholder="请输入"
                                    class="el-form-input-width"
                                    @input="() => (add.name = add.name.trim())"
                            ></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="性别：">
                            <el-select  style="width: 200px" v-model="add.sex" placeholder="请选择" prop="sex">
                                <el-option
                                        v-for="item in sexname"
                                        :key="item.id"
                                        :label="item.label"
                                        :value="item.value"
                                >
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="身份证号：" prop="idCard">
                            <el-input
                                style="width: 200px"
                                    v-model="add.idCard"
                                    placeholder="请输入"
                                    class="el-form-input-width"
                                    @input="() => (add.idCard = add.idCard.trim())"
                            ></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="专业方向：" prop="profDir">
                            <el-input
                                style="width: 200px"
                                    v-model="add.profDir"
                                    placeholder="请输入"
                                    class="el-form-input-width"
                                    @input="() => (add.profDir = add.profDir.trim())"
                            ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="职称：" prop="jodTitle">
                            <el-input
                                style="width: 200px"
                                    v-model="add.jodTitle"
                                    placeholder="请输入"
                                    class="el-form-input-width"
                                    @input="() => (add.jodTitle = add.jodTitle.trim())"
                            ></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="学历：" prop="education">
                            <el-input
                                style="width: 200px"
                                    v-model="add.education"
                                    placeholder="请输入"
                                    class="el-form-input-width"
                                    @input="() => (add.education = add.education.trim())"
                            ></el-input>
                        </el-form-item>
                        <el-form-item label="在任：" prop="online">
                            <el-select  style="width: 200px" v-model="add.online" placeholder="请选择">
                                <el-option
                                        v-for="item in onlinename"
                                        :key=" item.id"
                                        :label="item.label"
                                        :value="item.value"
                                >
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="身份类型：" prop="roleType">
                            <el-select  style="width: 200px" v-model="add.roleType" placeholder="请选择">
                                <el-option
                                        v-for="item in roleTypename"
                                        :key="item.id"
                                        :label="item.label"
                                        :value="item.value"
                                >
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="移动电话：" prop="phoneNum">
                            <el-input
                                style="width: 200px"
                                    v-model="add.phoneNum"
                                    placeholder="请输入"
                                    class="el-form-input-width"
                                    @input="() => (add.phoneNum = add.phoneNum.trim())"
                            ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="固定电话：" prop="telNum">
                            <el-input
                                style="width: 200px"
                                    v-model="add.telNum"
                                    placeholder="请输入"
                                    class="el-form-input-width"
                                    @input="() => (add.telNum = add.telNum.trim())"
                            ></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="电子邮箱：" prop="email">
                            <el-input
                                style="width: 200px"
                                    v-model="add.email"
                                    placeholder="请输入"
                                    class="el-form-input-width"
                                    @input="() => (add.email = add.email.trim())"
                            ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="邮政编码：" prop="postCode">
                            <el-input
                                style="width: 200px"
                                    v-model="add.postCode"
                                    placeholder="请输入"
                                    class="el-form-input-width"
                                    @input="() => (add.postCode = add.postCode.trim())"
                            ></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="微信：" prop="wechart">
                            <el-input
                                style="width: 200px"
                                    v-model="add.wechart"
                                    placeholder="请输入"
                                    class="el-form-input-width"
                                    @input="() => (add.wechart = add.wechart.trim())"
                            ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="QQ：" prop="qqNum">
                            <el-input
                                style="width: 200px"
                                    v-model="add.qqNum"
                                    placeholder="请输入"
                                    class="el-form-input-width"
                                    @input="() => (add.qqNum = add.qqNum.trim())"
                            ></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="教师类型：" prop="status">
                            <el-select  style="width: 200px" v-model="add.teacherType" placeholder="请选择">
                                <el-option
                                        v-for="item in statusname"
                                        :key="item.id"
                                        :label="item.label"
                                        :value="item.value"
                                >
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="工作单位：" prop="jobCompany">
                            <el-input
                                style="width: 200px"
                                    v-model="add.jobCompany"
                                    placeholder="请输入"
                                    class="el-form-input-width"
                                    @input="() => (add.jobCompany = add.jobCompany.trim())"
                            ></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="联系地址：" prop="contactAddress">
                            <el-input
                                style="width: 200px"
                                    type="textarea"
                                    :rows="2"
                                    placeholder="请输入内容"
                                    v-model="add.contactAddress"
                            >
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="备注：" prop="remark">
                            <el-input
                                style="width: 200px"
                                    type="textarea"
                                    :rows="2"
                                    placeholder="请输入内容"
                                    v-model="add.remark"
                            >
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="handleSaveamend('ruleForm')"
        >确 定</el-button
        >
      </span>
        </el-dialog>
        <!-- 新增 -->
        <el-dialog
                title="新增"
                :visible.sync="dialogVisible1"
                width="40%"
                style="text-align: left"
        >
            <p style="color: red; font-size: 14px; margin-bottom: 1rem">
                （提示：教师工号将作为教师登录帐号,身份证号后6位将作为老师的初始密码）
            </p>
            <el-form
                    :model="add"
                    :rules="teacherRules"
                    ref="ruleForm"
                    class="demo-ruleForm"
                    label-width="120px"
            >
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="上传照片：">
                            <el-upload
                                    ref="uploads"
                                    class="upload-demo"
                                    :on-success="onSuccess"
                                    :headers="myHeaders"
                                    :action="this.TEACHERADD">
                                <el-button size="small" type="primary">点击上传</el-button><span style="color:red;font-size:14px;margin-left:1rem">支持格式jpg/png</span>
                            </el-upload>
                            <!--              <label>-->
                            <!--                <input type="file" id="file" style="display:none;"/>-->
                            <!--                <div class="portrait_Sprite">-->
                            <!--                  <span>上传照片</span>-->
                            <!--                </div>-->
                            <!--              </label>-->
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="工号：" prop="jobNo">
                            <el-input
                                    v-model="add.jobNo"
                                    style="width: 200px"
                                    placeholder="请输入"
                                    class="el-form-input-width"
                                    @input="() => (add.jobNo = add.jobNo.trim())"
                            ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="姓名：">
                            <el-input
                                style="width: 200px"
                                    v-model="add.name"
                                    placeholder="请输入"
                                    class="el-form-input-width"
                                    @input="() => (add.name = add.name.trim())"
                            ></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="性别：">
                            <el-select v-model="add.sex"  style="width: 200px" placeholder="请选择" prop="sex">
                                <el-option
                                        v-for="item in sexname"
                                        :key="item.id"
                                        :label="item.label"
                                        :value="item.value"
                                >
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="身份证号：" prop="idCard">
                            <el-input
                                style="width: 200px"
                                    v-model="add.idCard"
                                    placeholder="请输入"
                                    class="el-form-input-width"
                                    @input="() => (add.idCard = add.idCard.trim())"
                            ></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="专业方向：" prop="profDir">
                            <el-input
                                style="width: 200px"
                                    v-model="add.profDir"
                                    placeholder="请输入"
                                    class="el-form-input-width"
                                    @input="() => (add.profDir = add.profDir.trim())"
                            ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="职称：" prop="jodTitle">
                            <el-input
                                style="width: 200px"
                                    v-model="add.jodTitle"
                                    placeholder="请输入"
                                    class="el-form-input-width"
                                    @input="() => (add.jodTitle = add.jodTitle.trim())"
                            ></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="学历：" prop="education">
                            <el-input
                                style="width: 200px"
                                    v-model="add.education"
                                    placeholder="请输入"
                                    class="el-form-input-width"
                                    @input="() => (add.education = add.education.trim())"
                            ></el-input>
                        </el-form-item>
                        <el-form-item label="在任：" prop="online">
                            <el-select  style="width: 200px" v-model="add.online" placeholder="请选择">
                                <el-option
                                        v-for="item in onlinename"
                                        :key=" item.id"
                                        :label="item.label"
                                        :value="item.value"
                                >
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="身份类型：" prop="roleType">
                            <el-select  style="width: 200px" v-model="add.roleType" placeholder="请选择">
                                <el-option
                                        v-for="item in roleTypename"
                                        :key="item.id"
                                        :label="item.label"
                                        :value="item.value"
                                >
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="移动电话：" prop="phoneNum">
                            <el-input
                                style="width: 200px"
                                    v-model="add.phoneNum"
                                    placeholder="请输入"
                                    class="el-form-input-width"
                                    @input="() => (add.phoneNum = add.phoneNum.trim())"
                            ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="固定电话：" prop="telNum">
                            <el-input
                                style="width: 200px"
                                    v-model="add.telNum"
                                    placeholder="请输入"
                                    class="el-form-input-width"
                                    @input="() => (add.telNum = add.telNum.trim())"
                            ></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="电子邮箱：" prop="email">
                            <el-input
                                style="width: 200px"
                                    v-model="add.email"
                                    placeholder="请输入"
                                    class="el-form-input-width"
                                    @input="() => (add.email = add.email.trim())"
                            ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="邮政编码：" prop="postCode">
                            <el-input
                                style="width: 200px"
                                    v-model="add.postCode"
                                    placeholder="请输入"
                                    class="el-form-input-width"
                                    @input="() => (add.postCode = add.postCode.trim())"
                            ></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="微信：" prop="wechart">
                            <el-input
                                style="width: 200px"
                                    v-model="add.wechart"
                                    placeholder="请输入"
                                    class="el-form-input-width"
                                    @input="() => (add.wechart = add.wechart.trim())"
                            ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="QQ：" prop="qqNum">
                            <el-input
                                style="width: 200px"
                                    v-model="add.qqNum"
                                    placeholder="请输入"
                                    class="el-form-input-width"
                                    @input="() => (add.qqNum = add.qqNum.trim())"
                            ></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="教师类型：" prop="status">
                            <el-select  style="width: 200px" v-model="add.status" placeholder="请选择">
                                <el-option
                                        v-for="item in statusname"
                                        :key="item.id"
                                        :label="item.label"
                                        :value="item.value"
                                >
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="工作单位：" prop="jobCompany">
                            <el-input
                                style="width: 200px"
                                    v-model="add.jobCompany"
                                    placeholder="请输入"
                                    class="el-form-input-width"
                                    @input="() => (add.jobCompany = add.jobCompany.trim())"
                            ></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="联系地址：" prop="contactAddress">
                            <el-input
                                style="width: 200px"
                                    type="textarea"
                                    :rows="2"
                                    placeholder="请输入内容"
                                    v-model="add.contactAddress"
                            >
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="备注：" prop="remark">
                            <el-input
                                style="width: 200px"
                                    type="textarea"
                                    :rows="2"
                                    placeholder="请输入内容"
                                    v-model="add.remark"
                            >
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible1 = false">取 消</el-button>
        <el-button type="primary" @click="handleSaveadd('ruleForm')"
        >确 定</el-button
        >
      </span>
        </el-dialog>
        <!-- 导入 上传文件 -->
        <el-dialog title="上传文件" :visible.sync="dialogVisibleUpload" width="30%">
            <div style="margin-bottom: 20px">
                <el-button type="primary" @click="downloadbutton">下载模板</el-button>
                <span style="color: red"
                >提示：导入前请先下载模板，根据模版整理好数据后上传导入.</span
                >
            </div>
            <el-upload
                    ref="upload"
                    class="upload-demo"
                    drag
                    action="#"
                    :show-file-list="false"
                    :http-request="uploadFile"
                    :before-upload="beforeUpload"
            >
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                <div class="el-upload__tip" slot="tip">
                    只能上传excel文件，且不超过50M
                </div>
            </el-upload>
        </el-dialog>
    </div>
</template>
<script>

    export default {
        created() {
            this.getPermission('eduAdminTeacher:list', () => {
                this.Pagerendering();
            });
        }, //页面创建前
        data() {
            return {
                myHeaders: {
                    'x-token': localStorage.loginStrage ? JSON.parse(localStorage.getItem('loginStrage'))['x-token'] : null,
                    'x-perm': localStorage.loginStrage ? JSON.parse(localStorage.getItem('loginStrage'))['x-perm'] : null
                },
                // 性别
                info: "站",
                online: "1",
                isRole: "",
                file: "", //上传文件参数
                total: 0, //默认总条数
                pageNum: 1, //默认每页显示1条数据
                pageSize: 12,
                currentPage4: 1, //默认在第一页
                textarea: "",
                search: {
                    name: "",
                },
                // 添加
                add: {
                    id: null,
                    jobNo: "", //工号
                    name: "", //姓名
                    sex: 0, //性别 0男 1女
                    idCard: "", //身份证号
                    profDir: "", //专业方向
                    jodTitle: "", //职称
                    education: "", //学历
                    online: 1, //是否在任 0否 1是
                    roleType: 1, //身份类型 0教师 1管理员 2教师管理员
                    phoneNum: "", //电话
                    telNum: "", //固定电话
                    email: "", //邮箱
                    postCode: "", //邮政编码
                    teacherType: "", //教师类型 0辅导教师 1主讲教师
                    wechart: "", //微信
                    qqNum: "", //qq
                    jobCompany: "", //工作地点
                    contactAddress: "", //地址
                    remark: "", //备注
                    headerUrl: "", //头像url
                 //   status: "", //是否是指导教师 0是 1否（新增，在毕业大作业指导教师列表体现)
                    IdentityStatus: 1, //1 管理员列表 2总部教师列表 3函授站教师列表
                },
                amend: {
                    jobNo: '12345622',//工号
                    name: '候桑',//姓名
                    sex: 0,//性别 0男 1女
                    idCard: '410182166505117661',//身份证号
                    profDir: '专业方向1',//专业方向
                    jodTitle: '职称1',//职称
                    education: '学历1',//学历
                    online: 0,//是否在任 0否 1是
                    roleType: 2,//身份类型 0教师 1管理员 2教师管理员
                    phoneNum: '电话1',//电话
                    telNum: '固定电话1',//固定电话
                    email: '邮箱1',//邮箱
                    postCode: '邮政编码1',//邮政编码
                    teacherType: 0,//教师类型 0辅导教师 1主讲教师 2批阅教师
                    wechart: '微信1',//微信
                    qqNum: 'qq1',//qq
                    jobCompany: '工作地点1',//工作地点
                    contactAddress: '地址1',//地址
                    remark: '备注1',//备注
                    headerUrl: '头像url1',//头像url
                    id: '6'//教师id
                },//编辑后每项内容

                tableChecked: [],
                tableData: [],
                correspondType: [
                    {
                        value: 0,
                        label: "全部",
                    },
                    {
                        value: 1,
                        label: "函授站",
                    },
                    {
                        value: 2,
                        label: "学习中心",
                    },
                    {
                        value: 3,
                        label: "函授站及学习中心",
                    },
                ],
                dialogVisible: false,
                dialogVisible1: false,
                dialogVisibleUpload: false,
                teacherRules: {
                    name: [{required: true, message: "请输入姓名", trigger: "blur"}],
                    fullName: [
                        {required: true, message: "请输入真实姓名", trigger: "blur"},
                    ],
                    sex: [{required: true, message: "请选择性别", trigger: "blur"}],
                    phone: [
                        {required: true, message: "请输入手机号码", trigger: "blur"},
                        {pattern: /^1[3456789]\d{9}$/, message: "手机号格式不正确"},
                    ],
                    email: [
                        {required: false, message: "请输入邮箱", trigger: "blur"},
                        {
                            pattern: /^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$/,
                            message: "邮箱格式不正确",
                        },
                    ],
                    idCard: [
                        {required: false, message: "请输入身份证号码", trigger: "blur"},
                        {
                            pattern: /^([1-6][1-9]|50)\d{4}(18|19|20)\d{2}((0[1-9])|10|11|12)(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/,
                            message: "身份证号格式不正确",
                        },
                    ],
                },
                //性别类别
                sexname: [
                    {
                        value: 0,
                        label: "男",
                    },
                    {
                        value: 1,
                        label: "女",
                    },
                ],
                //online是否在任 0否 1是
                onlinename: [
                    {
                        value: 0,
                        label: "是",
                    },
                    {
                        value: 1,
                        label: "否",
                    },
                ],
                // 身份类型 0教师 1管理员 2教师管理员
                roleTypename: [
                    {
                        value: 0,
                        label: "教师",
                    },
                    {
                        value: 1,
                        label: "管理员",
                    },
                    {
                        value: 2,
                        label: "教师管理员",
                    },
                ],
                // 教师类型
                statusname: [
                    {
                        value: 0,
                        label: "辅导教师",
                    },
                    {
                        value: 1,
                        label: "主讲教师",
                    },
                ],
                queryBo: {
                    page: 1,
                    pageSize: 10,
                    name: '',
                    online: '',
                  IdentityStatus: 1,
                },
            };

        },
        methods: {
            onSuccess(response, file, fileList) {
                this.add.headerUrl = response.data.fileUrl;
            },

            // 添加按钮
            handleSaveadd(row) {
                let objPar = JSON.parse(localStorage.getItem("loginStrage"));
                this.$axios_supermall({
                    method: "POST",
                    url: "hnjxjy-core/eduTeacher/add",
                    headers: objPar,
                    data: this.add,
                })
                    .then((res) => {
                        if (res.data.code == 2000) {
                            this.$message.success("操作成功");
                            this.Pagerendering();
                            this.dialogVisible1 = false;
                        } else {
                            // this.$message.warning(res.data.message);
                        }
                    })
                    .catch((err) => {
                    });
            },
            // 下载模版按键
            downloadbutton() {
              this.openLoadingView();
              let objPar = JSON.parse(localStorage.getItem("loginStrage"));
              this.$axios({
                method: "GET",
                url: "hnjxjy-core/eduTeacher/exportMB",
                responseType: "blob",
                headers: objPar,
                data: {
                  info: this.info,
                  online: this.online,
                  isRole: this.isRole,
                },
              }).then((res) => {
                let blob = new Blob([res.data], {
                  type: "application/vnd.ms-excel",
                });
                let downloadElement = document.createElement('a');
                let href = window.URL.createObjectURL(blob);
                downloadElement.href = href;
                downloadElement.download = '管理员列表导入模板.xls';　　　　　　　　　　// xxx.xls/xxx.xlsx
                document.body.appendChild(downloadElement);
                downloadElement.click();
                document.body.removeChild(downloadElement);
                window.URL.revokeObjectURL(href);
                this.$message.success("操作成功");
                this.loadingView.close();
              }).catch((err) => {
                this.$message.error("操作失败");
                this.loadingView.close();
              });
            },
            // 页面渲染
            Pagerendering() {
                this.openLoadingView();
                this.$axios_supermall.get("/hnjxjy-core/eduTeacher/listVo?page=" + this.queryBo.page + "&pageSize=" + this.queryBo.pageSize + "&name=" + this.queryBo.name + "&online=" + this.queryBo.online+"&IdentityStatus="+this.queryBo.IdentityStatus).then(resp => {
                    this.tableData = resp.data.data.list; //表格列表
                    this.total = resp.data.data.total;
                })
            },
            //基础-管理员列表编辑修改-
            handleSaveamend(row) {
                let objPar = JSON.parse(localStorage.getItem("loginStrage"));
                let formData = new FormData();
                formData.append('id', this.add.id);
                formData.append('contactAddress', this.add.contactAddress);
                formData.append('education', this.add.education);
                formData.append('email', this.add.email);
                formData.append('headerUrl', this.add.headerUrl);
                formData.append('idCard', this.add.idCard);
                // formData.append('IdentityStatus', '1');
                formData.append('jobCompany', this.add.jobCompany);
                formData.append('jobNo', this.add.jobNo);
                formData.append('jodTitle', this.add.jodTitle);
                formData.append('name', this.add.name);
                formData.append('online', this.add.online);
                formData.append('phoneNum', this.add.phoneNum);
                formData.append('postCode', this.add.postCode);
                formData.append('profDir', this.add.profDir);
                formData.append('qqNum', this.add.qqNum);
                formData.append('remark', this.add.remark);
                formData.append('roleType', this.add.roleType);
                formData.append('sex', this.add.sex);
                formData.append('teacherType', this.add.teacherType);
                formData.append('telNum', this.add.telNum);
                if(this.add.teacherType!=null){
                  this.$axios({
                    method: "PUT",
                    url: "hnjxjy-core/eduTeacher/edit",
                    headers: objPar,
                    data: formData,
                  })
                      .then((res) => {
                        if (res.data.code == 2000) {
                          this.$message.success("操作成功");
                          this.Pagerendering();
                          this.dialogVisible = false;
                        }
                      })
                      .catch((err) => {
                      });
                }else{
                  this.$message.error("教师类型不能为空")
                }

            },
            addcreate() {
                this.add = {}
                this.dialogVisible1 = true;
                this.$refs['uploads'].clearFiles() // 清除历史文件列表
            },
            // 批量删除
            batchDelete(row) {
                if (this.tableChecked == "") {
                    this.$message.error("请至少选择一项记录");
                } else {
                    this.$confirm(`您确定要删除${this.tableChecked.length}数据吗?`, "提示", {
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                        type: "warning",
                    }).then(() => {
                        let ids = "";
                        this.tableChecked.forEach(item => {
                            ids = ids + item.id + ",";
                        });
                        ids = ids.substr(0, ids.length - 1).trim();
                        this.openLoadingView();
                        this.$axios_supermall.get("/hnjxjy-core/eduTeacher/remove?ids=" + ids).then(resp => {
                            if (resp.data.code == 2000) {
                                this.$message.success("处理成功");
                                this.Pagerendering();
                            }
                            this.loadingView.close();
                        });
                    }).catch(() => {
                        this.$message({
                            type: "info",
                            message: "已取消删除",
                        });
                    });
                }
            },
            // 点击导入
            upload() {
                this.dialogVisibleUpload = true;
            },
            // 导入上传文件
            uploadFile(file) {
              let fileDate = file.file;
              let forms = new FormData();
              forms.append("file", fileDate);
              this.openLoadingView();
              this.$axios_supermall({
                responseType: "blob",
                method: "POST",
                url: "hnjxjy-core/eduTeacher/importData",
                data: forms
              }).then((res) => {
                if (res.data.type === "application/json") {
                  this.Pagerendering();
                  this.$message.success("操作成功");
                } else if (res.data.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
                  this.$message.error("操作失败");
                  let blob = new Blob([res.data], {
                    type: "application/vnd.ms-excel",
                  });
                  let downloadElement = document.createElement('a');
                  let href = window.URL.createObjectURL(blob);
                  downloadElement.href = href;
                  downloadElement.download = '问题数据.xls';　　　　　　　　　　// xxx.xls/xxx.xlsx
                  document.body.appendChild(downloadElement);
                  downloadElement.click();
                  document.body.removeChild(downloadElement);
                  window.URL.revokeObjectURL(href);
                  // let url = window.URL.createObjectURL(blob);
                  // window.location.href = url;
                }
                this.dialogVisibleUpload = false;
                this.loadingView.close();
              }).catch((err) => {
                this.$message.error("操作失败");
                this.loadingView.close();
              });
            },
            // 导入上传文件前判断文件格式
            beforeUpload(file) {
                const isXls = file.type === "application/vnd.ms-excel";
                const isXlsx =
                    file.type ===
                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
                if (!isXls && !isXlsx) {
                    this.$message.error("上传文件只能是XLS/XLSX格式！");
                    return false;
                }
                return isXls || isXlsx;
            },
            // 点击导出
            download() {
              let siteId = localStorage.getItem("siteId");
              this.openLoadingView();
              this.$axios_supermallDwon.get("hnjxjy-core/eduTeacher/export?siteId=" + siteId + "&status=1").then(resp => {
                let blob = new Blob([resp.data], {
                  type: "application/vnd.ms-excel",
                });
                let url = window.URL.createObjectURL(blob);
                window.location.href = url;
                this.$message.success("操作成功");
                this.loadingView.close();
              }).catch((err) => {
                this.$message.error("操作失败");
                this.loadingView.close();
              });
              // let objPar = JSON.parse(localStorage.getItem("loginStrage"));
              // this.$axios({
              //   method: "get",
              //   url: "hnjxjy-core/eduTeacher/export",
              //   responseType: "blob",
              //   headers: objPar,
              //   params: {
              //     info: "站", //姓名/工号
              //     online: 1, //是否在任 0否 1是
              //     IdentityStatus: 3, //身份类型（不传查所有  1 管理员列表 2总部教师列表 3函授站教师列表）
              //     siteId: 1, //函授站id
              //     isRole: "", //身份类型
              //     status: "", //1管理员名单 2总部教师名单 3.函授站教师名单
              //   },
              // })
              //     .then((res) => {
              //       let blob = new Blob([res.data], {
              //         type: "application/vnd.ms-excel",
              //       });
              //       let url = window.URL.createObjectURL(blob);
              //       window.location.href = url;
              //     })
              //     .catch((err) => {
              //     });
            },
            handleSelectionChange(val) {
                this.tableChecked = val;
            },
            // 点击修改
            editor(row) {
                this.dialogVisible = true;
                let objPar = JSON.parse(localStorage.getItem("loginStrage"));
                this.$axios({
                    method: "get",
                    url: "hnjxjy-core/eduTeacher/getOne",
                    params: {
                        id: row.id,
                    },
                    headers: objPar,
                })
                    .then((res) => {
                        this.add = res.data.data
                    })
                    .catch((err) => {
                    });
            },
            handleSizeChange(val) {
                this.queryBo.pageSize = val;
                this.Pagerendering();
            },
            handleCurrentChange(val) {
                this.queryBo.page = val;
                this.Pagerendering();
            },
            handleSearch() {
            },
            // 点击删除
            deleteCorrespond(row) {
                this.$confirm("确定要删除此数据吗?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                })
                    .then(() => {
                        this.openLoadingView();
                        this.$axios_supermall.get("/hnjxjy-core/eduTeacher/remove?ids=" + row.id).then(resp => {
                            if (resp.data.code == 2000) {
                                this.$message.success("处理成功");
                                this.Pagerendering();
                            }
                            this.loadingView.close();
                        }).catch(() => {
                            this.loadingView.close();
                        })
                    })
                    .catch(() => {
                        this.$message({
                            type: "info",
                            message: "已取消删除",
                        });
                    });
            },
        },
    };
</script>
<style scoped>
    .search {
        text-align: left;
        margin-top: 1rem;
        /* margin-left: 1rem; */
        padding: 1rem;
    }

    .table-wrap {
        overflow: auto;
        /* margin-left: 1rem; */
        /* margin-bottom: 1rem; */
        padding: 1rem;
    }

    .demo-ruleForm {
        /* width: 90%; */
        height: calc(100% - 125px);
    }

    .el-form-input-width {
        width: 100%;
    }

    .rigth {
        width: 93%;
        display: inline-block;
        height: 870px;
        overflow-y: scroll;
        /* background-color: #fff; */
        background-color: rgba(164, 174, 224, 0.11);
        float: right;
    }

    .left {
        width: 7%;
        display: inline-block;
    }

    .portrait_Sprite {
        padding: 0.2rem 1rem;
        display: inline-block;
        background-color: skyblue;
        color: #fff;
        border-radius: 10%;
        font-size: 13px;
    }
</style>
