<template>
  <div>
    <div >
      <div
        style="padding: 1rem; border-bottom: 1px solid #ddd; text-align: left"
      >
        预报名信息
      </div>
      <div class="search">
        <el-form class="demo-ruleForm">
          <el-form-item>
              <!-- el-icon-delete -->
            <el-button
                style="margin-bottom: 15px"
            icon="el-icon-delete"
             type="primary"
             @click="batchDelete"
              >删除</el-button
            >
            <el-button icon="el-icon-download" type="primary" @click="download"
              >导出</el-button
            >
            <el-button type="primary"
                       @click="handleSearch">查询</el-button
            >
            <el-button type="primary"
                       @click="reset()">重置</el-button
            >
            <el-button type="primary"
                       @click="importDialog=true">导入</el-button
            >
            <el-button type="primary"
                       @click="selects">身份证查重</el-button
            >
          </el-form-item>
        </el-form>
        <el-form :model="search" class="demo-ruleForm" label-width="105px" inline>
          <el-form-item label="姓名/证件号" style="margin-bottom:1rem">
            <el-input
              v-model="search.name"
              clearable
              @input="() => (search.name = search.name.trim())"
              placeholder="请输入内容"
            ></el-input>
          </el-form-item>
          <el-form-item label="批次" style="margin-bottom:1rem">
            <el-select
                clearable
                v-model="search.batchId"
                placeholder="请选择"
                class="el-form-input-width"
            >
              <el-option
                  v-for="item in eduGraduateWorkBatch"
                  :key="item.id"
                  :label="item.batchName"
                  :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="函授站" style="margin-bottom:1rem">
            <el-select
                clearable
                v-model="search.siteId"
                placeholder="请选择"
                class="el-form-input-width"
            >
              <el-option
                  v-for="item in site"
                  :key="item.id"
                  :label="item.siteName"
                  :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="是否录取" style="margin-bottom:1rem">
            <el-select
                v-model="search.isMatch"
                placeholder="请选择"
                class="el-form-input-width"
            >
              <el-option
                  v-for="item in correspondType"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="层次" style="margin-bottom:1rem">
            <el-select v-model="search.stageId" placeholder="请选择">
              <el-option
                  v-for="item in eduStage"
                  :key="item.id"
                  :label="item.stageName"
                  :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="选择专业" style="margin-bottom:1rem">
            <el-select
                clearable
                v-model="search.profId"
                placeholder="请选择"
                class="el-form-input-width"
            >
              <el-option
                  v-for="item in eduProfession"
                  :key="item.id"
                  :label="item.profName"
                  :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <div class="table-wrap">
        <el-table
            size="medium"
            height="500px"
            max-height="500px"
            :cell-style="{'text-align':'center'}"
            :header-cell-style="tableHeaderColor"
            class="item"
            border
            ref="multipleTable"
            :data="tableData"
            @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" width="55"> </el-table-column>
          <el-table-column label="序号" width="100" type="index">
          </el-table-column>
          <el-table-column  label="姓名" width="180" prop="name" sortable>
          </el-table-column>
          <el-table-column  label="证件号码" width="200" prop="certificateNo" sortable>
          </el-table-column>
          <el-table-column  label="函授站" width="200" prop="siteName" sortable>
          </el-table-column>
          <el-table-column  label="层次" width="240" prop="stageName">
          </el-table-column>
          <el-table-column  label="专业" width="150" prop="profName">
          </el-table-column>
          <el-table-column  label="证件类型" width="150" prop="certificateType">
          </el-table-column>
          <el-table-column  label="手机号码" width="150" prop="contactPhone">
          </el-table-column>
          <el-table-column  label="通讯地址" width="150" prop="contactAddr">
          </el-table-column>
          <el-table-column  label="报名序号" width="150" prop="id">
          </el-table-column>
          <el-table-column  label="是否录取" width="150" prop="isMatch">
          </el-table-column>
          <el-table-column label="操作" width="160" fixed="right">
            <template slot-scope="scope">
              <el-button @click="editor(scope.row)" type="primary" size="small"
              >编辑</el-button
              >
              <!--              <el-button-->
              <!--                  @click="forecastDelete(scope.row)"-->
              <!--                  type="danger"-->
              <!--                  size="small"-->
              <!--              >删除</el-button-->
              <!--              >-->
            </template>
          </el-table-column>
        </el-table>
        <div style="padding: 1rem">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="search.page"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="search.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <!-- 编辑 -->
    <el-dialog
      title="编辑"
      :visible.sync="dialogVisible"
      width="40%"
      style="text-align: left"
    >
      <el-form
        :model="teacherForm"
        :rules="teacherRules"
        ref="ruleForm"
        class="demo-ruleForm"
        label-width="120px"
      >
         <el-row>
          <el-col :span="12">
            <el-form-item label="姓名：" prop="name">
              <el-input
                v-model="teacherForm.name"
                placeholder="请输入"
                class="el-form-input-width"
                @input="() => (teacherForm.name = teacherForm.name.trim())"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="证件类型：" prop="certificateType">
              <el-select v-model="teacherForm.certificateType" placeholder="请选择">
                <el-option
                v-for="item in  options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
                </el-option>
            </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">

            <el-form-item label="报考层次" style="margin-bottom: 1rem">
              <el-select v-model="teacherForm.stageId" placeholder="请选择">
                <el-option
                    v-for="item in eduStage"
                    :key="item.id"
                    :label="item.stageName"
                    :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="证件号码：" prop="certificateNo">
              <el-input
                  v-model="teacherForm.certificateNo"
                  placeholder="请输入"
                  class="el-form-input-width"
                  @input="() => (teacherForm.certificateNo = teacherForm.certificateNo.trim())"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="联系电话：" prop="contactPhone">
              <el-input
                  v-model="teacherForm.contactPhone"
                  placeholder="请输入"
                  class="el-form-input-width"
                  @input="() => (teacherForm.contactPhone = teacherForm.contactPhone.trim())"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="通信地址：" prop="contactAddr">
              <el-input
                  v-model="teacherForm.contactAddr"
                  placeholder="请输入"
                  class="el-form-input-width"
                  @input="() => (teacherForm.contactAddr = teacherForm.contactAddr.trim())"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">关 闭</el-button>
        <el-button type="primary" @click="handleSave('ruleForm')"
          >保存后关闭</el-button
        >
      </span>
    </el-dialog>
    <el-dialog title="导入" :visible.sync="importDialog" width="30%">
      <div style="margin-bottom: 20px">
        <el-button type="primary" @click="downloadTemplate">下载模板</el-button>
        <span style="color: red">提示：导入前请先下载模板，根据模版整理好数据后上传导入.</span>
      </div>
      <el-upload
          class="upload-demo"
          drag
          action="#"
          :show-file-list="false"
          :http-request="uploadFile"
          :before-upload="beforeUpload">
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        <div class="el-upload__tip" slot="tip">
          只能上传excel文件，且不超过50M
        </div>
      </el-upload>
    </el-dialog>
    <!-- 新增 -->
    <!-- <el-dialog
      title="新增"
      :visible.sync="dialogVisible1"
      width="30%"
      style="text-align: left"
    >
      <el-form
        :model="teacherForm"
        :rules="teacherRules"
        ref="ruleForm"
        class="demo-ruleForm"
        label-width="120px"
      >
        <el-form-item label="课程编号：" prop="name">
          <el-input
            v-model="teacherForm.name"
            placeholder="请输入"
            class="el-form-input-width"
            @input="() => (teacherForm.name = teacherForm.name.trim())"
          ></el-input>
        </el-form-item>
        <el-form-item label="课程名称：" prop="name">
          <el-input
            v-model="teacherForm.name"
            placeholder="请输入"
            class="el-form-input-width"
            @input="() => (teacherForm.name = teacherForm.name.trim())"
          ></el-input>
        </el-form-item>
        <el-form-item label="课程类型：" prop="name">
          <el-select v-model="value" placeholder="请选择">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible1 = false">取 消</el-button>
        <el-button type="primary" @click="handleSave('ruleForm')"
          >确 定</el-button
        >
      </span>
    </el-dialog> -->
    <!-- 设置制作教师 -->
    <!-- <el-dialog
      title="设置制作教师"
      :visible.sync="dialogVisible2"
      width="50%"
      style="text-align: left"
    >
      <el-form :model="search" class="demo-ruleForm" inline>
        <el-form-item style="margin-bottom: 0">
          <el-input
            v-model="search.name"
            clearable
            @input="() => (search.name = search.name.trim())"
            placeholder="教师名字/工号"
            style="width: 300px"
          ></el-input>
          <el-button icon="el-icon-search" @click="handleSearch"></el-button>
        </el-form-item>
      </el-form>
      <div style="margin-top:1rem">
        <el-table :data="tableData" border style="width: 100%">
            <el-table-column
                type="selection"
                width="55">
                </el-table-column>
          <el-table-column prop="date" label="教师工号" >
          </el-table-column>
          <el-table-column prop="name" label="教师姓名" >
          </el-table-column>
           <el-table-column prop="name" label="性别">
          </el-table-column>
           <el-table-column prop="name" label="院系">
          </el-table-column>
           <el-table-column prop="name" label="教师类型">
          </el-table-column>
          <el-table-column prop="address" label="教师职称"> </el-table-column>
           <el-table-column label="操作" fixed="right">
            <template slot-scope="scope">
              <el-button
                @click="production(scope.row)"
                type="primary"
                size="small"
                >保存</el-button
              >
            </template>
          </el-table-column>
        </el-table>
         <div style="padding: 1rem;text-align:center">
          <el-pagination
            @size-change="handleSizeChange1"
            @current-change="handleCurrentChange1"
            :current-page="currentPage1"
            :page-sizes="[100, 200, 300, 400]"
            :page-size="100"
            layout="total, sizes, prev, pager, next, jumper"
            :total="400"
          >
          </el-pagination>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible2 = false">取 消</el-button>
        <el-button type="primary" @click="handleSave('ruleForm')"
          >确 定</el-button
        >
      </span>
    </el-dialog> -->
    <!-- 导入 上传文件 -->
    <el-dialog title="上传文件" :visible.sync="dialogVisibleUpload" width="30%">
      <div style="margin-bottom: 20px">
        <el-button type="primary">下载模板</el-button>
        <span style="color: red"
          >提示：导入前请先下载模板，根据模版整理好数据后上传导入.</span
        >
      </div>
      <el-upload
        class="upload-demo"
        drag
        action="#"
        :show-file-list="false"
        :http-request="uploadFile"
        :before-upload="beforeUpload"
      >
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        <div class="el-upload__tip" slot="tip">
          只能上传excel文件，且不超过50M
        </div>
      </el-upload>
    </el-dialog>
    <el-dialog class="add" :title="title" :visible.sync="putDialogSelect"  center>
      <div>
        <el-button type="primary" icon="el-icon-circle-close" size="small" @click="putDialogSelect=false">关闭</el-button>
        <el-button type="primary" icon="el-icon-folder-add" size="small" @click="queryLooks">批量设置有效</el-button>
        <el-button type="primary" icon="el-icon-folder-add" size="small" @click="queryLookss">批量设置无效</el-button>
      </div>
      <div style="margin-top: 20px">
        <el-input style="width: 30vw" placeholder="身份证/姓名"  v-model="searchs.info">
          <el-button slot="append" icon="el-icon-search" type="primary" @click="handleSearchs"></el-button>
        </el-input>
      </div>
      <div style="margin-top: 20px">
        <el-table
            size="medium"
            :header-cell-style="{'text-align':'center'}"
            :cell-style="{'text-align':'center'}"
            ref="multipleTable"
            :data="tableData2"
            border
            tooltip-effect="dark"
            style="width: 100%"
            @selection-change="selectStudent">
          <el-table-column type="selection" width="55"></el-table-column>
          <el-table-column
             type="index"
             width="80px"
              label="序号">
          </el-table-column>
          <el-table-column
              prop="name"
              align="center"
              show-overflow-tooltip
              label="姓名"
          >
          </el-table-column>
          <el-table-column
              prop="certificateNo"
              align="center"
              show-overflow-tooltip
              label="身份证"
          >
          </el-table-column>
          <el-table-column
              prop="siteName"
              align="center"
              show-overflow-tooltip
              label="函授点"
          >
          </el-table-column>
          <el-table-column
              prop="delFlag"
              align="center"
              show-overflow-tooltip
              label="是否有效"
          >
            <template slot-scope="scope">
              <span v-if="scope.row.delFlag==0">有效</span>
              <span v-if="scope.row.delFlag==1">无效</span>
            </template>
          </el-table-column>

        </el-table>
        <el-pagination
            @size-change="handleSizeChange2"
            @current-change="handleCurrentChange2"
            :current-page="searchs.page"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="searchs.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total2">
        </el-pagination>
      </div>
    </el-dialog>
    <!-- 设置教师 -->
      <!-- <el-dialog title="设置教师" :visible.sync="outerVisible">
           <div style="margin-top:1rem">
        <el-table :data="tableData" border style="width: 100%">
            <el-table-column
                type="selection"
                width="55">
                </el-table-column>
          <el-table-column prop="date" label="教师工号" >
          </el-table-column>
          <el-table-column prop="name" label="教师姓名" >
          </el-table-column>
           <el-table-column prop="name" label="性别">
          </el-table-column>
           <el-table-column prop="name" label="院系">
          </el-table-column>
           <el-table-column prop="name" label="教师类型">
          </el-table-column>
          <el-table-column prop="address" label="教师职称"> </el-table-column>
        </el-table>
         <div style="padding: 1rem;text-align:center">
          <el-pagination
            @size-change="handleSizeChange2"
            @current-change="handleCurrentChange2"
            :current-page="currentPage2"
            :page-sizes="[100, 200, 300, 400]"
            :page-size="100"
            layout="total, sizes, prev, pager, next, jumper"
            :total="400"
          >
          </el-pagination>
        </div>
      </div>
        <el-dialog
        width="50%"
        title="添加教师"
        :visible.sync="innerVisible"
        append-to-body>
        <el-form :model="search" class="demo-ruleForm" inline>
        <el-form-item style="margin-bottom: 0">
          <el-input
            v-model="search.name"
            clearable
            @input="() => (search.name = search.name.trim())"
            placeholder="姓名/工号"
            style="width: 300px"
          ></el-input>
          <el-button icon="el-icon-search" @click="handleSearch"></el-button>
        </el-form-item>
      </el-form>
      <div style="margin-top:1rem">
        <el-table :data="tableData" border style="width: 100%">
            <el-table-column
                type="selection"
                width="55">
                </el-table-column>
          <el-table-column prop="date" label="教师工号" >
          </el-table-column>
          <el-table-column prop="name" label="教师姓名" >
          </el-table-column>
           <el-table-column prop="name" label="性别">
          </el-table-column>
           <el-table-column prop="name" label="院系">
          </el-table-column>
           <el-table-column prop="name" label="教师类型">
          </el-table-column>
          <el-table-column prop="address" label="教师职称"> </el-table-column>
        </el-table>
         <div style="padding: 1rem;text-align:center">
          <el-pagination
            @size-change="handleSizeChange1"
            @current-change="handleCurrentChange1"
            :current-page="currentPage1"
            :page-sizes="[100, 200, 300, 400]"
            :page-size="100"
            layout="total, sizes, prev, pager, next, jumper"
            :total="400"
          >
          </el-pagination>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="innerVisible = true">保存后关闭</el-button>
        </div>
        </el-dialog>

      <div slot="footer" class="dialog-footer">
      <el-button @click="outerVisible = false">删 除</el-button>
      <el-button type="primary" @click="innerVisible = true">添加教师</el-button>
      </div>
  </el-dialog> -->
  </div>
</template>
<script>
export default {
  data() {
    return {
      eduStage: [],
      eduProfession: [],
      site:[],
      eduGraduateWorkBatch:[],
      input:"",
      total: 0,
      pageNum: 1,
      pageSize: 10,
      currentPage4: 1,
      currentPage1: 4,
      currentPage2: 4,
      textarea: "",
      search: {
        id:"",
        isMatch: "",
        name: "",
        batchId: "",
        siteId: "",
        profId: "",
        stageId: "",
        page: 1,
        pageSize: 10,
      },
      searchs:{
        info:"",
        page:1,
        pageSize:10,
      },
      teacherForm: {
        id:"",
        name: "",
        stageId:"",
        certificateType:"",
        certificateNo:"",
        contactPhone:"",
        contactAddr:""
      },
      outerVisible: false,
      innerVisible: false,
      tableChecked: [],
      // 获取所有的用户信息
      tableData: [],
      // 储存复选框选中的数据
      multipleTable:[],
      correspondType: [
        {
          value: 0,
          label: "否",
        },
        {
          value: 1,
          label: "是",
        },
      ],
      dialogVisible: false,
      importDialog: false,
      putDialogSelect: false,
      tableData2: [],
      total2: 0,
      title: "",
      dialogVisible1: false,
      dialogVisible2: false,
      dialogVisibleUpload: false,
      teacherRules: {
        name: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        fullName: [
          { required: true, message: "请输入真实姓名", trigger: "blur" },
        ],
        sex: [{ required: true, message: "请选择性别", trigger: "blur" }],
        phone: [
          { required: true, message: "请输入手机号码", trigger: "blur" },
          { pattern: /^1[3456789]\d{9}$/, message: "手机号格式不正确" },
        ],
        email: [
          { required: false, message: "请输入邮箱", trigger: "blur" },
          {
            pattern: /^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$/,
            message: "邮箱格式不正确",
          },
        ],
        idCard: [
          { required: false, message: "请输入身份证号码", trigger: "blur" },
          {
            pattern: /^([1-6][1-9]|50)\d{4}(18|19|20)\d{2}((0[1-9])|10|11|12)(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/,
            message: "身份证号格式不正确",
          },
        ],
      },
      options: [
        {
          value: "0",
          label: "身份证",
        },
        {
          value: "1",
          label: "军人证件",
        },
        {
          value: "2",
          label: "其他",
        },
      ],
      value: "",
    };
  },
  created(){
    this.handleSearch();

    this.queryEduStage();
    this.queryEduProfession();
    this.queryEduSite();
    this.QueryEduGraduateWorkBatch();
  },
  methods: {
    selectStudent(val) {
      this.selectStu = val;
    },
    // 获取所有的用户信息
    getList(){
      this.loginList = JSON.parse(localStorage.getItem("loginStrage"));
      // this.loginList = JSON.parse(localStorage.getItem("loginStrage"));
      this.$axios({
        url: "hnjxjy-core/eduEnrolStudent/listY",
        headers: this.loginList,
        method: "POST",
      }).then((res) => {
        if (res.data.code !== 2000) {
          return;
        }
        this.tableData = res.data.data.list;
        this.total = res.data.data.total;
        this.currentPage = res.data.data.pageNum;
        // this.ids = res.data.data.id;
      });
    },
    queryEduStage() {
      this.$axios_supermall.post("/hnjxjy-core/eduStage/listGL").then(resp => {
        if (resp.data.code == 2000) {
          this.eduStage = resp.data.data;
        }
      })
    },
    //查询专业
    queryEduProfession() {
      this.$axios_supermall.post("/hnjxjy-core/eduProfession/listGL").then(resp => {
        if (resp.data.code == 2000) {
          this.eduProfession = resp.data.data;
        }
      })
    },
    //查询函授站下拉
    queryEduSite() {
      let formData = new FormData();
      formData.append("isAll","1");
      this.$axios_supermallData.post("/hnjxjy-core/eduSite/queryAllSite", formData).then(resp => {
        if (resp.data.code == 2000) {
          this.site = resp.data.data;
        }
      })
    },
    //查询批次下拉框
    QueryEduGraduateWorkBatch() {
      // this.$axios_supermall.post("/hnjxjy-core/eduGraduateWorkBatch/list").then(resp => {
      //   if (resp.data.code == 2000) {
      //     this.eduGraduateWorkBatch = resp.data.data;
      //   }
      // })

      this.loginList = JSON.parse(localStorage.getItem("loginStrage"));
      // this.loginList = JSON.parse(localStorage.getItem("loginStrage"));
      this.openLoadingView();
      this.$axios({
        url: "hnjxjy-core/eduEnrolBatch/list",
        headers: this.loginList,
        method: "POST"
      }).then((res) => {
        if (res.data.code == 2000) {
          this.eduGraduateWorkBatch = res.data.data.list;
        }
      })

    },
    queryLooks(){
      if(this.selectStu!=null&&this.selectStu!=""){
        this.loginList = JSON.parse(localStorage.getItem("loginStrage"));
        let array = [];
        for (let i = 0; i < this.selectStu.length; i++) {
          array.push(this.selectStu[i].id);
        }
        let arr = array.toString();
        this.$axios({
          url: "hnjxjy-core/eduEnrolStudent/validOrInvalid",
          headers: this.loginList,
          method: "post",
          params: {
            ids: arr,
            starts: 0,
          },
        }).then((res) => {
          if (res.data.code == 2000) {
            this.$message({
              type: "success",
              message: "操作成功!",
            });
            this.handleSearchs()
          }
        })

            .catch(() => {
              this.$message({
                type: "info",
                message: "操作失败",
              });
            });
      }else{
        this.$message({
          type: "error",
          message: "请至少选择一条!",
        });
      }
    },
    queryLookss(){
      if(this.selectStu!=null&&this.selectStu!=""){
        this.loginList = JSON.parse(localStorage.getItem("loginStrage"));
        let array = [];
        for (let i = 0; i < this.selectStu.length; i++) {
          array.push(this.selectStu[i].id);
        }
        let arr = array.toString();
        this.$axios({
          url: "hnjxjy-core/eduEnrolStudent/validOrInvalid",
          headers: this.loginList,
          method: "post",
          params: {
            ids: arr,
            starts: 1,
          },
        }).then((res) => {
          if (res.data.code == 2000) {
            this.$message({
              type: "success",
              message: "操作成功!",
            });
            this.handleSearchs()
          }
        })

            .catch(() => {
              this.$message({
                type: "info",
                message: "操作失败",
              });
            });
      }else{
        this.$message({
          type: "error",
          message: "请至少选择一条!",
        });
      }
    },
    handleSearch() {
      this.openLoadingView()
        let fromData = new FormData();
        fromData.append("nameNo",this.search.name);
        fromData.append("stageId",this.search.stageId);
      fromData.append("profId",this.search.profId);
      fromData.append("siteId",this.search.siteId);
      fromData.append("batchId",this.search.batchId);
      fromData.append("isMatch",this.search.isMatch);
      fromData.append("page",this.search.page);
      fromData.append("pageSize",this.search.pageSize);
        this.$axios_supermall.post("/hnjxjy-core/eduEnrolStudent/listY",fromData).then(resp => {
          if (resp.data.code == 2000) {
            this.tableData = resp.data.data.list;
            this.total = resp.data.data.total;
            this.loadingView.close()
          }else{
            this.loadingView.close()
          }
        })
    },
    handleSearchs() {
     setTimeout(()=>{
       this.openLoadingView()
     },1)
      this.$axios_supermall.post("/hnjxjy-core/eduEnrolStudent/duplicateId?info="+this.searchs.info+"&page="+this.searchs.page+"&pageSize="+this.searchs.pageSize).then(resp => {
        if (resp.data.code == 2000) {
          this.tableData2 = resp.data.data.list;
          this.total2 = resp.data.data.total;
        }
      })
    },
    addcreate() {
      this.dialogVisible1 = true;
    },
    selects(){
      this.putDialogSelect=true
      this.handleSearchs();
    },
    // 批量删除
    batchDelete(row) {
      this.loginList = JSON.parse(localStorage.getItem("loginStrage"));
      if (this.tableChecked == "") {
        this.$message.error("请至少选择一项记录");
      } else {
        let array = [];
        for (let i = 0; i < this.tableChecked.length; i++) {
          array.push(JSON.stringify(this.tableChecked[i].id));
        }
        let arr = array.toString();
        this.$axios({
          url: `hnjxjy-core/eduEnrolStudent/updateEduStuInfoListY`,
          headers: this.loginList,
          method: "POST",
          params: {
            ids: arr,
          },
        })
          .then((resp) => {
            if (resp.data.code == 2000) {
              this.$message({
                type: "success",
                message: "删除成功!",
              });
            }else {
              this.$message({
                type: "error",
                message: "操作失败!",
              });
            }
            this.handleSearch();
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消删除",
            });
            this.handleSearch();
          });
      }
    },
    // 去除单删
    forecastDelete(row){
      // this.$axios_supermall.post("hnjxjy-core/eduEnrolStudent/updateEduStuInfoListY",{ids:row.id.toString()}
      // ).then(resp => {
      //   if(resp.data.code!=2000){
      //     this.$message({
      //       type: "warning",
      //       message: resp.data.message,
      //     });
      //     return;
      //   }
      //   this.$message({
      //     type: "success",
      //     message: resp.data.message,
      //   });
      //   this.getList();
      // })
    },
    // 点击导入
    upload() {
      this.dialogVisibleUpload = true;
    },
    //设置制作教师
    production() {
      this.dialogVisible2 = true;
    },
    //设置教师
    Setting(){
        this.outerVisible=true
    },
    downloadTemplate(){
      this.openLoadingView();
      this.$axios_supermall({
        method: "get",
        url: "/hnjxjy-core/eduEnrolStudent/exportDzyMB",
        responseType: "blob"
      }).then(res => {
        let blob = new Blob([res.data], {
          type: "application/vnd.ms-excel",
        });
        // let url = window.URL.createObjectURL(blob);
        // window.location.href = url;
        let downloadElement = document.createElement('a');
        let href = window.URL.createObjectURL(blob);
        downloadElement.href = href;
        downloadElement.download = '导入预报名信息模板.xls';　　　　　　　　　　// xxx.xls/xxx.xlsx
        document.body.appendChild(downloadElement);
        downloadElement.click();
        document.body.removeChild(downloadElement);
        window.URL.revokeObjectURL(href);
        this.$message.success("操作成功");
        this.loadingView.close();
      }).catch((err) => {
        this.$message.error("操作失败");
        this.loadingView.close();
      });
    },
    beforeUpload(file) {
      const isXls = file.type === "application/vnd.ms-excel";
      const isXlsx =
        file.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      if (!isXls && !isXlsx) {
        this.$message.error("上传文件只能是XLS/XLSX格式！");
        return false;
      }
      return isXls || isXlsx;
    },
    uploadFile(file) {
      let fileDate = file.file;
      let forms = new FormData();
      forms.append("file", fileDate);
      this.openLoadingView();
      this.$axios_supermall({
        method: "post",
        url: "hnjxjy-core/eduEnrolStudent/importData",
        data: forms,
        responseType: "blob",
      }).then((res) => {
        if(res.data.type === "application/json"){
          this.$message.success("操作成功");
          this.importDialog = false;
          this.handleSearch();
        }else{
          this.$message.error("操作失败");
          this.importDialog = false;
          let blob = new Blob([res.data], {
            type: "application/vnd.ms-excel",
          });
          window.location.href = window.URL.createObjectURL(blob);
          this.loadingView.close();
        }
        this.loadingView.close();
      }).catch((err) => {
        this.$message.error("操作失败");
        this.loadingView.close();
      });
    },
    tableHeaderColor({row,column,rowIndex,columnIndex}){
      return 'color:#64666A;font-size:13px;text-align:center;'
    },
    // 点击导出
    download() {
      let objPar = JSON.parse(localStorage.getItem("loginStrage"));
      this.openLoadingView();
      this.$axios({
        method: "get",
        url: "hnjxjy-core/eduEnrolStudent/exportY",
        responseType: "blob",
        headers: objPar,
      }).then((res) => {
        let blob = new Blob([res.data], {
          type: "application/vnd.ms-excel",
        });
        let url = window.URL.createObjectURL(blob);
        window.location.href = url;
        this.$message.success("操作成功");
        this.loadingView.close();
      }).catch((err) => {
        this.$message.error("操作失败");
        this.loadingView.close();
      });
    },
    handleSelectionChange(val) {
      this.tableChecked = val;
      this.multipleTable = val;
    },
    // 点击修改
    editor(row) {
      this.openLoadingView()
      this.$axios_supermall.get("hnjxjy-core/eduEnrolStudent/queryEduEnrolStudentById?"+
          "id="+row.id
      ).then(resp => {
        if(resp.data.code == 2000){
          this.dialogVisible = true;
          this.teacherForm = resp.data.data;
          this.teacherForm.certificateType+=""
        }
      })
    },
    handleSave(){
      this.$axios_supermall.post("hnjxjy-core/eduEnrolStudent/edit",
          {
                name: this.teacherForm.name,
                stageId: this.teacherForm.stageId,
                certificateType: this.teacherForm.certificateType,
                certificateNo: this.teacherForm.certificateNo,
                contactPhone	: this.teacherForm.contactPhone,
                contactAddr: this.teacherForm.contactAddr,
                id: this.teacherForm.id,
               }
      ).then(resp => {
        if (resp.data.code == 2000){
          this.$message.success("操作完成");
          this.handleSearch();
          this.dialogVisible = false;
        }
      })
    },

    handleSizeChange(val) {
      this.search.pageSize = val;
      this.handleSearch()
    },
    handleCurrentChange(val) {
      this.search.page = val;
      this.handleSearch()
    },
     handleSizeChange1(val) {
    },
    handleCurrentChange1(val) {
    },
    handleSizeChange2(val) {
      this.searchs.pageSize = val;
      this.handleSearchs()
    },
    handleCurrentChange2(val) {
      this.searchs.page = val;
      this.handleSearchs()
    },
    // 点击删除
    deleteCorrespond() {
      this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$message({
            type: "success",
            message: "删除成功!",
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    reset(){
      this.search.name = '';
      this.search.batchId = '';
      this.search.isMatch = '';
      this.search.siteId = '';
      this.search.stageId = '';
      this.search.profId = '';
    }
  },
};
</script>
<style>
.item .el-table-column__label{
color: blue;
}
.search {
  text-align: left;
  margin-top: 1rem;
  /* margin-left: 1rem; */
  padding: 1rem;
}
.table-wrap {
  overflow: auto;
  /* margin-left: 1rem; */
  /* margin-bottom: 1rem; */
  padding: 1rem;
}
.demo-ruleForm {
  /* width: 90%; */
  height: calc(100% - 125px);
}
.el-form-input-width {
  width: 100%;
}
.rigth {
  width: 93%;
  display: inline-block;
  height: 870px;
  overflow-y: scroll;
  /* background-color: #fff; */
  background-color: rgba(164, 174, 224, 0.11);
  float: right;
}
.left {
  width: 7%;
  display: inline-block;
}
.portrait_Sprite {
  padding: 0.2rem 1rem;
  display: inline-block;
  background-color: skyblue;
  color: #fff;
  border-radius: 10%;
  font-size: 13px;
}
</style>
