import { render, staticRenderFns } from "./prof-select-all.vue?vue&type=template&id=ca064424&scoped=true"
import script from "./prof-select-all.vue?vue&type=script&lang=js"
export * from "./prof-select-all.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ca064424",
  null
  
)

export default component.exports