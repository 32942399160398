<template>
    <div>
        <div>
            <div style="padding: 1rem; border-bottom: 1px solid #ddd; text-align: left">
                <span>好课联盟-公共课程库</span>
            </div>
            <div class="search">
                <el-form :inline="true" label-width="120px" size="mini">
                    <el-form-item>
                        <el-input v-model="queryBO.info" placeholder="请输入课程名"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-cascader
                                style="width: 200px"
                                clearable
                                :props="{label:'cateName',value:'id',children:'hklmCourseCates',checkStrictly: true}"
                                v-model="queryBO.cateId"
                                placeholder="请选择分类"
                                :options="province">
                        </el-cascader>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="queryBasic()">查询</el-button>
                    </el-form-item>
                </el-form>
            </div>
            <div class="table-wrap">
                <el-table
                        :data="tableData"
                        border
                        style="width: 100%"
                        :header-cell-style="{ 'text-align': 'center' }"
                        :cell-style="{ 'text-align': 'center' }"
                >
                    <el-table-column type="index" label="序号" fixed="left"/>
                    <el-table-column prop="courseName" label="商品名称"></el-table-column>
                    <el-table-column prop="declareInfo" label="课程介绍" width="150"></el-table-column>
                    <el-table-column prop="courseAttr" label="课程属性" width="150">
                        <template slot-scope="scope">
                            <span v-if="scope.row.courseAttr===0">免费</span>
                            <span v-if="scope.row.courseAttr===1">收费</span>
                            <span v-if="scope.row.courseAttr===2">Spoc本校可看</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="课程价格" width="150">
                        <template slot-scope="scope">
                            <span v-if="scope.row.courseAttr===0">免费</span>
                            <span v-if="scope.row.courseAttr!=0">{{scope.row.coursePrice}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="realName" label="审核人" width="150"></el-table-column>
                    <el-table-column prop="courseUrl" label="课程封面" width="150">
                        <template slot-scope="scope">
<!--                            <el-image :src="scope.row.courseUrl"></el-image>-->

                          <div>
                            <el-popover
                                placement="right"
                                trigger="hover" style="width: 100px;" >
                              <img :src="scope.row.courseUrl" width="350px"/>
                              <img slot="reference" :src="scope.row.courseUrl"  style="width: 50%">
                            </el-popover>
                          </div>

                        </template>
                    </el-table-column>
                    <el-table-column label="操作" width="150">
                        <template slot-scope="scope">
                            <el-button-group>
                                <el-button @click="buy(scope.row)" type="primary" size="mini"
                                           v-if="scope.row.courseAttr!=0">
                                    购买
                                </el-button>
                                <el-button @click="join(scope.row)" type="primary" size="mini"
                                           v-if="scope.row.courseAttr==0">
                                    加入
                                </el-button>
                                <el-button @click="look(scope.row)" type="primary" size="mini">
                                    预览
                                </el-button>
                            </el-button-group>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :current-page="queryBO.page"
                        :page-sizes="[10, 20, 50, 100]"
                        :page-size="queryBO.pageSize"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="total">
                </el-pagination>
            </div>
            <el-dialog
                    title="订单确认"
                    :visible.sync="buyDialog"
                    width="40%">
                <div>
                    <el-card class="box-card">
                        <div slot="header" class="clearfix">
                            <span>订单信息</span>
                        </div>
                        <div class=" infinite-list" style="width: 98%;overflow:auto;height: 200px">
                            <el-form label-width="80px">
                                <el-form-item label="商品名称">
                                    {{buyDetails.courseName}}
                                </el-form-item>
                                <el-form-item label="图片">
                                    <el-image :src="buyDetails.courseUrl"></el-image>
                                </el-form-item>
                                <el-form-item label="介绍">
                                    {{buyDetails.courseDesc}}
                                </el-form-item>
                                <el-form-item label="金额">
                                    {{buyDetails.coursePrice}}
                                </el-form-item>
                            </el-form>
                        </div>
                    </el-card>
                    <el-card class="box-card" style="margin-top: 15px">
                        <div slot="header" class="clearfix">
                            <span>支付信息</span>
                        </div>
                        <el-form label-position="left">
                            <el-form-item label="优惠券">
                                <el-collapse style="background: #fcfcfc;border: 1px solid #fcfcfc">
                                    <el-collapse-item name="1"
                                                      :disabled="buyDetails.hklmCoupons==null ||buyDetails.hklmCoupons.length<=0">
                                        <template #title>
                                            <span v-if="buyDetails.hklmCoupons!=null &&buyDetails.hklmCoupons.length>0">{{chooseCoupon.couponName}}</span>
                                            <span v-else>暂无符合当前课程的优惠劵</span>
                                        </template>
                                        <div>
                                            <el-button type="text" size="mini" @click="clearChooseCoupon">清除选择</el-button>
                                            <el-table
                                                    border
                                                    :data="buyDetails.hklmCoupons"
                                                    style="width: 100%;margin-top: 5px">
                                                <el-table-column
                                                        width="50px"
                                                        label="#">
                                                    <template #default="scope">
                                                        <el-tooltip
                                                                style=""
                                                                v-if="scope.row.amount>buyDetails.coursePrice"
                                                                content="优惠金额大于订单金额时不可用"
                                                                placement="top">
                                                            <el-radio :disabled="scope.row.amount>buyDetails.coursePrice" v-model="chooseCoupon.id"
                                                                      @change="radioChange"
                                                                      :label="scope.row.id" size="mini">
                                                                {{''}}
                                                            </el-radio>
                                                        </el-tooltip>
                                                        <el-radio v-else v-model="chooseCoupon.id" @change="radioChange"
                                                                  :label="scope.row.id" size="mini">
                                                            {{''}}
                                                        </el-radio>
                                                    </template>
                                                </el-table-column>
                                                <el-table-column
                                                        prop="couponName"
                                                        label="优惠券">
                                                </el-table-column>
                                                <el-table-column
                                                        prop="amount"
                                                        label="优惠金额">
                                                    <template #default="scope">
                                                        <span>{{scope.row.amount}} </span>
                                                    </template>
                                                </el-table-column>
                                                <el-table-column
                                                        prop="couponDesc"
                                                        label="说明">
                                                </el-table-column>
                                                <el-table-column
                                                        prop="couponType"
                                                        label="优惠券类型">
                                                    <template #default="scope">
                                                        <span v-if="scope.row.couponType===0">现金券</span>
                                                        <span v-if="scope.row.couponType===1">折扣券</span>
                                                    </template>
                                                </el-table-column>
                                            </el-table>
                                        </div>
                                    </el-collapse-item>
                                </el-collapse>
                            </el-form-item>
                            <!--                      <el-form-item label="商品类型" label-width="70px">-->
                            <!--                        <el-select v-model="chooseCoupon.goodsType" disabled  placeholder="请选择商品类型" >-->
                            <!--                          <el-option-->
                            <!--                              v-for="item in goodsTypes"-->
                            <!--                              :key="item.value"-->
                            <!--                              :label="item.label"-->
                            <!--                              :value="item.value">-->
                            <!--                          </el-option>-->
                            <!--                        </el-select>-->
                            <!--                      </el-form-item>-->
                            <el-form-item label="支付方式" label-width="70px">
                                <el-radio-group v-model="payType">
                                    <el-radio :label="1" border style="width: 120px">
                                        <img src="@/assets/pay/wechat.png"
                                             style="width: 70px;position: absolute;top:5px;">
                                    </el-radio>
                                    <el-radio :label="2" border style="width: 120px">
                                        <img src="@/assets/pay/alipay.png"
                                             style="width: 70px;position: absolute;top:5px;">
                                    </el-radio>
                                </el-radio-group>
                                <div style="clear: both;"></div>
                            </el-form-item>
                        </el-form>
                    </el-card>
                </div>
                <div slot="footer" class="dialog-footer">
                    <el-button @click="buyDialog = false">取 消</el-button>
                    <el-button type="primary" @click="commitPay">确 定</el-button>
                </div>
            </el-dialog>
            <el-dialog
                    :visible.sync="payImg"
                    width="30%">
                <div style="text-align: center">
                    <img :src="payUrl" alt="">
                </div>
                <div slot="footer" class="dialog-footer">
                    <el-button type="primary" @click="closeAll">我已完成支付</el-button>
                </div>
            </el-dialog>
            <el-dialog
                    :visible.sync="aliPay"
                    width="30%">
                <div style="text-align: center">
                    <p class="alertFoot">如果您遇到问题，可以拨打客服电话</p>
                    <el-button type="primary" style="margin-top: 15px" @click="closeAll">我已完成支付</el-button>
                </div>
            </el-dialog>
            <el-dialog
                    title="预览课程"
                    :visible.sync="lookDialog"
                    width="80%">
                <div style="width: 100%;height: 500px">
                    <iframe style="border: none;width: 100%;height: 100%" :src="lookLink"></iframe>
                </div>
                <div slot="footer" class="dialog-footer">
                    <el-button @click="lookDialog = false">取 消</el-button>
                    <el-button type="primary" @click="lookDialog = false">确 定</el-button>
                </div>
            </el-dialog>
        </div>
    </div>
</template>

<script>
    export default {
        name: "public-curriculum-list",
        data() {
            return {
                queryBO: {
                    info: '',//课程名称
                    page: 1,
                    pageSize: 10,
                    cateId: null//课程分类id
                },
                goodsTypes: [
                    {
                        value: 0,
                        label: "公共课程"
                    },
                    {
                        value: 1,
                        label: "课程"
                    },
                    {
                        value: 2,
                        label: "认证证书"
                    }
                ],
                orderId: null,
                tableData: [],
                total: 0,
                province: [],
                courseName: '',
                buyDialog: false,
                buyDetails: {},
                chooseCoupon: {
                    couponName: '选择优惠券',
                    id: 0
                },
                payType: 1,
                payImg: false,
                payUrl: '',
                aliPay: false,
                lookURL: 'https://ketangbang.com/studyOnline/detail?type=0&courseType=1&lookShow=1&id=',
                lookLink: '',
                lookDialog: false,
            }
        },
        methods: {
            clearChooseCoupon() {
                this.chooseCoupon = {
                    couponName: '选择优惠券',
                    id: 0
                }
            },
            radioChange() {
                let coupon = this.buyDetails.hklmCoupons.find(c => c.id === this.chooseCoupon.id);
                this.chooseCoupon.couponName = coupon.couponName;
            },
            look(row) {
                this.lookLink = this.lookURL + row.courseId;
                this.lookDialog = true;
            },
            join(row) {
                let formData = new FormData();
                formData.append('courseId', row.courseId);
                formData.append('flag', "1");
                this.openLoadingView();
                this.$axios_supermall.post('/hnjxjy-hklm/alipay/goPayFreeCourse', formData).then(response => {
                    if (response.data.code === 2000) {
                        this.$message.success("加入成功");
                    }
                })
            },
            closeAll() {
              this.openLoadingView()
               this.$axios_supermallData.get('/hnjxjy-hklm/wxService/verificationUserPay?id='+this.orderId).then(res=>{
                 if(res.data.code===2000){
                   if(res.data.data=="0"){
                      this.$message.success("购买成功");
                      this.loadingView.close();
                     this.payImg = false;
                     this.buyDialog=false;
                     this.queryBasic()
                   }else if(res.data.data=="1"){
                     this.$message.error("未支付此订单");
                     this.loadingView.close();
                   }else if(res.data.data=="-1"){
                     this.$message.error("购买失败,请重试");
                     this.loadingView.close();
                   }
                 }else if(res.data.code===5002){
                   this.$message.warning(res.data.message);
                   this.loadingView.close();
                 }else{
                   this.$message.error(res.data.message);
                   this.loadingView.close();
                 }
               }).catch((err) => {
                 this.loadingView.close();
               });
            },
            commitPay() {
                this.openLoadingView()
                let formData = new FormData();
                formData.append('courseId', this.buyDetails.id);
                let data = {
                    courseId: this.buyDetails.id,
                    goodsType: 0,
                    alipayType: this.payType
                };
                if (this.chooseCoupon.id > 0) {
                    formData.append('hklmCouponId', this.chooseCoupon.id);
                    this.$set(data, 'hklmCouponId', this.chooseCoupon.id)
                }
                // formData.append('hklmCouponId', this.chooseCoupon.hklmCouponId);
                formData.append('alipayType', this.payType);
                formData.append('goodsType', 0);
                this.$axios_supermall.post('/hnjxjy-hklm/hklmOrder/confirmHklmOrder', data).then(res => {
                    if (res.data.code === 2000) {
                        let bo = new FormData();
                        bo.append('id', res.data.data.id);
                        if (this.payType === 1) {//微信
                            this.$axios_supermall.post('/hnjxjy-hklm/wxService/wxPaymentOrder', bo).then(resp => {
                                if (resp.data.code === 2000) {
                                    this.payImg = true;
                                    this.$nextTick(() => {
                                        let base = 'https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=';
                                        this.payUrl = base + resp.data.data.code_url;
                                    });
                                }
                                this.loadingView.close();
                            })
                        } else {
                            let bo = new FormData();
                            bo.append('id', res.data.data.id);
                            this.$axios_supermall.post('/hnjxjy-hklm/alipay/goPay', bo).then(resp => {
                                let dataObj = resp.data;
                                let dwSafari;
                                dwSafari = window.open();
                                dwSafari.document.open();
                                let html = dataObj.replace(/[^\u0000-\u00FF]/g, function ($0) {
                                    return escape($0).replace(/(%u)(\w{4})/gi, "&#x$2;")
                                });
                                dwSafari.document.write("<html><head><title></title><meta charset='utf-8'><body>" + html + "</body></html>")
                                dwSafari.document.forms[0].submit();
                                dwSafari.document.close();
                                this.aliPay = true;
                            })
                        }
                    }
                    this.orderId = res.data.data.id;
                });
            },
            buy(row) {
                let formData = new FormData();
                formData.append('courseId', row.courseId);
                this.openLoadingView();
                this.$axios_supermall.post('/hnjxjy-hklm/hklmOrder/insertHklmCourseOrderByCourseId', formData).then(res => {
                    if (res.data.code === 2000) {
                        this.buyDetails = res.data.data;
                        this.buyDialog = true;
                    }
                })
            },
            queryBasic() {
                let data = '?page=' + this.queryBO.page + "&pageSize=" + this.queryBO.pageSize;
                if (this.getIsNull(this.queryBO.info)) {
                    data += '&info=' + this.queryBO.info;
                }
                if (this.getIsNull(this.queryBO.cateId) && this.queryBO.cateId.length > 0) {
                    data += '&cateId=' + this.queryBO.cateId[this.queryBO.cateId.length - 1];
                }
                this.openLoadingView();
                this.$axios_supermall.get('/hnjxjy-hklm/hklmCoursePub/queryHklmCoursePubBy' + data).then(response => {
                    if (response.data.code === 2000) {
                        this.tableData = response.data.data.list;
                        this.total = response.data.data.total;
                    }
                });
            },
            queryCate() {
                this.$axios_supermall
                    .get('/hnjxjy-hklm/hklmCourseCate/selectForHklmCourseCateById')
                    .then((resp) => {
                        if (resp.data.code == 2000) {
                            this.province = resp.data.data;
                            this.setArray(this.province);
                        }
                    })
            },
            setArray(array) {
                array.forEach(c => {
                    if (c.hklmCourseCates != null) {
                        if (c.hklmCourseCates.length == 0) {
                            c.hklmCourseCates = null;
                        } else {
                            this.setArray(c.hklmCourseCates);
                        }
                    }
                })
            },
            handleSizeChange(val) {
                this.queryBO.pageSize = val;
                this.queryBasic()
            },
            handleCurrentChange(val) {
                this.queryBO.page = val;
                this.queryBasic()
            },
        },
        mounted() {
            this.queryBasic();
            this.queryCate();
        }
    }
</script>

<style>
    .table-wrap {
        overflow: auto;
        /* margin-left: 1rem; */
        /* margin-bottom: 1rem; */
        padding: 1rem;
    }
</style>