<template>
  <div id="contain">
    <div>
      <div
          style="padding: 1rem; border-bottom: 1px solid #ddd; text-align: left"
      >
        学校短信统计
      </div>
      <div class="top_btn">
        <el-button style="margin-left: 1vw" type="primary" icon="el-icon-download" @click="down" v-permission="'eduMsgRecord:Alldaochu1'">导出</el-button>
      </div>

      <div class="search">
        <span class="">创建人名称</span>
        <el-input placeholder="创建人" v-model="bo.siteName" style="width: 400px;">
          <el-button slot="append" icon="el-icon-search" @click="query"></el-button>
        </el-input>
      </div>
      <div class="block">
        <span class="demonstration">起始时间</span>
        <el-date-picker
            @change="query"
            v-model="bo.createTime"
            type="date"
            placeholder="选择日期">
        </el-date-picker>
      </div>
      <div class="block">
        <span class="demonstration">结束时间</span>
        <el-date-picker
            @change="query"
            v-model="bo.updateTime"
            type="date"
            placeholder="选择日期">
        </el-date-picker>
      </div>

      <el-table
          :data="tableData"
          border
          style="width: 100%"
          height="630px"
          max-height="630px"
          size="medium"
          :header-cell-style="{'text-align':'center'}"
          :cell-style="{'text-align':'center'}"
      >
        <el-table-column
            prop="supportUnit"
            label="单位">
        </el-table-column>
        <el-table-column

            prop="siteName"
            label="创建人">
        </el-table-column>
        <el-table-column
            prop="allTimes"
            label="短信次数">
        </el-table-column>
        <el-table-column
            prop="actualNumber"
            label="实际次数">
        </el-table-column>
      </el-table>
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                     current-page="1"
                     :page-sizes="[10, 30, 50, 100]" :page-size="1" background
                     layout="total, sizes, prev, pager, next, jumper" :total="total">
      </el-pagination>
    </div>
  </div>


</template>


<script>

export default {

  data() {
    return {
      total: 0,
      bo: {
        page: 1,
        pageSize: 10,
        siteName: '',
        createTime: null,
        updateTime: null
      },
      options: [],
      options2: [],
      tableData: [],
      dialogVisible: false
    }
  },
  mounted() {
    this.query();
  },
  methods: {
    down(){
      let objPar = JSON.parse(localStorage.getItem("loginStrage"));
      this.openLoadingView();
      this.$axios({
        method: "GET",
        url: "hnjxjy-core/eduMsgRecord/export3",
        responseType: "blob",
        headers: objPar,
      }).then((res) => {
        let blob = new Blob([res.data], {
          type: "application/vnd.ms-excel",
        });
        let url = window.URL.createObjectURL(blob);
        window.location.href = url;
        this.$message.success("操作成功");
        this.loadingView.close();
      }).catch((err) => {
        this.$message.error("操作失败");
        this.loadingView.close();
      });
    },
    query() {
      this.getPermission('eduMsgRecord:selectEduMsgRecordVo1', () => {
        if (this.bo.createTime instanceof Date)
        {
          this.bo.createTime = this.bo.createTime.formatToLocalDateTime("yyyy/MM/dd HH:mm:ss");
        }
        if (this.bo.updateTime instanceof Date)
        {
          this.bo.updateTime = this.bo.updateTime.formatToLocalDateTime("yyyy/MM/dd HH:mm:ss");
        }
        this.openLoadingView();

        let formdata=new FormData();
        formdata.append("page",this.bo.page);
        formdata.append("pageSize",this.bo.pageSize);
        if (this.bo.siteName != ""){
          formdata.append("siteName",this.bo.siteName);
        }
        if (this.bo.createTime != null){
          formdata.append("createTime",this.bo.createTime);
        }
        if (this.bo.updateTime != null){
          formdata.append("updateTime",this.bo.updateTime);
        }
        this.$axios_supermallData.post("/hnjxjy-core/eduMsgRecord/selectEduMsgRecordVo", formdata).then(resp => {
          if (resp.data.code == 2000) {
            this.tableData = resp.data.data.list;
            this.total = resp.data.data.total;
            this.loadingView.close();
          }
        }).catch(() => {
          this.loadingView.close();
        })
      });
    },
    handleCurrentChange(val) {
      this.bo.page = val;
      this.query();
    },
    handleSizeChange(val) {
      this.bo.pageSize = val;
      this.query();
    },
  }
}
</script>

<style scoped>
.chuangjianren {
  font-weight: bold;
  color: #555;
}

#aaaaa {
  color: lightskyblue;
}

.top_btn {
  margin: 10px 10px 10px 10px;
  text-align: left;
}

.search {
  margin: 20px 10px 30px 10px;
  text-align: left;
  display: inline-block;
}

.search span {
  margin-right: 20px;
}

.main_contain {
  margin: 20px 10px 30px 10px;
  text-align: left;
}

.yingyong {
  margin-right: 100px;
  text-align: left;
}

.right {
  float: right;
  width: 93%;
  display: inline-block;
  height: 870px;
  overflow-y: scroll;
  /* background-color: #fff; */
  background-color: rgba(164, 174, 224, 0.11);

  text-align: left;
}

.left {
  width: 7%;
  display: inline-block;
}

.add {
  text-align: left im !important;
}

.block {
  display: inline-block;
  margin-right: 20px;
}

.demonstration {
  margin-right: 20px;
}
</style>
