<template>
    <div>
        <div>
            <div style="padding: 1rem; border-bottom: 1px solid #ddd; text-align: left">
                函授站列表
            </div>
            <div class="public-global-search">
                <el-form class="demo-ruleForm">
                    <el-form-item>
                        <template>
                            <el-button
                                    icon="el-icon-delete"
                                    type="danger"
                                    @click="batchDelete()" v-permission="'eduSite:deletedEduSite'">
                                批量删除
                            </el-button>
                            <el-button
                                    type="primary"
                                    @click="download"
                                    v-permission="'eduSite:exportSiteExcel'">
                                导出
                            </el-button>
                            <el-button
                                    type="primary"
                                    @click="upload"
                                    v-permission="'eduSite:importEduSiteExcel'">
                                导入
                            </el-button>
                            <el-button @click="add()" type="primary" v-permission="'eduSite:addEduSite'">
                                添加
                            </el-button>
                        </template>
                    </el-form-item>
                </el-form>
                <el-form :model="dataParm" class="demo-ruleForm" inline v-permission="'eduSite:queryAllEduSite'">
                    <el-form-item label="" style="margin-bottom: 0;margin-top: 20px">
                        <el-input
                                v-model="dataParm.info"
                                clearable
                                placeholder="名称/代码"
                                style="width: 200px">
                            <i slot="prefix" class="el-input__icon el-icon-search"></i>
                        </el-input>
                    </el-form-item>
                    <el-form-item style="margin-bottom: 0;margin-top: 20px">
                        <el-button type="primary" icon="el-icon-search" @click="doSearch">
                            搜索
                        </el-button>
                    </el-form-item>
                </el-form>
            </div>
            <div class="table-wrap">
                <el-table
                    size="medium"
                    height="600px"
                    max-height="600px"
                    :header-cell-style="{'text-align':'center'}"
                    :cell-style="{'text-align':'center'}"
                    border
                    ref="multipleTable"
                    :data="tableData"
                    @selection-change="handleSelectionChange"
                >
                    <el-table-column type="selection" width="55"></el-table-column>
                    <el-table-column label="序号" width="140" type="index" >
                    </el-table-column>
                    <el-table-column label="名称" width="140" prop="siteName" sortable >
                    </el-table-column>
                    <el-table-column label="类型" width="140" prop="siteTypes" sortable >
                    </el-table-column>
                    <el-table-column label="代码" width="140" prop="siteCode" sortable >
                    </el-table-column>
                    <el-table-column
                            label="是否启用"
                            width="140"
                            prop="delFlags"
                            sortable

                    >
                    </el-table-column>
                    <el-table-column
                            label="主站名称"
                            width="140"
                            prop="parentSiteName"
                            sortable

                    >
                    </el-table-column>
                    <el-table-column label="省份" width="140" prop="areaName" sortable >
                    </el-table-column>
                    <el-table-column label="市级" width="140" prop="areaName2" sortable >
                    </el-table-column>
                    <el-table-column label="县级" width="140" prop="areaName3" sortable >
                    </el-table-column>
                    <el-table-column
                            label="负责人"
                            width="140"
                            prop="supervisor"
                            sortable

                    >
                    </el-table-column>
                    <el-table-column
                            label="联系人"
                            width="140"
                            prop="contactPerson"
                            sortable

                    >
                    </el-table-column>
                    <el-table-column
                            label="电子邮箱"
                            width="140"
                            prop="contactEmail"
                            sortable

                    >
                    </el-table-column>
                    <el-table-column label="邮编" width="140" prop="postCode" sortable >
                    </el-table-column>
                    <el-table-column label="地址" width="140" prop="address" sortable >
                    </el-table-column>
                    <el-table-column label="操作" width="160" fixed="right">
                        <template slot-scope="scope">
                            <el-button @click="editor(scope.row)" type="primary" size="small"
                                       v-permission="'eduSite:modifySite'">
                                编辑
                            </el-button>
                            <el-button
                                    @click="deleteCorrespond(scope.row)"
                                    type="danger"
                                    size="small"
                                    v-permission="'eduSite:deletedEduSite'">
                                删除
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <div style="padding: 1rem">
                    <el-pagination
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                            :current-page="dataParm.page"
                            :page-sizes="[10, 20, 30, 40]"
                            :page-size="dataParm.pageSize"
                            layout="total, sizes, prev, pager, next, jumper"
                            :total="dataParm.total"
                    >
                    </el-pagination>
                </div>
            </div>
        </div>
        <!-- 添加/编辑 -->
        <el-dialog
                :title="addFormDialogVisibleTitle"
                :visible.sync="addFormDialogVisible"
                width="40%"
                style="text-align: left"
        >
            <el-form
                    :model="teacherForm"
                    :rules="teacherRules"
                    ref="teacherForm"
                    class="demo-ruleForm"
                    label-width="120px"
            >
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="上传照片：" prop="name">
                            <el-upload
                                    ref="upload"
                                    action=""
                                    :on-change="fileuploadChange"
                                    :auto-upload="false"
                                    :file-list="fileList"
                                    list-type="picture"
                            >
                                <el-button size="small" type="primary" icon="el-icon-upload">点击上传</el-button><span style="color:red;font-size:14px;margin-left:1rem">支持格式jpg/png</span>
                            </el-upload>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row>
                    <el-col :span="12">
                        <el-form-item label="名称：">
                            <el-input
                                style="width: 200px"
                                    v-model="teacherForm.siteName"
                                    placeholder="请输入"
                                    class="el-form-input-width"
                                    @input="
                  () => (teacherForm.siteName = teacherForm.siteName.trim())
                "
                            ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="编码：">
                            <el-input
                                style="width: 200px"
                                    v-model="teacherForm.siteCode"
                                    placeholder="请输入"
                                    class="el-form-input-width"
                                    @input="
                  () => (teacherForm.siteCode = teacherForm.siteCode.trim())
                "
                            ></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="依托单位：">
                            <el-input
                                style="width: 200px"
                                    v-model="teacherForm.supportUnit"
                                    placeholder="请输入"
                                    class="el-form-input-width"
                                    @input="
                  () =>
                    (teacherForm.supportUnit = teacherForm.supportUnit.trim())
                "
                            ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="依托单位性质：">
                            <el-select
                                style="width: 200px"
                                    v-model="teacherForm.supUnitNature"
                                    placeholder="请选择"
                                    filterable
                            >
                                <el-option
                                        v-for="item in yituodanweixingzhi"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value"
                                >
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="站点类型：">
                            <el-select
                                style="width: 200px"
                                    v-model="teacherForm.siteType"
                                    placeholder="请选择"
                                    filterable
                            >
                                <el-option
                                        v-for="item in siteTypeOpt"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value"
                                >
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="函授站类别：">
                            <el-select
                                style="width: 200px"
                                    v-model="teacherForm.siteClass"
                                    placeholder="请选择"
                                    filterable
                            >
                                <el-option
                                        v-for="item in hanshouzhan"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value"
                                >
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="省份：">
                            <el-select
                                style="width: 200px"
                                    v-model="teacherForm.areaId"
                                    placeholder="请选择"
                                    filterable
                                    @change="shnegfenid()"
                            >
                                <el-option
                                        v-for="(item, Index) in shengqu"
                                        :key="Index"
                                        :label="item.areaName"
                                        :value="item.id"
                                >
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="城市：">
                            <el-select
                                style="width: 200px"
                                    v-model="teacherForm.cityId"
                                    placeholder="请选择"
                                    filterable
                                    @change="chengshiid()"
                            >
                                <el-option
                                        v-for="(item, Index) in shiqu"
                                        :key="Index"
                                        :label="item.areaName"
                                        :value="item.id"
                                >
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="区/县：">
                            <el-select
                                style="width: 200px"
                                    v-model="teacherForm.districtId"
                                    placeholder="请选择"
                                    filterable
                            >
                                <el-option
                                        v-for="(item, Index) in xianqu"
                                        :key="Index"
                                        :label="item.areaName"
                                        :value="item.id"
                                >
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="负责人：">
                            <el-input
                                style="width: 200px"
                                    v-model="teacherForm.supervisor"
                                    placeholder="请输入"
                                    class="el-form-input-width"
                                    @input="
                  () => (teacherForm.supervisor = teacherForm.supervisor.trim())
                "
                            ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="联系人：">
                            <el-input
                                style="width: 200px"
                                    v-model="teacherForm.contactPerson"
                                    placeholder="请输入"
                                    class="el-form-input-width"
                                    @input="
                  () =>
                    (teacherForm.contactPerson =
                      teacherForm.contactPerson.trim())
                "
                            ></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="联系人电话：">
                            <el-input
                                style="width: 200px"
                                    v-model="teacherForm.contactMobile"
                                    placeholder="请输入"
                                    class="el-form-input-width"
                                    @input="
                  () =>
                    (teacherForm.contactMobile =
                      teacherForm.contactMobile.trim())
                "
                            ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="联系人邮箱："
                                      prop="contactEmail"
                                      :rules="[{ type: 'email', message: '请输入正确的邮箱地址', trigger: ['blur', 'change'] }]"
                        >
                            <el-input
                                style="width: 200px"
                                    v-model="teacherForm.contactEmail"
                                    placeholder="请输入"
                                    class="el-form-input-width"
                                    @input="
                  () =>
                    (teacherForm.contactEmail = teacherForm.contactEmail.trim())
                "
                            ></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="法人代表：">
                            <el-input
                                style="width: 200px"
                                    v-model="teacherForm.legalPerson"
                                    placeholder="请输入"
                                    class="el-form-input-width"
                                    @input="
                  () =>
                    (teacherForm.legalPerson = teacherForm.legalPerson.trim())
                "
                            ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="法人电话：">
                            <el-input
                                style="width: 200px"
                                    v-model="teacherForm.legalPhone"
                                    placeholder="请输入"
                                    class="el-form-input-width"
                                    @input="
                  () => (teacherForm.legalPhone = teacherForm.legalPhone.trim())
                "
                            ></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="地址：">
                            <el-input
                                style="width: 200px"
                                    v-model="teacherForm.address"
                                    placeholder="请输入"
                                    class="el-form-input-width"
                                    @input="
                  () => (teacherForm.address = teacherForm.address.trim())
                "
                            ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="邮编：">
                            <el-input
                                style="width: 200px"
                                    v-model="teacherForm.postCode"
                                    placeholder="请输入"
                                    class="el-form-input-width"
                                    @input="
                  () => (teacherForm.postCode = teacherForm.postCode.trim())
                "
                            ></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="对外服务电话：">
                            <el-input
                                style="width: 200px"
                                    v-model="teacherForm.servicePhone"
                                    placeholder="请输入"
                                    class="el-form-input-width"
                                    @input="
                  () =>
                    (teacherForm.servicePhone = teacherForm.servicePhone.trim())
                "
                            ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="排序：">
                            <el-input
                                style="width: 200px"
                                    v-model="teacherForm.sort"
                                    placeholder="请输入"
                                    class="el-form-input-width"
                                    @input="() => (teacherForm.sort = teacherForm.sort.trim())"
                            ></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <span slot="footer" class="dialog-footer">
        <el-button @click="addFormDialogVisibleFalse()">取 消</el-button>
        <el-button type="primary" @click="addbutton()">确 定</el-button>
      </span>
        </el-dialog>
        <!-- 导入 上传文件 -->
        <el-dialog title="上传文件" :visible.sync="dialogVisibleUpload" width="30%">
            <div style="margin-bottom: 20px">
                <el-button type="primary" @click="downloadbutton">下载模板</el-button>
                <span style="color: red"
                >提示：导入前请先下载模板，根据模版整理好数据后上传导入.</span
                >
            </div>
            <el-upload
                    ref="upload"
                    class="upload-demo"
                    drag
                    action="#"
                    :show-file-list="false"
                    :http-request="uploadFile"
                    :before-upload="beforeUpload"
            >
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                <div class="el-upload__tip" slot="tip">
                    只能上传excel文件，且不超过50M
                </div>
            </el-upload>
        </el-dialog>
    </div>
</template>
<script>


    export default {

        created() {
            this.getPermission('eduSite:queryAllEduSite', () => {
                this.dataLoading(); //表格渲染
                this.areaName(); //添加省区渲染
                this.shiquchaxun(); //添加函授站（市区查询）
                this.xianquchaxun(); //添加函授站（县区查询）
            });
        },
        data() {
            return {
              addbuttonType: 0,
                bianji: [],
                file: "", //上传文件参数
                fileList: [],
                multipleSelection: [], //选中数据的信息
                dataParm: {
                    info: "",
                    page: 1,
                    pageSize: 10,
                    total: 0,
                },
                teacherForm: {
                    id: 0,
                    file: null,
                    siteName: "", //站点名称
                    siteCode: "", //站点编号
                    siteType: 0, //站点类型 0院校 1函授站
                    supportUnit: "", //依托单位
                    supUnitNature: 0, //依托单位性质 0 民办 1 公办
                    areaId: "", //	省id
                    cityId: "", //城市id
                    districtId: "", //区县id
                    supervisor: "", //负责人
                    contactPerson: "", //联系人
                    contactMobile: "", //联系人电话
                    contactEmail: "", //联系人邮箱
                    legalPerson: "", //法人代表
                    legalPhone: "", //法人电话
                    address: "", //地址
                    postCode: "", //邮编
                    servicePhone: "", //对外服务电话
                    siteClass: 0, //函授站类别 0 函授站 1 学习中心 2 函授站以及学习中心
                    sort: "",
                },
                siteTypeOpt: [
                    {
                        value: 0,
                        label: "院校",
                    },
                    {
                        value: 1,
                        label: "函授站",
                    },
                ],
                // 依托单位性质
                yituodanweixingzhi: [
                    {
                        value: 0,
                        label: "民办",
                    },
                    {
                        value: 1,
                        label: "公办",
                    },
                ],
                // 函数站类型
                hanshouzhan: [
                    {
                        value: 0,
                        label: "函授站",
                    },
                    {
                        value: 1,
                        label: "学习中心",
                    },
                    {
                        value: 2,
                        label: "函授站以及学习中心",
                    },
                ],
                tableChecked1: "",
                tableChecked: [],
                tableData: [], //表格数据
                dialogVisible: false,
                dialogVisibleUpload: false,
                addFormDialogVisibleTitle: '添加函授站',
                addFormDialogVisible: false, //添加
                teacherRules: {
                    name: [{required: true, message: "请输入姓名", trigger: "blur"}],
                    fullName: [
                        {required: true, message: "请输入真实姓名", trigger: "blur"},
                    ],
                    sex: [{required: true, message: "请选择性别", trigger: "blur"}],
                    phone: [
                        {required: true, message: "请输入手机号码", trigger: "blur"},
                        {pattern: /^1[3456789]\d{9}$/, message: "手机号格式不正确"},
                    ],
                    email: [
                        {required: false, message: "请输入邮箱", trigger: "blur"},
                        {
                            pattern:
                                /^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$/,
                            message: "邮箱格式不正确",
                        },
                    ],
                    idCard: [
                        {required: false, message: "请输入身份证号码", trigger: "blur"},
                        {
                            pattern:
                                /^([1-6][1-9]|50)\d{4}(18|19|20)\d{2}((0[1-9])|10|11|12)(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/,
                            message: "身份证号格式不正确",
                        },
                    ],
                },
                shengqu: [], //添加省区渲染
                shiqu: [], //添加函授站（市区查询）
                xianqu: [], //添加函授站（县区查询）
            };
        },

        methods: {
            // 编辑修改确定按钮
            handleSave(vul) {
                let objPar = JSON.parse(localStorage.getItem("loginStrage"));
                this.$axios({
                    method: "POST",
                    url: "hnjxjy-core/eduSite/modifyEduSiteBySiteId",
                    headers: objPar,
                    data: this.bianji,
                })
                    .then((res) => {
                    })
                    .catch((err) => {
                    });
            },
            doSearch() {
                this.dataParm.page = 1;
                this.dataLoading();
            },
            // table数据加载
            dataLoading() {
                let that = this;
                let objPar = JSON.parse(localStorage.getItem("loginStrage"));
                this.openLoadingView();
                this.$axios_supermall({
                    method: "get",
                    url: "hnjxjy-core/eduSite/queryAllSiteByUserId",
                    params: {
                        info: this.dataParm.info,
                        page: this.dataParm.page,
                        pageSize: this.dataParm.pageSize,
                    },
                    headers: objPar,
                })
                    .then((res) => {
                        if (res.data.code !== 2000) {
                            // that.$message({
                            //     message: res.data.message,
                            //     type: "warning",
                            // });
                            return;
                        }
                        that.tableData = res.data.data.list;
                        that.dataParm.total = res.data.data.total;
                    })
                    .catch((err) => {
                    });
            },

            // 批量删除
            batchDelete(ow) {
                let objPar = JSON.parse(localStorage.getItem("loginStrage"));
                let array = [];
                for (let i = 0; i < this.multipleSelection.length; i++) {
                    array.push(JSON.stringify(this.multipleSelection[i].id));
                }
                let arr = array.toString();
                this.$confirm(`您确定要删除当前数据吗?`, "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                })
                    .then(() => {
                        this.$axios({
                            method: "POST",
                            url: "hnjxjy-core/eduSite/deletedEduSiteById",
                            data: {
                                sitIds: arr,
                            },
                            params: {
                                "x-perm": "perm_query",
                            },
                            headers: objPar,
                        })
                            .then((res) => {
                                if (res.data.code !== 2000) {
                                    // this.$message.info(res.data.message);
                                } else {
                                    this.$message.success(res.data.message);
                                    this.dataLoading();
                                }
                            })
                            .catch((err) => {
                            });
                    })
                    .catch(() => {
                        this.$message({
                            type: "info",
                            message: "已取消删除",
                        });
                    });
            },
            // 点击导入
            upload() {
                this.dialogVisibleUpload = true;
            },
            // 下载模版按键
            downloadbutton() {
              let objPar = JSON.parse(localStorage.getItem("loginStrage"));
              this.openLoadingView();
              this.$axios({
                method: "get",
                url: "hnjxjy-core/eduSite/exportExcelSiteTemplate",
                responseType: "blob",
                headers: objPar,
                params: {
                  status: 1
                }
              }).then((res) => {
                let blob = new Blob([res.data], {
                  type: "application/vnd.ms-excel",
                });
                let downloadElement = document.createElement('a');
                let href = window.URL.createObjectURL(blob);
                downloadElement.href = href;
                downloadElement.download = '函授站列表导入模板.xlsx';　　　　　　　　　　// xxx.xls/xxx.xlsx
                document.body.appendChild(downloadElement);
                downloadElement.click();
                document.body.removeChild(downloadElement);
                window.URL.revokeObjectURL(href);
                this.$message.success("操作成功");
                this.loadingView.close();
              }).catch((err) => {
                this.$message.error("操作失败");
                this.loadingView.close();
              });
            },
            // 导入上传文件
            uploadFile(file) {
              let fileDate = file.file;
              let forms = new FormData();
              forms.append("file", fileDate);
              let objPar = JSON.parse(localStorage.getItem("loginStrage"));
              this.openLoadingView();
              this.$axios({
                method: "post",
                url: "hnjxjy-core/eduSite/siteExcelImport",
                data: forms,
                headers: objPar,
              }).then((res) => {
                if (res.data.code == 2000) {
                  this.$message({
                    type: "success",
                    message: res.data.data,
                  });
                  this.dialogVisibleUpload = false;
                  this.dataLoading();
                } else {
                  this.$message({
                    type: "error",
                    message: res.data.data,
                  });
                }
                this.loadingView.close();
              }).catch((err) => {
                this.$message.error("操作失败");
                this.loadingView.close();
              });
            },
            // 导入上传文件前判断文件格式
            beforeUpload(file) {
                const isXls = file.type === "application/vnd.ms-excel";
                const isXlsx =
                    file.type ===
                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
                if (!isXls && !isXlsx) {
                    this.$message.error("上传文件只能是XLS/XLSX格式！");
                    return false;
                }
                return isXls || isXlsx;
            },
            // 点击导出
            download() {
              let objPar = JSON.parse(localStorage.getItem("loginStrage"));
              this.openLoadingView();
              this.$axios({
                method: "get",
                url: "hnjxjy-core/eduSite/exportExcelSite?x-perm=perm_query",
                responseType: "blob",
                headers: objPar,
              }).then((res) => {
                let blob = new Blob([res.data], {
                  type: "application/vnd.ms-excel",
                });
                let url = window.URL.createObjectURL(blob);
                window.location.href = url;
                this.$message.success("操作成功");
                this.loadingView.close();
              }).catch((err) => {
                this.$message.error("操作失败");
                this.loadingView.close();
              });
            },
            handleSelectionChange(val) {
                this.multipleSelection = val;
            },
            // 点击 编辑修改函授站前的查看
            editor(row) {
              this.addbuttonType = 1;
                this.addFormDialogVisibleTitle = '编辑函数站';
                this.addFormDialogVisible = true;
                let that = this;
                // this.teacherForm = row;
                let objPar = JSON.parse(localStorage.getItem("loginStrage"));
                this.$axios({
                    method: "get",
                    url: "hnjxjy-core/eduSite/modifyShowEduSiteBySiteId",
                    params: {
                        id: row.id,
                    },
                    headers: objPar,
                })
                    .then((res) => {
                        that.teacherForm = res.data.data;
                        that.fileList = [{name: "", url: that.teacherForm.headerUrl}];

                        that.areaName();
                        that.shiquchaxun();
                        that.xianquchaxun();
                    })
                    .catch((err) => {
                    });
            },
            // 点击添加
            add() {
                this.addbuttonType = 0;
                this.teacherForm = {
                    file: null,
                    siteName: "", //站点名称
                    siteCode: "", //站点编号
                    siteType: 0, //站点类型 0院校 1函授站
                    supportUnit: "", //依托单位
                    supUnitNature: 0, //依托单位性质 0 民办 1 公办
                    areaId: "", //	省id
                    cityId: "", //城市id
                    districtId: "", //区县id
                    supervisor: "", //负责人
                    contactPerson: "", //联系人
                    contactMobile: "", //联系人电话
                    contactEmail: "", //联系人邮箱
                    legalPerson: "", //法人代表
                    legalPhone: "", //法人电话
                    address: "", //地址
                    postCode: "", //邮编
                    servicePhone: "", //对外服务电话
                    siteClass: 0, //函授站类别 0 函授站 1 学习中心 2 函授站以及学习中心
                    sort: "",
                };
                this.addFormDialogVisibleTitle = '添加函数站';
                this.addFormDialogVisible = true;
                // this.$refs["teacherForm"].resetFields();
                this.areaName(); //添加省区渲染
            },
            //添加省区渲染
            areaName() {
                let objPar = JSON.parse(localStorage.getItem("loginStrage"));
                this.$axios({
                    method: "get",
                    url: "hnjxjy-core/eduArea/queryAllEduArea",
                    params: {
                        "x-perm": "perm_query",
                    },
                    headers: objPar,
                })
                    .then((res) => {
                        // this.tableData=res.data.data.list
                        this.shengqu = res.data.data;
                    })
                    .catch((err) => {
                    });
            },
            //添加函授站（市区查询）
            shiquchaxun() {
                let objPar = JSON.parse(localStorage.getItem("loginStrage"));
                let that = this;
                this.$axios({
                    method: "get",
                    url: "hnjxjy-core/eduArea/queryCityByAreaId",
                    params: {
                        "x-perm": "perm_query",
                        id: that.teacherForm.areaId,
                    },
                    headers: objPar,
                })
                    .then((res) => {
                        this.shiqu = res.data.data;
                    })
                    .catch((err) => {
                    });
            },

            //添加函授站（县区查询）
            xianquchaxun() {
                let objPar = JSON.parse(localStorage.getItem("loginStrage"));
                this.$axios({
                    method: "get",
                    url: "hnjxjy-core/eduArea/queryCityByAreaId",
                    params: {
                        "x-perm": "perm_query",
                        id: this.teacherForm.cityId,
                    },
                    headers: objPar,
                })
                    .then((res) => {
                        this.xianqu = res.data.data;
                    })
                    .catch((err) => {
                    });
            },
          addFormDialogVisibleFalse(){
              this.addFormDialogVisible = false;
            if (this.teacherForm.file != null && this.teacherForm.file !== "" ) {
              this.$refs.upload.clearFiles()
            }
          },
            // 添加按钮将数据传给后端
            addbutton() {
                let that = this;
                var param = new FormData(); // FormData 对象

              if (this.addbuttonType === 0){
                if (this.teacherForm.file != null) {
                  param.append("file", this.teacherForm.file); // 文件对象
                } else {
                  this.$message.warning("请上传图片");
                }
              }else {
                param.append("file", this.teacherForm.file); // 文件对象
              }
              if(this.teacherForm.areaId!=null){
                param.append("areaId", this.teacherForm.areaId);
              }
              if(this.teacherForm.siteName!=null){
                param.append("siteName", this.teacherForm.siteName);
              }
              if(this.teacherForm.siteCode!=null){
                param.append("siteCode", this.teacherForm.siteCode);
              }
              if(this.teacherForm.siteType!=null){
                param.append("siteType", this.teacherForm.siteType);
              }
              if(this.teacherForm.supportUnit!=null){
                param.append("supportUnit", this.teacherForm.supportUnit);
              }
              if(this.teacherForm.supUnitNature!=null){
                param.append("supUnitNature", this.teacherForm.supUnitNature);
              }
              if(this.teacherForm.cityId!=null){
                param.append("cityId", this.teacherForm.cityId);
              }
              if(this.teacherForm.districtId!=null){
                param.append("districtId", this.teacherForm.districtId);
              }
              if(this.teacherForm.supervisor!=null){
                param.append("supervisor", this.teacherForm.supervisor);
              }
              if(this.teacherForm.contactPerson!=null){
                param.append("contactPerson", this.teacherForm.contactPerson);
              }
              if(this.teacherForm.contactMobile!=null){
                param.append("contactMobile", this.teacherForm.contactMobile);
              }
              if(this.teacherForm.contactEmail!=null){
                param.append("contactEmail", this.teacherForm.contactEmail);

              }
              if(this.teacherForm.legalPerson!=null){
                param.append("legalPerson", this.teacherForm.legalPerson);
              }
              if(this.teacherForm.legalPhone!=null){
                param.append("legalPhone", this.teacherForm.legalPhone);
              }
              if(this.teacherForm.address!=null){
                param.append("address", this.teacherForm.address);
              }
              if(this.teacherForm.postCode!=null){
                param.append("postCode", this.teacherForm.postCode);
              }
              if(this.teacherForm.servicePhone!=null){
                param.append("servicePhone", this.teacherForm.servicePhone);
              }
              if(this.teacherForm.siteClass!=null){
                param.append("siteClass", this.teacherForm.siteClass);
              }
              if(this.teacherForm.sort!=null){
                param.append("sort", this.teacherForm.sort);
              }
                let opturl = "addEduSiteByUserId";
                if (this.teacherForm.id > 0) {
                    opturl = "modifyEduSiteBySiteId";
                    param.append("id", this.teacherForm.id);
                }
                let loginList = JSON.parse(localStorage.getItem("loginStrage"));

                this.$axios
                    .post("hnjxjy-core/eduSite/" + opturl, param, {
                        headers: loginList,
                    })
                    .then((res) => {
                        if (res.data.code !== 2000) {
                            // that.$message({
                            //     message: res.data.message,
                            //     type: "warning",
                            // });
                            return;
                        }
                        that.$message({
                            message: res.data.message,
                            type: "success",
                        });
                        this.doSearch()
                        this.addFormDialogVisible = false;
                      if (this.teacherForm.file != null && this.teacherForm.file !== "" ) {
                        this.$refs.upload.clearFiles()
                      }
                    })
                    .catch((err) => {
                      if (this.teacherForm.file != null && this.teacherForm.file !== "" ) {
                        this.$refs.upload.clearFiles()
                      }
                    });
            },
            // 数据返回将id值传给我
            yituodanweixingzhiid(val) {
                this.teacherForm.supUnitNature = val;
            },
            // 函授站类型id
            hanshouzhanid(val) {
                this.teacherForm.siteClass = val;
            },
            // 省份
            shnegfenid() {
                this.teacherForm.cityId = null;
                this.teacherForm.districtId = null;
                this.shiquchaxun();
            },
            // 城市
            chengshiid() {
                this.teacherForm.districtId = null;
                this.xianquchaxun();
            },
            // 区/县
            xianquid(val) {
            },

            handleSizeChange(val) {
                this.dataParm.page = 1;
                this.dataParm.pageSize = val;
                this.dataLoading();
            },
            handleCurrentChange(val) {
                this.dataParm.page = val;
                this.dataLoading();
            },
            // 点击删除
            deleteCorrespond(row) {
                let objPar = JSON.parse(localStorage.getItem("loginStrage"));
                this.$axios({
                    method: "POST",
                    url: "hnjxjy-core/eduSite/deletedEduSiteById",
                    data: {
                        sitIds: row.id,
                    },
                    params: {
                        "x-perm": "perm_query",
                    },
                    headers: objPar,
                })
                    .then((res) => {
                        if (res.data.code == 5002) {
                            this.$message.info(res.data.message);
                        }
                    })
                    .catch((err) => {
                    });
                this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                })
                    .then(() => {
                        this.$message({
                            type: "success",
                            message: "删除成功!",
                        });
                    })
                    .catch(() => {
                        this.$message({
                            type: "info",
                            message: "已取消删除",
                        });
                    });
            },
            fileuploadChange(file) {
                this.teacherForm.file = file.raw;
            },
        },
    };
</script>
<style scoped>
    .search {
        text-align: left;
        margin-top: 1rem;
        /* margin-left: 1rem; */
        padding: 1rem;
    }

    .table-wrap {
        overflow: auto;
        /* margin-left: 1rem; */
        /* margin-bottom: 1rem; */
        padding: 1rem;
    }

    .demo-ruleForm {
        /* width: 90%; */
        height: calc(100% - 125px);
    }

    .el-form-input-width {
        width: 100%;
    }

    .rigth {
        width: 93%;
        display: inline-block;
        height: 870px;
        overflow-y: scroll;
        /* background-color: #fff; */
        background-color: rgba(164, 174, 224, 0.11);
        float: right;
    }

    .left {
        width: 7%;
        display: inline-block;
    }

    .portrait_Sprite {
        padding: 0.2rem 1rem;
        display: inline-block;
        background-color: skyblue;
        color: #fff;
        border-radius: 10%;
        font-size: 13px;
    }
</style>
