<template>
    <div>
        <div>
            <div style="padding: 1rem; border-bottom: 1px solid #ddd; text-align: left">
                课程库
            </div>
            <div class="search">
                <el-form class="demo-ruleForm">
                    <el-form-item :data="tableData">
                        <el-button
                            style="margin-bottom: 10px"
                                icon="el-icon-delete"
                                type="primary"
                                @click="batchDelete()">
                            批量删除
                        </el-button>
                        <el-button icon="el-icon-edit" type="primary" @click="addcreate()"
                                   v-permission="'eduCourse:insertCourse'">
                            添加
                        </el-button>
                        <el-button
                                icon="el-icon-upload2"
                                type="primary"
                                @click="yichuzhizuojiaoshi"
                                v-permission="'eduCourse:deletedSetUpCourseTeacherByCourseId'">移除制作教师
                        </el-button>
                        <!--                        <el-button icon="el-icon-upload2" type="primary" @click="downloadupload">下载导入课程模板</el-button>-->
                        <el-button icon="el-icon-upload2" type="primary" @click="upload"
                                   v-permission="'eduCourse:exportExcelCourse'">导入课程
                        </el-button>
                        <el-button icon="el-icon-upload2" type="primary" @click="upload1"
                                   v-permission="'eduCourse:exportExcelCourseMakeTeacher'">导入课程教师
                        </el-button>
                        <el-button icon="el-icon-download" type="primary" @click="download"
                                   v-permission="'eduCourse:exportCourseExcelBySiteId'">导出课程
                        </el-button>
                        <el-button type="primary" @click="anmeizhangkechengtongji"
                                   v-permission="'eduCourse:courseStatisticsChapter'">按每章节进度统计
                        </el-button>
                        <el-button
                                type="primary"
                                v-permission="'eduCourse:courseLengthOfStudy'"
                                @click="anzhongxuexishichangtongji(tableData.id)">按总学习时长统计
                        </el-button>
                        <el-button
                                icon="el-icon-refresh-right"
                                type="primary"
                                @click="gengxinshijianchang"
                                v-permission="'eduCourse:updateCourseDuration'">
                            更新课程时长
                        </el-button>
                    </el-form-item>
                </el-form>
                <el-form :model="search" class="demo-ruleForm" inline @submit.native.prevent>
                    <el-form-item style="margin-bottom: 0">
                        <el-input
                                v-model="search.name"
                                clearable
                                @input="() => (search.name = search.name.trim())"
                                placeholder="课程名字/课程编号"
                                style="width: 300px">
                            <el-button icon="el-icon-search" @click="yemianchaxun" slot="append"></el-button>
                        </el-input>

                    </el-form-item>
                </el-form>
            </div>
            <div class="table-wrap">
                <el-table
                    size="medium"
                    height="600px"
                    max-height="600px"
                    :header-cell-style="{'text-align':'center'}"
                    :cell-style="{'text-align':'center'}"
                    border
                    ref="multipleTable"
                    :data="tableData"
                    @selection-change="handleSelectionChange"
                >
                    <el-table-column type="selection" width="55" ></el-table-column>
                  <el-table-column type="index" label="序号" width="80" ></el-table-column>
                    <el-table-column :show-overflow-tooltip="true" label="课程编号" width="140" prop="courseCode" >
                    </el-table-column>
                    <el-table-column
                            :show-overflow-tooltip="true"
                            label="课程名称"
                            width="180"
                            prop="courseName"
                            sortable
                    >
                    </el-table-column>
                    <el-table-column
                            label="课程类型"
                            width="150"
                            prop="courseType"
                            sortable
                    >
                    </el-table-column>
                    <el-table-column
                            label="制作教师"
                            width="200"
                            prop="producer"
                            sortable
                    >
                    </el-table-column>
                    <el-table-column :show-overflow-tooltip="true" label="教师" width="240" prop="teachers" >
                    </el-table-column>
                    <el-table-column label="学习进度统计方式" width="150" prop="statType" >
                    </el-table-column>
                    <el-table-column
                            label="课程时长（小时）"
                            width="150"
                            prop="courseDuration"
                    >
                    </el-table-column>
                    <el-table-column label="操作" fixed="right" width="400">
                        <!-- slot-scope="scope"插槽 -->
                        <template slot-scope="scope" class="botton-all">

                            <el-button
                                    @click="production2(scope.row)"
                                    type="primary"
                                    size="small"
                                    v-permission="'eduCourse:setUpMakeCourseTeacher'">
                                设置制作教师
                            </el-button>
                            <el-button
                                    @click="Setting(scope.row)"
                                    type="primary"
                                    size="small" v-permission="'eduCourse:addSetUpCourseTeacher'">
                                设置教师
                            </el-button>
                            <el-button
                                    @click="editor(scope.row)"
                                    type="primary"
                                    size="small">
                                编辑
                            </el-button>
                            <el-button
                                    @click="deleteCorrespond(scope.row)"
                                    type="danger"
                                    size="small"
                                    v-permission="'eduCourse:deletedCourse'">
                                删除
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <div style="padding: 1rem">
                    <el-pagination
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                            :current-page="currentPage4"
                            :page-sizes="[10, 20, 50, 100]"
                            :page-size="10"
                            layout="total, sizes, prev, pager, next, jumper"
                            :total="total"
                    >
                    </el-pagination>
                </div>
            </div>
        </div>
        <!-- 编辑弹窗 -->
        <el-dialog
                title="编辑"
                :visible.sync="dialogVisible"
                width="30%"
                style="text-align: left">
            <el-form
                    :model="teacherForm"
                    :rules="teacherRules"
                    ref="ruleForm"
                    class="demo-ruleForm item"
                    label-width="120px">
                <el-form-item label="课程编号：" prop="courseCode">
                    <el-input
                        style="width: 200px"
                            v-model="teacherForm.courseCode"
                            placeholder="请输入"
                            class="el-form-input-width"
                            @input="() => (teacherForm.courseCode = teacherForm.courseCode.trim())"></el-input>
                </el-form-item>
                <el-form-item label="课程名称：" prop="courseName">
                    <el-input
                        style="width: 200px"
                            v-model="teacherForm.courseName"
                            placeholder="请输入"
                            class="el-form-input-width"
                            @input="() => (teacherForm.courseName = teacherForm.courseName.trim())"></el-input>
                </el-form-item>
                <el-form-item label="课程类型：" prop="courseType">
                    <el-select style="width: 200px" v-model="teacherForm.courseType" placeholder="请选择">
                        <el-option
                                v-for="item in options"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <!-- 学分 -->
                <el-form-item label="学分：" prop="stuScore">
                    <el-input
                        style="width: 200px"
                            v-model="teacherForm.stuScore" type="number" min="0"
                            placeholder="请输入"
                            class="el-form-input-width"
                            @input="() => (teacherForm.stuScore = teacherForm.stuScore.trim())"
                    ></el-input>
                </el-form-item>
                <el-form-item label="授权好课联盟：" prop="stuScore">
                    <el-select style="width: 200px" v-model="teacherForm.courseStaus" disabled placeholder="请选择">
                        <el-option
                                v-for="item in courseStausArrays"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="课程属性：" prop="stuScore" v-show="teacherForm.courseStaus==1">
                    <el-select style="width: 200px" v-model="teacherForm.courseAttr" placeholder="请选择">
                        <el-option
                                v-for="item in courseAttrArrays"
                                :key="item.id"
                                :label="item.label"
                                :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="好课联盟分类：" prop="stuScore" v-show="teacherForm.courseStaus==1">
                    <el-cascader
                        ref="teacherFormCourseTypeId"
                        style="width: 200px"
                            :props="{label:'cateName',value:'id',children:'hklmCourseCates',checkStrictly: true}"
                            v-model="teacherForm.courseTypeId"
                            :options="courseTypeArrays" clearable>
                    </el-cascader>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="editingscript('ruleForm')">确 定</el-button>
      </span>
        </el-dialog>
        <!-- 新增弹窗 -->
        <el-dialog
                title="新增"
                :visible.sync="dialogVisible1"
                width="30%"
                style="text-align: left">
            <el-form
                    :model="teacherForm"
                    :rules="teacherRules"
                    ref="ruleForm"
                    class="demo-ruleForm item"
                    label-width="120px">
                <el-form-item label="课程编号：" prop="courseCode">
                    <el-input
                        style="width: 200px"
                            v-model="teacherForm.courseCode"
                            placeholder="请输入"
                            class="el-form-input-width"
                            @input="() => (teacherForm.courseCode = teacherForm.courseCode.trim())"></el-input>
                </el-form-item>
                <el-form-item label="课程名称：" prop="courseName">
                    <el-input
                        style="width: 200px"
                            v-model="teacherForm.courseName"
                            placeholder="请输入"
                            class="el-form-input-width"
                            @input="() => (teacherForm.courseName = teacherForm.courseName.trim())"></el-input>
                </el-form-item>
                <el-form-item label="课程类型：" prop="courseType">
                    <el-select  style="width: 200px" v-model="teacherForm.courseType" placeholder="请选择">
                        <el-option
                                v-for="item in options"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <!-- 学分 -->
                <el-form-item label="学分：" prop="stuScore">
                    <el-input
                        style="width: 200px"
                            v-model="teacherForm.stuScore" type="number" min="0"
                            placeholder="请输入"
                            class="el-form-input-width"
                            @input="() => (teacherForm.stuScore = teacherForm.stuScore.trim())"
                    ></el-input>
                </el-form-item>
                <el-form-item label="授权好课联盟：">
                    <el-select  style="width: 200px" v-model="teacherForm.courseStaus" placeholder="请选择">
                        <el-option
                                v-for="item in courseStausArrays"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="课程属性：" v-show="teacherForm.courseStaus==1">
                    <el-select  style="width: 200px" v-model="teacherForm.courseAttr" placeholder="请选择">
                        <el-option
                                v-for="item in courseAttrArrays"
                                :key="item.id"
                                :label="item.label"
                                :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="好课联盟分类：" v-show="teacherForm.courseStaus==1">
                    <el-cascader
                        style="width: 200px"
                            :props="{label:'cateName',value:'id',children:'hklmCourseCates',checkStrictly: true}"
                            v-model="teacherForm.courseTypeId"
                            :options="courseTypeArrays">
                    </el-cascader>
                    <!--                    <el-select v-model="teacherForm.courseTypeId" placeholder="请选择">-->

                    <!--                        <el-option-->
                    <!--                                v-for="item in courseTypeArrays"-->
                    <!--                                :key="item.id"-->
                    <!--                                :label="item.cateName"-->
                    <!--                                :value="item.id">-->
                    <!--                        </el-option>-->
                    <!--                    </el-select>-->
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible1 = false">取 消</el-button>
        <el-button type="primary" @click="addbutton('ruleForm')">确 定</el-button>
      </span>
        </el-dialog>
        <!-- 设置制作教师弹窗 -->
        <el-dialog
                title="设置制作课程教师"
                :visible.sync="dialogVisible2"
                width="50%"
                style="text-align: left">
            <el-form :model="search2" class="demo-ruleForm" inline>
                <el-form-item style="margin-bottom: 0">
                    <el-input
                            v-model="search2.name2"
                            clearable
                            @input="() => (search2.name2 = search2.name2.trim())"
                            placeholder="教师名字/工号"
                            style="width: 300px"
                    ></el-input>
                    <el-button
                            icon="el-icon-search"
                            @click="handleSearchproduction"
                    ></el-button>
                </el-form-item>
            </el-form>
            <div style="margin-top: 1rem">
                <el-table :data="tableData2" border style="width: 100%">
                    <el-table-column type="selection" width="55"></el-table-column>
                    <el-table-column prop="jobNo" label="教师工号"></el-table-column>
                    <el-table-column prop="name" label="教师姓名"></el-table-column>
                    <el-table-column prop="sex" label="性别"></el-table-column>
                    <el-table-column prop="profDir" label="专业方向"></el-table-column>
                    <el-table-column prop="teacherType" label="教师类型">
                    </el-table-column>
                    <el-table-column prop="tearcherAttr" label="教师职称">
                    </el-table-column>
                    <el-table-column label="操作" fixed="right">
                        <template slot-scope="scope">
                            <el-button
                                    @click="production1(scope.row)"
                                    type="primary"
                                    size="small">保存
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <div style="padding: 1rem; text-align: center">
                    <el-pagination
                            @size-change="handleSizeChange5"
                            @current-change="handleCurrentChange5"
                            :current-page="currentPage5"
                            :page-sizes="[10, 20, 50, 100]"
                            :page-size="10"
                            layout="total, sizes, prev, pager, next, jumper"
                            :total="total1"
                    >
                    </el-pagination>
                </div>
            </div>
        </el-dialog>
        <!-- 课程导入 上传文件 -->
        <el-dialog
                title="上传文件"
                :visible.sync="dialogVisibleUpload1"
                width="30%">
            <div style="margin-bottom: 20px">
                <el-button type="primary" @click="kechengjiaoshidaoru1"
                           v-permission="'eduCourse:exportTemplateCourseMake'">课程教师导入模板下载
                </el-button>
                <span style="color: red">提示：导入前请先下载模板，根据模版整理好数据后上传导入.</span>
            </div>
            <el-upload
                    class="upload-demo"
                    drag
                    action="#"
                    :show-file-list="false"
                    :http-request="uploadFile1"
                    :before-upload="beforeUpload1">
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                <div class="el-upload__tip" slot="tip">
                    只能上传excel文件，且不超过50M
                </div>
            </el-upload>
        </el-dialog>
        <!-- 课程教师导入 上传文件 -->
        <el-dialog title="上传文件" :visible.sync="dialogVisibleUpload" width="30%">
            <div style="margin-bottom: 20px">
                <el-button type="primary" @click="kechengjiaoshidaoru" v-permission="'eduCourse:exportTemplateCourse'">
                    下载课程模板
                </el-button>
                <span style="color: red">提示：导入前请先下载模板，根据模版整理好数据后上传导入.</span>
            </div>
            <el-upload
                    class="upload-demo"
                    drag
                    action="#"
                    :show-file-list="false"
                    :http-request="uploadFile"
                    :before-upload="beforeUpload"
            >
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                <div class="el-upload__tip" slot="tip">
                    只能上传excel文件，且不超过50M
                </div>
            </el-upload>
        </el-dialog>
        <!-- 设置教师弹窗 -->
        <el-dialog title="设置教师" :visible.sync="outerVisible" :before-close="outerVisibleClose">
            <div style="margin-top: 1rem">
                <el-table
                        :data="tableData2"
                        border
                        style="width: 100%"
                        @selection-change="tanchaid"
                        ref="tanchaidselection">
                    <el-table-column type="selection" width="55"></el-table-column>
                    <el-table-column prop="jobNo" label="教师工号"></el-table-column>
                    <el-table-column prop="name" label="教师姓名"></el-table-column>
                    <el-table-column prop="sex" label="性别"></el-table-column>
                    <el-table-column prop="profDir" label="专业方向"></el-table-column>
                    <el-table-column prop="teacherType" label="教师类型">
                    </el-table-column>
                    <el-table-column prop="tearcherAttr" label="教师职称">
                    </el-table-column>
                </el-table>
                <div style="padding: 1rem; text-align: center">
                    <el-pagination
                            @size-change="handleSizeChange2"
                            @current-change="handleCurrentChange2"
                            :current-page="currentPage2"
                            :page-sizes="[10, 20, 50, 100]"
                            :page-size="10"
                            layout="total, sizes, prev, pager, next, jumper"
                            :total="total2"
                    >
                    </el-pagination>
                </div>
            </div>
            <!-- 添加按钮弹窗 -->
            <el-dialog
                    width="50%"
                    title="添加教师"
                    :visible.sync="innerVisible"
                    append-to-body>
                <el-form :model="search3" class="demo-ruleForm" inline>
                    <el-form-item style="margin-bottom: 0">
                        <el-input
                                v-model="search3.name3"
                                clearable
                                @input="() => (search3.name3 = search3.name3.trim())"
                                placeholder="姓名/工号"
                                style="width: 300px"
                        ></el-input>
                        <el-button icon="el-icon-search" @click="handleSearch2"></el-button>
                    </el-form-item>
                </el-form>
                <div style="margin-top: 1rem">
                    <el-table :data="tableData3" border style="width: 100%" @selection-change="tanchaid1"
                              ref="tanchaidselection1">
                        <el-table-column type="selection" width="55"></el-table-column>
                        <el-table-column prop="jobNo" label="教师工号"></el-table-column>
                        <el-table-column prop="name" label="教师姓名"></el-table-column>
                        <el-table-column prop="sex" label="性别"></el-table-column>
                        <el-table-column prop="siteName" label="院系"></el-table-column>
                        <el-table-column prop="teacherType" label="教师类型">
                        </el-table-column>
                        <el-table-column prop="jodTitle" label="教师职称">
                        </el-table-column>
                    </el-table>
                    <div style="padding: 1rem; text-align: center">
                        <el-pagination
                                @size-change="handleSearch2"
                                @current-change="handleSearch2"
                                :current-page="currentPage1"
                                :page-sizes="[10, 20, 50, 100]"
                                :page-size="pageSize1"
                                layout="total, sizes, prev, pager, next, jumper"
                                :total="total"
                        >
                        </el-pagination>
                    </div>
                </div>
                <div slot="footer" class="dialog-footer">
                    <el-button type="primary" @click="production3()  ">保存后关闭</el-button>
                </div>
            </el-dialog>
            <div slot="footer" class="dialog-footer">
                <el-button @click="outerVisible1">删 除</el-button>
                <el-button
                        type="primary"
                        @click="(innerVisible = true), handleSearch2()"
                >添加教师
                </el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
    export default {
        created() {
            this.queryCourseTypeIdArrays();//查询好客联盟分类
            this.yemianchaxun(); //页面查询
            this.production(); //设置制作课程教师
        },
        data() {
            return {
                total1: 0, //设置制作教师-按钮弹窗查询分页总数
                x1: [],
                // 课程id
                kechengid: "",
                //拼接后课程需要参数id
                pinjieid: "", //删除课程id
                numid: "", // 编辑前id
                courseIds: "",
                courseId: "",
                total: 0,
                total2: 0,
                pageNum: 1,
                pageSize: "",
                pageSize1: 10,
                currentPage4: 1,
                currentPage1: 1,
                currentPage2: 1,
                currentPage5: 1,
                textarea: "",
                search: {
                    name: "",
                }, //主页面表格查询
                search2: {
                    name2: "",
                }, //设置教师名称/工号查询
                search3: {
                    name3: "",
                }, //设置教师查询
                teacherForm: {
                    schoolId: '',
                    courseCode: "",
                    courseName: "",
                    courseType: 0,
                    stuScore: 0,
                    courseAttr: 1,
                    courseStaus: 0,
                    courseTypeId: null
                },
                outerVisible: false,
                innerVisible: false,
                tableChecked: [],
                tableData: [], //设置页面数据
                tableData2: [], //设置教师弹窗tab数据
                tableData1: [], //设置教师弹窗添加tab数据
                tableData3: [],//设置教师弹窗添加--tab数据
                correspondType: [
                    {
                        value: 0,
                        label: "全部",
                    },
                    {
                        value: 1,
                        label: "函授站",
                    },
                    {
                        value: 2,
                        label: "学习中心",
                    },
                    {
                        value: 3,
                        label: "函授站及学习中心",
                    },
                ],
                dialogVisible: false,
                dialogVisible1: false,
                dialogVisible2: false,
                dialogVisibleUpload: false,
                dialogVisibleUpload1: false,
                tanchaidselection: [],
                tanchaidselection1: [],
                teacherRules: {
                    name: [{required: true, message: "请输入姓名", trigger: "blur"}],
                    fullName: [
                        {required: true, message: "请输入真实姓名", trigger: "blur"},
                    ],
                    sex: [{required: true, message: "请选择性别", trigger: "blur"}],
                    phone: [
                        {required: true, message: "请输入手机号码", trigger: "blur"},
                        {pattern: /^1[3456789]\d{9}$/, message: "手机号格式不正确"},
                    ],
                    email: [
                        {required: false, message: "请输入邮箱", trigger: "blur"},
                        {
                            pattern: /^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$/,
                            message: "邮箱格式不正确",
                        },
                    ],
                    idCard: [
                        {required: false, message: "请输入身份证号码", trigger: "blur"},
                        {
                            pattern: /^([1-6][1-9]|50)\d{4}(18|19|20)\d{2}((0[1-9])|10|11|12)(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/,
                            message: "身份证号格式不正确",
                        },
                    ],
                },
                options: [
                    {
                        value: 0,
                        label: "普通 ",
                    },
                    {
                        value: 1,
                        label: "毕业设计 ",
                    },
                    {
                        value: 2,
                        label: "毕业大作业 ",
                    },
                    {
                        value: 3,
                        label: "选修课 ",
                    },
                ],
                value: "",
                courseAttrArrays: [
                    {id: 1, label: "mooc"},
                    {id: 2, label: "Spoc本校可看"}
                ],//课程属性 2Spoc本校可看  1mooc (好课联盟授权为1时显示)
                courseStausArrays: [
                    {value: 0, label: "否"},
                    {value: 1, label: "是"}
                ],//是否授权好课联盟 0否 1是
                courseTypeArrays: [],//好课联盟课程分类id(好课联盟授权为1时显示)
            };
        },
        methods: {
          outerVisibleClose(done){
            done();
            this.yemianchaxun();
          },
            //移除教师
            removeTeacher() {
                if (this.tableChecked.length <= 0) {
                    this.$message.warning("请选择条目");
                }
            },
            // 页面查询
            yemianchaxun() {
                this.getPermission('eduCourse:queryAllCourseBySiteId', () => {
                    this.openLoadingView();
                    this.$axios({
                        method: "GET",
                        url: "hnjxjy-core/eduCourse/queryAllCourseBySiteId",
                        params: {
                            page: this.currentPage4, //页数
                            pageSize: this.pageSize, //条数
                            codeOrName: this.search.name, //课程名称或者编号
                        }
                    }).then((res) => {
                        this.tableData = res.data.data.list;
                        this.total = res.data.data.total;
                        this.loadingView.close();
                    }).catch((err) => {
                        this.loadingView.close();
                    });
                });
            },
            //设置制作教师-按钮查看制作课程老师 列表
            production() {
                let objPar = JSON.parse(localStorage.getItem("loginStrage"));
                this.openLoadingView();
                this.$axios({
                    method: "GET",
                    url: "hnjxjy-core/eduGraduateWorkBatch/queryAllGuidanceTeacherBySiteId",
                    parame: {
                        page: this.currentPage5, //页数
                        pageSize: this.pageSize, //条数
                        nameOrCode: this.search.name, //名称或者编码
                    },
                    headers: objPar,
                }).then((res) => {
                    this.tableData2 = res.data.data.list; //分页数据
                    this.total1 = res.data.data.total;
                    this.loadingView.close();
                }).catch((err) => {
                  this.loadingView.close();
                });
            },
            // 设置教师弹窗页面渲染
            shezhijiaoshi() {
                let objPar = JSON.parse(localStorage.getItem("loginStrage"));
                this.$axios({
                    method: "GET",
                    url: "hnjxjy-core/eduCourse/querySetUpCourseTeacherByCourseId",
                    params: {
                        id: this.kechengid,
                        page: this.currentPage2, //页数
                        pageSize: this.pageSize, //条数
                    },
                    headers: objPar,
                }).then((res) => {
                    this.tableData2 = res.data.data.list;
                    this.total2 = res.data.data.total;
                }).catch((err) => {
                });
            },
            //设置教师弹窗-添加按钮-列表渲染
            innerVisible1() {
                let objPar = JSON.parse(localStorage.getItem("loginStrage"));
                this.$axios({
                    method: "get",
                    url: "hnjxjy-core/eduGraduateWorkBatch/queryAllGuidanceTeacherBySiteId",
                    params: {
                        page: this.currentPage1, //页数
                        pageSize: this.pageSize1, //页数
                        id: this.kechengid,
                    },
                    headers: objPar,
                }).then((res) => {
                    this.total = res.data.data.total;
                    // list
                    this.tableData3 = res.data.data.list;
                }).catch((err) => {
                });
            },
            // 主页面课程名字/课程编号查询
            handleSearch1() {
                let objPar = JSON.parse(localStorage.getItem("loginStrage"));
                this.$axios({
                    method: "GET",
                    url: "hnjxjy-core/eduGraduateWorkBatch/queryAllGuidanceTeacherBySiteId",
                    params: {
                        page: "", //页数
                        pageSize: "", //条数
                        codeOrName: this.search.name, //课程名称或者编号
                    },
                    headers: objPar,
                })
                    .then((res) => {
                        this.tableData = res.data.data.list;
                    })
                    .catch((err) => {
                    });
            },
            // 姓名/工号列表查询
            handleSearch2() {
                let bo = "?page=" + this.currentPage1 + "&pageSize=" + this.pageSize1;
                if (this.nameOrCode != null && this.nameOrCode != '') {
                    bo += "&nameOrCode=" + this.nameOrCode;
                }
                this.$axios_supermall.get("hnjxjy-core/eduGraduateWorkBatch/queryAllGuidanceTeacherBySiteId" + bo,).then((res) => {
                    this.tableData3 = res.data.data.list;
                }).catch((err) => {
                });
            },
            addcreate() {
                this.teacherForm = {
                    schoolId: '',
                    courseCode: "",
                    courseName: "",
                    courseType: 0,
                    stuScore: 0,
                    courseAttr: 1,
                    courseStaus: 0,
                    courseTypeId: null
                };
                this.dialogVisible1 = true;
            },
            // 批量删除
            batchDelete() {
                if (this.tableChecked.length <= 0) {
                    this.$message.error("请至少选择一项记录");
                } else {
                    this.$confirm(`您确定要删除${this.tableChecked.length}数据吗?`, "提示", {
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                        type: "warning",
                    })
                        .then(() => {

                            let ids = "";
                            this.tableChecked.forEach(item => {
                                ids = ids + item.id + ",";
                            })
                            ids = ids.substr(0, ids.length - 1);
                            let bo = {
                                courseIds: ids
                            };
                            this.$axios_supermall.post("/hnjxjy-core/eduCourse/deletedCourseByCourseId", bo).then(resp => {
                                if (resp.data.code == 2000) {
                                    this.$message.success("删除成功");
                                    this.yemianchaxun();
                                }
                            })
                        })
                        .catch(() => {
                            this.$message({
                                type: "info",
                                message: "已取消删除",
                            });
                        });
                }
            },
            // 导入课程
            upload() {
                this.dialogVisibleUpload = true;
            },
            //导入课程教师点击导入
            upload1() {
                this.dialogVisibleUpload1 = true;
            },
            //设置制作教师-按钮查看制作课程老师按键
            production2(row) {
                this.tableData2 = []; //分页数据
                this.total1 = 0;
                this.production(); //设置制作课程教师
                this.kechengid = row.id;
                this.dialogVisible2 = true;
            },
            // 制作课程老师-按照名字查询
            handleSearchproduction() {
                let objPar = JSON.parse(localStorage.getItem("loginStrage"));
                this.$axios({
                    method: "GET",
                    url: "hnjxjy-core/eduGraduateWorkBatch/queryAllGuidanceTeacherBySiteId",
                    params: {
                        page: "", //页数
                        pageSize: "", //条数
                        nameOrCode: this.search2.name2, //名称或者编码
                    },
                    headers: objPar,
                })
                    .then((res) => {
                        this.tableData2 = res.data.data.list; //分页数据
                        // this.total1 = res.data.data.total;
                    })
                    .catch((err) => {
                    });
            },

            // 设置制作教师-弹窗
            production1(row) {
                let objPar = JSON.parse(localStorage.getItem("loginStrage"));
                this.$axios({
                    method: "POST",
                    url: "hnjxjy-core/eduCourse/setUpMakeCourseTeacher",
                    data: {
                        courseId: this.kechengid, //课程id
                        teacherId: row.id, //老师id
                    },
                    headers: objPar,
                })
                    .then((res) => {
                        if (res.data.code == 2000) {
                            this.$message.success("操作成功");
                            this.dialogVisible2 = false;
                            this.yemianchaxun();
                        }
                    })
                    .catch((err) => {
                    });
            },
            //设置教师-保存并关闭
            production3() {
                if (this.tanchaidselection1 == null || this.tanchaidselection1.size <= 0) {
                    this.$message.warning("请选择要添加的教师");
                    return;
                }
                let data = {
                    courseId: this.kechengid, //课程id
                    teacherIds: '', //老师id
                };
                this.tanchaidselection1.forEach(t => {
                    data.teacherIds += t.id + ",";
                });
                data.teacherIds = data.teacherIds.substring(0, data.teacherIds.length - 1);
                this.$axios_supermall.post("hnjxjy-core/eduCourse/addSetUpCourseTeacher", data).then((res) => {
                    if (res.data.code == 2000) {
                        this.$message.success("设置成功");
                        this.innerVisible = false;
                        this.shezhijiaoshi();
                    } else {
                        this.$message.warning(res.data.message);
                    }
                }).catch((err) => {
                    this.$message.warning(err);
                });
            },
            //设置教师
            Setting(row) {
                this.tableData2 = []; //分页数据
                this.total1 = 0;
                this.outerVisible = true;
                this.kechengid = row.id;
                this.shezhijiaoshi();
            },
            //  课程导入 上传文件
            uploadFile1(file) {
              let fileDate = file.file;
              let forms = new FormData();
              forms.append("file", fileDate);
              let objPar = JSON.parse(localStorage.getItem("loginStrage"));
              this.openLoadingView();
              this.$axios({
                method: "post",
                url: "hnjxjy-core/eduCourse/exportExcelCourseMakeTeacher",
                data: forms,
                headers: objPar,
              }).then((res) => {
                if (res.data.code === 2000){
                  this.$message.success(res.data.data)
                  this.dialogVisibleUpload1 = false;
                  this.yemianchaxun();
                }else {
                  this.$message.error("操作失败");
                  this.dialogVisibleUpload1 = false;
                  this.loadingView.close();
                }
              }).catch((err) => {
                this.$message.error("操作失败")
                this.dialogVisibleUpload1 = false;
                this.loadingView.close();
              });
            },
            //  课程教师导入 上传文件前判断文件格式
            beforeUpload1(file) {
                const isXls = file.type === "application/vnd.ms-excel";
                const isXlsx =
                    file.type ===
                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
                if (!isXls && !isXlsx) {
                    this.$message.error("上传文件只能是XLS/XLSX格式！");
                    return false;
                }
                return isXls || isXlsx;
            },
            // 课程教师导入-下载模版
            kechengjiaoshidaoru() {
              let objPar = JSON.parse(localStorage.getItem("loginStrage"));
              this.openLoadingView();
              this.$axios({
                method: "get",
                url: "hnjxjy-core/eduCourse/exportTemplateCourse",
                responseType: "blob",
                headers: objPar,
              }).then((res) => {
                let blob = new Blob([res.data], {
                  type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8',
                });
                // let url = window.URL.createObjectURL(blob);
                // window.location.href = url;
                let downloadElement = document.createElement('a');
                let href = window.URL.createObjectURL(blob);
                downloadElement.href = href;
                downloadElement.download = '课程导入模板.xlsx';　　　　　　　　　　// xxx.xls/xxx.xlsx
                document.body.appendChild(downloadElement);
                downloadElement.click();
                document.body.removeChild(downloadElement);
                window.URL.revokeObjectURL(href);
                this.$message.success("操作成功");
                this.loadingView.close();
                //application/vnd.openxmlformats-officedocument.spreadsheetml.sheet这里表示xlsx类型
              }).catch((err) => {
                this.$message.error("操作失败");
                this.loadingView.close();
              });
            },
            // 课程下载模版
            kechengjiaoshidaoru1() {
              let objPar = JSON.parse(localStorage.getItem("loginStrage"));
              this.openLoadingView();
              this.$axios({
                method: "get",
                url: "hnjxjy-core/eduCourse/exportTemplateCourseMake",
                responseType: "blob",
                headers: objPar,
              }).then((res) => {
                let blob = new Blob([res.data], {
                  type: "application/vnd.ms-excel",
                });
                let downloadElement = document.createElement('a');
                let href = window.URL.createObjectURL(blob);
                downloadElement.href = href;
                downloadElement.download = '课程教师导入模板.xlsx';　　　　　　　　　　// xxx.xls/xxx.xlsx
                document.body.appendChild(downloadElement);
                downloadElement.click();
                document.body.removeChild(downloadElement);
                window.URL.revokeObjectURL(href);
                this.$message.success("操作成功");
                this.loadingView.close();
              }).catch((err) => {
                this.$message.error("操作失败");
                this.loadingView.close();
              });
            },
            // 课程教师导入 上传文件
            uploadFile(file) {
                let fileDate = file.file;
                let forms = new FormData();
                forms.append("file", fileDate);
                let objPar = JSON.parse(localStorage.getItem("loginStrage"));
                this.openLoadingView();
                this.$axios({
                    method: "post",
                    url: "hnjxjy-core/eduCourse/exportExcelCourse",
                    data: forms,
                    headers: objPar,
                }).then((res) => {
                  if (res.data.code === 2000){
                    this.$message.success(res.data.data)
                    this.dialogVisibleUpload = false;
                    this.yemianchaxun();
                  }else {
                    this.$message.error("操作失败")
                    this.dialogVisibleUpload = false;
                    this.loadingView.close();
                  }
                }).catch((err) => {
                  this.$message.error("操作失败")
                  this.dialogVisibleUpload = false;
                  this.loadingView.close();
                });
            },
            // 课程教师导入 上传文件 前判断文件格式
            beforeUpload(file) {
                const isXls = file.type === "application/vnd.ms-excel";
                const isXlsx =
                    file.type ===
                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
                if (!isXls && !isXlsx) {
                    this.$message.error("上传文件只能是XLS/XLSX格式！");
                    return false;
                }
                return isXls || isXlsx;
            },
            // 点击导出课程-点击按钮
            download() {
              let objPar = JSON.parse(localStorage.getItem("loginStrage"));
              this.openLoadingView();
              this.$axios({
                method: "get",
                url: "hnjxjy-core/eduCourse/exportCourseExcelBySiteId",
                responseType: "blob",
                headers: objPar,
              }).then((res) => {
                let blob = new Blob([res.data], {
                  type: "application/vnd.ms-excel",
                });
                let url = window.URL.createObjectURL(blob);
                window.location.href = url;
                this.$message.success("操作成功");
                this.loadingView.close();
              }).catch((err) => {
                this.$message.error("操作失败");
                this.loadingView.close();
              });
            },
            downloadupload() {
                let objPar = JSON.parse(localStorage.getItem("loginStrage"));
                this.$axios({
                    method: "get",
                    url: "hnjxjy-core/eduCourse/exportTemplateCourse",
                    responseType: "arraybuffer",
                    headers: objPar,
                }).then((res) => {
                    let blob = new Blob([res.data], {
                        type: "application/vnd.ms-excel",
                    });
                    let url = window.URL.createObjectURL(blob);
                    window.location.href = url;
                }).catch((err) => {
                });
            },
            // 更新课程时长
            gengxinshijianchang() {
                this.$axios_supermall.post("hnjxjy-core/eduCourse/updateCourseDuration").then((res) => {
                    if (res.data.code === 2000) {
                      this.$message.success("处理成功");
                      this.yemianchaxun();
                    }
                }).catch((err) => {
                });
            },
            handleSelectionChange(val) {
                this.tableChecked = val;
                // this.tableChecked.forEach((item) => {
                //   this.kechengid = item.id;
                // });
                // this.x1.push(this.kechengid);
                // let str = [...new Set(this.x1)]
                // let str1 = str.join();
                // this.pinjieid = str1;
            },
            // 按总学习时长统计
            anzhongxuexishichangtongji() {
                if (this.tableChecked == null || this.tableChecked.length <= 0) {
                    this.$message.warning("请至少选择一行")
                    return;
                }
                let data = {
                    courseIds: "", //课程id //课程id，多个逗号相隔
                };
                this.tableChecked.forEach(t => {
                    data.courseIds += t.id + ',';
                });
                data.courseIds = data.courseIds.substring(0, data.courseIds.length - 1);
                this.$axios_supermall.post("hnjxjy-core/eduCourse/courseLengthOfStudy", data).then((res) => {
                    if (res.data.code === 2000) {
                        this.$message.success("处理成功");
                        this.yemianchaxun();
                    }
                }).catch((err) => {
                });
            },
            // 按每章节进度统计
            anmeizhangkechengtongji() {
                if (this.tableChecked == null || this.tableChecked.length <= 0) {
                    this.$message.warning("请至少选择一行")
                    return;
                }
                let data = {
                    courseIds: "", //课程id //课程id，多个逗号相隔
                };
                this.tableChecked.forEach(t => {
                    data.courseIds += t.id + ',';
                });
                data.courseIds = data.courseIds.substring(0, data.courseIds.length - 1)
                this.$axios_supermall.post("hnjxjy-core/eduCourse/courseStatisticsChapter", data).then((res) => {
                    if (res.data.code === 2000) {
                        this.$message.success("处理成功")
                        this.yemianchaxun()
                    }
                }).catch((err) => {
                });
            },
            // 移出制作教师
            yichuzhizuojiaoshi() {
                if (this.tableChecked.length <= 0) {
                    this.$message.warning("请选择课程");
                    return;
                }
                let ids = '';
                this.tableChecked.forEach(t => {
                    ids += t.id + ","
                });
                ids = ids.substring(0, ids.length - 1);

                let objPar = JSON.parse(localStorage.getItem("loginStrage"));
                this.$axios({
                    method: "post",
                    url: "hnjxjy-core/eduCourse/removeCourseWorkTeacher",
                    headers: objPar,
                    data: {
                        ids: ids, //课程id //课程id，多个逗号相隔
                    },
                }).then((res) => {
                    if (res.data.code === 2000) {
                        this.yemianchaxun();
                    }
                }).catch((err) => {
                });
            },
            // 点击 编辑修改
            editor(row) {
                this.openLoadingView();
                let objPar = JSON.parse(localStorage.getItem("loginStrage"));
                this.$axios({
                    method: "get",
                    url: "hnjxjy-core/eduCourse/showModifyCourseById",
                    headers: objPar,
                    params: {
                        id: row.id, //课程id //课程id，多个逗号相隔
                    },
                }).then((res) => {
                    this.teacherForm = res.data.data;
                    this.dialogVisible = true;
                    this.loadingView.close();
                }).catch((err) => {
                });
            },
            // 编辑后-点击确认按钮
            editingscript() {
                if(this.teacherForm.stuScore<0){
                    this.$message.warning('学分不能为负数')
                    return;
                }
                let objPar = JSON.parse(localStorage.getItem("loginStrage"));
                this.openLoadingView();
                if (null != this.teacherForm.courseTypeId){
                  this.teacherForm.courseTypeId = this.$refs['teacherFormCourseTypeId'].getCheckedNodes()[0].value;
                }
                
                this.$axios({
                    method: "post",
                    url: "hnjxjy-core/eduCourse/updateCourseService",
                    headers: objPar,
                    data: this.teacherForm,
                })
                    .then((res) => {
                        if (res.data.code == 2000) {
                            this.dialogVisible = false;
                            this.yemianchaxun();
                        } else {
                            this.$message.warning(res.data.message)
                        }
                        this.loadingView.close();
                    }).catch((err) => {
                    this.loadingView.close();
                });
            },
            //增加-确定按钮
            addbutton() {
                if(this.teacherForm.stuScore<0){
                    this.$message.warning('学分不能为负数')
                    return;
                }
                let objPar = JSON.parse(localStorage.getItem("loginStrage"));
                this.openLoadingView();
                let bo = JSON.parse(JSON.stringify(this.teacherForm));
                if (this.teacherForm.courseStaus == 1) {
                    bo.courseTypeId = bo.courseTypeId[bo.courseTypeId.length - 1];
                }
                this.$axios({
                    method: "post",
                    url: "hnjxjy-core/eduCourse/add",
                    headers: objPar,
                    data: bo,
                }).then((res) => {
                    this.loadingView.close();
                    if (res.data.code == 2000) {
                        this.dialogVisible1 = false;
                        this.yemianchaxun();
                    }else{
                      this.$message.error(res.data.message)
                    }
                }).catch((err) => {
                    this.loadingView.close();
                });
            },
            // 主页面分页查询
            handleSizeChange(val) {
              this.currentPage4 = val;
                let objPar = JSON.parse(localStorage.getItem("loginStrage"));
                this.$axios({
                    method: "GET",
                    url: "hnjxjy-core/eduCourse/queryAllCourseBySiteId",
                    params: {
                        page: this.currentPage4, //页数
                        pageSize: val, //条数
                        codeOrName: this.search.name, //课程名称或者编号
                    },
                    headers: objPar,
                })
                    .then((res) => {
                        this.tableData = res.data.data.list;
                        this.total = res.data.data.total;
                        this.pageSize = res.data.data.pageSize;
                    })
                    .catch((err) => {
                    });
            },
            handleCurrentChange(val) {
              this.currentPage4 = val;
                let objPar = JSON.parse(localStorage.getItem("loginStrage"));
                this.$axios({
                    method: "GET",
                    url: "hnjxjy-core/eduCourse/queryAllCourseBySiteId",
                    params: {
                        page: val, //页数
                        pageSize: this.pageSize, //条数
                        codeOrName: this.search.name, //课程名称或者编号
                    },
                    headers: objPar,
                })
                    .then((res) => {
                        this.tableData = res.data.data.list;
                        this.total = res.data.data.total;
                    })
                    .catch((err) => {
                    });
            },
            handleSizeChange1(val) {
                let objPar = JSON.parse(localStorage.getItem("loginStrage"));
                this.$axios({
                    method: "GET",
                    url: "hnjxjy-core/eduCourse/querySetUpCourseTeacherByCourseId",
                    params: {
                        id: this.kechengid, //课程id
                        page: this.currentPage2, //页数
                        pageSize: val, //条数
                    },
                    headers: objPar,
                })
                    .then((res) => {
                        this.tableData = res.data.data.list;
                        this.total = res.data.data.total;
                        this.pageSize = res.data.data.pageSize;
                    })
                    .catch((err) => {
                    });
            },
            handleCurrentChange1(val) {
                let objPar = JSON.parse(localStorage.getItem("loginStrage"));
                this.$axios({
                    method: "GET",
                    url: "hnjxjy-core/eduCourse/querySetUpCourseTeacherByCourseId",
                    params: {
                        id: this.kechengid, //课程id
                        page: val, //页数
                        pageSize: this.pageSize, //条数
                    },
                    headers: objPar,
                })
                    .then((res) => {
                        this.tableData = res.data.data.list;
                        this.total = res.data.data.total;
                    })
                    .catch((err) => {
                    });
            },
            handleSizeChange2(val) {
                let objPar = JSON.parse(localStorage.getItem("loginStrage"));
                this.$axios({
                    method: "GET",
                    url: "hnjxjy-core/eduCourse/querySetUpCourseTeacherByCourseId",
                    params: {
                        id: this.kechengid, //课程id
                        page: this.currentPage2, //页数
                        pageSize: val, //条数
                    },
                    headers: objPar,
                })
                    .then((res) => {
                        this.tableData = res.data.data.list;
                        this.total = res.data.data.total;
                        this.pageSize = res.data.data.pageSize;
                    })
                    .catch((err) => {
                    });
            },
            handleCurrentChange2(val) {
                let objPar = JSON.parse(localStorage.getItem("loginStrage"));
                this.$axios({
                    method: "GET",
                    url: "hnjxjy-core/eduCourse/querySetUpCourseTeacherByCourseId",
                    params: {
                        id: this.kechengid, //课程id
                        page: val, //页数
                        pageSize: this.pageSize, //条数
                    },
                    headers: objPar,
                })
                    .then((res) => {
                        this.tableData = res.data.data.list;
                        this.total = res.data.data.total;
                    })
                    .catch((err) => {
                    });
            },
            // 设置制作课程教师弹窗-分页查询
            handleSizeChange5(val) {
                let objPar = JSON.parse(localStorage.getItem("loginStrage"));
                this.$axios({
                    method: "GET",
                    url: "hnjxjy-core/eduGraduateWorkBatch/queryAllGuidanceTeacherBySiteId",
                    params: {
                        page: this.currentPage5, //页数
                        pageSize: val, //条数
                        nameOrCode: this.search.name, //名称或者编码
                    },
                    headers: objPar,
                })
                    .then((res) => {
                        this.tableData2 = res.data.data.list; //分页数据
                        this.total1 = res.data.data.total;
                        this.pageSize = res.data.data.pageSize;
                    })
                    .catch((err) => {
                    });
                this.production();
            },
            handleCurrentChange5(val) {
                let objPar = JSON.parse(localStorage.getItem("loginStrage"));
                this.$axios({
                    method: "GET",
                    url: "hnjxjy-core/eduGraduateWorkBatch/queryAllGuidanceTeacherBySiteId",
                    params: {
                        page: val, //页数
                        pageSize: this.pageSize, //条数
                        nameOrCode: this.search.name, //名称或者编码
                    },
                    headers: objPar,
                })
                    .then((res) => {
                        this.tableData2 = res.data.data.list; //分页数据
                        this.total1 = res.data.data.total;
                    })
                    .catch((err) => {
                    });
                this.production();
            },
            // 点击删除按钮
            deleteCorrespond(row) {
                this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                }).then(() => {
                    let objPar = JSON.parse(localStorage.getItem("loginStrage"));
                    this.$axios({
                        method: "post",
                        url: "hnjxjy-core/eduCourse/deletedCourseByCourseId",
                        data: {
                            courseIds: row.id, //课程id，多个逗号相隔
                        },
                        headers: objPar,
                    }).then((res) => {
                        // this.tableData = res.data.data.list;
                        if (res.data.code === 2000) {
                            this.$message({
                                type: "success",
                                message: "删除成功!",
                            });
                            this.yemianchaxun();
                        }
                    }).catch((err) => {
                    });

                }).catch(() => {
                    this.$message({
                        type: "info",
                        message: "已取消删除",
                    });
                });
            },
            // 设置教师弹窗
            tanchaid(val) {
                this.tanchaidselection = val;
            },
            tanchaid1(val) {
                this.tanchaidselection1 = val;
            },
            // 设置教师弹窗-删除按钮
            outerVisible1() {
                let data = {
                    courseId: this.kechengid, //课程id
                    teacherIds: ''
                };
                this.tanchaidselection.forEach(t => {
                    data.teacherIds += t.id + ',';
                });
                data.teacherIds = data.teacherIds.substring(0, data.teacherIds.length - 1);
                this.$axios_supermall.post("hnjxjy-core/eduCourse/deletedSetUpCourseTeacherByCourseId", data).then((res) => {
                    // this.tableData = res.data.data.list;
                    if (res.data.code == 2000) {
                        this.$message.success("删除成功")
                        this.shezhijiaoshi();
                    }
                }).catch((err) => {
                });
            },
            //region 好客联盟-分类查询
            queryCourseTypeIdArrays() {
                this.$axios_supermallData.get("/hnjxjy-hklm/hklmCourseCate/selectForHklmCourseCateById").then(resp => {
                    if (resp.data.code === 2000) {
                        this.courseTypeArrays = resp.data.data;
                        this.setArray(this.courseTypeArrays);
                    }
                });
            },
            //endregion
            setArray(array) {
                array.forEach(c => {
                    if (c.hklmCourseCates != null) {
                        if (c.hklmCourseCates.length == 0) {
                            c.hklmCourseCates = null;
                        } else {
                            this.setArray(c.hklmCourseCates);
                        }
                    }
                })
            }
        },
    }
    ;
</script>
<style>
.item .el-form-item__label{
  color: #64666A;
  font-weight: bold;
  text-align: left;
  padding-left: 10px;
}
    .search {
        text-align: left;
        margin-top: 1rem;
        /* margin-left: 1rem; */
        padding: 1rem;
    }

    .table-wrap {
        overflow: auto;
        /* margin-left: 1rem; */
        /* margin-bottom: 1rem; */
        padding: 1rem;
    }

    .demo-ruleForm {
        /* width: 90%; */
        height: calc(100% - 125px);
    }

    .el-form-input-width {
        width: 100%;
    }

    .rigth {
        width: 93%;
        display: inline-block;
        height: 870px;
        overflow-y: scroll;
        /* background-color: #fff; */
        background-color: rgba(164, 174, 224, 0.11);
        float: right;
    }

    .left {
        width: 7%;
        display: inline-block;
    }

    .portrait_Sprite {
        padding: 0.2rem 1rem;
        display: inline-block;
        background-color: skyblue;
        color: #fff;
        border-radius: 10%;
        font-size: 13px;
    }

    .cell section {
        display: flex;
        justify-content: center;
        flex-flow: wrap;
    }

    .cell section .el-button {
        width: 65%;
        margin-top: 1rem;
    }
</style>
