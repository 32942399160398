<template>
  <div>
    <div>
      <div style="padding:1rem;border-bottom: 1px solid #ddd;text-align:left">
        招生须知
      </div>
      <div class="search">
        <el-form class="demo-ruleForm" inline>
          <el-form-item>
            <el-button icon="el-icon-s-tools" type="primary" @click="reporting()" size="small"
              v-permission="'eduEnrolNotice:edit'">设置报到时间
            </el-button>
            <el-button icon="el-icon-s-tools" type="primary" @click="instructions()" size="small"
              v-permission="'eduEnrolNotice:edit'">设置报到须知
            </el-button>
            <el-button icon="el-icon-s-tools" type="primary" @click="guidelines()" size="small"
              v-permission="'eduEnrolNotice:edit'">设置缴费指引
            </el-button>
            <el-button icon="el-icon-s-tools" type="primary" @click="indicator()" size="small"
              v-permission="'eduEnrolNotice:edit'">设置报到成功指引
            </el-button>
          </el-form-item>
          <div>
          </div>
        </el-form>
        <div class="public-global-main_contain">
          <el-card class="box-card">
            <el-form>
              <el-form-item label="当前批次">
                <div v-html="dataTo.batchName"></div>
              </el-form-item>
              <el-form-item label="报道时间">
                <div v-html="dataTo.activeTime"></div>
              </el-form-item>
              <el-form-item label="报到须知">
                <div v-html="dataTo.activeNotice"></div>
              </el-form-item>
              <el-form-item label="缴费指引">
                <div v-html="dataTo.payGuide"></div>
              </el-form-item>
              <el-form-item label="成功指引">
                <div v-html="dataTo.activeTips"></div>
              </el-form-item>
            </el-form>
          </el-card>
        </div>
      </div>
    </div>
    <el-dialog class="add" title="设置报到时间" :visible.sync="dialogVisible4" width="35%" center>
      <el-button icon="el-icon-s-tools" type="primary" @click="dialogVisible4 = false" size="small">关闭</el-button>
      <el-button icon="el-icon-s-tools" type="primary" @click="updateData(0)" size="small">关闭并保存</el-button>

      <el-form ref="form" label-width="100px" class="item" style="margin-top: 30px" size="mini">
        <el-form-item label="当前批次">
          <el-input resize="none" v-model="dataBo.batchName" disabled style="width: 300px">
          </el-input>
        </el-form-item>
        <el-form-item label="报道时间">
          <el-date-picker style="width: 300px" v-model="dataBo.activeTime" type="date" format="yyyy-MM-dd"
            placeholder="选择日期">
          </el-date-picker>
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-dialog class="add" title="设置报到须知" :visible.sync="dialogVisible1" width="70%" center>
      <el-form class="demo-ruleForm" inline>
        <el-button icon="el-icon-s-tools" type="primary" @click="dialogVisible1 = false" size="small">关闭</el-button>
        <el-button icon="el-icon-s-tools" type="primary" @click="updateData(1)" size="small">关闭并保存</el-button>
      </el-form>
      <div style="margin-top: 20px">
        <editor-bar v-model="dataBo.activeNotice" :isClear="isClear"></editor-bar>
      </div>

    </el-dialog>
    <el-dialog class="add" title="设置缴费指引" :visible.sync="dialogVisible2" width="70%" center>
      <el-form class="demo-ruleForm" inline>
        <el-button icon="el-icon-s-tools" type="primary" @click="dialogVisible2 = false" size="small">关闭</el-button>
        <el-button icon="el-icon-s-tools" type="primary" @click="updateData(2)" size="small">关闭并保存</el-button>
      </el-form>
      <div style="margin-top: 20px">
        <editor-bar v-model="dataBo.payGuide" :isClear="isClear"></editor-bar>
      </div>

    </el-dialog>
    <el-dialog class="add" title="设置成功指引" :visible.sync="dialogVisible3" width="70%" center>
      <el-form class="demo-ruleForm" inline>
        <el-button icon="el-icon-s-tools" type="primary" @click="dialogVisible3 = false" size="small">关闭</el-button>
        <el-button icon="el-icon-s-tools" type="primary" @click="updateData(3)" size="small">关闭并保存</el-button>
      </el-form>
      <div style="margin-top: 20px">
        <editor-bar v-model="dataBo.activeTips" :isClear="isClear"></editor-bar>
      </div>

    </el-dialog>
  </div>
</template>
<script>
import EditorBar from '@/components/wangEnduit/editoritem'

export default {
  components: {
    EditorBar
  },
  data() {
    return {
      dataBo: {
        id: '',
        activeTime: '',//报道时间
        activeNotice: '',// 报到须知
        payGuide: '',//缴费指引
        activeTips: '',//成功指引
        batchName: '',
      },
      dataTo: {
        id: '',
        activeTime: '',//报道时间
        activeNotice: '',// 报到须知
        payGuide: '',//缴费指引
        activeTips: '',//成功指引
        batchName: '',
      },
      isClear: false,
      detail: '',
      dialogVisible: false,
      dialogVisible1: false,
      dialogVisible2: false,
      dialogVisible3: false,
      dialogVisible4: false,
      input: "",
      textarea: "",
      radio: '1',
      title: "",
      options: [],
      value: "",
      value1: '',
    };
  },
  mounted() {
    this.query();
    // this.init();
  },
  methods: {
    init() {
      this.getPermission('eduEnrolNotice:queryById', () => {
        this.openLoadingView();
        this.$axios_supermall.get("/hnjxjy-core/eduEnrolNotice/queryById")
          .then(resp => {
            if (resp.data.code === 2000) {
              if (resp.data.data != null) {
                this.dataTo = resp.data.data;
                this.loadingView.close();
              }
            }
          }).catch(err => {
            this.$message.error(err)
            this.loadingView.close();
          })
      });
    },
    updateData(type) {
      let fromData = new FormData();
      fromData.append("id", this.dataBo.id);
      if (type === 1) {
        fromData.append("activeNotice", this.dataBo.activeNotice);
      } else if (type === 2) {
        fromData.append("payGuide", this.dataBo.payGuide);
      } else if (type === 3) {
        fromData.append("activeTips", this.dataBo.activeTips);
      } else if (type === 0) {
        fromData.append("activeTime", this.dataBo.activeTime.formatToLocalDateTime("yyyy/MM/dd").toString());
      }
      this.openLoadingView();
      this.$axios_supermallData.post("/hnjxjy-core/eduEnrolNotice/edit", fromData).then(resp => {
        if (resp.data.code === 2000) {
          this.$message.success("修改成功");
          this.init();
        } else {
          this.$message.error("操作失败")
          this.loadingView.close();
        }
      }).catch((resp) => {
        this.$message({
          type: "info",
          message: resp.message,
        });
        this.loadingView.close();
      });
      if (type === 1) {
        this.dialogVisible1 = false;
      } else if (type === 2) {
        this.dialogVisible2 = false;
      } else if (type === 3) {
        this.dialogVisible3 = false;
      } else if (type === 0) {
        this.dialogVisible4 = false;
      }
    },
    query() {
      this.getPermission('eduEnrolNotice:queryById', () => {
        this.openLoadingView();
        this.$axios.get("/hnjxjy-core/eduEnrolNotice/queryById").then(resp => {
          if (resp.data.code === 2000) {
            if (resp.data.data != null) {
              this.dataBo = resp.data.data;
              this.loadingView.close();
            }
          }
        })
          .catch(err => {
            this.$message.error(err)
            this.loadingView.close();
          })
      });
    },
    handleSearch() {
    },

    //设置报到时间
    reporting() {
      this.dialogVisible4 = true;
      setTimeout(() => {
        this.query();
      }, 100);
    },
    instructions() {
      this.dialogVisible1 = true;
      setTimeout(() => {
        this.query();
      }, 100);
    },
    guidelines() {
      this.dialogVisible2 = true;
      setTimeout(() => {
        this.query();
      }, 100);
    },
    indicator() {
      this.dialogVisible3 = true;
      setTimeout(() => {
        this.query();
      }, 100);
    }
  },
};
</script>
<style>
.item .el-form-item__label {
  color: #64666A;
  font-weight: bold;
}

.search {
  text-align: left;
  margin-top: 1rem;
  /* margin-left: 1rem; */
  padding: 1rem;
}

.table-wrap {
  overflow: auto;
  /* margin-left: 1rem; */
  /* margin-bottom: 1rem; */
  padding: 1rem;
}

.demo-ruleForm {
  /* width: 90%; */
  margin-left: 20px;
  height: calc(100% - 125px);
}

.el-form-input-width {
  width: 100%;
}

.rigth {
  width: 93%;
  height: 870px;
  overflow-y: scroll;
  display: inline-block;
  /* background-color: #fff; */
  background-color: rgba(164, 174, 224, 0.11);
  float: right;
}

.left {
  width: 7%;
  display: inline-block;
}

::v-deep .el-form-item__content {
  text-align: left;
}
</style>
