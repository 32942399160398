<template>
    <div>
        <div class="public-global-right-line infinite-list-wrapper" style="overflow:auto">
            <!-- 主体内容部分 -->
            <section class="zhuti">
                <header class="header">
                </header>

                <!-- 内容部分 -->
                <!-- 主题内容 -->
                <main class="main">
                    <el-row :gutter="20" class="mobilehide">
                        <!-- 左边主内容 -->
                        <el-col :span="16"
                        >
                            <div class="grid-content bg-purple">
                                <el-container>
                                    <!-- 2021年04月22日 -->
                                    <el-header class="time">
                                        <section class="riqi">
                                            <div>
                                                <p>
                                                    {{timeBasic.time}}
                                                </p>
                                                <p>
                                                    {{timeBasic.week}}
                                                </p>
                                            </div>
                                        </section>
                                    </el-header>
                                    <!-- 常用功能 -->
                                    <el-main class="changyonggongneng">
                                        <!-- 常用功能 -->
                                        <section class="changyong">
                                            <div style="margin-left:15px;margin-top: 15px">
                                                <h4>常用功能</h4>
                                                <ul>
                                                    <li v-permission="'eduIndex:termmanagement'">
                                                        <a href="../v1/base/termmanagement">
                                                            <div>
                                                                <img src="../../../assets/accound.png" alt=""/>
                                                            </div>
                                                            <p>学期管理</p></a
                                                        >
                                                    </li>
                                                    <li v-permission="'eduIndex:coursePlanForSchool'">
                                                        <a href="../Online/coursePlanForSchool">
                                                            <div>
                                                                <img src="../../../assets/accound.png" alt=""/>
                                                            </div>
                                                            <p>选课管理</p></a
                                                        >
                                                    </li>
                                                    <li v-permission="'eduIndex:levelInfo'">
                                                        <a href="../v1/base/levelInfo">
                                                            <div>
                                                                <img src="../../../assets/accound.png" alt=""/>
                                                            </div>
                                                            <p>层次列表</p></a
                                                        >
                                                    </li>
                                                    <li v-permission="'eduIndex:systemusers'">
                                                        <a href="../v1/system/systemusers">
                                                            <div>
                                                                <img src="../../../assets/accound.png" alt=""/>
                                                            </div>
                                                            <p>系统用户</p></a
                                                        >
                                                    </li>
                                                    <li v-permission="'eduIndex:management'">
                                                        <a href="../v1/system/management">
                                                            <div>
                                                                <img src="../../../assets/accound.png" alt=""/>
                                                            </div>
                                                            <p>角色管理</p></a
                                                        >
                                                    </li>
                                                    <li v-permission="'eduIndex:newstudentsms'">
                                                        <a href="../v1/notice/newstudentsms">
                                                            <div>
                                                                <img src="../../../assets/accound.png" alt=""/>
                                                            </div>
                                                            <p>新生短信</p></a
                                                        >
                                                    </li>
                                                    <li v-permission="'eduIndex:gradeInfo'">
                                                        <a href="../v1/base/gradeInfo">
                                                            <div>
                                                                <img src="../../../assets/accound.png" alt=""/>
                                                            </div>
                                                            <p>年级列表</p></a>
                                                    </li>
                                                    <li v-permission="'eduIndex:Registration'">
                                                        <a href="../Online/Registration">
                                                            <div>
                                                                <img src="../../../assets/accound.png" alt=""/>
                                                            </div>
                                                            <p>注册列表</p></a>
                                                    </li>
                                                    <li v-permission="'eduIndex:CourseStatistics'">
                                                        <a href="../Online/CourseStatistics">
                                                            <div>
                                                                <img src="../../../assets/accound.png" alt=""/>
                                                            </div>
                                                            <p>选课统计</p></a
                                                        >
                                                    </li>
                                                </ul>
                                            </div>
                                        </section>
                                    </el-main>
                                    <!-- 教学数据-学生人数情况 -->
                                    <el-main class="xushengrenshu" v-permission="'eduIndex:teachData'">
                                        <!-- 教学数据-学生人数情况 -->
                                        <section class="jiaoxueshuju">
                                            <!-- 教学数据-学生人数情况-头部 -->
                                            <div class="jiaoxue">
                                                <p>教学数据</p>
                                                <p>学生人数情况</p>
                                            </div>
                                            <!-- 教师-毕业人数 -->
                                            <ul class="jiaoshi">
                                                <li>
                                                    <div>
                                                        <img src="../../../assets/001.png" alt=""/>
                                                    </div>
                                                    <div>
                                                        <p>{{ reqData.teacherCount }}</p>
                                                        <p>教师</p>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div>
                                                        <img src="../../../assets/001.png" alt=""/>
                                                    </div>
                                                    <div>
                                                        <p>{{ reqData.teacherRoleCount }}</p>
                                                        <p>管理员</p>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div>
                                                        <img src="../../../assets/001.png" alt=""/>
                                                    </div>
                                                    <div>
                                                        <p>{{ reqData.courseCount }}</p>
                                                        <p>课程</p>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div>
                                                        <img src="../../../assets/001.png" alt=""/>
                                                    </div>
                                                    <div>
                                                        <p>{{ reqData.enroStudentCount }}</p>
                                                        <p>在籍人员</p>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div>
                                                        <img src="../../../assets/001.png" alt=""/>
                                                    </div>
                                                    <div>
                                                        <p>{{ reqData.graduatStudentCount }}</p>
                                                        <p>毕业人数</p>
                                                    </div>
                                                </li>
                                            </ul>
                                        </section>
                                    </el-main>
                                    <el-main v-permission="'eduIndex:studentCountData'">
                                        <histogram></histogram>
                                    </el-main>
                                    <!-- 年纪注册情况 -->
                                    <div class="nianji">
                                        <!-- 年纪注册情况 -->
                                        <yuanhuan v-permission="'eduIndex:gradeRegistCountData'"></yuanhuan>
                                        <!-- 平台登录情况 -->
                                        <div class="lines" v-permission="'eduIndex:platformLoginCountData'">
                                            <lines style="width: 100%"></lines>
                                        </div>
                                    </div>
                                    <!-- 年度缴费情况 -->
                                    <div class="niandu"
                                         v-permission="'eduIndex:yearPayCountData'">
                                        <div style="width:49%;float: left;">
                                            <bingzuang></bingzuang>
                                        </div>
                                        <div style="width:49%;float: left;">
                                            <echarsMap></echarsMap>
                                        </div>
                                    </div>
                                </el-container>
                            </div>
                        </el-col>
                        <!-- 右边 主内容-->
                        <el-col :span="8">
                            <div class="grid-content bg-purple">
                                <el-main class="ceshis">
                                    <!-- 测试大学 -->
                                    <ul class="ceshi">
                                        <li>
                                            <!-- 左边图片 -->
                                            <div class="zuobiantupian">
                                                <img v-if='userInfo!=null && userInfo.headerUrl'
                                                     :src="userInfo.headerUrl" alt=""/>
                                            </div>
                                            <!-- 右边文字 -->
                                            <div class="zuobianwenzi">
                                                <p v-if="userInfo!=null&&userInfo.siteName">{{ userInfo.siteName }}</p>
                                                <section>
                                                    <i class="el-icon-s-platform"></i
                                                    ><span>上次登录:{{ reqData.lastLoginTime }}</span>
                                                </section>
                                            </div>
                                        </li>
                                        <!-- 公告更多 -->
                                        <li style="height: 10%">
                                            <article class="gonggao">
                                                <p>公告</p>
                                                <section><a href="../v1/notice/noticemng" style="color: #1b1a1a">更多</a>
                                                </section>
                                            </article>
                                        </li>
                                    </ul>
                                    <div style="width:100%;height: 65%;overflow-x: hidden;overflow-y: auto;">
                                        <div style="width: 100%; height: 10%; border-bottom: 1px solid #ddd;"
                                             v-for="announcement in announcementData">
                                            <el-link class="link-color" style="height: 100%; padding-left: 2em"
                                                     @click="announcementQuery(announcement.id)">{{announcement.title}}
                                            </el-link>
                                        </div>
                                    </div>
                                </el-main>


                                <!--            region 内容-->
                                <el-dialog
                                        title="内容"
                                        :before-close="contentBeforeClose"
                                        :visible.sync="dialogVisible11"
                                        width="50%">
                                    <div>
                                        <el-form label-width="80px">
                                            <el-form-item label="标题">
                                                {{content.title}}
                                            </el-form-item>
                                            <el-form-item>
                                                <div v-html="content.contentDetils"></div>
                                            </el-form-item>
                                        </el-form>
                                    </div>
                                </el-dialog>


<!--                                <el-main class="ceshise">-->
<!--                                    &lt;!&ndash; 产品快报-更多 &ndash;&gt;-->
<!--                                    <ul class="ceshi">-->
<!--                                        <li style="height: 10%">-->
<!--                                            <article class="gonggao">-->
<!--                                                <p>产品快报</p>-->
<!--                                                <section><a href="#" style="color: #1b1a1a">更多</a></section>-->
<!--                                            </article>-->
<!--                                        </li>-->
<!--                                    </ul>-->
<!--                                </el-main>-->
                                <el-main style="margin-top: 20px">
                                    <el-calendar>
                                    </el-calendar>
                                </el-main>
                            </div>
                        </el-col>
                    </el-row>
                </main>
            </section>
        </div>
    </div>
</template>
<script>

    import histogram from "../../../components/echarts/histogram"; //柱状图
    import yuanhuan from "../../../components/echarts/yuanhuan"; //圆环
    import lines from "../../../components/echarts/lines";
    import bingzuang from "../../../components/echarts/bingzuang";
    import echarsMap from '../../../components/echarts/map'

    export default {
        components: {
            yuanhuan,
            histogram,
            lines,
            bingzuang,
            echarsMap
        },
        data() {
            return {
                content: {
                    title: '',
                    contentDetils: '',
                },
                announcementData: [],
                reqData: {},
                loginHearders: {},
                userInfo: {},
                timeBasic: {
                    time: '',
                    week: ''
                },
                dialogVisible11: false
            };
        },
        created() {

            // this.getIndex();
        },
        methods: {
            // 关闭dialogVisible11
            contentBeforeClose(done) {
                this.content = {
                    title: '',
                    contentDetils: '',
                };
                done();
            },
            // 根据id查询公告
            announcementQuery(id) {
                this.openLoadingView();
                this.$axios_supermall.get("hnjxjy-core/edunotice/queryById?id=" + id).then(resp => {
                    this.content.contentDetils = resp.data.data.content;
                    this.content.title = resp.data.data.title;
                    this.dialogVisible11 = true;
                })
            },
            // 查询公告
            qureyAnnouncement() {
                this.$axios_supermallData({
                    url: '/hnjxjy-core/edunotice/queryByPage',
                    method: 'get',
                    // params: {
                    //   page: 1,
                    //   pageSize: 10
                    // }
                }).then(resp => {
                    if (resp.data.code == 2000) {
                        if (null !== resp.data.data) {
                            for (let i = 0; i < resp.data.data.list.length; i++) {
                                if (resp.data.data.list[i].topFlag === 1) {
                                    this.announcementData.push(resp.data.data.list[i]);
                                }
                            }
                        }
                    }
                });
            },
            //教学数据统计
            queryTeachData() {
                this.$axios_supermall.get("/hnjxjy-core/eduIndex/teachData").then(resp => {
                    if (resp.data.code == 2000) {
                        this.reqData = resp.data.data;
                    }
                });
            },
            getIndex() {
                // this.loginHearders = this.$route.params.loginHearders;
            },
            postIndex() {
                // this.$axios('hnjxjy-admin/sysMenu/queryMenuByMenuId?id=1', {
                //
                //   method: "GET",
                //   headers: this.loginHearders,
                // }).then(res => {
                //     if (res.data.code !== 2000) {
                //       return;
                //     }
                //   })
            },
            setNowTimes() {
                let myDate = new Date();
                let wk = myDate.getDay();
                let yy = String(myDate.getFullYear());
                let mm = myDate.getMonth() + 1;
                let dd = String(myDate.getDate() < 10 ? '0' + myDate.getDate() : myDate.getDate());
                let hou = String(myDate.getHours() < 10 ? '0' + myDate.getHours() : myDate.getHours());
                let min = String(myDate.getMinutes() < 10 ? '0' + myDate.getMinutes() : myDate.getMinutes());
                let sec = String(myDate.getSeconds() < 10 ? '0' + myDate.getSeconds() : myDate.getSeconds());
                let weeks = ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六'];
                let week = weeks[wk];
                this.timeBasic.time = yy + '年' + mm + '月' + dd + '日';
                this.timeBasic.week = week;
            }
        },
        mounted() {
            this.qureyAnnouncement();
            this.setNowTimes();
            this.queryTeachData();
            this.setNowTimes();
            this.queryTeachData();
            let userInfo = localStorage.getItem("userInfo");
            this.userInfo = JSON.parse(userInfo);
        }
    };
</script>
<style scoped>
    * {
        margin: 0;
        padding: 0;
    }

    ul,
    li {
        list-style: none;
    }

    a {
        text-decoration: none;
    }

    .demo-ruleForm {
        /* width: 90%; */
        height: calc(100% - 125px);
    }

    /* .el-form-input-width {
      width: 100%;
    } */
    .rigth {
        width: 93%;
        height: 870px;
        overflow-y: scroll;
        display: inline-block;
        /* background-color: #fff; */
        background-color: rgba(164, 174, 224, 0.11);
        float: right;
    }

    .left {
        width: 7%;
        display: inline-block;
    }

    /* 主题内容部分 */
    .zhuti {
        padding-left: 10px;
        padding-right: 10px;
        margin-top: 10px;
    }

    /* 头部 */
    .home-index {
        width: 100px;
        line-height: 54px;
        background: #5689f4;
        color: #fff;
        font-size: 17px;
        font-weight: 400;
        margin-bottom: 10px;
    }

    .home-index li {
        display: flex;
        justify-content: center;
        cursor: pointer;
    }

    .home-index span {
        display: inline-block;
        margin-left: 5px;
    }

    /* 圆点 */
    .dot {
        width: 9px;
        height: 9px;
        border-radius: 50%;
        background: #cfd6e9;
    }

    /* mian内容部分 */
    .main {
        width: 100%;
    }

    .el-row {
        margin-bottom: 20px;
    }

    .el-row:last-child {
        margin-bottom: 0;
    }

    .el-col {
        border-radius: 4px;
    }

    .bg-purple {
        background: #f1f1f1;
    }

    .grid-content {
        border-radius: 4px;
        min-height: 3.6px;
    }

    .time {
        height: 100px !important;
        width: 100%;
        display: flex;
        justify-content: space-between;
        background: #fff;
        padding: 5px;
    }

    .riqi {
        margin-left: 15px;
        width: 100%;
        height: 10px;
        display: flex;
        justify-content: space-between;
    }

    .riqi div:nth-child(1) {
        width: 60%;
        height: 100px;
        line-height: 100px;
        display: flex;
        justify-content: flex-start;
    }

    .riqi div p:nth-child(1) {
        font-size: 34px;
        line-height: 100px;
        color: #5689f4;
        font-weight: 500;
    }

    .riqi div p:nth-child(2) {
        font-size: 21px;
        line-height: 100px;
        color: #576cff;
        margin-left: 12px;
        font-weight: 400;
    }

    .riqi div:nth-child(2) {
        width: 40%;
        height: 100px;
        line-height: 100px;
        display: flex;
        justify-content: space-between;
    }

    /* 我的工单 */
    .wodegongdan {
        width: 50%;
        height: 80px;
        margin-top: 10px;
        border-left: 1px solid rgba(0, 0, 0, 0.1);
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .riqi .wodegongdan div {
        width: 56px;
        height: 56px;
        margin-right: 18px;
    }

    .riqi .wodegongdan div img {
        width: 100%;
    }

    .riqi .wodegongdan p:nth-child(2) {
        font-size: 17px;
        color: #414141;
        font-weight: 400;
        margin-right: 10px;
    }

    .riqi .wodegongdan p:nth-child(3) {
        font-size: 24px;
        color: #f55e5b;
        margin-right: 10px;
        font-weight: 500;
    }

    /* 代办事项 */
    .riqi .daibanshixiang {
        width: 50%;
        height: 80px;
        margin-top: 10px;
        border-left: 1px solid rgba(0, 0, 0, 0.1);
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        position: relative;
    }

    .riqi .daibanshixiang div {
        width: 56px;
        height: 56px;
        margin-right: 18px;
    }

    .riqi .daibanshixiang img {
        width: 100%;
    }

    .riqi .daibanshixiang .daiban {
        font-size: 17px;
        color: #414141;
        font-weight: 400;
    }

    /* 常用用能 */
    .changyonggongneng {
        margin-top: 20px;
        margin-bottom: 20px;
        background: #fff;
    }

    .changyong {
        width: 100%;
    }

    .changyong h4 {
        width: 100%;
        font-size: 24px;
        text-align: left;
    }

    .changyong ul {
        width: 100%;
        margin: auto;
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
    }

    .changyong ul li {
        width: 16%;
        float: left;
        margin-bottom: 20px;
    }

    .changyong ul li a {
        display: flex;
        color: black;
        justify-content: flex-start;
    }

    .changyong ul li div {
        width: 26px;
        height: 26px;
        margin-top: 12px;
        margin-bottom: 12px;
    }

    .changyong ul li div img {
        width: 100%;
    }

    .changyong ul li p {
        font-size: 17px;
        padding: 12px;
    }

    /* 教学数据-学生人数情况 */
    .xushengrenshu {
        padding: 0;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .jiaoxueshuju {
        width: 100%;
    }

    .jiaoxueshuju .jiaoxue {
        width: 100%;
        display: flex;
        margin-bottom: 20px;
        justify-content: space-between;
    }

    .jiaoxueshuju .jiaoxue p {
        width: 50%;
        font-size: 24px;
        text-align: center;
        font-weight: 600;
        color: #2a2a2a;
    }

    .jiaoxueshuju .jiaoshi {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    .jiaoxueshuju .jiaoshi li {
        width: 17%;
        height: 115px;
        padding: 13px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        background: #fff;
    }

    .jiaoxueshuju .jiaoshi li div:nth-child(1) {
        width: 64px;
        height: 64px;
    }

    .jiaoxueshuju .jiaoshi li div:nth-child(1) img {
        width: 100%;
    }

    .jiaoxueshuju .jiaoshi li div:nth-child(2) {
        margin-left: 20px;
    }

    .jiaoxueshuju .jiaoshi li div:nth-child(2) p {
        font-size: 17px;
    }

    .nianji {
        width: 100%;
        display: flex;
        justify-content: space-between;
        background: #fff;
        margin-top: 20px;
    }

    .nianji div {
        width: 50%;
    }

    .lines {
        width: 100%;
        height: 400px;
    }

    .niandu {
        width: 100%;
        height: 400px;
        background: #fff;
        margin-top: 20px;
    }

    /* 测试大学 */
    .ceshis {
        background: #fff;
        height: 540px;
    }

    .ceshi {
        width: 100%;
    }

    .ceshi li {
        height: 87px;
        margin-top: 10px;
        padding: 10px;
        border-bottom: 1px solid #ddd;
        display: flex;
        justify-content: flex-start;
    }

    /* 左边图片 */
    .zuobiantupian {
        width: 75px;
        height: 75px;
        margin-left: 10px;
        display: inline-block;
        vertical-align: middle;
    }

    .zuobiantupian img {
        width: 100%;
    }

    /* 左边文字 */
    .zuobianwenzi {
        display: inline-block;
        vertical-align: middle;
        margin-left: 20px;
    }

    .zuobianwenzi p {
        font-size: 17px;
        text-align: left;
        margin-bottom: 20px;
    }

    .zuobianwenzi section {
        font-size: 12px;
    }

    /* 公告更多 */
    .gonggao {
        width: 100%;
        display: flex;
        justify-content: space-between;
    }

    .gonggao p {
        font-size: 24px;
        font-weight: 600;
        margin-left: 10px;
    }

    .gonggao section {
        font-size: 17px;
        margin-right: 10px;
    }

    .ceshise {
        margin-top: 20px;
        background: #fff;
        height: 540px;
    }
</style>
