<template>
  <div id="contain">
    <div >
      <div
          style="padding: 1rem; border-bottom: 1px solid #ddd; text-align: left"
      >
        新生短信
      </div>
      <div class="top_btn">
        <el-button style="margin-left: 0.9vw" type="primary" icon="el-icon-circle-plus-outline" @click="dioAdd" v-permission="'eduMsgNew:addEduMsgNew'">新增</el-button>
      </div>
      <div class="search">
        <span class="batch" style="width: 120px">招生批次</span>
        <el-select v-model="bo.batchId" clearable placeholder="请选择" style="width: 300px;" @change="query">
          <el-option v-for="item in optionsOpt" :key="item.id" :label="item.batchName" :value="item.id">
          </el-option>
        </el-select>
      </div>
      <el-table :data="tableData" border style="width: 99%; margin-left: 10px;"
                height="630px"
                max-height="630px"
                size="medium"
                :header-cell-style="{'text-align':'center'}"
                :cell-style="{'text-align':'center'}"
      >
        <el-table-column type="index" label="序号" width="50">
        </el-table-column>
        <el-table-column prop="tempName" label="模板名称">
        </el-table-column>
        <el-table-column prop="tempContent" label="模板内容">
        </el-table-column>
        <el-table-column prop="sendNum" label="发送人数">
        </el-table-column>
        <el-table-column label="状态" prop="checkStatus">
          <template slot-scope="scope">
            <span v-if="scope.row.checkStatus == 0">待审核</span>
            <span v-if="scope.row.checkStatus == 1">已发送</span>
            <span v-if="scope.row.checkStatus == 2">驳回</span>
          </template>
        </el-table-column>
        <el-table-column prop="siteName" label="创建人">
        </el-table-column>
      </el-table>
      <div class="ye" style="width: 99%; margin-left: 10px;">
        <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="bo.page"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="bo.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="this.total">
        </el-pagination>
      </div>

    </div>
    <el-dialog class="add" title="发送短信" :visible.sync="dialogVisible2" width="70%" center>
      <el-button type="primary" size="mini" @click="dialogVisible2 = false">关闭</el-button>
      <el-button type="primary" size="mini" @click="end()">保存并关闭</el-button>
      <el-form ref="form" label-width="100px" style="margin-top: 30px" size="mini">
        <el-form-item label="选择模板">
          <el-select placeholder="请选择" clearable v-model="addBo.tempId">
            <el-option
                v-for="item in tempIdOpt"
                :key="item.id"
                :label="item.tempName"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="选择批次">
          <el-select placeholder="请选择" clearable v-model="addBo.batchId">
            <el-option
                v-for="item in optionsOpt"
                :key="item.id"
                :label="item.batchName"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="选择签名">
          <el-select placeholder="请选择" clearable v-model="addBo.singnId">
            <el-option
                v-for="item in singnIdOpt"
                :key="item.id"
                :label="item.signName"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="mini" @click="endSit">设定函授站范围</el-button>
          已经选择学生数:{{ selectionData.length }}
        </el-form-item>
      </el-form>

    </el-dialog>
    <el-dialog class="add" title="选择学生" :visible.sync="dialogVisible3" width="70%" center>
      <el-button type="primary" size="mini" @click="onSelect">关闭</el-button>
      <el-button type="primary" size="mini" @click="dialogVisible3 = false">保存并关闭</el-button>
      <el-form ref="form" label-width="100px" style="margin-top: 30px" size="mini" :inlist="true">
        <el-form-item label="批次">
          <el-select placeholder="请选择" clearable v-model="stuBo.batchId" @change="queryStudentByBatchIdAndSiteId">
            <el-option
                v-for="item in optionsOpt"
                :key="item.id"
                :label="item.batchName"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="函授站">
          <el-select placeholder="请选择" clearable v-model="stuBo.siteId" @change="queryStudentByBatchIdAndSiteId">
            <el-option
                v-for="item in siteIdOpt"
                :key="item.id"
                :label="item.siteName"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <el-table :data="tableDataStu" border style="width: 100%" @selection-change="handleSelectionChange"
                height="480px"
                max-height="480px"
                size="medium"
                :header-cell-style="{'text-align':'center'}"
                :cell-style="{'text-align':'center'}"
                v-loading="isLoading" element-loading-text="加载数据中........">
        <el-table-column type="selection" width="55">
        </el-table-column>
        <el-table-column prop="id" label="学生ID">
        </el-table-column>
        <el-table-column prop="name" label="学生姓名">
        </el-table-column>
        <el-table-column prop="contactPhone" label="电话号码">
        </el-table-column>
      </el-table>
    </el-dialog>

  </div>
</template>
<script>
export default {
  data() {
    return {
      isLoading: false,
      tableDataStu: [],
      stuBo: {
        batchId: '',
        siteId: ''
      },
      siteIdOpt: [],
      singnIdOpt: [],
      tempIdOpt: [],
      total: 0,
      addBo: {
        tempId: '',//模板ID
        batchId: '',//批次模板
        singnId: '',
        eduEnrolStudents: []


      },
      tableData: [],
      dialogVisible3: false,
      dialogVisible1: false,
      dialogVisible2: false,
      smsModel: "",
      smsView: "",
      text: "",
      form: {},
      value: "",
      item: [],
      optionsOpt: [],
      putDialog: false,
      diglog: {tempName: ""},
      textarea: '',
      bo: {
        batchId: '',
        page: 1,
        pageSize: 10,
      },
      selectionData: [],
    }
  },
  created() {
  },
  mounted() {
    this.queryAllSite();
    this.querysingnIdOpt();
    this.queryEnrolBatchByDown();
    this.querytempIdOpt();
    this.queryAllSiteByUserId();
    this.query();
  },
  methods: {
    queryAllSiteByUserId() {
      this.openLoadingView();
      this.$axios_supermall.get('/hnjxjy-core/eduSite/queryAllSiteByUserId' ).then(response => {
        if (response.data.code === 2000) {
          this.siteIdOpt = response.data.data.list;
        }
      }).catch(response => {

      })
    },
    dioAdd() {
      this.selectionData = [];
      this.addBo = {
        tempId: '',//模板ID
        batchId: '',//批次模板
        singnId: '',
        eduEnrolStudents: []
      };

      this.stuBo = {
        batchId: '',
        siteId: ''
      }
      this.dialogVisible2 = true;
    },
    end() {
      if (this.selectionData.length <= 0) {
        this.$message.warning("未选择学生");
        return;
      }
      this.selectionData.forEach(item => {
        this.addBo.eduEnrolStudents.push({
          id: item.id,
          name: item.name,
          contactPhone: item.contactPhone
        });
      })
      this.$axios_supermall.post("/hnjxjy-core/eduMsgNew/addEduMsgNew", this.addBo).then(resp => {
        if (resp.data.code == 2000) {
          this.$message.success("操作成功");
          this.dialogVisible2 = false;
          this.query();
        }
      })

    },
    onSelect() {
      this.dialogVisible3 = false;
      this.selectionData = [];
    },
    handleSelectionChange(val) {
      this.selectionData = val;
    },
    endSit() {
      this.queryStudentByBatchIdAndSiteId();
      this.dialogVisible3 = true;
    },
    queryStudentByBatchIdAndSiteId() {
      this.isLoading = true;
      this.$axios_supermall.get("/hnjxjy-core/eduMsgNew/queryStudentByBatchIdAndSiteId?batchId=" + this.stuBo.batchId + "&siteId=" + this.stuBo.siteId).then(resp => {
        if (resp.data.code == 2000) {
          this.tableDataStu = resp.data.data;
        }
        this.isLoading = false;
      }).catch(() => {
        this.isLoading = false;
      })
    },
    queryAllSite() {
      let formData = new FormData();
      formData.append("isAll","1");
      this.$axios_supermallData.post("/hnjxjy-core/eduSite/queryAllSite", formData).then(resp => {
        if (resp.data.code == 2000) {
          this.siteIdOpt = resp.data.data;
        }
      })
    },
    query() {
      this.getPermission('eduMsgNew:selectEduMsgNewRecruitStudents', () => {
        this.openLoadingView();
        this.$axios_supermall.get("/hnjxjy-core/eduMsgNew/selectEduMsgNewRecruitStudents?page=" + this.bo.page + "&pageSize=" + this.bo.pageSize + "&batchId=" + this.bo.batchId).then(resp => {
          if (resp.data.code == 2000) {
            this.tableData = resp.data.data.list;
            this.total = resp.data.data.total;
          }
          this.loadingView.close();
        }).catch(() => {
          this.loadingView.close();
        })
      });
    },
    querysingnIdOpt() {
      this.$axios_supermall.get("/hnjxjy-core/eduMsgNew/queryEduMsgSignList").then(resp => {
        if (resp.data.code == 2000) {
          this.singnIdOpt = resp.data.data;
        }
      })
    },
    querytempIdOpt() {
      this.$axios_supermall.get("/hnjxjy-core/eduMsgTemp/queryAllMsgTemp").then(resp => {
        if (resp.data.code == 2000) {
          this.tempIdOpt = resp.data.data;
        }
      })
    },
    queryEnrolBatchByDown() {
      this.$axios_supermall.get("/hnjxjy-core/eduEnrolBatch/queryEnrolBatchByDown").then(resp => {
        if (resp.data.code == 2000) {
          this.optionsOpt = resp.data.data;
        }
      })
    },
    handleSizeChange(val) {
      this.bo.pageSize = val;
      this.query();
    },
    handleCurrentChange(val) {
      this.bo.page = val;
      this.query();
    },
  }
}
</script>
<style scoped>
.right {
  float: right;
  width: 93%;
  display: inline-block;
  height: 870px;
  overflow-y: scroll;
  /* background-color: #fff; */
  background-color: rgba(164, 174, 224, 0.11);
}

.left {
  width: 7%;
  display: inline-block;
}

.yingyong {
  font-weight: bold;
  color: #555;
}

#aaaaa {
  color: lightskyblue;
}

.top_btn {
  margin: 10px 10px 10px 10px;
  text-align: left;
}

.search {
  margin: 20px 10px 30px 10px;
  text-align: left;
}

.main_contain {
  margin: 20px 10px 30px 10px;
  text-align: left;
}

.batch {
  margin-right: 10px;
  text-align: left;
}

.left {
  width: 7%;
  display: inline-block;
}

.add {
  text-align: left im !important;
}

.ye {
  background: #fff;


}
</style>
