<template>
    <div>
        <div>
            <div style="padding: 1rem; border-bottom: 1px solid #ddd; text-align: left">
                函授站教师列表
            </div>
            <div class="search">
                <el-form class="demo-ruleForm">
                    <el-form-item>
                      <el-button
                          size="small"
                          icon="el-icon-edit"
                          type="primary"
                          @click="addcreate()"
                          v-if="addButton != 4"
                          v-permission="'hnjxjy_han_teacher_insert'">
                        添加
                      </el-button>
                        <el-button
                                style="margin-bottom: 20px"
                                icon="el-icon-delete"
                                type="primary"
                                @click="batchDelete()"
                                v-permission="'eduCorrespondence:deleteds'">
                            批量删除
                        </el-button>
                        <el-dropdown style="display: inline-block;margin-left: 10px" @command="handleCommand2"
                                     size="small" v-permission="'eduCorrespondence:reject'">
                            <el-button type="primary">
                                驳回<i class="el-icon-arrow-down el-icon--right"></i>
                            </el-button>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item command="noAll">设置选中</el-dropdown-item>
                                <el-dropdown-item command="all">设置全部</el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>

                        <el-dropdown style="display: inline-block;margin-left: 10px" @command="handleCommand1"
                                     v-permission="'eduCorrespondence:examOk'">
                            <el-button type="primary">
                                审核通过<i class="el-icon-arrow-down el-icon--right"></i>
                            </el-button>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item command="noAll">设置选中</el-dropdown-item>
                                <el-dropdown-item command="all">设置全部</el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                        <el-button icon="el-icon-download" type="primary" @click="download"
                                   v-permission="'eduCorrespondence:export'"
                                   style="display: inline-block;margin-left: 10px">
                            导出
                        </el-button>
                    </el-form-item>
                </el-form>
                <el-form :model="search" class="demo-ruleForm" inline v-permission="'eduCorrespondence:list'">
                    <el-form-item label="姓名/工号" style="">
                        <el-input
                                v-model="bo.name"
                                clearable
                                @input="() => (bo.name = bo.name.trim())"
                                placeholder="请输入内容"
                                style="width: 200px">
                        </el-input>
                    </el-form-item>
                    <el-form-item label="函授站" style="margin-bottom: 0">
                        <el-select
                                clearable
                                v-model="bo.siteId"
                                placeholder="请选择"
                                class="el-form-input-width">
                            <el-option
                                    v-for="item in siteOpt"
                                    :key="item.id"
                                    :label="item.siteName"
                                    :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="身份类型" style="margin-bottom: 0">
                        <el-select
                                clearable
                                v-model="bo.roleType"
                                placeholder="请选择"
                                class="el-form-input-width">
                            <el-option
                                    v-for="item in roleTypeOpt"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="教师类型" style="margin-bottom: 0">
                        <el-select
                                clearable
                                v-model="bo.teacherType"
                                placeholder="请选择"
                                class="el-form-input-width">
                            <el-option
                                    v-for="item in teacherTypeOpt"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="审核状态" style="margin-bottom: 0">
                        <el-select
                                clearable
                                v-model="bo.exam"
                                placeholder="请选择"
                                class="el-form-input-width">
                            <el-option
                                    v-for="item in examOpt"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-button type="primary" size="small" @click="queryListVo">
                        查询
                    </el-button>
                </el-form>
            </div>
            <div class="table-wrap"
                 v-permission="'eduCorrespondence:list'">
                <el-table
                    size="medium"
                    height="550px"
                    max-height="550px"
                    :header-cell-style="{'text-align':'center'}"
                    :cell-style="{'text-align':'center'}"
                    ref="multipleTable"
                    :data="tableData"
                    @selection-change="handleSelectionChange">
                    <el-table-column type="selection" width="55" ></el-table-column>
                    <el-table-column label="序号" width="140" type="index" >
                    </el-table-column>
                    <el-table-column label="姓名" width="180" prop="name" sortable >
                    </el-table-column>
                    <el-table-column label="工号" width="200" prop="jobNo" sortable >
                    </el-table-column>
                    <el-table-column label="性别" width="200" prop="sex" sortable >
                    </el-table-column>
                    <el-table-column label="学历" width="150" prop="education" >
                    </el-table-column>
                    <el-table-column label="职称" width="150" prop="jodTitle" >
                    </el-table-column>
                    <el-table-column label="所属函授站" width="150" prop="siteName" >
                    </el-table-column>
                    <el-table-column label="身份类型" width="150" prop="roleType" >
                    </el-table-column>
                    <el-table-column label="教师类型" width="150" prop="teacherType" >
                    </el-table-column>
                    <el-table-column label="审核状态" width="150" prop="exam" >
                    </el-table-column>
                    <el-table-column label="驳回原因" width="150" prop="remark" >
                    </el-table-column>
                    <el-table-column label="QQ" width="150" prop="qqNum" >
                    </el-table-column>
                    <el-table-column label="微信" width="150" prop="wechart" >
                    </el-table-column>
                    <el-table-column label="操作" fixed="right" width="200" >
                        <template slot-scope="scope">
                            <!--              <el-button-->
                            <!--                @click="production(scope.row)"-->
                            <!--                type="primary"-->
                            <!--                size="small"-->
                            <!--                >设置制作教师</el-button-->
                            <!--              >-->
                            <!--              <el-button @click="Setting(scope.row)" type="primary" size="small"-->
                            <!--                >设置教师</el-button-->
                            <!--              >-->
                            <el-button @click="editor(scope.row)" type="primary" size="small" v-permission="'eduCorrespondence:update'">
                                编辑
                            </el-button>
                            <el-button
                                    @click="deleteCorrespond(scope.row)"
                                    type="danger"
                                    size="small"
                            v-permission="'eduCorrespondence:deleted'">
                              删除
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <div style="padding: 1rem">
                    <el-pagination
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                            :current-page="bo.page"
                            :page-sizes="[10, 30, 50, 100]"
                            :page-size="bo.pageSize"
                            layout="total, sizes, prev, pager, next, jumper"
                            :total="total"
                    >
                    </el-pagination>
                </div>
            </div>
        </div>

        <el-dialog
                title="编辑"
                :visible.sync="dialogVisible"
                width="60%"
                style="text-align: left"
        >
            <p style="color:red;font-size:14px;margin-bottom:1rem">（提示：教师工号将作为教师登录帐号,身份证号后6位将作为老师的初始密码）</p>
            <el-form
                    size="small"
                    :model="userBo"
                    :rules="boRules"
                    ref="ruleForm"
                    class="demo-ruleForm"
                    label-width="120px"
            >
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="上传照片：">
                            <el-upload
                                    style="width:100%"
                                    class="avatar-uploader"
                                    ref="uploadddd"
                                    :on-success="onSuccess"
                                    :headers="myHeaders"
                                    :show-file-list="false"
                                    :action="this.TEACHERADD">
                                <img v-if="userBo.headerUrl" :src="userBo.headerUrl" class="avatar">
                                <i v-else class="el-icon-plus avatar-uploader-icon" style="border: 1px dashed #d9d9d9;"></i>
                            </el-upload><span style="color:red;font-size:14px;margin-left:1rem">支持格式jpg/png</span>
                            <!--              <label>-->
                            <!--                <input type="file" id="file" style="display:none;"/>-->
                            <!--                <div class="portrait_Sprite">-->
                            <!--                  <span>上传照片</span>-->
                            <!--                </div>-->
                            <!--              </label>-->
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="工号：" prop="jobNo">
                            <el-input
                                style="width: 300px"
                                    v-model="userBo.jobNo"
                                    placeholder="请输入"
                                    class="el-form-input-width"
                                    @input="() => (userBo.jobNo = userBo.jobNo.trim())"
                            ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="姓名：" prop="name">
                            <el-input
                                style="width: 300px"
                                    v-model="userBo.name"
                                    placeholder="请输入"
                                    class="el-form-input-width"
                                    @input="() => (userBo.name = userBo.name.trim())"
                            ></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="性别：" prop="sex">
                            <el-select style="width: 300px" v-model="userBo.sex" placeholder="请选择">
                                <el-option
                                        v-for="item in sexOpt"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value"
                                >
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="身份证号：" prop="idCard">
                            <el-input
                                style="width: 300px"
                                    v-model="userBo.idCard"
                                    placeholder="请输入"
                                    class="el-form-input-width"
                                    @input="() => (userBo.idCard = userBo.idCard.trim())"
                            ></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="专业方向：" prop="profDir">
                            <el-input
                                style="width: 300px"
                                    v-model="userBo.profDir"
                                    placeholder="请输入"
                                    class="el-form-input-width"
                                    @input="() => (userBo.profDir = userBo.profDir.trim())"
                            ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="职称：" prop="jodTitle">
                            <el-input
                                style="width: 300px"
                                    v-model="userBo.jodTitle"
                                    placeholder="请输入"
                                    class="el-form-input-width"
                                    @input="() => (userBo.jodTitle = userBo.jodTitle.trim())"
                            ></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="学历：" prop="education">
                            <el-input
                                style="width: 300px"
                                    v-model="userBo.education"
                                    placeholder="请输入"
                                    class="el-form-input-width"
                                    @input="() => (userBo.education = userBo.education.trim())"
                            ></el-input>
                        </el-form-item>
                        <el-form-item label="在任：" prop="online">
                            <el-select style="width: 300px" v-model="userBo.online" placeholder="请选择">
                                <el-option
                                        v-for="item in onlineOpt"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value"
                                >
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="身份类型：" prop="roleType">
                            <el-select style="width: 300px" v-model="userBo.roleType" placeholder="请选择">
                                <el-option
                                        v-for="item in roleTypeOpt"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value"
                                >
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="移动电话：" prop="phoneNum">
                            <el-input
                                style="width: 300px"
                                    v-model="userBo.phoneNum"
                                    placeholder="请输入"
                                    class="el-form-input-width"
                                    @input="() => (userBo.phoneNum = userBo.phoneNum.trim())"
                            ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="固定电话：" prop="telNum">
                            <el-input
                                style="width: 300px"
                                    v-model="userBo.telNum"
                                    placeholder="请输入"
                                    class="el-form-input-width"
                                    @input="() => (userBo.telNum = userBo.telNum.trim())"
                            ></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="电子邮箱：" prop="email">
                            <el-input
                                style="width: 300px"
                                    v-model="userBo.email"
                                    placeholder="请输入"
                                    class="el-form-input-width"
                                    @input="() => (userBo.email = userBo.email.trim())"
                            ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="邮政编码：" prop="postCode">
                            <el-input
                                style="width: 300px"
                                    v-model="userBo.postCode"
                                    placeholder="请输入"
                                    class="el-form-input-width"
                                    @input="() => (userBo.postCode = userBo.postCode.trim())"
                            ></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="微信：" prop="wechart">
                            <el-input
                                style="width: 300px"
                                    v-model="userBo.wechart"
                                    placeholder="请输入"
                                    class="el-form-input-width"
                                    @input="() => (userBo.wechart = userBo.wechart.trim())"
                            ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="QQ：" prop="qqNum">
                            <el-input
                                style="width: 300px"
                                    v-model="userBo.qqNum"
                                    placeholder="请输入"
                                    class="el-form-input-width"
                                    @input="() => (userBo.qqNum = userBo.qqNum.trim())"
                            ></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="教师类型：" prop="teacherType">
                            <el-select style="width: 300px" v-model="userBo.teacherType" placeholder="请选择">
                                <el-option
                                        v-for="item in teacherTypeOpt"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value"
                                >
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="工作单位：" prop="jobCompany">
                            <el-input
                                style="width: 300px"
                                    v-model="userBo.jobCompany"
                                    placeholder="请输入"
                                    class="el-form-input-width"
                                    @input="() => (userBo.jobCompany = userBo.jobCompany.trim())"
                            ></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="联系地址：" prop="contactAddress">
                            <el-input
                                style="width: 300px"
                                    type="textarea"
                                    :rows="2"
                                    placeholder="请输入内容"
                                    v-model="userBo.contactAddress">
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="备注：" prop="remark">
                            <el-input
                                style="width: 300px"
                                    type="textarea"
                                    :rows="2"
                                    placeholder="请输入内容"
                                    v-model="userBo.remark">
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="dialogVisible = false">取 消</el-button>
        <el-button size="small" type="primary" @click="handleSave('ruleForm')"
        >确 定</el-button
        >
      </span>
        </el-dialog>
        <el-dialog
                title="驳回状态"
                :visible.sync="dialogVisibleStart"
                width="40%"
        >
            <el-form
                    size="small"
                    class="demo-ruleForm"
                    label-width="120px"
            >
                <el-form-item>
                    您将对（{{ tableChecked.length }}）条数据进行操作
                </el-form-item>
                <el-form-item label="驳回原因">
                    <el-input type="textarea" v-model="reason"/>
                </el-form-item>
                <el-form-item>
                    <el-button @click="dialogVisibleStart = false">取消</el-button>
                    <el-button @click="submitEnd">确定</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
        <!-- 编辑 -->
        <!-- <el-dialog
          title="编辑"
          :visible.sync="dialogVisible"
          width="30%"
          style="text-align: left"
        >
          <el-form
            :model="teacherForm"
            :rules="teacherRules"
            ref="ruleForm"
            class="demo-ruleForm"
            label-width="120px"
          >
            <el-form-item label="课程编号：" prop="name">
              <el-input
                v-model="teacherForm.name"
                placeholder="请输入"
                class="el-form-input-width"
                @input="() => (teacherForm.name = teacherForm.name.trim())"
              ></el-input>
            </el-form-item>
            <el-form-item label="课程名称：" prop="name">
              <el-input
                v-model="teacherForm.name"
                placeholder="请输入"
                class="el-form-input-width"
                @input="() => (teacherForm.name = teacherForm.name.trim())"
              ></el-input>
            </el-form-item>
            <el-form-item label="课程类型：" prop="name">
              <el-select v-model="value" placeholder="请选择">
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-form>
          <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="handleSave('ruleForm')"
              >确 定</el-button
            >
          </span>
        </el-dialog> -->
        <!-- 新增 -->
         <el-dialog
          title="新增"
          :visible.sync="dialogVisible1"
          width="50%"
          style="text-align: left"
        >
           <el-form
               size="small"
               :model="userBo"
               :rules="boRules"
               ref="ruleForm"
               inline
               label-position="left"
               class="inlineForm"
               label-width="100px">
             <el-form-item label="上传照片：">
                 <el-upload
                         style="width:100%"
                         class="avatar-uploader"
                         ref="uploadddd"
                         :on-success="onSuccess"
                         :headers="myHeaders"
                         :show-file-list="false"
                         :action="this.TEACHERADD">
                     <img v-if="userBo.headerUrl" :src="userBo.headerUrl" class="avatar">
                     <i v-else class="el-icon-plus avatar-uploader-icon" style="border: 1px dashed #d9d9d9;"></i>
                 </el-upload><span style="color:red;font-size:14px;margin-left:1rem">支持格式jpg/png</span>
               <!--              <label>-->
               <!--                <input type="file" id="file" style="display:none;"/>-->
               <!--                <div class="portrait_Sprite">-->
               <!--                  <span>上传照片</span>-->
               <!--                </div>-->
               <!--              </label>-->
             </el-form-item>
             <el-form-item label="工号：" prop="jobNo">
               <el-input
                   suffix-icon="none"
                   v-model="userBo.jobNo"
                   placeholder="请输入"
                   class="el-form-input-width"
                   @input="() => (userBo.jobNo = userBo.jobNo.trim())">
               </el-input>
             </el-form-item>
             <el-form-item label="姓名：" prop="name">
               <el-input
                   suffix-icon="none"
                   v-model="userBo.name"
                   placeholder="请输入"
                   class="el-form-input-width"
                   @input="() => (userBo.name = userBo.name.trim())">
               </el-input>
             </el-form-item>
             <el-form-item label="性别：" prop="sex">
               <el-select v-model="userBo.sex" placeholder="请选择">
                 <el-option
                     v-for="item in sexOpt"
                     :key="item.value"
                     :label="item.label"
                     :value="item.value"
                 >
                 </el-option>
               </el-select>
             </el-form-item>
             <el-form-item label="身份证号：" prop="idCard">
               <el-input
                   suffix-icon="none"
                   v-model="userBo.idCard"
                   placeholder="请输入"
                   class="el-form-input-width"
                   @input="() => (userBo.idCard = userBo.idCard.trim())">
               </el-input>
             </el-form-item>
             <el-form-item label="专业方向：" prop="profDir">
               <el-input
                   suffix-icon="none"
                   v-model="userBo.profDir"
                   placeholder="请输入"
                   class="el-form-input-width"
                   @input="() => (userBo.profDir = userBo.profDir.trim())">
               </el-input>
             </el-form-item>
             <el-form-item label="职称：" prop="jodTitle">
               <el-input
                   suffix-icon="none"
                   v-model="userBo.jodTitle"
                   placeholder="请输入"
                   class="el-form-input-width"
                   @input="() => (userBo.jodTitle = userBo.jodTitle.trim())">
               </el-input>
             </el-form-item>
             <el-form-item label="学历：" prop="education">
               <el-input
                   suffix-icon="none"
                   v-model="userBo.education"
                   placeholder="请输入"
                   class="el-form-input-width"
                   @input="() => (userBo.education = userBo.education.trim())">
               </el-input>
             </el-form-item>
             <el-form-item label="在任：" prop="online">
               <el-select v-model="userBo.online" placeholder="请选择">
                 <el-option
                     v-for="item in onlineOpt"
                     :key="item.value"
                     :label="item.label"
                     :value="item.value">
                 </el-option>
               </el-select>
             </el-form-item>
             <!--                <el-form-item label="列表选择：" prop="online" v-if="userBo.id ==null">-->
             <!--                    <el-select v-model="userBo.IdentityStatus" placeholder="请选择">-->
             <!--                        <el-option-->
             <!--                                v-for="item in IdentityStatusType"-->
             <!--                                :key="item.value"-->
             <!--                                :label="item.label"-->
             <!--                                :value="item.value"-->
             <!--                        >-->
             <!--                        </el-option>-->
             <!--                    </el-select>-->
             <!--                </el-form-item>-->
             <el-form-item label="身份类型：" prop="roleType">
               <el-select v-model="userBo.roleType" placeholder="请选择">
                 <el-option
                     v-for="item in roleTypeOpt"
                     :key="item.value"
                     :label="item.label"
                     :value="item.value"
                 >
                 </el-option>
               </el-select>
             </el-form-item>
             <el-form-item label="移动电话：" prop="phoneNum">
               <el-input
                   suffix-icon="none"
                   v-model="userBo.phoneNum"
                   placeholder="请输入"
                   class="el-form-input-width"
                   @input="() => (userBo.phoneNum = userBo.phoneNum.trim())">
               </el-input>
             </el-form-item>
             <el-form-item label="固定电话：" prop="telNum">
               <el-input
                   suffix-icon="none"
                   v-model="userBo.telNum"
                   placeholder="请输入"
                   class="el-form-input-width"
                   @input="() => (userBo.telNum = userBo.telNum.trim())">
               </el-input>
             </el-form-item>
             <el-form-item label="电子邮箱：" prop="email">
               <el-input
                   suffix-icon="none"
                   v-model="userBo.email"
                   placeholder="请输入"
                   class="el-form-input-width"
                   @input="() => (userBo.email = userBo.email.trim())">
               </el-input>
             </el-form-item>
             <el-form-item label="邮政编码：" prop="postCode">
               <el-input
                   suffix-icon="none"
                   v-model="userBo.postCode"
                   placeholder="请输入"
                   class="el-form-input-width"
                   @input="() => (userBo.postCode = userBo.postCode.trim())">
               </el-input>
             </el-form-item>
             <el-form-item label="微信：" prop="wechart">
               <el-input
                   suffix-icon="none"
                   v-model="userBo.wechart"
                   placeholder="请输入"
                   class="el-form-input-width"
                   @input="() => (userBo.wechart = userBo.wechart.trim())">
               </el-input>
             </el-form-item>
             <el-form-item label="QQ：" prop="qqNum">
               <el-input
                   suffix-icon="none"
                   v-model="userBo.qqNum"
                   placeholder="请输入"
                   class="el-form-input-width"
                   @input="() => (userBo.qqNum = userBo.qqNum.trim())">
               </el-input>
             </el-form-item>
             <el-form-item label="教师类型：" prop="teacherType">
               <el-select v-model="userBo.teacherType" placeholder="请选择">
                 <el-option
                     v-for="item in teacherTypeOpt"
                     :key="item.value"
                     :label="item.label"
                     :value="item.value">
                 </el-option>
               </el-select>
             </el-form-item>
             <el-form-item label="工作单位：" prop="jobCompany">
               <el-input
                   suffix-icon="none"
                   v-model="userBo.jobCompany"
                   placeholder="请输入"
                   class="el-form-input-width"
                   @input="() => (userBo.jobCompany = userBo.jobCompany.trim())">
               </el-input>
             </el-form-item>
             <el-form-item label="联系地址：" prop="contactAddress">
               <el-input
                   style="width: 205px;"
                   suffix-icon="none"
                   type="textarea"
                   :rows="2"
                   placeholder="请输入内容"
                   v-model="userBo.contactAddress">
               </el-input>
             </el-form-item>
             <el-form-item label="备注：" prop="remark">
               <el-input
                   style="width: 205px;"
                   suffix-icon="none"
                   type="textarea"
                   :rows="2"
                   placeholder="请输入内容"
                   v-model="userBo.remark">
               </el-input>
             </el-form-item>
           </el-form>
          <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible1 = false">取 消</el-button>
            <el-button type="primary" @click="handleSaves('ruleForm')"
              >确 定</el-button
            >
          </span>
        </el-dialog>
        <!-- 设置制作教师 -->
        <!-- <el-dialog
          title="设置制作教师"
          :visible.sync="dialogVisible2"
          width="50%"
          style="text-align: left"
        >
          <el-form :model="search" class="demo-ruleForm" inline>
            <el-form-item style="margin-bottom: 0">
              <el-input
                v-model="search.name"
                clearable
                @input="() => (search.name = search.name.trim())"
                placeholder="教师名字/工号"
                style="width: 300px"
              ></el-input>
              <el-button icon="el-icon-search" @click="handleSearch"></el-button>
            </el-form-item>
          </el-form>
          <div style="margin-top:1rem">
            <el-table :data="tableData" border style="width: 100%">
                <el-table-column
                    type="selection"
                    width="55">
                    </el-table-column>
              <el-table-column prop="date" label="教师工号" >
              </el-table-column>
              <el-table-column prop="name" label="教师姓名" >
              </el-table-column>
               <el-table-column prop="name" label="性别">
              </el-table-column>
               <el-table-column prop="name" label="院系">
              </el-table-column>
               <el-table-column prop="name" label="教师类型">
              </el-table-column>
              <el-table-column prop="address" label="教师职称"> </el-table-column>
               <el-table-column label="操作" fixed="right">
                <template slot-scope="scope">
                  <el-button
                    @click="production(scope.row)"
                    type="primary"
                    size="small"
                    >保存</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
             <div style="padding: 1rem;text-align:center">
              <el-pagination
                @size-change="handleSizeChange1"
                @current-change="handleCurrentChange1"
                :current-page="currentPage1"
                :page-sizes="[100, 200, 300, 400]"
                :page-size="100"
                layout="total, sizes, prev, pager, next, jumper"
                :total="400"
              >
              </el-pagination>
            </div>
          </div>
          <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible2 = false">取 消</el-button>
            <el-button type="primary" @click="handleSave('ruleForm')"
              >确 定</el-button
            >
          </span>
        </el-dialog> -->
        <!-- 导入 上传文件 -->
        <el-dialog title="上传文件" :visible.sync="dialogVisibleUpload" width="30%">
            <div style="margin-bottom: 20px">
                <el-button type="primary">下载模板</el-button>
                <span style="color: red"
                >提示：导入前请先下载模板，根据模版整理好数据后上传导入.</span
                >
            </div>
            <el-upload
                    class="upload-demo"
                    drag
                    action="#"
                    :show-file-list="false"
                    :http-request="uploadFile"
                    :before-upload="beforeUpload"
            >
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                <div class="el-upload__tip" slot="tip">
                    只能上传excel文件，且不超过50M
                </div>
            </el-upload>
        </el-dialog>
        <!-- 设置教师 -->
        <!-- <el-dialog title="设置教师" :visible.sync="outerVisible">
             <div style="margin-top:1rem">
          <el-table :data="tableData" border style="width: 100%">
              <el-table-column
                  type="selection"
                  width="55">
                  </el-table-column>
            <el-table-column prop="date" label="教师工号" >
            </el-table-column>
            <el-table-column prop="name" label="教师姓名" >
            </el-table-column>
             <el-table-column prop="name" label="性别">
            </el-table-column>
             <el-table-column prop="name" label="院系">
            </el-table-column>
             <el-table-column prop="name" label="教师类型">
            </el-table-column>
            <el-table-column prop="address" label="教师职称"> </el-table-column>
          </el-table>
           <div style="padding: 1rem;text-align:center">
            <el-pagination
              @size-change="handleSizeChange2"
              @current-change="handleCurrentChange2"
              :current-page="currentPage2"
              :page-sizes="[100, 200, 300, 400]"
              :page-size="100"
              layout="total, sizes, prev, pager, next, jumper"
              :total="400"
            >
            </el-pagination>
          </div>
        </div>
          <el-dialog
          width="50%"
          title="添加教师"
          :visible.sync="innerVisible"
          append-to-body>
          <el-form :model="search" class="demo-ruleForm" inline>
          <el-form-item style="margin-bottom: 0">
            <el-input
              v-model="search.name"
              clearable
              @input="() => (search.name = search.name.trim())"
              placeholder="姓名/工号"
              style="width: 300px"
            ></el-input>
            <el-button icon="el-icon-search" @click="handleSearch"></el-button>
          </el-form-item>
        </el-form>
        <div style="margin-top:1rem">
          <el-table :data="tableData" border style="width: 100%">
              <el-table-column
                  type="selection"
                  width="55">
                  </el-table-column>
            <el-table-column prop="date" label="教师工号" >
            </el-table-column>
            <el-table-column prop="name" label="教师姓名" >
            </el-table-column>
             <el-table-column prop="name" label="性别">
            </el-table-column>
             <el-table-column prop="name" label="院系">
            </el-table-column>
             <el-table-column prop="name" label="教师类型">
            </el-table-column>
            <el-table-column prop="address" label="教师职称"> </el-table-column>
          </el-table>
           <div style="padding: 1rem;text-align:center">
            <el-pagination
              @size-change="handleSizeChange1"
              @current-change="handleCurrentChange1"
              :current-page="currentPage1"
              :page-sizes="[100, 200, 300, 400]"
              :page-size="100"
              layout="total, sizes, prev, pager, next, jumper"
              :total="400"
            >
            </el-pagination>
          </div>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" @click="innerVisible = true">保存后关闭</el-button>
          </div>
          </el-dialog>

          <div slot="footer" class="dialog-footer">
          <el-button @click="outerVisible = false">删 除</el-button>
          <el-button type="primary" @click="innerVisible = true">添加教师</el-button>
          </div>
      </el-dialog> -->
    </div>
</template>
<script>
    export default {
        data() {
            return {
                addButton: localStorage.getItem('accountType'),
                reason: null,
                dialogVisibleStart: false,
                myHeaders: {
                    'x-token': localStorage.loginStrage ? JSON.parse(localStorage.getItem('loginStrage'))['x-token'] : null,
                    'x-perm': localStorage.loginStrage ? JSON.parse(localStorage.getItem('loginStrage'))['x-perm'] : null
                },
                userBo: {
                  jobNo: null,//工号
                  name: null,//姓名
                  sex: 0,//性别 0男 1女
                  idCard: null,//身份证号
                  profDir: null,//专业方向
                  jodTitle: null,//职称
                  education: null,//学历
                  online: 0,//是否在任 0否 1是
                  roleType: 0,//身份类型 0教师 1管理员 2教师管理员
                  phoneNum: null,//电话
                  telNum: null,//固定电话
                  email: null,//邮箱
                  postCode: null,//邮政编码
                  teacherType: 0,//教师类型 0辅导教师 1主讲教师 2批阅教师
                  wechart: null,//微信
                  qqNum: null,//QQ
                  jobCompany: null,//工作地点
                  contactAddress: null,//地址
                  remark: null,//备注
                  headerUrl: null,//头像url
                  status: 0,//是否是指导教师 0是 1否（新增，在毕业大作业指导教师列表体现）
                  IdentityStatus: 3,//1 管理员列表 2总部教师列表 3函授站教师列表
                },
                examOpt: [
                    {
                        value: 0,
                        label: "待审核",
                    },
                    {
                        value: 1,
                        label: "已通过",
                    },
                    {
                        value: 2,
                        label: "驳回",
                    },
                ],
                bo: {
                    name: "",
                    page: 1,
                    pageSize: 10,
                    IdentityStatus: 3,
                    siteId: "",//站点
                    roleType: "",//身份类型
                    teacherType: "",//教师类型
                    exam: "",//审核状态
                },
                total: 0,
                pageNum: 1,
                pageSize: 10,
                currentPage4: 1,
                currentPage1: 4,
                currentPage2: 4,
                textarea: "",
                search: {
                    name: "",
                },
                teacherForm: {
                    name: "",
                },
                outerVisible: false,
                innerVisible: false,
                tableChecked: [],
                tableData: [],
                correspondType: [
                    {
                        value: 0,
                        label: "全部",
                    },
                    {
                        value: 1,
                        label: "函授站",
                    },
                    {
                        value: 2,
                        label: "学习中心",
                    },
                    {
                        value: 3,
                        label: "函授站及学习中心",
                    },
                ],
                dialogVisible: false,
                dialogVisible1: false,
                dialogVisible2: false,
                dialogVisibleUpload: false,
                teacherRules: {
                    name: [{required: true, message: "请输入姓名", trigger: "blur"}],
                    fullName: [
                        {required: true, message: "请输入真实姓名", trigger: "blur"},
                    ],
                    sex: [{required: true, message: "请选择性别", trigger: "blur"}],
                    phone: [
                        {required: true, message: "请输入手机号码", trigger: "blur"},
                        {pattern: /^1[3456789]\d{9}$/, message: "手机号格式不正确"},
                    ],
                    email: [
                        {required: false, message: "请输入邮箱", trigger: "blur"},
                        {
                            pattern: /^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$/,
                            message: "邮箱格式不正确",
                        },
                    ],
                    idCard: [
                        {required: false, message: "请输入身份证号码", trigger: "blur"},
                        {
                            pattern: /^([1-6][1-9]|50)\d{4}(18|19|20)\d{2}((0[1-9])|10|11|12)(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/,
                            message: "身份证号格式不正确",
                        },
                    ],
                },
                boRules: {
                    jobNo: [{required: true, message: "请输入工号", trigger: "blur"}],
                    name: [{required: true, message: "请输入姓名", trigger: "blur"}],
                    sex: [{required: true, message: "请选择性别", trigger: "blur"}],
                    profDir: [{required: true, message: "请输入专业方向", trigger: "blur"}],
                    jodTitle: [{required: true, message: "请输入职称", trigger: "blur"}],
                    education: [{required: true, message: "请输入学历", trigger: "blur"}],
                    online: [{required: true, message: "请选择是否在任", trigger: "blur"}],
                    roleType: [{required: true, message: "请选择身份类型", trigger: "blur"}],
                    telNum: [{required: true, message: "请输入固定电话", trigger: "blur"}],
                    postCode: [{required: true, message: "请输入邮政编码", trigger: "blur"}],
                    teacherType: [{required: true, message: "请选择教师类型", trigger: "blur"}],
                    wechart: [{required: true, message: "请输入微信", trigger: "blur"}],
                    qqNum: [{required: true, message: "请输入QQ", trigger: "blur"}],
                    jobCompany: [{required: true, message: "请输入工作地点", trigger: "blur"}],
                    contactAddress: [{required: true, message: "请输入地址", trigger: "blur"}],
                    status: [{required: true, message: "请选择是否是指导老师", trigger: "blur"}],
                    IdentityStatus: [{required: true, message: "请选择列表类型", trigger: "blur"}],
                    phoneNum: [
                        {required: true, message: "请输入手机号码", trigger: "blur"},
                        {pattern: /^1[3456789]\d{9}$/, message: "手机号格式不正确"},
                    ],
                    idCard: [{required: true, message: "请输入身份证号码", trigger: "blur"}, {
                        pattern: /^([1-6][1-9]|50)\d{4}(18|19|20)\d{2}((0[1-9])|10|11|12)(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/,
                        message: "身份证号格式不正确",
                    },
                    ],
                    email: [
                        {required: false, message: "请输入邮箱", trigger: "blur"},
                        {
                            pattern: /^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$/,
                            message: "邮箱格式不正确",
                        },
                    ],
                },
                teacherTypeOpt: [
                    {
                        value: 0,
                        label: "辅导教师",
                    }, {
                        value: 1,
                        label: "主讲教师",
                    },
                ],
                roleTypeOpt: [
                    {
                        value: 0,
                        label: "教师",
                    },
                    {
                        value: 1,
                        label: "管理员",
                    }, {
                        value: 2,
                        label: "教师管理员",
                    },
                ],
                onlineOpt: [
                    {
                        value: 0,
                        label: "是",
                    },
                    {
                        value: 1,
                        label: "否",
                    }
                ],
                sexOpt: [
                    {
                        value: 0,
                        label: "男",
                    },
                    {
                        value: 1,
                        label: "女",
                    },
                ],
                options: [
                    {
                        value: "选项1",
                        label: "黄金糕",
                    },
                    {
                        value: "选项2",
                        label: "双皮奶",
                    },
                    {
                        value: "选项3",
                        label: "蚵仔煎",
                    },
                    {
                        value: "选项4",
                        label: "龙须面",
                    },
                    {
                        value: "选项5",
                        label: "北京烤鸭",
                    },
                ],
                value: "",
                siteOpt: [],
                status: null,
            };

        },
        methods: {
            submitEnd() {
                let ids = "";
                this.tableChecked.forEach(item => {
                    ids = ids + item.id + ",";
                })
                ids = ids.substr(0, ids.length - 1);
                let fromData = new FormData();
                fromData.append("ids", ids);
                fromData.append("status", '2');
                fromData.append("reason", this.reason);
                this.$axios_supermallData.post("/hnjxjy-core/eduTeacher/editExam", fromData).then(resp => {
                    if (resp.data.code == 2000) {
                        this.$message({
                            type: "success",
                            message: "操作成功!",
                        });
                        this.queryListVo();
                    }
                });
                this.dialogVisibleStart = false;
                this.tableChecked = [];
                this.reason = '';
            },
            handleCommand1(command) {
                //驳回
                if (command == 'noAll') {
                    if (this.tableChecked.length <= 0) {
                        this.$message.warning("请选择条目");
                    } else {
                        this.$confirm(`您确定要操作(${this.tableChecked.length})条数据吗?`, "提示", {
                            confirmButtonText: "确定",
                            cancelButtonText: "取消",
                            type: "warning",
                        })
                            .then(() => {
                                let ids = "";
                                this.tableChecked.forEach(item => {
                                    ids = ids + item.id + ",";
                                })
                                ids = ids.substr(0, ids.length - 1);
                                let fromData = new FormData();
                                fromData.append("ids", ids);
                                fromData.append("status", '1');
                                fromData.append("reason", '');
                                this.$axios_supermall.post("/hnjxjy-core/eduTeacher/editExam", fromData).then(resp => {
                                    if (resp.data.code == 2000) {
                                        this.$message({
                                            type: "success",
                                            message: "操作成功!",
                                        });
                                        this.queryListVo();
                                    }
                                })

                            })
                            .catch(() => {
                                this.$message({
                                    type: "info",
                                    message: "已取消操作",
                                });
                            });
                    }
                } else if (command == 'all') {

                    this.$confirm(`您确定要操作(${this.tableData.length})条数据吗?`, "提示", {
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                        type: "warning",
                    })
                        .then(() => {
                            let ids = "";
                            this.tableData.forEach(item => {
                                ids = ids + item.id + ",";
                            })
                            ids = ids.substr(0, ids.length - 1);
                            let fromData = new FormData();
                            fromData.append("ids", ids);
                            fromData.append("status", '1');
                            fromData.append("reason", '');
                            this.$axios_supermallData.post("/hnjxjy-core/eduTeacher/editExam", fromData).then(resp => {
                                if (resp.data.code == 2000) {
                                    this.$message({
                                        type: "success",
                                        message: "操作成功!",
                                    });
                                    this.queryListVo();
                                }
                            })

                        })
                        .catch(() => {
                            this.$message({
                                type: "info",
                                message: "已取消操作",
                            });
                        });
                }


            },
            handleCommand2(command) {
                if (command == 'noAll') {
                    if (this.tableChecked.length <= 0) {
                        this.$message.warning("请选择条目");

                        return;
                    }
                } else if (command == 'all') {
                    this.tableChecked = this.tableData;
                }
                this.dialogVisibleStart = true;
            },
            onSuccess(response, file, fileList) {
                this.userBo.headerUrl = response.data.fileUrl
            },
            handleSave(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        let formData = new FormData();
                        formData.append('id', this.userBo.id);
                        formData.append('contactAddress', this.userBo.contactAddress);
                        formData.append('education', this.userBo.education);
                        formData.append('email', this.userBo.email);
                        formData.append('headerUrl', this.userBo.headerUrl);
                        formData.append('idCard', this.userBo.idCard);
                        // formData.append('IdentityStatus', this.bo.IdentityStatus);
                        formData.append('jobCompany', this.userBo.jobCompany);
                        formData.append('jobNo', this.userBo.jobNo);
                        formData.append('jodTitle', this.userBo.jodTitle);
                        formData.append('name', this.userBo.name);
                        formData.append('online', this.userBo.online);
                        formData.append('phoneNum', this.userBo.phoneNum);
                        formData.append('postCode', this.userBo.postCode);
                        formData.append('profDir', this.userBo.profDir);
                        formData.append('qqNum', this.userBo.qqNum);
                        formData.append('remark', this.userBo.remark);
                        formData.append('roleType', this.userBo.roleType);
                        formData.append('sex', this.userBo.sex);
                        formData.append('status', this.userBo.status);
                        formData.append('teacherType', this.userBo.teacherType);
                        formData.append('telNum', this.userBo.telNum);
                        this.$axios_supermall.put("/hnjxjy-core/eduTeacher/edit", formData).then(resp => {
                            if (resp.data.code == 2000) {
                                this.$message.success("编辑成功");
                                this.queryListVo();
                            } else {
                                //this.$message.error(resp.data.data)
                            }
                        })
                        this.$refs[formName].resetFields();
                        this.dialogVisible = false;
                    } else {
                        return false;
                    }
                });
            },
          handleSaves(formName) {
            this.$refs[formName].validate((valid) => {
              if (valid) {
                let formData = new FormData();
                formData.append('id', this.userBo.id);
                formData.append('contactAddress', this.userBo.contactAddress);
                formData.append('education', this.userBo.education);
                formData.append('email', this.userBo.email);
                formData.append('headerUrl', this.userBo.headerUrl);
                formData.append('idCard', this.userBo.idCard);
                // formData.append('IdentityStatus', this.userBo.IdentityStatus);
                formData.append('jobCompany', this.userBo.jobCompany);
                formData.append('jobNo', this.userBo.jobNo);
                formData.append('jodTitle', this.userBo.jodTitle);
                formData.append('name', this.userBo.name);
                formData.append('online', this.userBo.online);
                formData.append('phoneNum', this.userBo.phoneNum);
                formData.append('postCode', this.userBo.postCode);
                formData.append('profDir', this.userBo.profDir);
                formData.append('qqNum', this.userBo.qqNum);
                formData.append('remark', this.userBo.remark);
                formData.append('roleType', this.userBo.roleType);
                formData.append('sex', this.userBo.sex);
                formData.append('wechart', this.userBo.wechart);
                formData.append('teacherType', this.userBo.teacherType);
                formData.append('telNum', this.userBo.telNum);

                this.$axios_supermall.post("/hnjxjy-core/eduTeacher/add", this.userBo).then(resp => {
                  if (resp.data.code == 2000) {
                    this.$message.success("添加成功");
                    this.queryListVo()
                    this.dialogVisible1 = false
                  }
                })
                this.$refs[formName].resetFields();
                this.dialogVisible = false;
              } else {
                return false;
              }
            });
          },
            //查询列表信息
            queryListVo() {
                this.openLoadingView();
                this.$axios_supermall.get("/hnjxjy-core/eduTeacher/listVo?page=" + this.bo.page + "&pageSize=" + this.bo.pageSize
                    + "&roleType=" + this.bo.roleType
                    + "&exam=" + this.bo.exam
                    + "&name=" + this.bo.name
                    + "&siteId=" + this.bo.siteId
                    + "&teacherType=" + this.bo.teacherType
                    + "&IdentityStatus=" + this.bo.IdentityStatus
                ).then(resp => {
                    if (resp.data.code == 2000) {
                        this.tableData = resp.data.data.list;
                        this.total = resp.data.data.total;
                    }
                })
            },
            queryAllSite() {
              let formData = new FormData();
              formData.append("isAll","1");
              this.$axios_supermallData.post("/hnjxjy-core/eduSite/queryAllSite", formData).then(resp => {
                if (resp.data.code == 2000) {
                  this.site = resp.data.data;
                }
              })
            },
            handleSearch() {
            },
          addcreate() {
            this.userBo = {
              jobNo: null,//工号
              name: null,//姓名
              sex: 0,//性别 0男 1女
              idCard: null,//身份证号
              profDir: null,//专业方向
              jodTitle: null,//职称
              education: null,//学历
              online: 0,//是否在任 0否 1是
              roleType: 0,//身份类型 0教师 1管理员 2教师管理员
              phoneNum: null,//电话
              telNum: null,//固定电话
              email: null,//邮箱
              postCode: null,//邮政编码
              teacherType: 0,//教师类型 0辅导教师 1主讲教师 2批阅教师
              wechart: null,//微信
              qqNum: null,//QQ
              jobCompany: null,//工作地点
              contactAddress: null,//地址
              remark: null,//备注
              headerUrl: null,//头像url
              status: 0,//是否是指导教师 0是 1否（新增，在毕业大作业指导教师列表体现）
              IdentityStatus: 3,//1 管理员列表 2总部教师列表 3函授站教师列表
            };
            this.dialogVisible1 = true;
            this.$nextTick(() => {
              this.$refs['uploadphoto'].clearFiles() // 清除历史文件列表
            })
          },
            // 批量删除
            batchDelete() {
                if (this.tableChecked == "") {
                    this.$message.error("请至少选择一项记录");
                } else {
                    this.$confirm(`您确定要删除${this.tableChecked.length}数据吗?`, "提示", {
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                        type: "warning",
                    })
                        .then(() => {
                            let ids = "";
                            this.tableChecked.forEach(item => {
                                ids = ids + item.id + ",";
                            })
                            ids = ids.substr(0, ids.length - 1);
                            this.$axios_supermall.get("/hnjxjy-core/eduTeacher/remove?ids=" + ids).then(resp => {
                                if (resp.data.code == 2000) {
                                    this.$message({
                                        type: "success",
                                        message: "删除成功!",
                                    });
                                    this.queryListVo();
                                }
                            });
                        })
                        .catch(() => {
                            this.$message({
                                type: "info",
                                message: "已取消删除",
                            });
                        });
                }
            },
            // 点击导入
            upload() {
            },
            //设置制作教师
            production() {
                this.dialogVisible2 = true;
            },
            //设置教师
            Setting() {
                this.outerVisible = true
            },
            // 导入上传文件
            uploadFile() {
            },
            // 导入上传文件前判断文件格式
            beforeUpload(file) {
                const isXls = file.type === "application/vnd.ms-excel";
                const isXlsx =
                    file.type ===
                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
                if (!isXls && !isXlsx) {
                    this.$message.error("上传文件只能是XLS/XLSX格式！");
                    return false;
                }
                return isXls || isXlsx;
            },
            // 点击导出
            download() {
              this.openLoadingView();
              this.$axios_supermall({
                method: 'get',
                url:  "/hnjxjy-core/eduTeacher/export?status=3",
                responseType: 'blob'
              }).then(resp => {
                let blob = new Blob([resp.data], {
                  type: "application/vnd.ms-excel",
                });
                let downloadElement = document.createElement('a');
                let href = window.URL.createObjectURL(blob);
                downloadElement.href = href;
                downloadElement.download = '函数站教师列表.xls';
                document.body.appendChild(downloadElement);
                downloadElement.click();
                document.body.removeChild(downloadElement);
                window.URL.revokeObjectURL(href);
                this.loadingView.close();
                this.$message.success("操作成功");
                this.loadingView.close();
              }).catch((err) => {
                this.$message.error("操作失败");
                this.loadingView.close();
              });
            },
            handleSelectionChange(val) {
                this.tableChecked = val;
            },
            // 点击修改
            editor(row) {
                this.$axios_supermall.get("/hnjxjy-core/eduTeacher/getOne?id=" + row.id).then(resp => {
                    if (resp.data.code == 2000) {
                        this.userBo = resp.data.data;
                    }
                });
                this.dialogVisible = true;
            },
            handleSizeChange(val) {
                this.bo.pageSize = val;
                this.queryListVo();
            },
            handleCurrentChange(val) {
                this.bo.page = val;
                this.queryListVo();
            },
            handleSizeChange1(val) {
            },
            handleCurrentChange1(val) {
            },
            handleSizeChange2(val) {
            },
            handleCurrentChange2(val) {
            },
            // 点击删除
            deleteCorrespond(row) {
                this.$confirm(`您确定要删除(${row.name})的数据吗?`, "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                })
                    .then(() => {

                        this.$axios_supermall.get("/hnjxjy-core/eduTeacher/remove?ids=" + row.id).then(resp => {
                            if (resp.data.code == 2000) {
                                this.$message({
                                    type: "success",
                                    message: "删除成功!",
                                });
                                this.queryListVo();
                            }
                        });

                    })
                    .catch(() => {
                        this.$message({
                            type: "info",
                            message: "已取消删除",
                        });
                    });
            },
        },
        mounted() {
            //查询函授站下拉框
            this.queryAllSite();
            //查询列表信息
            this.queryListVo();
        }
    };
</script>
<style scoped>
    .search {
        text-align: left;
        margin-top: 1rem;
        /* margin-left: 1rem; */
        padding: 1rem;
    }

    .table-wrap {
        overflow: auto;
        /* margin-left: 1rem; */
        /* margin-bottom: 1rem; */
        padding: 1rem;
    }

    .demo-ruleForm {
        /* width: 90%; */
        height: calc(100% - 125px);
    }

    .el-form-input-width {
        width: 100%;
    }

    .rigth {
        width: 93%;
        display: inline-block;
        height: 870px;
        overflow-y: scroll;
        /* background-color: #fff; */
        background-color: rgba(164, 174, 224, 0.11);
        float: right;
    }

    .left {
        width: 7%;
        display: inline-block;
    }

    .portrait_Sprite {
        padding: 0.2rem 1rem;
        display: inline-block;
        background-color: skyblue;
        color: #fff;
        border-radius: 10%;
        font-size: 13px;
    }
    .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }
    .avatar-uploader .el-upload:hover {
        border-color: #409EFF;
    }
    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 178px;
        height: 178px;
        line-height: 178px;
        text-align: center;
    }
    .avatar {
        width: 178px;
        height: 178px;
        display: block;
    }
</style>
