<template>
  <div>

    <div class="header">
      <div class="header_top">
        <reviewleft></reviewleft>
        <router-view></router-view>
      </div>
      <div class="content">
        <div class="course">课程选课数据</div>
        <div class="export">
          <div class="export_left">
            <el-button icon="el-icon-bottom" type="primary">导出</el-button>
          </div>
          <div class="export_right">
            <el-button type="primary">查询</el-button>
            <el-button
              type="primary" class="rest"
              >重置</el-button
            >
          </div>
        </div>
        <div class="name_num">
          <div
            style="width: 32%; display: inline-block; text-align: left"
            class="choose"
          >
            学号/姓名<i class="span"></i>
            <el-input v-model="input" placeholder="学号/姓名"></el-input>
          </div>
          <div
            style="
              width: 28%;
              display: inline-block;
              text-align: left;
              margin-right: 90px;
            "
            class="choose"
          >
            函授站<i class="span"></i>
            <el-select v-model="value" placeholder="全部">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <div
            style="width: 28%; display: inline-block; text-align: left"
            class="choose"
          >
            年级<i style="margin-right: 68px"></i>
            <el-select v-model="value" placeholder="全部">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <div
            style="width: 32%; display: inline-block; text-align: left"
            class="choose"
          >
            层次<i style="margin-right: 72px"></i>
            <el-select v-model="value" placeholder="全部">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <div
            style="
              width: 28%;
              display: inline-block;
              text-align: left;
              margin-right: 90px;
            "
            class="choose"
          >
            <span style="margin-right: 10px"
              >专业<i class="span" style="margin-right: 8px"></i
            ></span>
            <el-select v-model="value" placeholder="全部">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <div
            style="width: 28%; display: inline-block; text-align: left"
            class="choose"
          >
            设置批次<i class="span"></i>
            <el-select v-model="value" placeholder="全部">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="excle">
          <div class="word">共选中0条数据</div>
          <el-table
            :data="tableData"
            border
            height="500px"
            style="width: 100%"
            :default-sort="{ prop: 'date', order: 'descending' }"
            slot="empty"
          >
            <el-table-column type="selection" width="50"> </el-table-column>
            <el-table-column
              prop="address"
              label="序号"
              :formatter="formatter"
              width="80"
            ></el-table-column>
            <el-table-column
              prop="name"
              label="姓名"
              sortable
              width="174"
            ></el-table-column>
            <el-table-column
              prop="date"
              label="学号"
              sortable
              width="174"
            ></el-table-column>
            <el-table-column
              prop="sex"
              label="函授站"
              sortable
              width="174"
            ></el-table-column>
            <el-table-column
              prop="age"
              label="年级"
              sortable
              width="174"
            ></el-table-column>
            <el-table-column
              prop="string"
              label="层次"
              sortable
              width="140"
            ></el-table-column>
            <el-table-column
              prop="boolen"
              label="专业"
              sortable
              width="140"
            ></el-table-column>
            <el-table-column
              prop="sex_one"
              label="联系电话"
              :formatter="formatter"
              sortable
              width="140"
            ></el-table-column>
            <el-table-column
              prop="sex_two"
              label="类型"
              :formatter="formatter"
              sortable
              width="140"
            ></el-table-column>
            <el-table-column
              prop="age_one"
              label="选课时间"
              :formatter="formatter"
              sortable
              width="170"
            ></el-table-column>
            <el-table-column
              prop="age_two"
              label="毕业设计批次"
              :formatter="formatter"
              sortable
              width="180"
            ></el-table-column>
          </el-table>
          <div class="button">
            <el-pagination
              @size-change="handleSizeChange1"
              @current-change="handleCurrentChange1"
              :current-page="currentPage"
              :page-sizes="[100, 200, 300, 400]"
              :page-size="100"
              layout="total, sizes, prev, pager, next, jumper"
              :total="400"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>


export default {

  data() {
    return {
      pickerOptions: {
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              picker.$emit("pick", new Date());
            },
          },
          {
            text: "昨天",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24);
              picker.$emit("pick", date);
            },
          },
          {
            text: "一周前",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", date);
            },
          },
        ],
      },
      value1: "",
      value2: "",
      value3: "",
      options: [
        {
          value: "选项1",
          label: "黄金糕",
        },
        {
          value: "选项2",
          label: "双皮奶",
        },
        {
          value: "选项3",
          label: "蚵仔煎",
        },
        {
          value: "选项4",
          label: "龙须面",
        },
        {
          value: "选项5",
          label: "北京烤鸭",
        },
      ],
      tableData: [
        {
          date: "2016-05-02",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄",
          sex: "1111",
          age: "1111",
          string: "111",
          boolen: "41111",
          sex_one: "11111",
          sex_two: "11111",
          age_one: "111111",
          age_two: "11111",
          age_three: "1111111",
          age_four: "1111111",
        },
        {
          date: "2016-05-04",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1517 弄",
          sex: "1111",
          age: "1111",
          string: "111",
          boolen: "41111",
          sex_one: "11111",
          sex_two: "11111",
          age_one: "111111",
          age_two: "11111",
          age_three: "1111111",
          age_four: "1111111",
        },
        {
          date: "2016-05-01",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1519 弄",
          sex: "1111",
          age: "1111",
          string: "111",
          boolen: "41111",
          sex_one: "11111",
          sex_two: "11111",
          age_one: "111111",
          age_two: "11111",
          age_three: "1111111",
          age_four: "1111111",
        },
        {
          date: "2016-05-03",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1516 弄",
          sex: "1111",
          age: "1111",
          string: "111",
          boolen: "41111",
          sex_one: "11111",
          sex_two: "11111",
          age_one: "111111",
          age_two: "11111",
          age_three: "1111111",
          age_four: "1111111",
        },
        {
          date: "2016-05-02",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄",
          sex: "1111",
          age: "1111",
          string: "111",
          boolen: "41111",
          sex_one: "11111",
          sex_two: "11111",
          age_one: "111111",
          age_two: "11111",
          age_three: "1111111",
          age_four: "1111111",
        },
        {
          date: "2016-05-02",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄",
          sex: "1111",
          age: "1111",
          string: "111",
          boolen: "41111",
          sex_one: "11111",
          sex_two: "11111",
          age_one: "111111",
          age_two: "11111",
          age_three: "1111111",
          age_four: "1111111",
        },
        {
          date: "2016-05-02",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄",
          sex: "1111",
          age: "1111",
          string: "111",
          boolen: "41111",
          sex_one: "11111",
          sex_two: "11111",
          age_one: "111111",
          age_two: "11111",
          age_three: "1111111",
          age_four: "1111111",
        },
        {
          date: "2016-05-02",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄",
          sex: "1111",
          age: "1111",
          string: "111",
          boolen: "41111",
          sex_one: "11111",
          sex_two: "11111",
          age_one: "111111",
          age_two: "11111",
          age_three: "1111111",
          age_four: "1111111",
        },
        {
          date: "2016-05-02",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄",
          sex: "1111",
          age: "1111",
          string: "111",
          boolen: "41111",
          sex_one: "11111",
          sex_two: "11111",
          age_one: "111111",
          age_two: "11111",
          age_three: "1111111",
          age_four: "1111111",
        },
      ],
    };
  },
  methods: {
    formatter(row, column) {
      return row.address;
    },
  },
};
</script>
<style scoped>
.header_top {
  width: 7%;
}
.header {
  display: flex;
  justify-content: flex-start;
  margin-top: 5px;
}
.content {
  text-align: left;
  width: 93%;
}
.course {
  background-color: #f1f1f1;
  line-height: 54px;
  padding-left: 10px;
}
.export {
  line-height: 50px;
  display: flex;
  justify-content: space-between;
  background-color: rgba(164, 174, 224, 0.11);
  padding-bottom: 15px;
}
.rest{
  background-color: #fff;
  color: #000;
  border: 1px solid #ccc;
}
.rest:hover{
  background-color: rgb(232, 244, 255) !important;
  color: rgb(24, 144, 255);
}
.export_left {
  padding-left: 15px;
}
.export_right {
  padding-right: 28%;
}
.el-dropdown-link {
  cursor: pointer;
  color: #409eff;
}
.el-icon-arrow-down {
  font-size: 12px;
}
.demonstration {
  display: block;
  color: #8492a6;
  font-size: 14px;
  margin-bottom: 20px;
}
.name_num {
  background-color: rgba(164, 174, 224, 0.11);
  padding-left: 50px;
  text-align: left;
}
.name_num >>> .el-input--suffix {
  width: 280px;
}
.name_num >>> .el-input {
  width: 380px;
}
.choose {
  margin-bottom: 15px;
  margin-right: 30px;
}
.demonstration {
  display: inline-block;
  padding-right: 10px;
  font-size: 16px;
  color: #000;
}
.el-input__suffix {
  right: 5px;
}
.el-input__inner {
  width: 100%;
}
.span {
  display: inline-block;
  width: 36px;
}
.excle {
  width: 97.7%;
  height: 650px;
  padding: 0 20px 20px 20px;
  background-color: rgba(164, 174, 224, 0.11);
}
.excle >>> .el-table {
  height: 550px;
}
.word {
  font-size: 14px;
  color: #00f;
  display: flex;
  justify-content: flex-start;
}
.button{
  padding: 1rem;
  text-align: left;
  background-color:#fff;
}
.excle>>>.el-table__body td,
.excle>>>.el-table__body tr,
.excle>>>.el-table th>.cell{
  text-align: center;
}
@media screen and (max-width:750px) {
  html{
    padding-right: 0;
  }
  .export{
    display: flex;
    justify-content: space-between;
  }
  .export_right{
    padding-right: 1%;
  }
  .name_num{
    width: 100%;
    padding-left: 0;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .button{
    overflow-x: scroll;
  }
  .header_top{
    width: 0%;
    padding-left: 0px;
  }
  .tabTop{
    width: 100% !important;
  }
  .excle{
    width: 100%;
    padding: 0 0 20px 0;
  }
  .content{
    width: 100%;
  }
}
/* .el-table__body-wrapper{
  height: 550px;
} */
</style>
