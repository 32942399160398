<template>
    <!-- 饼状图 -->
    <div id="mianse">
        <section id="four">
            <div id="pies"></div>
            <!--      <el-dropdown id="fist" @command="handleCommand">-->
            <!--        <el-button type="primary" id="three">-->
            <!--          {{ year }}<i class="el-icon-arrow-down el-icon&#45;&#45;right"></i>-->
            <!--        </el-button>-->
            <!--        <el-dropdown-menu slot="dropdown" @click="handleClick">-->
            <!--          <el-dropdown-item v-for="k in kuList" :key="k.id" :command="k.year" >{{k.year}}</el-dropdown-item>-->
            <!--        </el-dropdown-menu>-->
            <!--      </el-dropdown>-->
            <div id="fist">
                <template>
                    <el-select v-model="year" style="width:40%" placeholder="请选择" @change="selectChange">
                        <el-option
                                v-for="item in kuList"
                                :key="item.id"
                                :label="item.year"
                                :value="item.year">
                        </el-option>
                    </el-select>
                </template>
            </div>
        </section>

        <!--            <section id="ones">-->
        <!--              <div id="twos"></div>-->
        <!--              <el-dropdown id="fist">-->
        <!--        <el-button type="primary" id="three">-->
        <!--&lt;!&ndash;          河南省<i class="el-icon-arrow-down el-icon&#45;&#45;right"></i>&ndash;&gt;-->
        <!--        </el-button>-->
        <!--        <el-dropdown-menu slot="dropdown">-->
        <!--          <el-dropdown-item>山东省</el-dropdown-item>-->
        <!--          <el-dropdown-item>江苏省</el-dropdown-item>-->
        <!--          <el-dropdown-item>海南省</el-dropdown-item>-->
        <!--        </el-dropdown-menu>-->
        <!--              </el-dropdown>-->
        <!--            </section>-->
    </div>
</template>
<style scoped>
    #mianse {
        width: 100%;
        height: 400px;
        background: #fff;
    }

    #mianse #four {
        width: 100%;
        height: 400px;
        float: left;
        position: relative;
    }

    #mianse #pies {
        width: 100%;
        height: 400px;
    }

    #mianse #ones {
        width: 60%;
        height: 400px;
        float: right;
        position: relative;
    }

    #mianse #ones #twos {
        width: 100%;
        height: 400px;
    }

    /* 下拉框 */
    #fist {
        position: absolute;
        top: 20px;
        right: 0px;
    }

    .el-dropdown {
        vertical-align: top;
    }

    .el-dropdown + .el-dropdown {
        margin-left: 15px;
    }

    .el-icon-arrow-down {
        font-size: 12px;
    }

    #three {
        background: #fff;
        color: #ddd;
        border: 1px solid #ddd;
    }
</style>
<script>
    var echarts = require("echarts");
    export default {
        data() {
            return {
                kuList: [],
                year: null,
                reqData: null,
            };
        },
        methods: {
            selectChange() {
                this.queryYearPayCountData();
            },
            // 年纪下拉框查询
            queryAllEduGradeByCodeAndName() {
                let thisYear = new Date().getFullYear()
                let lastYear = thisYear - 1;
                let date1 = {
                    id: 1,
                    year: thisYear,
                }
                let date2 = {
                    id: 2,
                    year: lastYear,
                }
                this.kuList.push(date1);
                this.kuList.push(date2);
                this.year = thisYear;
            },
            //年度缴费情况
            queryYearPayCountData() {
                this.$axios_supermall.get("/hnjxjy-core/eduIndex/yearPayCountData?year=" + this.year).then(resp => {
                    if (resp.data.code == 2000) {
                        this.reqData = resp.data.data;
                        // 基于准备好的dom，初始化echarts实例柱状图
                        let piemcharts = echarts.init(document.querySelector("#pies"));
                        // let piemchart = echarts.init(document.querySelector("#twos"));
                        //  数据1:
                        let pieData = [
                            {
                                name: "已缴费",
                                value: "",
                            },
                            {
                                name: "未缴费",
                                value: "",
                            },
                        ];

                        pieData[0].value = this.reqData.payCount;
                        pieData[1].value = this.reqData.noPayCount;

                        //数据2
                        let pietwo = [
                            {
                                name: "高起本",
                                value: "13",
                            },
                            {
                                name: "专起本",
                                value: "3",
                            },
                            {
                                name: "高起专",
                                value: "11",
                            },
                        ];
                        piemcharts.setOption({
                            legend: {
                                data: ['已缴费', '未缴费'],
                                bottom: 20,
                            },
                            title: {
                                text: "年度缴费情况", //主标题
                                top: 20,
                                left: 20,
                                textStyle: {
                                    color: "#2a2a2a", //主标题颜色
                                    fontSize: 24, //字体大小
                                },
                            }, //主标题
                            series: [
                                {
                                    type: "pie",
                                    data: pieData,
                                    top: '20%',
                                    left: '20%',
                                    width: "60%",
                                    height: "60%",
                                    label: {
                                        show: "true", //显示文字
                                        formatter: function (arg) {
                                            return arg.value + "人";
                                        },
                                    },
                                },
                            ],
                        });
                        //region 去掉
                        // piemchart.setOption({
                        //     grid: {
                        //         width: "60%",
                        //         height: "60%",
                        //         left: "20%",
                        //         top: "20%",
                        //     },
                        //     legend: {
                        //         bottom: "5%",
                        //         left: "center",
                        //     },
                        //     series: [
                        //         {
                        //             type: "pie",
                        //             radius: ["30%", "40%"],
                        //             data: pieData,
                        //             label: {
                        //                 show: "true", //显示文字
                        //                 formatter: function (arg) {
                        //                     return arg.value + "人";
                        //                 },
                        //             },
                        //         },
                        //     ],
                        // });
                        //endregion
                    }
                });
            },
            // 下拉
            handleClick() {
                alert("button click");
            },
        },
        mounted() {
            this.queryAllEduGradeByCodeAndName();
            this.queryYearPayCountData();

        },
    };
</script>
