<template>
    <div>
        <div>
            <div style="padding: 1rem; border-bottom: 1px solid #ddd; text-align: left">
                短信模板
            </div>
            <!--            <div class="top_btn">-->

            <!--        <el-button type="primary" icon="el-icon-circle-plus-outline" @click="dialogVisible2 = true">新增</el-button>-->
            <!--            </div>-->
            <div class="public-global-search">
                <el-form>
                    <el-form-item>
                        <el-button type="primary" icon="el-icon-circle-plus-outline" @click="enrollmentDiglog" v-permission="'eduMsgTemp:insertSelective'">
                            招生短信模板
                        </el-button>
                    </el-form-item>
                </el-form>
                <!--        <span class=" batch">招生批次</span>-->
                <!--        <el-select v-model="value" placeholder="请选择" style="width: 300px;">-->
                <!--          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">-->
                <!--          </el-option>-->
                <!--        </el-select>-->
            </div>
            <div class="public-global-main_contain">
                <el-table :data="tableData" border style="width: 100%" height="700px" max-height="700px" size="medium"
                          :header-cell-style="{'text-align':'center'}" :cell-style="{'text-align':'center'}">
                    <el-table-column type="index" label="序号" width="50">
                    </el-table-column>
                    <el-table-column prop="tempName" label="模板名称">
                    </el-table-column>
                    <el-table-column prop="tempContent" label="模板内容">
                    </el-table-column>
                    <el-table-column prop="tempType" label="模板类型" :formatter="formatType">
                    </el-table-column>
                    <el-table-column label="创建时间" prop="createTime">
                    </el-table-column>
                    <el-table-column label="操作">
                        <template slot-scope="scope">
                            <el-button size="mini" type="danger" @click="del(scope.row.id)" v-permission="'eduMsgTemp:delMsgTempById'">删除</el-button>
                            <el-button size="mini" @click="updateOne(scope.row)" v-permission="'eduMsgTemp:updateMsgTempById'">修改</el-button>
                        </template>

                    </el-table-column>
                </el-table>
                <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                               :page-sizes="[10, 30, 50, 100]" background
                               :current-page="currentPage4"
                               layout="total, sizes, prev, pager, next, jumper" :total="total">
                </el-pagination>
            </div>
<!--            <div class="ye">-->

<!--            </div>-->

        </div>
        <el-dialog class="add" :title="title" :visible.sync="putDialog" width="70%" center>
            <div class="top_btn">
                <el-button type="primary" icon="el-icon-folder-add" @click="add" size="small">保存后关闭</el-button>
            </div>
            <div>
                <el-form ref="rules" :rules="rules" :model="diglog" label-width="120px">
                    <el-form-item label="模板名称">
                        <el-input placeholder="请输入内容（必填项）" resize="none" v-model="diglog.tempName">
                        </el-input>
                    </el-form-item>
                    <el-form-item label="模板CODE">
                        <el-input placeholder="请输入内容（必填项-请与阿里云申请一致）" resize="none" v-model="diglog.tempCode">
                        </el-input>
                    </el-form-item>
                    <el-form-item label="模板类型">
                        <!--            <el-input  placeholder="请输入内容" resize="none" v-model="diglog.tempType">-->
                        <el-select v-model="diglog.tempType" placeholder="请选择类型（必填项）">
                            <el-option
                                    v-for="item in options"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="短信模板">
                        <el-input type="textarea" :rows="4" placeholder="请输入内容（必填项）" resize="none"
                                  v-model="diglog.tempContent">
                        </el-input>
                    </el-form-item>
                    <el-button class="btn" type="primary" icon="el-icon-view" @click="showView()" size="small">预览
                    </el-button>
                    <el-form-item label="短信预览">
                        <el-input type="textarea" :rows="4" resize="none" v-model="showViewCenter" size="small">
                        </el-input>
                    </el-form-item>
                </el-form>
                <el-form ref="form" :inline="true" :model="form" label-width="120px" v-if="diglog.tempType == 0">
                    <el-form-item label="自定义字段1">
                        <el-input type="text" style="width:150px" placeholder="请输入内容" v-model="diglog.field1"
                                  maxlength="10" show-word-limit>
                        </el-input>
                    </el-form-item>
                    <el-form-item label="自定义字段2">
                        <el-input style="width:150px" type="text" placeholder="请输入内容" v-model="diglog.field2"
                                  maxlength="10" show-word-limit>
                        </el-input>
                    </el-form-item>
                    <el-form-item label="自定义字段3">
                        <el-input style="width:150px" type="text" placeholder="请输入内容" v-model="diglog.field3"
                                  maxlength="10" show-word-limit>
                        </el-input>
                    </el-form-item>

                </el-form>
                <span style="margin:10px 10px 10px 50px;font-size:14px;color:#555">模板规则</span>
                <span style="font-size:15px;color:#555">{0} 学生姓名,{1} 学校名称,{2} 批次名称,{3} 年级名称,{4} 层次名称,{5} 专业名称, {6} 学号,{7} 密码,{8}
          自定义1,{9} 自定义2,{10} 自定义3
        </span>
            </div>
        </el-dialog>
    </div>
</template>
<script>
    import axios from "axios";

    export default {
        data() {
            return {
              rules: {
                tempName: [{required: true, message: '此项为必填项', trigger: 'blur'},],
                tempCode: [{required: true, message: '此项为必填项', trigger: 'blur'},],
                tempType: [{required: true, message: '此项为必填项', trigger: 'blur'},],
                tempContent: [{required: true, message: '此项为必填项', trigger: 'blur'},],
              },
                currentPage4: 1,
                total: 0,
                showViewCenter: "",
                tableData: [],
                dialogVisible1: false,
                dialogVisible2: false,
                smsModel: "",
                smsView: "",
                text: "",
              title: "",
                form: {},
                value: "",
                item: [],
                options: [
                    {
                        value: 0,
                        label: '招生模板'
                    }, {
                        value: 1,
                        label: '普通模板'
                    }, {
                        value: 2,
                        label: '验证码模板'
                    },
                ],
                putDialog: false,
                diglog: {
                    id: null,
                    tempCode: "",
                    tempType: 0,
                    tempContent: "",
                    tempName: "",
                    field1: "",
                    field2: "",
                    field3: ""
                },
                textarea: '',
                page: 1,
                pageSize: 10,
            }
        },
        created() {
            this.loginStar()
        },
        mounted() {
            this.getTableData();
        },
        methods: {
            updateOne(row) {
                this.diglog = row;
              this.title = '修改模板'
                this.putDialog = true;
            },
            add() {
                let url = "eduMsgTemp/insertSelective";
                if (this.diglog.id != null) {
                    url = "eduMsgTemp/updateMsgTempById";
                }
             
                if(this.diglog.tempName && this.diglog.tempCode&& this.diglog.tempType!=null && this.diglog.tempContent){
                  this.$axios_supermall.post("/hnjxjy-core/" + url, this.diglog).then(resp => {
                    if (resp.data.code === 2000) {
                      this.$message.success("操作成功");
                      this.putDialog = false;
                      this.getTableData();
                    }
                  });
                }else{
                  this.$message.error("必填项不能为空")
                }

            },
            //预览
            showView() {
                this.showViewCenter = this.$stringFormat(this.diglog.tempContent, ["张小明", "测试大学", "第三批次", "三年级", "第三层次", "软件工程专业", "200000", "1213486", this.diglog.field1, this.diglog.field2, this.diglog.field3]);
            },

            loginStar() {
                this.loginList = JSON.parse(localStorage.getItem("loginStrage"));
            },
            getTableData() {//查询

              this.getPermission('eduMsgTemp:queryAllMsgTemp', () => {
                this.openLoadingView();
                axios({
                  method: "GET",
                  url: "/hnjxjy-core/eduMsgTemp/queryAllMsgTemp?page=" + this.page + "&pageSize=" + this.pageSize,
                  headers: this.loginList,
                })
                    .then((res) => {
                      if (res.data.code == 2000) {
                        this.tableData = res.data.data.list;
                        this.total = res.data.data.total;
                      }
                      this.loadingView.close();
                    })
                    .catch(() => {
                      this.loadingView.close();
                    });
              });
            },
            del(id) { //删除
                this.$confirm("确认删除该项吗？", "提示", {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    axios({
                        method: "GET",
                        url: "/hnjxjy-core/eduMsgTemp/delMsgTempById?id=" + id,
                        headers: this.loginList,
                    })
                        .then((res) => {
                            if (res.data.code == 2000) {
                                this.getTableData();
                                this.$message.success("删除成功!")

                            } else {
                                this.$message.error('删除失败！')
                            }

                        })
                        .catch();
                }).catch(() => {
                    this.$message({
                        type: "info",
                        message: "已取消删除",
                    });
                });
                ;
            },
            enrollmentDiglog() {
                this.diglog = {
                    tempCode: "",
                    tempType: "",
                    tempContent: "",
                    tempName: "",
                    field1: "",
                    field2: "",
                    field3: ""
                };
                this.title = '新增模板'
                this.putDialog = true
            },
            enrollment() { //新增
                this.loading = true;
                axios({
                    method: "POST",
                    url: "/hnjxjy-core/eduMsgTemp/insertSelective",
                    headers: this.loginList,
                    data: {
                        tempName: this.diglog.tempName,
                        tempContent: this.diglog.tempContent,
                        field1: this.diglog.field1,
                        field2: this.diglog.field2,
                        field3: this.diglog.field3,
                        tempType: this.diglog.tempType,
                        tempCode: this.diglog.tempCode,
                    }
                })
                    .then((res) => {
                        if (res.data.code == 2000) {
                            this.tableData = res.data.data.list;

                        }
                        this.loading = false;
                    })
                    .catch();


            },

            formatType(data) {
                if (data.tempType == '0') {
                    return "招生模板";
                } else if (data.tempType == '1') {
                    return "普通模板";
                } else if (data.tempType == '2') {
                    return "验证码模板";
                }
            },
            handleSizeChange(val) {
                this.pageSize = val;
                this.getTableData();
            },
            handleCurrentChange(val) {
                this.page = val;
                this.getTableData();
            },
        }
    }
</script>
<style scoped>
    .right {
        float: right;
        width: 93%;
        display: inline-block;
        height: 870px;
        overflow-y: scroll;
        /* background-color: #fff; */
        background-color: rgba(164, 174, 224, 0.11);
    }

    .left {
        width: 7%;
        display: inline-block;
    }

    .yingyong {
        font-weight: bold;
        color: #555;
    }

    #aaaaa {
        color: lightskyblue;
    }

    .top_btn {
        margin: 10px 10px 10px 10px;
        text-align: left;
    }

    .search {
        margin: 20px 10px 30px 10px;
        text-align: left;
    }

    .main_contain {
        margin: 20px 10px 30px 10px;
        text-align: left;
    }

    .batch {
        margin-right: 100px;
        text-align: left;
    }

    .left {
        width: 7%;
        display: inline-block;
    }

    .add {
        text-align: left im !important;
    }

    .ye {
        background: #fff;


    }
</style>
