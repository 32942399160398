<template>
  <div>
    <div id="line" style="width:100%; height: 400px;"></div>

  </div>
</template>
<script>
var echarts = require("echarts");
export default {
  data() {
    return {
      reqData: {},
    };
  },
  methods: {
    //平台登录情况折线图
    queryLoginCountData() {
      this.$axios_supermall.get("/hnjxjy-core/eduIndex/platformLoginCountData").then(resp => {
        if (resp.data.code == 2000) {
          this.reqData = resp.data.data;

          // 基于准备好的dom，初始化echarts实例柱状图

          let mcharts = echarts.init(document.querySelector("#line"));
          let opt = {
            grid: {
              width: '60%',
              height: '60%',
              left: '20%',
              top: '20%',
            },
            title: {
              text: "平台登录信息", //主标题
              top: 20,
              textStyle: {
                color: "#2a2a2a", //主标题颜色
                fontSize: 24, //字体大小
              },
            }, //主标题
            legend: {
              data: ["学生登录数", "教师登录数"],
              bottom: 20,
            },
            xAxis: {
              type: "category", //类目组
              data: [

              ],
            },
            yAxis: {
              type: "value", //数值轴
            },
            series: [
              {
                name: "学生登录数", //名字
                type: "line", //线状图
                data: [],
                label: {
                  show: true, //是否显示标签
                  position: "top",
                }, //数值显示
                smooth: false, //线条平滑
                lineStyle: {
                  color: "green",
                  type: "solid",
                }, //线条颜色
                areaStyle: {
                  color: "green",
                }, //填充
              },
              {
                name: "教师登录数", //名字
                type: "line", //线状图
                data: [],
                label: {
                  show: true, //是否显示标签
                  position: "top",
                }, //数值显示
                smooth: false, //线条平滑
                lineStyle: {
                  color: "red",
                  type: "solid",
                }, //线条颜色
                areaStyle: {
                  color: "red",
                }, //填充
              },
            ],
          };
          this.reqData.teacherCountData.forEach(item =>{
            opt.xAxis.data.push(item.day);
            opt.series[0].data.push(item.count);
          });
          this.reqData.studentCountData.forEach(item =>{
            opt.series[1].data.push(item.count);
          });
          mcharts.setOption(opt);
        }
      });
    },
  },
  mounted() {
    this.queryLoginCountData();
  }
};
</script>