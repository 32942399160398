<template>
    <div style="text-align: center">
        <div>
            <div v-if="msg.bottomType==2">
                <!--                <span v-html="msg.bottomUrl"></span>-->
                {{msg.bottomUrl}}
            </div>
            <div v-if="msg.bottomType==0">
                <!--                <span v-html="msg.bottomUrl"></span>-->
                <el-image :src="msg.bottomUrl"></el-image>
            </div>
            <div v-if="msg.bottomType==1">
                <!--                <span v-html="msg.bottomUrl"></span>-->
                {{msg.bottomUrl}}
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "openFoot",
        data(){
            return{
                msg:{}
            }
        },
        mounted() {
            let msg = localStorage.getItem('footMessage');
            this.msg = JSON.parse(msg);
        }
    }
</script>

<style scoped>

</style>