<template>
    <div>
        <div>
            <div style="padding: 1rem; border-bottom: 1px solid #ddd; text-align: left">
                层次列表
            </div>
            <div class="search">
                <el-form :model="search" class="demo-ruleForm" inline>
                    <el-form-item style="margin-bottom: 0" v-permission="'eduStage:queryAllStageBySiteId'">
                        <el-input
                                v-model="search.name"
                                clearable
                                @input="() => (search.name = search.name.trim())"
                                placeholder="层次编号/层次名称"
                                style="width: 200px"><i slot="prefix" class="el-input__icon el-icon-search"></i>
                        </el-input>
                    </el-form-item>
                    <el-form-item style="margin-bottom: 0">
                        <el-button
                                icon="el-icon-search"
                                @click="handleSearch"
                                type="primary"
                                plain
                                v-permission="'eduStage:queryAllStageBySiteId'">
                            搜索
                        </el-button>
                        <el-button icon="el-icon-edit" type="primary" @click="addGrade"
                                   v-permission="'eduStage:addStageBySiteId'">
                            添加
                        </el-button>
                        <el-button icon="el-icon-download" type="primary" @click="download"
                                   v-permission="'eduStage:exportEduStageExcel'">
                            导出
                        </el-button>
                    </el-form-item>
                </el-form>
            </div>
            <div class="table-wrap">
                <el-table ref="multipleTable" :data="tableData" border
                          size="medium"
                          height="650px"
                          max-height="650px"
                          :header-cell-style="{'text-align':'center'}"
                          :cell-style="{'text-align':'center'}"
                >
                    <el-table-column label="序号" width="260" type="index" >
                    </el-table-column>
                    <el-table-column label="层次编号" prop="stageCode" sortable >
                    </el-table-column>
                    <el-table-column label="层次名称" prop="stageName" sortable >
                    </el-table-column>
                    <el-table-column label="类型" prop="stageTypes" sortable >
                    </el-table-column>
                    <el-table-column label="学习形式" prop="learnStyles" sortable >
                    </el-table-column>
                    <el-table-column label="层次" prop="stageLevels" sortable >
                    </el-table-column>
                    <el-table-column label="操作" width="180" fixed="right" >
                        <template slot-scope="scope">
                            <el-button @click="editor(scope.row)" type="primary" size="small"
                                       v-permission="'eduStage:showModifyEduStageById'">
                                编辑
                            </el-button>
                            <el-button
                                    @click="deleteGrade(scope.row)"
                                    type="danger"
                                    size="small"
                                    v-permission="'eduStage:deletedEduStageById'">
                                删除
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <div style="padding: 1rem" class="fenye">
                    <el-pagination
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                            :current-page="pageNum"
                            :page-sizes="[10, 20, 30, 40]"
                            :page-size="pageSize"
                            layout="total, sizes, prev, pager, next, jumper"
                            :total="total"
                    >
                    </el-pagination>
                </div>
            </div>
        </div>
        <!-- 创建/编辑 -->
        <el-dialog :title="title" :visible.sync="dialogVisible" width="30%">
            <el-form
                    :model="teacherForm"
                    :rules="teacherRules"
                    ref="ruleForm"
                    class="demo-ruleForm"
                    label-width="120px">
                <el-form-item label="层次名称：" prop="stageName">
                    <el-input
                            v-model="teacherForm.stageName"
                            placeholder="请输入层次名称"
                            class="el-form-input-width"
                            @input="() => (teacherForm.stageName = teacherForm.stageName.trim())">
                    </el-input>
                </el-form-item>
                <el-form-item label="层次编号：" prop="stageCode">
                    <el-input
                            v-model="teacherForm.stageCode"
                            placeholder="请输入层次编号"
                            class="el-form-input-width"
                            @input="() => (teacherForm.stageCode = teacherForm.stageCode.trim())">
                    </el-input>
                </el-form-item>
                <el-form-item label="类型：" prop="stageType">
                    <el-select
                            v-model="teacherForm.stageType"
                            placeholder="请选择类型"
                            class="el-form-input-width">
                        <el-option
                                v-for="item in stageType"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="学习形式：" prop="stageLevel">
                    <el-select
                            v-model="teacherForm.stageLevel"
                            placeholder="请选择学习形式"
                            class="el-form-input-width">
                        <el-option
                                v-for="item in stageLevel"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="层次：" prop="learnStyle">
                    <el-select
                            v-model="teacherForm.learnStyle"
                            placeholder="请选择层次"
                            class="el-form-input-width">
                        <el-option
                                v-for="item in learnStyle"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="层数描述：" prop="stageDesc">
                    <el-input
                            type="textarea"
                            :rows="2"
                            placeholder="请输入内容"
                            v-model="teacherForm.stageDesc">
                    </el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="Editbutton('ruleForm')" v-permission="'eduStage:modifyEduStageById'">
                    确 定
                </el-button>
            </div>
        </el-dialog>
        <!-- 添加 -->
        <el-dialog :title="title" :visible.sync="addlogVisible" width="30%">
            <el-form
                    :model="teacherForm"
                    :rules="teacherRules"
                    ref="ruleForm"
                    class="demo-ruleForm"
                    label-width="120px"
            >
                <el-form-item label="层次名称：" prop="stageName">
                    <el-input
                            v-model="teacherForm.stageName"
                            placeholder="请输入层次名称"
                            class="el-form-input-width"
                            @input="
              () => (teacherForm.stageName = teacherForm.stageName.trim())
            "
                    ></el-input>
                </el-form-item>
                <el-form-item label="层次编号：" prop="stageCode">
                    <el-input
                            v-model="teacherForm.stageCode"
                            placeholder="请输入层次编号"
                            class="el-form-input-width"
                            @input="
              () => (teacherForm.stageCode = teacherForm.stageCode.trim())
            "
                    ></el-input>
                </el-form-item>
                <el-form-item label="类型：" prop="stageType">
                    <el-select
                            v-model="teacherForm.stageType"
                            placeholder="请选择类型"
                            class="el-form-input-width"
                    >
                        <el-option
                                v-for="item in stageType"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="学习形式：" prop="stageLevel">
                    <el-select
                            v-model="teacherForm.stageLevel"
                            placeholder="请选择学习形式"
                            class="el-form-input-width"
                    >
                        <el-option
                                v-for="item in stageLevel"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="层次：" prop="learnStyle">
                    <el-select
                            v-model="teacherForm.learnStyle"
                            placeholder="请选择层次"
                            class="el-form-input-width"
                    >
                        <el-option
                                v-for="item in learnStyle"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="层数描述：" prop="stageDesc">
                    <el-input
                            type="textarea"
                            :rows="2"
                            placeholder="请输入内容"
                            v-model="teacherForm.stageDesc"
                    >
                    </el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
        <el-button @click="addlogVisible = false">取 消</el-button>
        <el-button type="primary" @click="handleSave('ruleForm')"
        >确 定</el-button
        >
      </span>
        </el-dialog>
    </div>
</template>
<script>
    export default {
        created() {
            this.listrendering();
        },
        data() {
            return {
                total: 0,
                pageNum: 1,
                pageSize: 10,
                textarea: "",
                title: "",
                search: {
                    name: "",
                },
                teacherForm: {
                    stageCode: "",
                    stageName: "",
                    stageType: 1,
                    stageLevel: 2,
                    learnStyle: 3,
                    stageDesc: "",
                },
                tableChecked: [],
                tableData: [], //列表信息
                // 请选择类型
                stageType: [
                    {
                        value: 0,
                        label: "专科",
                    },
                    {
                        value: 1,
                        label: "本科",
                    },
                ],
                //请选择学习层次
                stageLevel: [
                    {
                        value: 0,
                        label: "高起本",
                    },
                    {
                        value: 1,
                        label: "专升本",
                    },
                    {
                        value: 2,
                        label: "本科",
                    },
                ],
                // 学习形式
                learnStyle: [
                    {
                        value: 0,
                        label: "函授 ",
                    },
                    {
                        value: 1,
                        label: "业余",
                    },
                    {
                        value: 2,
                        label: "夜大",
                    },
                    {
                        value: 3,
                        label: "脱产",
                    },
                ],
                dialogVisible: false,
                dialogVisibleUpload: false,
                addlogVisible: false,
                teacherRules: {
                    name: [{required: true, message: "请输入姓名", trigger: "blur"}],
                },
            };
        },
        computed: {},
        methods: {
            // 编辑按钮
            Editbutton(row) {
                let objPar = JSON.parse(localStorage.getItem("loginStrage"));
                this.$axios({
                    method: "POST",
                    url: "hnjxjy-core/eduStage/modifyEduStageById",
                    params: {
                        id: this.teacherForm.id,
                    },
                    data: this.teacherForm,
                    headers: objPar,
                })
                    .then((res) => {
                        if (res.data.code != 2000) {
                            // this.$message({
                            //     type: "warning",
                            //     message: res.data.message,
                            // });
                            return;
                        }
                        this.$message({
                            type: "success",
                            message: res.data.message,
                        });
                        this.listrendering();
                        this.dialogVisible = false;
                    })
                    .catch((err) => {
                    });
            },
            // 添加按钮
            handleSave() {
                let objPar = JSON.parse(localStorage.getItem("loginStrage"));
                this.$axios({
                    method: "POST",
                    url: "hnjxjy-core/eduStage/addStageBySiteId",
                    data: this.teacherForm,
                    headers: objPar,
                })
                    .then((res) => {
                        if (res.data.code != 2000) {
                            // this.$message({
                            //     type: "warning",
                            //     message: res.data.message,
                            // });
                            return;
                        }
                        this.$message({
                            type: "success",
                            message: res.data.message,
                        });
                        this.listrendering();
                        this.addlogVisible = false;
                    })
                    .catch((err) => {
                    });
            },
            // 列表渲染
            listrendering() {
                this.getPermission('eduStage:queryAllStageBySiteId', () => {
                    this.openLoadingView();
                    this.$axios_supermall({
                        method: "get",
                        url: "hnjxjy-core/eduStage/queryAllStageBySiteId",
                        params: {
                            stageNameAndCode: this.search.name,
                            page: this.pageNum,
                            pageSize: this.pageSize,
                        }
                    }).then((res) => {
                        this.tableData = res.data.data.list;
                        this.total = res.data.data.total; //总数据条数
                        this.pageNum = res.data.data.pageNum; //当前页
                        this.pageSize = res.data.data.pageSize;
                    }).catch((err) => {
                    });
                });
            },
            // 查询
            handleSearch() {
                this.listrendering();
            },
            // 添加按钮
            addGrade() {
                this.title = "创建层次列表";
              this.teacherForm= {
                stageCode: "",
                    stageName: "",
                    stageType: "",
                    stageLevel: "",
                    learnStyle: "",
                    stageDesc: "",
              }
                this.addlogVisible = true;
            },
            // 点击导入
            upload() {
                this.dialogVisibleUpload = true;
            },
            // 导入上传文件
            uploadFile() {
            },
            // 导入上传文件前判断文件格式
            beforeUpload(file) {
                const isXls = file.type === "application/vnd.ms-excel";
                const isXlsx =
                    file.type ===
                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
                if (!isXls && !isXlsx) {
                    this.$message.error("上传文件只能是XLS/XLSX格式！");
                    return false;
                }
                return isXls || isXlsx;
            },
            // 点击导出
            download() {
              let objPar = JSON.parse(localStorage.getItem("loginStrage"));
              this.openLoadingView();
              this.$axios({
                method: "get",
                url: "hnjxjy-core/eduStage/exportEduStageExcel",
                responseType: "blob",
                headers: objPar,
              }).then((res) => {
                let blob = new Blob([res.data], {
                  type: "application/vnd.ms-excel",
                });
                let url = window.URL.createObjectURL(blob);
                window.location.href = url;
                this.$message.success("操作成功");
                this.loadingView.close();
              }).catch((err) => {
                this.$message.error("操作失败");
                this.loadingView.close();
              });
            },
            // 点击编辑修改前
            editor(row) {
                var num = row.id.toString();
                this.title = "修改层次列表";
                this.dialogVisible = true;
                let objPar = JSON.parse(localStorage.getItem("loginStrage"));
                this.$axios({
                    method: "GET",
                    url: "hnjxjy-core/eduStage/showModifyEduStageById",
                    params: {
                        id: num,
                    },
                    headers: objPar,
                })
                    .then((res) => {
                        this.teacherForm = res.data.data;
                    })
                    .catch((err) => {
                    });
            },
            // 点击删除
            deleteGrade(row) {
                let objPar = JSON.parse(localStorage.getItem("loginStrage"));
                this.$confirm("确定要删除该层次数据?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                })
                    .then(() => {
                        this.$axios({
                            method: "GET",
                            url: "hnjxjy-core/eduStage/deletedEduStageById",
                            params: {
                                id: row.id,
                            },
                            headers: objPar,
                        })
                            .then((res) => {
                                if (res.data.code != 2000) {
                                    // this.$message({
                                    //     type: "warning",
                                    //     message: res.data.message,
                                    // });
                                    return;
                                }
                                this.$message.success("操作成功");
                                this.listrendering();
                            })
                            .catch((err) => {
                            });
                    })
                    .catch(() => {
                        this.$message({
                            type: "info",
                            message: "已取消删除",
                        });
                    });
            },
            handleSizeChange(val) {
                this.pageSize = val;
                this.handleSearch();
            },
            handleCurrentChange(val) {
                this.pageNum = val;
                this.handleSearch();
            },
        },
    };
</script>
<style scoped>
    .search {
        text-align: left;
        margin-top: 1rem;
        /* margin-left: 1rem; */
        padding: 1rem;
    }

    .table-wrap {
        overflow: auto;
        /* margin-left: 1rem; */
        /* margin-bottom: 1rem; */
        padding: 1rem;
    }

    .demo-ruleForm {
        /* width: 90%; */
        height: calc(100% - 125px);
    }

    .el-form-input-width {
        width: 100%;
    }

    .rigth {
        width: 93%;
        display: inline-block;
        height: 870px;
        overflow-y: scroll;
        /* background-color: #fff; */
        background-color: rgba(164, 174, 224, 0.11);
        float: right;
    }

    .left {
        width: 7%;
        display: inline-block;
    }

    @media (max-width: 750px) {
        .rigth {
            width: 100%;
        }

        .left {
            display: none;
        }

        .el-form-item {
            margin-top: 20px;
        }

        .fenye {
            box-sizing: border-box;
            padding: 1rem;
            overflow-x: scroll;
        }
    }
</style>
