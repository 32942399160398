<template>
    <div>
        <div>
            <div style="padding:1rem;border-bottom: 1px solid #ddd;text-align:left">
                招生批次-当前批次
            </div>
            <div class="public-global-search">

            </div>
            <div class="public-global-main_contain">
                <el-card class="box-card">
                    <el-form label-width="200px">
                        <el-form-item label="批次代码">
                            {{messageInfo.batchCode}}
                        </el-form-item>
                        <el-form-item label="批次名称">
                            {{messageInfo.batchName}}
                        </el-form-item>
                        <el-form-item label="报名开始日期">
                            {{messageInfo.batchStartTime}}
                        </el-form-item>
                        <el-form-item label="报名结束日期">
                            {{messageInfo.batchEndTime}}
                        </el-form-item>
                        <el-form-item label="注册时间">
                            {{messageInfo.registerTime}}
                        </el-form-item>
                        <el-form-item label="报名须知">
                            {{messageInfo.registerNotes}}
                        </el-form-item>
                        <el-form-item label="招生简章">
                            {{messageInfo.enrolGuide}}
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" @click="beforeQuery">查看批次相关专业</el-button>
                        </el-form-item>
                    </el-form>
                </el-card>
            </div>
        </div>
        <el-dialog
                title="相关专业"
                :visible.sync="dialogVisible"
                width="40%">
            <div>
                <el-table
                        :data="tableData"
                        border
                        style="width: 100%">
                    <el-table-column
                        type="index"
                            label="序号">
                    </el-table-column>
                    <el-table-column
                            prop="profCode"
                            label="编号">
                    </el-table-column>
                    <el-table-column
                            prop="profName"
                            label="专业名称">
                    </el-table-column>
                </el-table>
                <el-pagination
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :current-page.sync="queryBO.page"
                        :page-size="queryBO.pageSize"
                        :page-sizes="[10,20,50,100]"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="total">
                </el-pagination>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        name: "currentBatch",
        data() {
            return {
                messageInfo: {
                  batchCode: "",
                  batchName: "",
                  batchStartTime: "",
                  batchEndTime: "",
                  registerTime: "",
                  registerNotes: "",
                  enrolGuide: "",
                },
                dialogVisible: false,
                queryBO: {
                    page: 1,
                    pageSize: 10
                },
                total: 0,
                tableData: []
            }
        },
        methods: {
            beforeQuery() {
                let bo = 'batchId=' + this.messageInfo.id + '&page=' + this.queryBO.page + '&pageSize=' + this.queryBO.pageSize;
                this.openLoadingView();
                this.$axios_supermall.get('/hnjxjy-core/eduProfession/queryAllProfessionByBachId?' + bo).then(response => {
                    if (response.data.code === 2000) {
                        this.tableData = response.data.data.list;
                        this.total = response.data.data.total;
                        this.dialogVisible = true;
                    }else {
                      this.$message.error(this.data.message)
                    }
                })
            },
            queryBasic() {
                this.openLoadingView();
                this.$axios_supermall.get('/hnjxjy-core/eduEnrolBatch/querySiteBatchEnro').then(response => {
                    if (response.data.code === 2000) {
                      if (response.data.data.code === 2000){
                        this.messageInfo = response.data.data.data;
                      }else {
                        this.$message.error(response.data.data.message)
                      }
                    }else {
                      this.$message.error(response.data.message)
                    }
                    this.loadingView.close();
                }).catch((err) => {
                  this.loadingView.close();
                })
            },
            handleSizeChange(val){
                this.queryBO.pageSize = val;
                this.beforeQuery();
            },
            handleCurrentChange(val){
                this.queryBO.page = val;
                this.beforeQuery();
            }
        },
        mounted() {
            this.queryBasic();
        }
    }
</script>

<style scoped>
    .table-wrap {
        overflow: auto;
        /* margin-left: 1rem; */
        /* margin-bottom: 1rem; */
        padding: 1rem;
    }

    .el-form-input-width {
        width: 100%;
    }

    ::v-deep .el-form-item__content {
        text-align: left;
    }

    .table_header .el-checkbox {
        display: none !important
    }
</style>
