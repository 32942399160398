<template>
    <div id="histogram" style="width: 100%; height:400px;background: #fff;"></div>
</template>
<script>
    export default {
        data() {
            return {}
        },
        methods: {},
        created() {
            // 基于准备好的dom，初始化echarts实例柱状图
            let opt = {
                title: {
                    text: "学生人数统计", //主标题
                    textStyle: {
                        color: "#2a2a2a", //主标题颜色
                        fontSize: 24, //字体大小

                    },

                }, //主标题
                tooltip: {
                    trigger: "item",
                    trggerOn: "mouseover",
                    formatter: "{b}的人数为{c}",
                }, //提示框
                legend: {
                    data: ["高起专", "专升本", "高起本", "总人数"],
                    right: "0",
                },
                xAxis: {
                    type: "category", //类目组
                    data: [],
                    axisLabel:{ //横向坐标值全部显示
                      interval: 0
                    }
                },
                yAxis: {
                    type: "value", //数值轴
                },
                series: [
                    {
                        name: "高起专", //名字
                        type: "bar", //柱状图
                        data: [],
                        label: {
                            show: true, //是否显示标签
                            position: "top",
                        }, //数值显示
                    },
                    {
                        name: "专升本", //名字
                        type: "bar", //柱状图
                        data: [],
                        label: {
                            show: true, //是否显示标签
                            position: "top",
                        }, //数值显示
                    },
                    {
                        name: "高起本", //名字
                        type: "bar", //柱状图
                        data: [],
                        label: {
                            show: true, //是否显示标签
                            position: "top",
                        }, //数值显示
                    },
                    {
                      name: "总人数", //名字
                      type: "bar", // 柱状图
                      data: [],
                      label: {
                      show: true, //是否显示标签
                      position: "top",
                      }, //数值显示
                    },
                ],
            };
            this.$axios_supermallData.get("/hnjxjy-core/eduIndex/studentCountData").then(resp => {
                if (resp.data.code == 2000) {
                    let reqData = resp.data.data;
                    let mcharts = this.$echarts.init(document.querySelector("#histogram"));
                    for (let i = 0; i < reqData.length; i++) {
                        opt.xAxis.data[i] = reqData[i].gradeName;
                        opt.series[0].data[i] = reqData[i].gqz;
                        opt.series[1].data[i] = reqData[i].zsb;
                        opt.series[2].data[i] = reqData[i].gqb;
                        opt.series[3].data[i] = reqData[i].zrs;
                    }
                    mcharts.setOption(opt);
                }
            });

        },
    }
</script>
