<template>
    <div id="contain">
        <div>
          <div
              style="padding: 1rem; border-bottom: 1px solid #ddd; text-align: left"
          >
            通知公告
          </div>
            <!-- 添加 删除 置顶 取消置顶按钮 -->
            <div class="top_btn">
                <el-button type="primary" icon="el-icon-circle-plus-outline" @click="addDialogVisible()">添加
                </el-button>
                <el-button type="primary" icon="el-icon-delete" @click="del">删除</el-button>
                <el-button type="primary" icon="el-icon-check" @click="top(1)">置顶</el-button>
                <el-button type="primary" icon="el-icon-close" @click="top(0)">取消置顶</el-button>
            </div>
            <!-- 共选中 0 条数据 -->
            <div class="main_contain">
                <span id="aaaaa">共选中 {{selectDataCount}} 条数据</span>
                <!-- 表格 -->
                <el-table :data="tableData" :default-sort="{prop:'topFlag',order:'descending'}" border height="680px" max-height="680px" :header-cell-style="{'text-align':'center'}"
                          :cell-style="{'text-align':'center'}" @selection-change="handleSelectionChange" size="medium"
                          style="width: 100%">
                    <el-table-column type="selection" width="55">
                    </el-table-column>
                    <el-table-column label="查看" width="180">
                        <template slot-scope="scope">
                            <el-button @click="showRow(scope.row, contentDetils)" type="text" size="small">
                                内容
                            </el-button>
                        </template>
                    </el-table-column>
                    <el-table-column label="阅读情况" width="180">
                        <template slot-scope="scope">
                            <el-button @click="show(scope.row.id)" type="text" size="small">
                                阅读情况
                            </el-button>
                        </template>
                    </el-table-column>
                    <el-table-column prop="topFlag" :formatter="topFormart"  sortable label="置顶">
                    </el-table-column>
                    <el-table-column prop="publishObjs" sortable label="发布对象">
                    </el-table-column>
                    <el-table-column prop="title" sortable label="标题">
                    </el-table-column>
                    <el-table-column prop="createTime" sortable label="发布时间">
                    </el-table-column>
                    <el-table-column prop="siteName" sortable label="发布人">
                    </el-table-column>
                    <!-- 条页 -->
                </el-table>
                <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                               :current-page="page.pageNo" :page-sizes="[10, 20, 50, 100]" :page-size="page.pageSize"
                               background :total="page.total"
                               layout="total, sizes, prev, pager, next, jumper">
                </el-pagination>
            </div>

            <!-- region 新增  -->
            <el-dialog class="add" title="新增" :visible.sync="dialogVisible" width="1000px">
                <el-button type="primary" icon="el-icon-circle-close" @click="dialogVisible = false">关闭</el-button>
                <el-button type="primary" icon="el-icon-view" @click="preview">预览</el-button>
                <el-button type="primary" icon="el-icon-folder-add" @click="add">保存后关闭</el-button>
                <div>
                    <el-form ref="form" :model="form" label-width="80px" style="margin-top: 15px">
                        <el-form-item label="是否置顶">
                            <el-checkbox v-model="insertNoticeBO.topFlag"></el-checkbox>
                        </el-form-item>
                        <el-form-item label="发布对象">
                            <el-select v-model="insertNoticeBO.publishObj" placeholder="请选择">
                                <el-option v-for="item in options2" :key="item.value" :label="item.label"
                                           :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item>
                          <div v-if="tableData2.length > 0" style="display:inline-block;margin-right: 10px"><el-button size="mini"  type="success" icon="el-icon-check" circle></el-button></div>
                            <el-button size="big" type="primary" @click="dialogVisible2 = true">设定函数站范围</el-button>
                          <div v-if="tableData4.length > 0" style="display:inline-block;margin-right: 10px; margin-left: 10px"><el-button size="mini"  type="success" icon="el-icon-check" circle></el-button></div>
                            <el-button size="big" type="primary" @click="dialogVisible4 = true">设定年级范围</el-button>
                          <div v-if="tableData6.length > 0" style="display:inline-block;margin-right: 10px; margin-left: 10px"><el-button size="mini"  type="success" icon="el-icon-check" circle></el-button></div>
                            <el-button size="big" type="primary" @click="dialogVisible6 = true">设定层次范围</el-button>
                          <div v-if="tableData8.length > 0" style="display:inline-block;margin-right: 10px; margin-left: 10px"><el-button size="mini"  type="success" icon="el-icon-check" circle></el-button></div>
                            <el-button size="big" type="primary" @click="dialogVisible8 = true">设定专业范围</el-button>
                        </el-form-item>
                        <el-form-item label="标题">
                            <el-input v-model="insertNoticeBO.title" placeholder="请输入内容"></el-input>
                        </el-form-item>
                        <el-form-item label="内容">
                            <editoritem ref="editoritem" style="height: 300px" v-model="insertNoticeBO.content"></editoritem>
                            <!--  <quill-editor class="editor" v-model="content"   ref="myQuillEditor"
                              @blur="onEditorBlur($event)" @focus="onEditorFocus($event)" @change="onEditorChange($event)">
                                      </quill-editor> -->
                        </el-form-item>
                      <div style="height: 100px"></div>
                    </el-form>
                </div>
            </el-dialog>
            <!--            endregion-->
            <!--          region 选择函授站-->
            <el-dialog
                    title="选择函授站"
                    :visible.sync="dialogVisible2"
                    width="50%">
                <div>
                    <el-form inline label-width="80px">
                        <el-form-item>
                            <el-button type="primary" icon="el-icon-circle-close" @click="dialogVisible2 = false">关闭
                            </el-button>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" icon="el-icon-delete" @click="deleteTableData2">删除</el-button>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" icon="el-icon-circle-plus-outline"
                                       @click="dialogVisible3True()">
                                添加
                            </el-button>
                        </el-form-item>
                    </el-form>
                    <el-table
                            @selection-change="handleSelectionChange2"
                            :data="tableData2"
                            style="width: 100%"
                            :header-cell-style="{'text-align':'center'}"
                            :cell-style="{'text-align':'center'}"
                            size="medium"
                    >
                        <el-table-column type="selection"></el-table-column>
                        <el-table-column
                                prop="siteName"
                                label="名称">
                        </el-table-column>
                        <el-table-column
                                prop="siteCode"
                                label="编码">
                        </el-table-column>
                    </el-table>
                </div>
            </el-dialog>
            <!--          endregion -->
            <!--          region 选择函授站 添加-->
            <el-dialog
                    title="选择函授站"
                    :visible.sync="dialogVisible3"
                    width="50%">
                <div>
                    <el-form inline label-width="80px">
                        <el-form-item>
                            <el-button type="primary" icon="el-icon-circle-close" @click="dialogVisible3=false">关闭
                            </el-button>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" icon="el-icon-circle-plus-outline" @click="addAndClose1">保存后关闭
                            </el-button>
                        </el-form-item>
                        <br/>
                        <el-form-item inline="false">
                            <el-input placeholder="名称" v-model="page2.info" class="input-with-select"
                                      style="width: 400px">
                                <el-button slot="append" icon="el-icon-search"
                                           @click="queryAllSiteByUserId"></el-button>
                            </el-input>
                        </el-form-item>
                    </el-form>
                    <el-table
                            @selection-change="handleSelectionChange3"
                            :data="tableData3"
                            style="width: 100%"
                            :header-cell-style="{'text-align':'center'}"
                            :cell-style="{'text-align':'center'}"
                            size="medium"
                    >
                        <el-table-column type="selection"></el-table-column>
                        <el-table-column
                                prop="siteName"
                                label="名称">
                        </el-table-column>
                        <el-table-column
                                prop="siteCode"
                                label="编码">
                        </el-table-column>
                    </el-table>
                    <el-pagination @size-change="handleSizeChange2" @current-change="handleCurrentChange2"
                                   :current-page="page2.pageNo" :page-sizes="[20, 50, 100, 500]"
                                   :page-size="page2.pageSize"
                                   background :total="page2.total"
                                   layout="total, sizes, prev, pager, next, jumper">
                    </el-pagination>
                </div>
            </el-dialog>
            <!--          endregion-->
            <!--          region 选择年级-->
            <el-dialog
                    title="选择年级"
                    :visible.sync="dialogVisible4"
                    width="50%">
                <div>
                    <el-form inline label-width="80px">
                        <el-form-item>
                            <el-button type="primary" icon="el-icon-circle-close" @click="dialogVisible4 = false">关闭
                            </el-button>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" icon="el-icon-delete" @click="deleteTableData4">删除</el-button>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" icon="el-icon-circle-plus-outline"
                                       @click="dialogVisible5True()">
                                添加
                            </el-button>
                        </el-form-item>
                    </el-form>
                    <el-table
                            @selection-change="handleSelectionChange4"
                            :data="tableData4"
                            border
                            style="width: 100%"
                            :header-cell-style="{'text-align':'center'}"
                            :cell-style="{'text-align':'center'}"
                            size="medium"
                    >
                        <el-table-column type="selection"></el-table-column>
                        <el-table-column
                                prop="gradeName"
                                label="名称">
                        </el-table-column>
                        <el-table-column
                                prop="gradeCode"
                                label="编码">
                        </el-table-column>
                    </el-table>
                </div>
            </el-dialog>
            <!--          endregion -->
            <!--          region 选择年级 添加-->
            <el-dialog
                    title="选择年级"
                    :visible.sync="dialogVisible5"
                    width="50%">
                <div>
                    <el-form inline label-width="80px">
                        <el-form-item>
                            <el-button type="primary" icon="el-icon-circle-close" @click="dialogVisible5=false">关闭
                            </el-button>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" icon="el-icon-circle-plus-outline" @click="addAndClose5">保存后关闭
                            </el-button>
                        </el-form-item>
                        <br/>
                        <el-form-item inline="false">
                            <el-input placeholder="名称" v-model="page3.info" class="input-with-select"
                                      style="width: 400px">
                                <el-button slot="append" icon="el-icon-search"
                                           @click="queryAllEduGradeByCodeAndName"></el-button>
                            </el-input>
                        </el-form-item>
                    </el-form>
                    <el-table
                            @selection-change="handleSelectionChange5"
                            :data="tableData5"
                            style="width: 100%"
                            :header-cell-style="{'text-align':'center'}"
                            :cell-style="{'text-align':'center'}"
                            size="medium"
                    >
                        <el-table-column type="selection"></el-table-column>
                        <el-table-column
                                prop="gradeName"
                                label="名称">
                        </el-table-column>
                        <el-table-column
                                prop="gradeCode"
                                label="编码">
                        </el-table-column>
                    </el-table>
                    <el-pagination @size-change="handleSizeChange3" @current-change="handleCurrentChange3"
                                   :current-page="page3.pageNo" :page-sizes="[20, 50, 100, 500]"
                                   :page-size="page3.pageSize"
                                   background :total="page3.total"
                                   layout="total, sizes, prev, pager, next, jumper">
                    </el-pagination>
                </div>
            </el-dialog>
            <!--          endregion-->
            <!--          region 选择层次-->
            <el-dialog
                    title="选择层次"
                    :visible.sync="dialogVisible6"
                    width="50%">
                <div>
                    <el-form inline label-width="80px">
                        <el-form-item>
                            <el-button type="primary" icon="el-icon-circle-close" @click="dialogVisible6 = false">关闭
                            </el-button>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" icon="el-icon-delete" @click="deleteTableData6">删除</el-button>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" icon="el-icon-circle-plus-outline"
                                       @click="dialogVisible7True()">
                                添加
                            </el-button>
                        </el-form-item>
                    </el-form>
                    <el-table
                            @selection-change="handleSelectionChange6"
                            :data="tableData6"
                            border
                            style="width: 100%"
                            :header-cell-style="{'text-align':'center'}"
                            :cell-style="{'text-align':'center'}"
                            size="medium"
                    >
                        <el-table-column type="selection"></el-table-column>
                        <el-table-column
                                prop="stageName"
                                label="名称">
                        </el-table-column>
                        <el-table-column
                                prop="stageCode"
                                label="编码">
                        </el-table-column>
                    </el-table>
                </div>
            </el-dialog>
            <!--          endregion -->
            <!--          region 选择层次 添加-->
            <el-dialog
                    title="选择层次"
                    :visible.sync="dialogVisible7"
                    width="50%">
                <div>
                    <el-form inline label-width="80px">
                        <el-form-item>
                            <el-button type="primary" icon="el-icon-circle-close" @click="dialogVisible7=false">关闭
                            </el-button>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" icon="el-icon-circle-plus-outline" @click="addAndClose6">保存后关闭
                            </el-button>
                        </el-form-item>
                        <br/>
                        <el-form-item inline="false">
                            <el-input placeholder="名称" v-model="page4.info" class="input-with-select"
                                      style="width: 400px">
                                <el-button slot="append" icon="el-icon-search"
                                           @click="queryAllStageBySiteId"></el-button>
                            </el-input>
                        </el-form-item>
                    </el-form>
                    <el-table
                            @selection-change="handleSelectionChange7"
                            :data="tableData7"
                            style="width: 100%"
                            :header-cell-style="{'text-align':'center'}"
                            :cell-style="{'text-align':'center'}"
                            size="medium"
                    >
                        <el-table-column type="selection"></el-table-column>
                        <el-table-column
                                prop="stageName"
                                label="名称">
                        </el-table-column>
                        <el-table-column
                                prop="stageCode"
                                label="编码">
                        </el-table-column>
                    </el-table>
                    <el-pagination @size-change="handleSizeChange4" @current-change="handleCurrentChange4"
                                   :current-page="page4.pageNo" :page-sizes="[20, 50, 100, 500]"
                                   :page-size="page4.pageSize"
                                   background :total="page4.total"
                                   layout="total, sizes, prev, pager, next, jumper">
                    </el-pagination>
                </div>
            </el-dialog>
            <!--          endregion-->
            <!--          region 选择专业-->
            <el-dialog
                    title="选择专业"
                    :visible.sync="dialogVisible8"
                    width="50%">
                <div>
                    <el-form inline label-width="80px">
                        <el-form-item>
                            <el-button type="primary" icon="el-icon-circle-close" @click="dialogVisible8 = false">关闭
                            </el-button>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" icon="el-icon-delete" @click="deleteTableData8">删除</el-button>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" icon="el-icon-circle-plus-outline"
                                       @click="dialogVisible9True()">
                                添加
                            </el-button>
                        </el-form-item>
                    </el-form>
                    <el-table
                            @selection-change="handleSelectionChange8"
                            :data="tableData8"
                            border
                            style="width: 100%"
                            :header-cell-style="{'text-align':'center'}"
                            :cell-style="{'text-align':'center'}"
                            size="medium"
                    >
                        <el-table-column type="selection"></el-table-column>
                        <el-table-column
                                prop="profName"
                                label="名称">
                        </el-table-column>
                        <el-table-column
                                prop="profCode"
                                label="编码">
                        </el-table-column>
                    </el-table>
                </div>
            </el-dialog>
            <!--          endregion -->
            <!--          region 选择专业 添加-->
            <el-dialog
                    title="选择专业"
                    :visible.sync="dialogVisible9"
                    width="50%">
                <div>
                    <el-form inline label-width="80px">
                        <el-form-item>
                            <el-button type="primary" icon="el-icon-circle-close" @click="dialogVisible9=false">关闭
                            </el-button>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" icon="el-icon-circle-plus-outline" @click="addAndClose9">保存后关闭
                            </el-button>
                        </el-form-item>
                        <br/>
                        <el-form-item inline="false">
                            <el-input placeholder="名称" v-model="page5.info" class="input-with-select"
                                      style="width: 400px">
                                <el-button slot="append" icon="el-icon-search"
                                           @click="queryAllProfessionBySiteId"></el-button>
                            </el-input>
                        </el-form-item>
                    </el-form>
                    <el-table
                            @selection-change="handleSelectionChange9"
                            :data="tableData9"
                            style="width: 100%"
                            :header-cell-style="{'text-align':'center'}"
                            :cell-style="{'text-align':'center'}"
                            size="medium"
                    >
                        <el-table-column type="selection"></el-table-column>
                        <el-table-column
                                prop="profName"
                                label="名称">
                        </el-table-column>
                        <el-table-column
                                prop="profCode"
                                label="编码">
                        </el-table-column>
                    </el-table>
                    <el-pagination @size-change="handleSizeChange5" @current-change="handleCurrentChange5"
                                   :current-page="page5.pageNo" :page-sizes="[20, 50, 100, 500]"
                                   :page-size="page5.pageSize"
                                   background :total="page5.total"
                                   layout="total, sizes, prev, pager, next, jumper">
                    </el-pagination>
                </div>
            </el-dialog>
            <!--          endregion-->
            <!--            region 预览-->
            <el-dialog
                    title="预览"
                    :visible.sync="dialogVisible10"
                    width="30%">
                <div>
                    <el-form label-width="80px">
                        <el-form-item label="标题">
                            {{insertNoticeBO.title}}
                        </el-form-item>
                        <el-form-item>
                            <div v-html="insertNoticeBO.content"></div>
                        </el-form-item>
                    </el-form>
                </div>
            </el-dialog>
            <!--            endregion-->
            <!--            region 内容-->
            <el-dialog
                    title="内容"
                    :visible.sync="dialogVisible11"
                    width="50%">
                <div>
                    <el-form label-width="80px">
                        <el-form-item label="标题">
                            {{content.title}}
                        </el-form-item>
                        <el-form-item>
                            <div v-html="content.contentDetils"></div>
                        </el-form-item>
                    </el-form>
                </div>
            </el-dialog>
            <!--            endregion-->
            <!--          region 阅读情况-->
            <el-dialog
                    title="阅读情况"
                    :visible.sync="dialogVisible12"
                    width="50%">
                <div>
                    <el-form inline label-width="80px">
                        <el-form-item>
                            <el-button type="primary" icon="el-icon-circle-close" @click="dialogVisible12=false">关闭
                            </el-button>
                        </el-form-item>
                        <br/>
                        <el-form-item inline="false">
                            <el-input placeholder="名称" v-model="page6.NameOrAcc" class="input-with-select"
                                      style="width: 400px">
                                <el-button slot="append" icon="el-icon-search"
                                           @click="queryEduRecordAll()"></el-button>
                            </el-input>
                        </el-form-item>
                        <el-form-item inline="false">
                            <el-select v-model="page6.status" placeholder="阅读情况" @change="queryEduRecordAll()">
                                <el-option
                                        v-for="item in optionsList"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-form>
                    <el-table
                            @selection-change="handleSelectionChange6"
                            :data="tableData10"
                            style="width: 100%"
                            :header-cell-style="{'text-align':'center'}"
                            :cell-style="{'text-align':'center'}"
                            size="medium"
                    >
                        <el-table-column type="selection"></el-table-column>
                        <el-table-column
                                label="名称">

                          <template slot-scope="scope">
                            <span v-if="scope.row.studentName != null">{{scope.row.studentName}}</span>
                            <span v-else-if="scope.row.teacherName!=null">{{scope.row.teacherName}}</span>
                            <span v-else-if="scope.row.siteName != null">{{scope.row.siteName}}</span>
                          </template>

                        </el-table-column>
                        <el-table-column
                                label="账号">
                          <template slot-scope="scope">
                            <span v-if="scope.row.loginAccountStu != null">{{scope.row.loginAccountStu}}</span>
                            <span v-else-if="scope.row.loginAccountTea != null">{{scope.row.loginAccountTea}}</span>
                            <span v-else-if="scope.row.loginAccountSite != null">{{scope.row.loginAccountSite}}</span>
                          </template>
                        </el-table-column>
                        <el-table-column
                                prop="statuss"
                                label="是否已读">
                        </el-table-column>
                        <el-table-column
                                prop="updateTime"
                                label="阅读时间">
                        </el-table-column>
                    </el-table>
                    <el-pagination @size-change="handleSizeChange6" @current-change="handleCurrentChange6"
                                   :current-page="page6.pageNo" :page-sizes="[20, 50, 100, 500]"
                                   :page-size="page6.pageSize"
                                   background :total="page6.total"
                                   layout="total, sizes, prev, pager, next, jumper">
                    </el-pagination>
                </div>
            </el-dialog>
            <!--          endregion-->

        </div>
    </div>
</template>
<script>
    import axios from "axios";
    import Editoritem from "../../../components/wangEnduit/editoritem";

    export default {
        components: {
            Editoritem
        },
        data() {
            return {
                options: [],
                options2: [
                    {label: '总校教师', value: 0},
                    {label: '总校管理员', value: 1},
                    {label: '总校教师管理员', value: 2},
                    {label: '函授站教师', value: 3},
                    {label: '函授站账号', value: 4},
                    {label: '学生', value: 5}
                ],
                tableData: [],
                contentDetils: "",
                readDetils: "",
                dialogVisible: false,
                dialogVisible2: false,//选择函授站
                dialogVisible3: false,//选择函授站 添加
                dialogVisible4: false,//选择年级
                dialogVisible5: false,//选择年级 添加
                dialogVisible6: false,//选择年级 添加
                dialogVisible7: false,//选择年级 添加
                dialogVisible8: false,//选择年级 添加
                dialogVisible9: false,//选择年级 添加
                dialogVisible10: false,//预览
                dialogVisible11: false,//内容
                dialogVisible12: false,//阅读情况
                value: '',
                noticeId: "",
                currentPage4: '',
                form: {},
                insertNoticeBO: {
                    topFlag: "",
                    title: '',
                    content: '',
                    publishObj: '',
                    siteId: '',
                    gradeId: '',
                    profId: '',
                    stageId: ''
                },
                value1: '',
                multipleSelection: [],
                multipleSelection2: [],//函授站列表
                multipleSelection3: [],//函授站添加列表
                multipleSelection4: [],//选择年级
                multipleSelection5: [],//选择年级 添加
                multipleSelection6: [],//选择层次 添加
                multipleSelection7: [],//选择层次 添加
                multipleSelection8: [],//选择层次 添加
                multipleSelection9: [],//选择层次 添加
                multipleSelection10: [],//阅读情况
                page: {
                    pageNo: 1,
                    pageSize: 10,
                    total: 0
                },
                page2: {
                    pageNo: 1,
                    pageSize: 20,
                    total: 0,
                    info: ''
                },
                page3: {
                    pageNo: 1,
                    pageSize: 20,
                    total: 0,
                    info: ''
                },
                page4: {
                    pageNo: 1,
                    pageSize: 20,
                    total: 0,
                    info: ''
                },
                page5: {
                    pageNo: 1,
                    pageSize: 20,
                    total: 0,
                    info: ''
                },
                page6: {
                  noticeId: null,
                    pageNo: 1,
                    pageSize: 20,
                    total: 0,
                    NameOrAcc: '',
                    status:null
                },
                tableData2: [],//函授站列表
                tableData3: [],//函授站列表添加
                tableData4: [],//选择年级
                tableData5: [],//选择年级 添加
                tableData6: [],//选择层次
                tableData7: [],//选择层次 添加
                tableData8: [],//选择专业
                tableData9: [],//选择专业 添加
                tableData10: [],//阅读情况
                content:{
                    title:'',
                    contentDetils:'',
                },
                optionsList:[
                    {value:null,label:"全部"},
                    {value:0,label:"未读"},
                    {value:1,label:"已读"},
                ],
                selectDataCount:0
            }
        },
        mounted() {
            this.loginList = JSON.parse(localStorage.getItem("loginStrage"));
            this.getTableData();
        },
        methods: {
            //region 预览
            preview() {
                this.dialogVisible10 = true;
            },
            //endregion
            //region 删除专业
            deleteTableData8() {
                this.multipleSelection8.forEach(m => {
                    this.tableData8.splice(this.tableData8.findIndex(item => item.id == m.id), 1)
                });
            },
            //endregion
            //region 添加专业 保存并关闭
            addAndClose9() {
                let map = new Map();
                this.tableData8.forEach(t => {
                    map.set(t.id, t)
                });
                this.multipleSelection9.forEach(m => {
                    map.set(m.id, m)
                });
                this.tableData8 = [];
                this.tableData8.push(...map.values());
                this.multipleSelection9 = [];
                this.dialogVisible9 = false;
            },
            //endregion
            //region 查询专业
            queryAllProfessionBySiteId() {
                this.openLoadingView();
                this.$axios_supermall.get('/hnjxjy-core/eduProfession/queryAllProfessionBySiteId?page=' + this.page5.pageNo + '&pageSize=' + this.page5.pageSize + '&profNameAndCode=' + this.page5.info).then(response => {
                    if (response.data.code === 2000) {
                        this.tableData9 = response.data.data.list;
                        this.page5.total = response.data.data.total;
                    }
                }).catch(response => {

                })
            },
            //endregion
            //region 删除层次
            deleteTableData6() {
                this.multipleSelection6.forEach(m => {
                    this.tableData6.splice(this.tableData6.findIndex(item => item.id == m.id), 1)
                });
            },
            //endregion
            //region 添加层次 保存并关闭
            addAndClose6() {
                let map = new Map();
                this.tableData6.forEach(t => {
                    map.set(t.id, t)
                });
                this.multipleSelection7.forEach(m => {
                    map.set(m.id, m)
                });
                this.tableData6 = [];
                this.tableData6.push(...map.values());
                this.multipleSelection7 = [];
                this.dialogVisible7 = false;
            },
            //endregion
            //region 查询层次
            queryAllStageBySiteId() {
                this.openLoadingView();
                this.$axios_supermall.get('/hnjxjy-core/eduStage/queryAllStageBySiteId?page=' + this.page4.pageNo + '&pageSize=' + this.page4.pageSize + '&stageNameAndCode=' + this.page4.info).then(response => {
                    if (response.data.code === 2000) {
                        this.tableData7 = response.data.data.list;
                        this.page4.total = response.data.data.total;
                    }
                }).catch(response => {

                })
            },
            //endregion
            //region 删除函授站
            deleteTableData4() {
                this.multipleSelection4.forEach(m => {
                    this.tableData4.splice(this.tableData4.findIndex(item => item.id == m.id), 1)
                });
            },
            //endregion
            //region 添加函授站 保存并关闭
            addAndClose5() {
                let map = new Map();
                this.tableData4.forEach(t => {
                    map.set(t.id, t)
                });
                this.multipleSelection5.forEach(m => {
                    map.set(m.id, m)
                });
                this.tableData4 = [];
                this.tableData4.push(...map.values());
                this.multipleSelection5 = [];
                this.dialogVisible5 = false;
            },
            //endregion
            //region 查询年级
            queryAllEduGradeByCodeAndName() {
                this.openLoadingView();
                this.$axios_supermall.get('/hnjxjy-core/eduGrade/queryAllEduGradeByCodeAndName?page=' + this.page3.pageNo + '&pageSize=' + this.page3.pageSize + '&gradeNameOrCode=' + this.page3.info).then(response => {
                    if (response.data.code === 2000) {
                        this.tableData5 = response.data.data.list;
                        this.page3.total = response.data.data.total;
                    }
                }).catch(response => {

                })
            },
            //endregion
            //region 删除函授站
            deleteTableData2() {
                this.multipleSelection2.forEach(m => {
                    this.tableData2.splice(this.tableData2.findIndex(item => item.id == m.id), 1)
                });
            },
            //endregion
            //region 添加函授站 保存并关闭
            addAndClose1() {
                let map = new Map();
                this.tableData2.forEach(t => {
                    map.set(t.id, t)
                });
                this.multipleSelection3.forEach(m => {
                    map.set(m.id, m)
                });
                this.tableData2 = [];
                this.tableData2.push(...map.values());
                this.multipleSelection3 = [];
                this.dialogVisible3 = false;
            },
            //endregion
            //region 查询函授站列表
            queryAllSiteByUserId() {
                this.openLoadingView();
                this.$axios_supermall.get('/hnjxjy-core/eduSite/queryAllSiteByUserId?page=' + this.page2.pageNo + '&pageSize=' + this.page2.pageSize + '&info=' + this.page2.info).then(response => {
                    if (response.data.code === 2000) {
                        this.page2.total = response.data.data.total;
                        this.tableData3 = response.data.data.list;
                    }
                }).catch(response => {

                })
            },
            //endregion
            getTableData() { //查询
                this.openLoadingView();
                this.$axios_supermall({
                    method: "GET",
                    url: "/hnjxjy-core/edunotice/queryByPage?page=" + this.page.pageNo + "&pageSize=" + this.page.pageSize,
                    headers: this.loginList,
                }).then((res) => {
                    if (res.data.code == 2000) {
                        this.tableData = res.data.data.list;
                        this.page.total = res.data.data.total;
                    }
                    this.loading = false;
                }).catch();
            },
            //查看内容
            showRow(row) {
                this.openLoadingView();
                this.$axios_supermall.get("hnjxjy-core/edunotice/queryById?id=" + row.id).then(resp => {
                    this.content.contentDetils = resp.data.data.content;
                    this.content.title = resp.data.data.title;
                    this.dialogVisible11 = true;
                })
            },
            //阅读情况
            show(id) {
                // let fromData = new FormData();
                // fromData.append("noticeId",this.noticeId);
              this.page6.noticeId = id;
                this.openLoadingView();
                this.$axios_supermall.get("hnjxjy-core/edunotice/queryEduRecordAll?page="+this.page6.pageNo+"&pageSize="+this.page6.pageSize+"&noticeId="+id).then(resp => {
                    this.tableData10 = resp.data.data.list;
                    this.page6.total = resp.data.data.total;
                    this.dialogVisible12 = true;
                })
            },
          queryEduRecordAll(){
            this.openLoadingView();
            this.$axios_supermallData({
              url: "hnjxjy-core/edunotice/queryEduRecordAll",
              method: 'get',
              params: {
                page: this.page6.page,
                pageSize: this.page6.pageSize,
                noticeId: this.page6.noticeId,
                NameOrAcc: this.page6.NameOrAcc,
                status: this.page6.status,
              }
            }).then(resp => {
                  if (resp.data.code == 2000){
                    this.tableData10 = resp.data.data.list;
                    this.page6.total = resp.data.data.total;
                    this.loadingView.close();
                  }else {
                    this.loadingView.close();
                  }
            }).catch(err => {
              this.$message.error(err)
              this.loadingView.close();
            });
          },
          resetTableDataValue() {
            this.tableData2 = [];
            this.tableData4 = [];
            this.tableData6 = [];
            this.tableData8 = [];
          },
            //添加
            add() {
              let title = this.insertNoticeBO.title;
              let content = this.insertNoticeBO.content;
              // let content = this.insertNoticeBO.content.replace(/<[^>]+>|&[^>]+;/g,"").trim()
              let topFlag = 0;
              if (this.insertNoticeBO.topFlag == true){
                topFlag = 1;
              }
              let publishObj = this.insertNoticeBO.publishObj;

              let array2 = [];
              // for (let i = 0; i < this.multipleSelection2.length; i++) {
              //   array2.push(JSON.stringify(this.multipleSelection2[i].id));
              // }
              for (let i = 0; i < this.tableData2.length; i++) {
                array2.push(JSON.stringify(this.tableData2[i].id));
              }
              let siteIds = array2.toString();

              let array4 = [];
              // for (let i = 0; i < this.multipleSelection4.length; i++) {
              //   array4.push(JSON.stringify(this.multipleSelection4[i].id));
              // }
              for (let i = 0; i < this.tableData4.length; i++) {
                array4.push(JSON.stringify(this.tableData4[i].id));
              }
              let gradeIds = array4.toString();

              let array6 = [];
              // for (let i = 0; i < this.multipleSelection6.length; i++) {
              //   array6.push(JSON.stringify(this.multipleSelection6[i].id));
              // }
              for (let i = 0; i < this.tableData6.length; i++) {
                array6.push(JSON.stringify(this.tableData6[i].id));
              }
              let profIds = array6.toString();

              let array8 = [];
              // for (let i = 0; i < this.multipleSelection8.length; i++) {
              //   array8.push(JSON.stringify(this.multipleSelection8[i].id));
              // }
              for (let i = 0; i < this.tableData8.length; i++) {
                array8.push(JSON.stringify(this.tableData8[i].id));
              }
              let stageIds = array8.toString();

              if (!this.getIsNull(this.insertNoticeBO.publishObj)){
                this.$message.error("请选择发布对象")
              }else if (array2.length == 0){
                this.$message.error("请至少选择一个函数站范围")
              }else if(array4.length == 0 && this.insertNoticeBO.publishObj === 5){
                this.$message.error("请至少选择一个年级范围")
              }else if(array6.length == 0 && this.insertNoticeBO.publishObj === 5){
                this.$message.error("请至少选择一个层次范围")
              }else if(array8.length == 0 && this.insertNoticeBO.publishObj === 5){
                this.$message.error("请至少选择一个专业范围")
              }else if(this.insertNoticeBO.title == ""){
                this.$message.error("标题不可为空")
              }else if(content == ""){
                this.$message.error("内容不能为空")
              }else {
                var reqData = {
                  title: title.toString(),
                  content: content.toString(),
                  topFlag: topFlag,
                  publishObj: publishObj,
                  siteIds: siteIds,
                  gradeIds: gradeIds,
                  profIds: profIds,
                  stageIds: stageIds
                };
                this.openLoadingView();
                this.$axios_supermall({
                    method: "POST",
                    url: "/hnjxjy-core/edunotice/insertNotice",
                    headers: this.loginList,
                    data: reqData
                }).then(res => {
                    if (res.data.code == 2000) {
                      this.$message.success("添加成功");
                      this.getTableData();
                    }else {
                      this.$message.error("操作失败");
                    }
                    this.dialogVisible = false;
                });
              }
            },
            del() {//删除
                var ids = [];
                for (let v of this.multipleSelection) {
                    ids.push(v.id);
                }
                this.$confirm("确认删除选中的" + ids.length + "项吗？", "提示", {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    axios({
                        method: "GET",
                        url: `/hnjxjy-core/edunotice/deleteById?ids=${ids}`,
                        headers: this.loginList,
                    }).then(res => {

                        if (res.data.code == 2000) {
                            this.$message({
                                type: "success",
                                message: "删除成功!",
                            });
                            this.getTableData();
                        }
                    }).catch();
                }).catch(() => {
                    this.$message({
                        type: "info",
                        message: "已取消删除",
                    });
                });
                ;

            },
            top(topflag) {//置顶
                if (this.multipleSelection.length == 0) {
                    this.$message.error('请至少选择一项！');
                } else if (this.multipleSelection.length == 1) {
                    var reqData = {
                        id: this.multipleSelection[0].id,
                        topFlag: topflag
                    }
                    axios({
                        method: "POST",
                        url: "/hnjxjy-core/edunotice/updateStatusById",
                        headers: this.loginList,
                        params: reqData
                    }).then((res) => {
                        if (res.data.code == 2000) {
                            if (topflag == 1) {
                                this.$message({
                                    type: "success",
                                    message: "置顶成功!",
                                });
                            } else {
                                this.$message({
                                    type: "success",
                                    message: "取消置顶成功!",


                                });
                            }
                        }
                        this.getTableData();
                    }).catch();
                } else {
                    this.$message.error('请选择一项！');
                }
            },
            topFormart(data) {
                if (data.topFlag == 0) {
                    return '否'
                } else {
                    return '是'
                }
            },
            getSelect() {

            },
            handleSizeChange(val) {
              this.page.pageSize = val
                this.getTableData();
            },
            handleCurrentChange(val) {
              this.page.pageNo = val
                this.getTableData();
            },
            handleSizeChange2(val) {
                this.page2.pageSize = val;
                this.queryAllSiteByUserId();
            },
            handleCurrentChange2(val) {
                this.page2.pageNo = val;
                this.queryAllSiteByUserId();
            },
            handleSizeChange3(val) {
                this.page3.pageSize = val;
                this.queryAllEduGradeByCodeAndName();
            },
          handleSizeChange6(val) {
            this.page3.pageSize = val;
            this.queryEduRecordAll();
          },
            handleSizeChange4(val) {
                this.page4.pageSize = val;
                this.queryAllStageBySiteId();
            },
            handleSizeChange5(val) {
                this.page5.pageSize = val;
                this.queryAllProfessionBySiteId();
            },
            handleCurrentChange3(val) {
                this.page3.pageNo = val;
                this.queryAllEduGradeByCodeAndName();
            },
          handleCurrentChange6(val) {
            this.page3.pageNo = val;
            this.queryEduRecordAll();
          },
            handleCurrentChange4(val) {
                this.page4.pageNo = val;
                this.queryAllStageBySiteId();
            },
            handleCurrentChange5(val) {
                this.page5.pageNo = val;
                this.queryAllProfessionBySiteId();
            },
            handleSelectionChange(val) {

                this.selectDataCount = val.length
                this.multipleSelection = val;
            },
            handleSelectionChange2(val) {
                this.multipleSelection2 = val;
            },
            handleSelectionChange3(val) {
                this.multipleSelection3 = val;
            },
            handleSelectionChange4(val) {
                this.multipleSelection4 = val;
            },
            handleSelectionChange5(val) {
              this.multipleSelection5 = val;
            },
            handleSelectionChange6(val) {
                this.multipleSelection6 = val;
            },
            handleSelectionChange7(val) {
                this.multipleSelection7 = val;
            },
            handleSelectionChange8(val) {
                this.multipleSelection8 = val;
            },
            handleSelectionChange9(val) {
                this.multipleSelection9 = val;
            },
            addDialogVisible(){
              this.dialogVisible = true;
              this.resetTableDataValue();
              this.insertNoticeBO = {};
            },
          dialogVisible3True(){
              this.dialogVisible3 = true;
              setTimeout(() => {
                this.queryAllSiteByUserId();
              }, 100);
          },
          dialogVisible5True(){
            this.dialogVisible5 = true;
            setTimeout(() => {
              this.queryAllEduGradeByCodeAndName();
            }, 100);
          },
          dialogVisible7True(){
            this.dialogVisible7 = true;
            setTimeout(() => {
              this.queryAllStageBySiteId();
            }, 100);
          },
          dialogVisible9True(){
            this.dialogVisible9 = true;
            setTimeout(() => {
              this.queryAllProfessionBySiteId();
            }, 100);
          },
        }
    }
</script>

<style scoped>


    #aaaaa {
        color: lightskyblue;
    }

    .top_btn {
        margin: 10px 10px 10px 10px;
        text-align: left;
    }


    .main_contain {
        margin: 20px 10px 30px 10px;
        text-align: left;
    }


    .right {
        float: right;
        width: 93%;
        display: inline-block;
        height: 870px;
        overflow-y: scroll;
        /* background-color: #fff; */
        background-color: rgba(164, 174, 224, 0.11);

        text-align: left;
    }

    .left {
        width: 7%;
        display: inline-block;
    }

    .add {
        text-align: left !important;
    }
</style>
