<template>
  <div>
    <div>
      <div style="padding:1rem;border-bottom: 1px solid #ddd;text-align:left">
        专业列表
      </div>
      <div class="search">
        <el-form :model="search" class="demo-ruleForm" inline>
          <el-form-item style="margin-bottom:0" v-permission="'eduMajor:list'">
            <el-input v-model="search.name" clearable @input="() => (search.name = search.name.trim())"
              placeholder="名称/编号/国际代码/国际专业" style="width: 250px"></el-input>
            <el-button icon="el-icon-search" @click="Pagerendering"></el-button>
          </el-form-item>
          <el-form-item style="margin-bottom:0">
            <el-button icon="el-icon-edit" type="primary" @click="addGrade" v-permission="'eduMajor:insert'">
              添加
            </el-button>
            <el-button icon="el-icon-upload2" type="primary" @click="upload" v-permission="'eduMajor:ImportMajor'">
              导入
            </el-button>
            <el-button icon="el-icon-download" type="primary" @click="download" v-permission="'eduMajor:exportMajor'">
              导出
            </el-button>
            <!-- <el-button icon="el-icon-upload2" type="primary" @click="upload"
                          >导入缴费标准</el-button
                        > -->
          </el-form-item>
        </el-form>
      </div>
      <div class="table-wrap" v-permission="'eduMajor:list'">
        <el-table ref="multipleTable" :data="tableData" height="650px" max-height="650px" border size="medium"
          :header-cell-style="{ 'text-align': 'center' }" :cell-style="{ 'text-align': 'center' }">
          <el-table-column type="selection" width="55"></el-table-column>
          <el-table-column label="序号" type="index" width="80"></el-table-column>
          <el-table-column label="名称" prop="profName"></el-table-column>
          <el-table-column label="编号" prop="profCode"></el-table-column>
          <el-table-column label="层次" prop="stageName"></el-table-column>
          <el-table-column label="简称" prop="subProfName"></el-table-column>
          <!-- <el-table-column
                      label="国标代码(旧版)"
                      prop="num"
                      width="200"
                      sortable
                    >
                    </el-table-column>
                    <el-table-column
                      label="国标专业(旧版)"
                      prop="num"
                      width="200"
                      sortable
                    >
                    </el-table-column>
                    <el-table-column
                      label="国标代码(新版)"
                      prop="num"
                      width="200"
                      sortable
                    >
                    </el-table-column>
                    <el-table-column
                      label="国标专业(新版)"
                      prop="num"
                      width="200"
                      sortable
                    >
                    </el-table-column> -->
          <el-table-column label="学制" prop="eduSys">
          </el-table-column>
          <el-table-column label="是否启用" prop="enabled">
          </el-table-column>
          <el-table-column label="排序" prop="sort">
          </el-table-column>
          <el-table-column label="备注" prop="remark">
          </el-table-column>
          <el-table-column label="图片" prop="imageUrl">
            <template slot-scope="scope">
              <!--                            <el-image-->
              <!--                                    style="width: 100px; height: 100px"-->
              <!--                                    :src="scope.row.imageUrl">-->
              <!--                                <div slot="error" style="text-align: center;line-height: 100px" class="image-slot">-->
              <!--                                    <i class="el-icon-picture-outline" style="color: #cccccc"><br>图片为空</i>-->
              <!--                                </div>-->
              <!--                            </el-image>-->
              <div>
                <el-popover placement="right" trigger="hover" style="width: 100px;">
                  <img :src="scope.row.imageUrl" width="350px" />
                  <img slot="reference" :src="scope.row.imageUrl" style="width: 50%">
                </el-popover>
              </div>
            </template>
          </el-table-column>

          <!-- <el-table-column label="缴费方式" prop="sex" sortable width="200">
                    </el-table-column>
                    <el-table-column label="应缴学费总额" prop="sex" sortable width="200">
                    </el-table-column>
                    <el-table-column label="每次缴费额度" prop="sex" sortable width="200">
                    </el-table-column> -->
          <!-- <el-table-column label="是否同步" prop="sex" sortable width="200">
                    </el-table-column> -->
          <el-table-column label="操作" width="180" fixed="right">
            <template slot-scope="scope">
              <el-button @click="editor(scope.row)" type="primary" size="small" v-permission="'eduMajor:updateMajor'">
                编辑
              </el-button>
              <el-button @click="deleteGrade(scope.row)" type="danger" size="small"
                v-permission="'eduMajor:deletedMajor'">
                删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <div style="padding: 1rem" class="fenye">
          <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
            :current-page="this.pageNum" :page-sizes="[10, 20, 50, 100]" :page-size="this.pageSize"
            layout="total, sizes, prev, pager, next, jumper" :total="total">
          </el-pagination>
        </div>
      </div>
    </div>
    <!-- 创建/添加 -->
    <el-dialog :title="title" :visible.sync="dialogVisible1" width="800px">

      <el-form :model="addbutton" :rules="teacherRules" ref="ruleForm" class="demo-ruleForm" label-width="120px">

        <el-form-item label="专业名称：" prop="profName">
          <el-input v-model="addbutton.profName" placeholder="专业名称" class="el-form-input-width"
            @input="() => (addbutton.profName = addbutton.profName.trim())"></el-input>
        </el-form-item>
        <el-form-item label="编号：" prop="profCode">
          <el-input v-model="addbutton.profCode" placeholder="请输入编号" class="el-form-input-width"
            @input="() => (addbutton.profCode = addbutton.profCode.trim())"></el-input>
        </el-form-item>
        <el-form-item label="层次：" prop="stageId">
          <el-select v-model="addbutton.stageId" class="el-form-input-width">
            <el-option v-for="item in versionList" :key="item.id" :label="item.stageName" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="学制：" prop="eduSys">
          <el-input v-model="addbutton.eduSys" placeholder="请输入编号" class="el-form-input-width"
            @input="() => (addbutton.eduSys = addbutton.eduSys.trim())"></el-input>
        </el-form-item>
        <el-form-item label="简称：" prop="subProfName">
          <el-input v-model="addbutton.subProfName" placeholder="请输入简称" class="el-form-input-width"
            @input="() => (addbutton.subProfName = addbutton.subProfName.trim())"></el-input>
        </el-form-item>
        <el-form-item label="备注:" prop="remark">
          <el-input v-model="addbutton.remark" placeholder="请输入备注" class="el-form-input-width"
            @input="() => (addbutton.remark = addbutton.remark.trim())"></el-input>
        </el-form-item>
        <el-form-item label="排序：" prop="sort">
          <el-input v-model="addbutton.sort" placeholder="请输入编号" class="el-form-input-width"
            @input="() => (addbutton.sort = addbutton.sort.trim())"></el-input>
        </el-form-item>
        <el-form-item label="是否启用：" prop="enabled">
          <el-select v-model="addbutton.enabled" class="el-form-input-width">
            <el-option v-for="item in enabledname" :key="item.id" :label="item.value" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="上传图片">
          <!--                    <el-upload-->
          <!--                            action="#"-->
          <!--                            ref="uploadsss"-->
          <!--                            :auto-upload="false"-->
          <!--                            :http-request="uploadS"-->
          <!--                            :on-change="fileuploadChange">-->
          <!--                        <el-button size="small" type="primary" icon="el-icon-upload"-->
          <!--                        >点击上传-->
          <!--                        </el-button-->
          <!--                        >-->
          <!--                    </el-upload>-->

          <el-upload action="#" ref="uploadsss" list-type="picture-card" :show-file-list="false" :auto-upload="false"
            :on-change="fileuploadChange">
            <img v-if="dialogVisibleImg" :src="dialogImageUrl" style="width: 100%; height: 100%" class="avatar">
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload><span style="color:red;font-size:14px;margin-left:1rem">支持格式jpg/png</span>
        </el-form-item>


        <!-- <el-form-item label="应缴学费总额：" prop="version">
                  <el-input-number
                    v-model="num"
                    @change="handleChange"
                    :min="1"
                    :max="10"
                    label="描述文字"
                  ></el-input-number>
                </el-form-item>
                <el-form-item label="每次缴费金额：" prop="version">
                  <el-input-number
                    v-model="num"
                    @change="handleChange"
                    :min="1"
                    :max="10"
                    label="描述文字"
                  ></el-input-number>
                </el-form-item> -->
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible1 = false; dialogImageUrl = null; dialogVisibleImg = false;">取 消</el-button>
        <el-button type="primary" @click="handleSave2('ruleForm')">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 创建/编辑 -->
    <el-dialog :title="title" :visible.sync="dialogVisible" width="800px">

      <el-form :model="addbutton" :rules="teacherRules" ref="ruleForm" class="demo-ruleForm" label-width="120px">

        <el-form-item label="专业名称：" prop="profName">
          <el-input v-model="addbutton.profName" placeholder="专业名称" class="el-form-input-width"
            @input="() => (addbutton.profName = teacherForm.profName.trim())"></el-input>
        </el-form-item>
        <el-form-item label="编号：" prop="profCode">
          <el-input v-model="addbutton.profCode" placeholder="请输入编号" class="el-form-input-width"
            @input="() => (addbutton.profCode = addbutton.profCode.trim())"></el-input>
        </el-form-item>
        <el-form-item label="层次：" prop="stageId">
          <el-select v-model="addbutton.stageId" class="el-form-input-width">
            <el-option v-for="item in versionList" :key="item.id" :label="item.stageName" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="学制：" prop="eduSys">
          <el-input v-model="addbutton.eduSys" placeholder="请输入编号" class="el-form-input-width"
            @input="() => (addbutton.eduSys = addbutton.eduSys.trim())"></el-input>
        </el-form-item>
        <el-form-item label="简称：" prop="subProfName">
          <el-input v-model="addbutton.subProfName" placeholder="请输入编号" class="el-form-input-width"
            @input="() => (addbutton.subProfName = addbutton.subProfName.trim())"></el-input>
        </el-form-item>
        <el-form-item label="备注:" prop="remark">
          <el-input v-model="addbutton.remark" placeholder="请输入编号" class="el-form-input-width"
            @input="() => (addbutton.remark = addbutton.remark.trim())"></el-input>
        </el-form-item>
        <el-form-item label="排序：" prop="sort">
          <el-input v-model="addbutton.sort" placeholder="请输入编号" class="el-form-input-width"
            @input="() => (addbutton.sort = addbutton.sort.trim())"></el-input>
        </el-form-item>
        <el-form-item label="是否启用：" prop="enabled">
          <el-select v-model="addbutton.enabled" class="el-form-input-width">
            <el-option v-for="item in enabledname" :key="item.id" :label="item.value" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-col>
          <el-form-item label="上传照片：" prop="name">
            <el-upload action="#" ref="uploadsss" list-type="picture-card" :show-file-list="false" :auto-upload="false"
              :on-change="fileuploadChange">
              <img v-if="dialogVisibleImg" :src="dialogImageUrl" style="width: 100%; height: 100%" class="avatar">
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload><span style="color:red;font-size:14px;margin-left:1rem">支持格式jpg/png</span>
          </el-form-item>
        </el-col>
        <!-- <el-form-item label="应缴学费总额：" prop="version">
                  <el-input-number
                    v-model="num"
                    @change="handleChange"
                    :min="1"
                    :max="10"
                    label="描述文字"
                  ></el-input-number>
                </el-form-item>
                <el-form-item label="每次缴费金额：" prop="version">
                  <el-input-number
                    v-model="num"
                    @change="handleChange"
                    :min="1"
                    :max="10"
                    label="描述文字"
                  ></el-input-number>
                </el-form-item> -->
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false; dialogImageUrl = null; dialogVisibleImg = false;">取 消</el-button>
        <el-button type="primary" @click="handleSave1('ruleForm')">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 导入 上传文件 -->
    <el-dialog title="上传文件" :visible.sync="dialogVisibleUpload" width="30%">
      <div style="margin-bottom: 20px">
        <el-button type="primary" @click="downloadbutton">下载模板</el-button>
        <span style="color: red">提示：导入前请先下载模板，根据模版整理好数据后上传导入.</span>
      </div>
      <el-upload ref="upload" class="upload-demo" drag action="#" :show-file-list="false" :http-request="uploadFile"
        :before-upload="beforeUpload">
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        <div class="el-upload__tip" slot="tip">
          只能上传excel文件，且不超过50M
        </div>
      </el-upload>
    </el-dialog>
  </div>
</template>
<script>
export default {
  created() {
    this.Pagerendering()
    this.dropdown()
  },
  data() {
    return {
      dialogVisibleImg: false,
      dialogImageUrl: null,
      fileList: [],
      // 添加信息
      addbutton: {
        id: null,
        profName: "",
        profCode: "",
        stageId: "",
        eduSys: "",
        subProfName: "",
        sort: "",
        remark: "",
        enabled: "",
        file: []
      },
      total: 0,
      pageNum: 1,
      pageSize: 10,
      currentPage4: 4,
      num: 1,
      title: "",
      search: {
        name: "",
      },
      teacherForm: {
        name: "",
        time: "",
      },
      tableChecked: [],
      tableData: [],
      versionList: [],//层次列表
      enabledname: [
        {
          id: 0,
          value: '是'
        },
        {
          id: 1,
          value: '否'
        },
      ],//是否启用
      dialogVisible: false,
      dialogVisible1: false,
      dialogVisibleUpload: false,
      teacherRules: {
        profName: [{ required: true, message: "请输入专业名称", trigger: "blur" }],
        profCode: [{ required: true, message: "请输入专业代码", trigger: "blur" }],
        stageId: [{ required: true, message: "请选择层次ID", trigger: "blur" }],
        eduSys: [{ required: true, message: "请输入学制", trigger: "blur" }],
        subProfName: [{ required: true, message: "请输入简称", trigger: "blur" }],
        sort: [{ required: true, message: "请输入排序方式", trigger: "blur" }],

      },
      ruleForm: "",
      value: ''
    };
  },
  methods: {
    fileuploadChange(file) {
      this.dialogImageUrl = URL.createObjectURL(file.raw);
      this.dialogVisibleImg = true;
      this.addbutton.file = file.raw;
    },
    // 下载模版按键
    downloadbutton() {
      let objPar = JSON.parse(localStorage.getItem("loginStrage"));
      this.openLoadingView();
      this.$axios({
        method: "get",
        url: "hnjxjy-core/eduProfession/exportTemplateProfession",
        responseType: "blob",
        headers: objPar,
      }).then((res) => {
        let blob = new Blob([res.data], {
          type: "application/vnd.ms-excel",
        });
        let downloadElement = document.createElement('a');
        let href = window.URL.createObjectURL(blob);
        downloadElement.href = href;
        downloadElement.download = '专业列表导入模板.xlsx';　　　　　　　　　　// xxx.xls/xxx.xlsx
        document.body.appendChild(downloadElement);
        downloadElement.click();
        document.body.removeChild(downloadElement);
        window.URL.revokeObjectURL(href);
        this.$message.success("操作成功");
        this.loadingView.close();
      }).catch((err) => {
        this.$message.error("操作失败");
        this.loadingView.close();
      });
    },
    // 页面渲染
    // 数据加载
    Pagerendering() {
      let objPar = JSON.parse(localStorage.getItem("loginStrage"));
      this.openLoadingView();
      this.$axios_supermall({
        method: "GET",
        url: "hnjxjy-core/eduProfession/queryAllProfessionBySiteId",
        params: {
          profNameAndCode: this.search.name,
          page: this.pageNum,
          pageSize: this.pageSize,
        },
        headers: objPar,
      }).then((res) => {
        this.tableData = res.data.data.list
        this.total = res.data.data.total
        this.tableData.forEach((item, index) => {
          if (item.enabled === 0) {
            item.enabled = '是'
          } else if (item.enabled === 1) {
            item.enabled = '否'
          }
          if (item.stageId === 0) {
            item.stageId = '本科'
          } else {
            item.stageId = '1920专业层次'
          }
        })
        this.tableData.forEach((item, index) => {
          if (item.stageId === 0) {
            item.stageId = '本科'
          } else {
            item.stageId = '1920专业层次'
          }
        })
      }).catch((err) => {
        this.loadingView.close();
      });
    },
    // 下拉框渲染
    dropdown() {
      let objPar = JSON.parse(localStorage.getItem("loginStrage"));
      this.$axios({
        method: "POST",
        url: "hnjxjy-core/eduStage/listGL",
        headers: objPar,
      })
        .then((res) => {
          this.versionList = res.data.data
        })
        .catch((err) => {
        });
    },
    // 添加按钮
    addGrade() {
      this.title = "创建";
      this.addbutton = {
        profName: "",
        profCode: "",
        stageId: "",
        eduSys: "",
        subProfName: "",
        sort: "",
        remark: "",
        enabled: "",
        file: []
      },
        this.dialogVisible1 = true;
      let uploads = this.$refs.uploads
      this.$refs['uploadsss'].clearFiles()
    },
    // 添加-确定按钮
    handleSave2(ruleForm) {
      this.$refs[ruleForm].validate((valid) => {
        if (valid) {
          let formData = new FormData();
          formData.append("profName", this.addbutton.profName);
          formData.append("profCode", this.addbutton.profCode);
          formData.append("stageId", this.addbutton.stageId);
          formData.append("eduSys", this.addbutton.eduSys);
          formData.append("subProfName", this.addbutton.subProfName);
          formData.append("sort", this.addbutton.sort);
          formData.append("remark", this.addbutton.remark);
          formData.append("enabled", this.addbutton.enabled);
          formData.append("file", this.addbutton.file);
          this.$axios_supermallData.post("/hnjxjy-core/eduProfession/addProfessionBySiteId", formData).then((res) => {
            if (res.data.code == 2000) {
              this.$message.success("操作成功");
              this.dialogVisible1 = false;
              this.Pagerendering();
            }
            this.dialogVisibleImg = false;
            this.dialogImageUrl = null;
          })
            .catch((err) => {
              this.dialogVisibleImg = false;
              this.dialogImageUrl = null;
            });
        }
      });

    },
    // 编辑
    handleSave1(ruleForm) {
      this.$refs[ruleForm].validate((valid) => {
        if (valid) {
          let formData = new FormData();
          formData.append("profName", this.addbutton.profName);
          formData.append("profCode", this.addbutton.profCode);
          formData.append("stageId", this.addbutton.stageId);
          formData.append("eduSys", this.addbutton.eduSys);
          formData.append("subProfName", this.addbutton.subProfName);
          formData.append("sort", this.addbutton.sort);
          formData.append("remark", this.addbutton.remark);
          formData.append("enabled", this.addbutton.enabled);
          formData.append("file", this.addbutton.file);
          formData.append("id", this.addbutton.id);
          this.$axios_supermallData.post("/hnjxjy-core/eduProfession/modifyProfessionById", formData).then((res) => {
            if (res.data.code == 2000) {
              this.$message.success("操作成功");
              this.dialogVisible = false;
              this.Pagerendering();
            }
            this.dialogVisibleImg = false;
            this.dialogImageUrl = null;
          })
            .catch((err) => {
              this.dialogVisibleImg = false;
              this.dialogImageUrl = null;
            });
        }
      })

    },
    // 点击导入
    upload() {
      this.dialogVisibleUpload = true;

    },
    // 导入上传文件
    uploadFile(file) {
      let fileDate = file.file;
      let forms = new FormData();
      forms.append("file", fileDate);
      let objPar = JSON.parse(localStorage.getItem("loginStrage"));
      this.openLoadingView();
      this.$axios({
        method: "POST",
        url: "hnjxjy-core/eduProfession/importProfessionBySiteId",
        data: forms,
        headers: objPar,
      }).then((res) => {
        if (res.data.code === 2000) {
          this.dialogVisibleUpload = false
          this.$message.success(res.data.data)
          this.Pagerendering();
        } else {
          this.$message.error(res.data.data)
        }
        this.loadingView.close();
      }).catch((err) => {
        this.$message.error("操作失败")
        this.loadingView.close();
      });
    },
    // 导入上传文件前判断文件格式
    beforeUpload(file) {
      const isXls = file.type === "application/vnd.ms-excel";
      const isXlsx =
        file.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      if (!isXls && !isXlsx) {
        this.$message.error("上传文件只能是XLS/XLSX格式！");
        return false;
      }
      return isXls || isXlsx;
    },
    // 上传图片
    uploadFile1(file) {
      let fileDate = file.file;
      let formData = new FormData();
      formData.append("file", fileDate);
      this.addbutton.file = fileDate
    },
    // 点击导出
    download() {
      let objPar = JSON.parse(localStorage.getItem("loginStrage"));
      this.openLoadingView();
      this.$axios({
        method: "get",
        url: "hnjxjy-core/eduProfession/exportProfessionBySiteId",
        responseType: "blob",
        headers: objPar,
      }).then((res) => {
        let blob = new Blob([res.data], {
          type: "application/vnd.ms-excel",
        });
        let url = window.URL.createObjectURL(blob);
        window.location.href = url;
        this.$message.success("操作成功");
        this.loadingView.close();
      }).catch((err) => {
        this.$message.error("操作失败")
        this.loadingView.close();
      });
    },
    // 点击修改
    editor(row) {
      this.addbutton.id = row.id
      this.title = "修改";
      this.dialogVisible = true;
      setTimeout(() => {
        this.openLoadingView();
      }, 100);
      setTimeout(() => {
        let objPar = JSON.parse(localStorage.getItem('loginStrage'))
        this.$axios_supermall({
          mothod: 'get',
          url: 'hnjxjy-core/eduProfession/showModifyProfessionById',
          headers: objPar,
          params: {
            id: row.id
          }
        }).then((res) => {
          this.addbutton = res.data.data;
          this.dialogImageUrl = res.data.data.imageUrl;
          this.dialogVisibleImg = true;

        }).catch((err) => {
          this.loadingView.close();
        })
      }, 1000);
    },
    // 点击删除
    deleteGrade(row) {
      let objPar = JSON.parse(localStorage.getItem("loginStrage"));

      this.$confirm("确定要删除次专业吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$axios({
            method: 'get',
            url: 'hnjxjy-core/eduProfession/deletedProfessionById',
            params: {
              id: row.id
            },
            headers: objPar,
          }).then((res) => {
            if (res.data.code == 2000) {
              this.$message({
                type: "success",
                message: "删除成功!",
              });
            }
            this.Pagerendering();
          }).catch((err) => {
          })

        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.Pagerendering();
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.Pagerendering();
    },
    handleChange(value) {
    },
  },
};
</script>
<style scoped>
.search {
  text-align: left;
  margin-top: 1rem;
  /* margin-left: 1rem; */
  padding: 1rem;
}

.table-wrap {
  overflow: auto;
  /* margin-left: 1rem; */
  /* margin-bottom: 1rem; */
  padding: 1rem;
}

.demo-ruleForm {
  /* width: 90%; */
  height: calc(100% - 125px);
}

.el-form-input-width {
  width: 100%;
}

.rigth {
  width: 93%;
  display: inline-block;
  height: 870px;
  overflow-y: scroll;
  /* background-color: #fff; */
  background-color: rgba(164, 174, 224, 0.11);
  float: right;
}

.left {
  width: 7%;
  display: inline-block;
}

::v-deep .el-form-item__content {
  text-align: left;
}

.portrait_Sprite {
  padding: 0.2rem 1rem;
  display: inline-block;
  background-color: skyblue;
  color: #fff;
  border-radius: 10%;
  font-size: 13px;
}

@media (max-width: 750px) {
  .rigth {
    width: 100%;
  }

  .left {
    display: none;
  }

  .el-form-item {
    margin-top: 20px;
  }

  .fenye {
    box-sizing: border-box;
    padding: 1rem;
    overflow-x: scroll;
  }
}
</style>
