<template>
  <div id="contain">
    <div class="right">
      <div style="padding: 1rem; border-bottom: 1px solid #ddd; text-align: left">
        站内信
      </div>

      <div class="search" style="margin-top: 20px;margin-left: 20px">
        <el-form :model="form" inline ref="announcementForm" class="form-btn-group">
          <template>
            <el-form-item label="标题" prop="title">
              <el-input
                  v-model="form.title"
                  placeholder="请输入"
                  size="medium"
                  clearable
              ></el-input>
            </el-form-item>
            <el-form-item label="状态" prop="status">
              <el-select v-model="form.status" clearable filterable>
                <el-option
                    v-for="(item, index) in stateOption"
                    :label="item.name"
                    :value="item.key"
                    :key="index"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="日期范围" prop="dateScope">
              <el-date-picker
                  type="daterange"
                  v-model="form.dateScope"
                  format="yyyy/MM/dd"
                  value-format="yyyy/MM/dd HH:mm:ss"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  range-separator="至"
                  clearable
              ></el-date-picker>
            </el-form-item>
            <el-form-item class="button-item">
              <el-button @click="reset">重置</el-button>
              <el-button type="primary" @click="query">查询</el-button>
            </el-form-item>
          </template>
        </el-form>
      </div>

      <el-table :data="tableData" border style="width: 98%; margin-left: 20px"
                height="630px"
                max-height="630px"
                size="medium"
                :header-cell-style="{'text-align':'center'}"
                :cell-style="{'text-align':'center'}"
      >
        <el-table-column type="index" label="序号" width="80">
        </el-table-column>
        <el-table-column prop="title" label="标题">
          <template slot-scope="scope">
            <el-button type="text" class="state_btn" @click="updateEduRecordBySite(scope.row)">
              {{scope.row.title }}
            </el-button>
          </template>
        </el-table-column>
        <el-table-column prop="createTime" label="时间">
        </el-table-column>
        <el-table-column label="状态" prop="status">
          <template slot-scope="scope">
            <div v-if="scope.row.status === 1">已读</div>
            <div v-if="scope.row.status === 0">未读</div>
          </template>
        </el-table-column>
      </el-table>
      <div class="ye">
        <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="bo.page"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="bo.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="this.total">
        </el-pagination>
      </div>

      <el-dialog :title="notice.title" :visible.sync="importDialog" width="30%" :before-close="beforeClose">
        <div class="drawer-content" style="color: #aaa">
          {{ notice.createTime }}
        </div>
        <div class="drawer-content" v-html="notice.content">
          {{ notice.content }}
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
export default {
name: "mail",
  data() {
    return {
      importDialog: false,
      notice: {
        title: '',
        content: '',
        createTime: '',
      },
      form: {
        title: '',
        status: '',
        dateScope: null,
      },
      stateOption: [
        { name: "全部", key: "-1" },
        { name: "已读", key: "1" },
        { name: "未读", key: "0" },
      ],
      tableData: [],
      total: 0,
      bo: {
        page: 1,
        pageSize: 10,
      }

    }
  },
  mounted() {
    this.query();
  },
  methods: {
    query(){
      if (this.form.status === "-1"){
        this.form.status = null;
      }
      const startTime = this.form.dateScope ? this.form.dateScope[0] : null;
      const endTime = this.form.dateScope ? this.form.dateScope[1] : null;
      this.openLoadingView();
      this.$axios_supermallData.get('/hnjxjy-core/edunotice/queryEduRecordBySite',
          {
            params:
                {
                  title: this.form.title,
                  status: this.form.status,
                  startTime: startTime,
                  endTime: endTime,
                  page: this.bo.page,
                  pageSize: this.bo.pageSize
                }
          }
      ).then((resp) => {
        if (resp.data.code === 2000){
          this.total = resp.data.data.total;
          this.tableData = resp.data.data.list;
        }
        this.loadingView.close();
      }).catch((err) => {
        this.loadingView.close();
      });
    },
    updateEduRecordBySite(row) {
      let data = { noticeId: row.noticeId };
      this.openLoadingView();
      this.$axios_supermall.post('/hnjxjy-core/edunotice/updateEduRecordBySite',data)
      .then((resp) => {
        this.notice.title = resp.data.data.title;
        this.notice.content = resp.data.data.content;
        this.notice.createTime = resp.data.data.createTime;
        this.importDialog = true;
        this.loadingView.close();
      })
      .catch((err) => {
        this.loadingView.close();
      });
    },
    handleSizeChange(val){
      this.bo.pageSize = val;
      this.query();
    },
    handleCurrentChange(val){
      this.bo.page = val;
      this.query();
    },
    reset() {
      this.$refs['announcementForm'].resetFields();
    },
    beforeClose(done){
      done();
      this.query();
    },
  },
}
</script>

<style scoped>

</style>