<template>
  <el-select placeholder="请选择层次" v-model="selValue" v-on:change="onChangeOne" clearable>
    <el-option
        v-for="item in options"
        :key="item.id"
        :label="item.stageName"
        :value="item.id">
    </el-option>
  </el-select>
</template>

<script>
export default {
  name: "stage-select-all",
  data() {
    return {
      selValue: this.value,
      options: []
    };
  }, mounted() {
    this.query();
  }, methods: {
    onChangeOne(){
      this.$emit('input',this.selValue);
    },
    query() {
      this.$axios_supermall.post("/hnjxjy-core/eduStage/listGL").then(resp => {
        if (resp.data.code == 2000) {
          this.options = resp.data.data;
        }
      })
    },
    clearValue(){
      this.selValue = ''
    }
  }
  ,
}
</script>

<style scoped>

</style>