<template>
  <div id="contain">
    <section >
      <!-- 右 -->
      <div>
        <div style="padding: 1rem; border-bottom: 1px solid #ddd; text-align: left">
          短信详情
        </div>
        <div class="top_btn">
          <el-button type="primary" icon="el-icon-download" @click="down" v-permission="'eduMsgRecord:export'">导出</el-button>
        </div>

        <div class="search">
          <span class="">手机号</span>
          <el-input placeholder="手机号" v-model="bo.phoneNum" style="width: 400px;">
            <el-button slot="append" icon="el-icon-search" @click="query"></el-button>
          </el-input>
        </div>
        <div class="block">
          <span class="demonstration">起始时间</span>
          <el-date-picker  v-model="bo.startTime" type="date" placeholder="选择日期">
          </el-date-picker>
        </div>
        <div class="block">
          <span class="demonstration">结束时间</span>
          <el-date-picker v-model="bo.endTime" type="date" placeholder="选择日期">
          </el-date-picker>
        </div>
        <div class="block">
          <el-button type="primary" icon="el-icon-search" @click="query">查询</el-button>
        </div>
        <el-table :data="tableData" border height="630px" max-height="630px" style="width: 100%"
                  size="medium"
                  :header-cell-style="{'text-align':'center'}"
                  :cell-style="{'text-align':'center'}">
          <el-table-column type="index" width="100px" label="序号">
          </el-table-column>
          <!-- 创建时间 -->
          <el-table-column
              prop="createTime"
              sortable
              label="创建时间">
          </el-table-column>
          <!-- 创建时间 -->
          <el-table-column
              prop="createName"
              sortable
              label="创建人">
          </el-table-column>
          <!-- 接收号码 -->
          <el-table-column
              prop="phoneNum"
              sortable
              label="接收号码">
          </el-table-column>
          <!-- 短信内容 -->
          <el-table-column
              prop="msgContent"
              sortable
              label="短信内容">
          </el-table-column>
          <!-- 短信状态 0未发送 1已发送 2发送失败-->
          <el-table-column
              prop="msgStatus"
              sortable
              label="短信状态">
            <template slot-scope="scope">
              <span v-if="scope.row.msgStatus == 0">未发送</span>
              <span v-if="scope.row.msgStatus == 1">已发送</span>
              <span v-if="scope.row.msgStatus == 2">发送失败</span>
            </template>
          </el-table-column>

        </el-table>
        <!-- 分页器 -->
        <div class="block">
          <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="currentPage"
              :page-sizes="[10, 20, 50, 100]"
              :page-size="10"
              layout="total, sizes, prev, pager, next, jumper"
              :total="total">
          </el-pagination>
        </div>
      </div>
    </section>
  </div>
</template>


<script>

export default {
  data() {
    return {
      bo: {
        page: 1,
        pageSize: 10,
        phoneNum: '',
        startTime: '',
        endTime: ''
      },
      total: 0,//信息条数
      currentPage: 1,//当前页
      search: [
        {
          name: "", //创建人姓名
        }
      ],
      options: [],
      options2: [],
      tableData: [],
      dialogVisible: false
    }
  },
  mounted() {
    this.query();
  },
  methods: {
    down(){
      let objPar = JSON.parse(localStorage.getItem("loginStrage"));
      this.openLoadingView();
      this.$axios({
        method: "GET",
        url: "hnjxjy-core/eduMsgRecord/export",
        responseType: "blob",
        headers: objPar,
      }).then((res) => {
        let blob = new Blob([res.data], {
          type: "application/vnd.ms-excel",
        });
        let url = window.URL.createObjectURL(blob);
        window.location.href = url;
        this.$message.success("操作成功");
        this.loadingView.close();
      }).catch((err) => {
        this.$message.error("操作失败");
        this.loadingView.close();
      });
    },
    query() {
      this.getPermission('eduMsgRecord:queryEduMsgReAll', () => {
        this.openLoadingView();
        if (this.bo.startTime == null)
        {
          this.bo.startTime = '';
        }
        if (this.bo.endTime == null)
        {
          this.bo.endTime = '';
        }
        let startTime = '';
        let endTime = '';
        if (this.bo.startTime !== '' ){
          startTime =  new Date(this.bo.startTime).formatToLocalDateTime("yyyy/MM/dd HH:mm:ss").toString();
        }
        if (this.bo.endTime !== ''  ){
          endTime =  new Date(this.bo.endTime).formatToLocalDateTime("yyyy/MM/dd HH:mm:ss").toString();
        }
        this.$axios_supermallData.get("/hnjxjy-core/eduMsgRecord/queryEduMsgReAll",
            {
              params: {
                page: this.bo.page,
                pageSize: this.bo.pageSize,
                phoneNum: this.bo.phoneNum,
                startTime: startTime,
                endTime: endTime
              }
            }
        ).then(resp =>{
          if (resp.data.code == 2000){
            this.tableData = resp.data.data.list;
            this.total = resp.data.data.total;
          }
          this.loadingView.close();
        }).catch(()=>{
          this.loadingView.close();
        })
      });
    },
    handleSizeChange(val) {
      this.bo.pageSize = val;
      this.query();
    },
    handleCurrentChange(val) {
      this.bo.page = val;
      this.query();
    },
  }
}
</script>

<style scoped>
.chuangjianren {
  font-weight: bold;
  color: #555;
}

#aaaaa {
  color: lightskyblue;
}

.top_btn {
  margin: 10px 10px 10px 10px;
  text-align: left;
}

.search {
  margin: 20px 10px 30px 10px;
  text-align: left;
  display: inline-block;
}

.search span {
  margin-right: 20px;
}

.main_contain {
  margin: 20px 10px 30px 10px;
  text-align: left;
}

.yingyong {
  margin-right: 100px;
  text-align: left;
}

/* 右 */
.right {
  box-sizing: border-box;
  width: 93%;
  display: inline-block;
  height: 870px;
  overflow-y: scroll;
  /* background-color: #fff; */
  background-color: rgba(164, 174, 224, 0.11);

  text-align: left;
}

.left {
  width: 7%;
  display: inline-block;
}

.add {
  text-align: left im !important;
}

.block {
  display: inline-block;
  margin-right: 20px;
}

.demonstration {
  margin-right: 20px;
}

/* 主盒子 */
.contain {
  width: 100%;
}

/* mains */
.mains {
  display: flex;
}

@media (max-width: 750px) {
  .left {
    display: none;
  }

  .right {
    width: 100%;
  }
}
</style>
