<template>
  <!-- 饼状图 -->
  <div id="mians1" >
    <div id="pie1"></div>
    <section id="one1">
      <div id="two1"></div>
<!--      <el-dropdown id="fist1" @command="handleCommand">-->
<!--        <el-button type="primary" id="three">-->
<!--          {{ year }}<i class="el-icon-arrow-down el-icon&#45;&#45;right"></i>-->
<!--        </el-button>-->
<!--        <el-dropdown-menu slot="dropdown">-->
<!--          <el-dropdown-item v-for="k in kuList" :key="k.id" :command="k.gradeCode" >{{k.gradeName}}</el-dropdown-item>-->
<!--        </el-dropdown-menu>-->
<!--      </el-dropdown>-->
      <div id="fist1">
        <template>
          <el-select v-model="year" style="width:60%" placeholder="请选择" @change="selectChange">
            <el-option
                v-for="item in kuList"
                :key="item.id"
                :label="item.gradeName"
                :value="item.gradeCode">
            </el-option>
          </el-select>
        </template>
      </div>
    </section>
  </div>
</template>
<script>
export default {
  data() {
    return {
      kuList: [],
      year:null,
    };
  },
  methods: {
    queryGradeRegistCountData(){
      // 基于准备好的dom，初始化echarts实例柱状图
      // let pie1mchart = echarts.init(document.querySelector("#two1"));
      //  数据1:
      let pie1Data = [
        {
          name: "专升本注册数",
          value: "13",
        },
        {
          name: "录取总数",
          value: "3",
        },
        {
          name: "高起本注册数",
          value: "11",
        },{
          name: "专升本录取数",
          value: "13",
        },
        {
          name: "高起专录取数",
          value: "3",
        },
        {
          name: "高起专注册数",
          value: "11",
        },
        {
          name: "注册总数",
          value: "11",
        },
        {
          name: "高起本录取数",
          value: "11",
        },
      ];
      //数据2
      let pie1two1 = [
        {
          name: "高起本",
          value: "13",
        },
        {
          name: "专起本",
          value: "3",
        },
        {
          name: "高起专",
          value: "11",
        },
      ];


      let opt ={
        grid: {
          width: "60%",
          height: "60%",
          left: "20%",
          top: "20%",
        },
        legend: {
          bottom: "5%",
          left: "center",
        },
        title: {
          text: "年级注册情况", //主标题
          top: 20,
          textStyle: {
            color: "#2a2a2a", //主标题颜色
            fontSize: 24, //字体大小
          },
        }, //主标题
        series: [
          {
            type: "pie",
            radius: ["30%", "40%"],
            data: pie1Data,
            label: {
              show: "true", //显示文字
              formatter: function (arg) {
                return arg.value + "人";
              },
            },
          },
        ],
      };

      this.$axios_supermall.get("/hnjxjy-core/eduIndex/gradeRegistCountData?gradeCode="+this.year).then(resp =>{
        if (resp.data.code == 2000)
        {
          let pie1mcharts = this.$echarts.init(document.querySelector("#pie1"));
          pie1Data[0].value = resp.data.data.zsbZCount;
          pie1Data[1].value = resp.data.data.totalLCount;
          pie1Data[2].value = resp.data.data.gqbZCount;
          pie1Data[3].value = resp.data.data.zsbLCount;
          pie1Data[4].value = resp.data.data.gqzLCount;
          pie1Data[5].value = resp.data.data.gqzZCount;
          pie1Data[6].value = resp.data.data.totalZCount;
          pie1Data[7].value = resp.data.data.gqbLCount;
          pie1mcharts.setOption(opt);

        }
      });
      // pie1mchart.setOption({
      //   grid: {
      //     width: "60%",
      //     height: "60%",
      //     left: "20%",
      //     top: "20%",
      //   },
      //   legend: {
      //     bottom: "5%",
      //     left: "center",
      //   },
      //   series: [
      //     {
      //       type: "pie1",
      //       radius: ["30%", "40%"],
      //       data: pie1Data,
      //       label: {
      //         show: "true", //显示文字
      //         formatter: function (arg) {
      //           return arg.value + "人";
      //         },
      //       },
      //     },
      //   ],
      // });
    },
    // 年纪下拉框查询
    queryAllEduGradeByCodeAndName(){
      this.$axios_supermallData.get("/hnjxjy-core/eduGrade/queryAllEduGradeByCodeAndName").then(resp =>{
        this.kuList = resp.data.data.list;
        if (this.kuList.length > 0){
          this.year = this.kuList[0].gradeCode;
        }
      })
    },
    selectChange(){
      this.queryGradeRegistCountData();
    },
    // 下拉
    handleClick() {
      alert("button click");
    },
  },
  created() {
    this.queryAllEduGradeByCodeAndName();
    setTimeout(() => {
      this.queryGradeRegistCountData();
    }, 1000);
  },
};
</script>
<style scoped>
#mains {
  width: 100%;
  height: 400px;
  background: #fff;
  position: relative;
}
#pie1 {
  width: 70%;
  height: 400px;
  float: left;
}
#one1 {
  width: 30%;
  height: 400px;
  float: right;
  position: relative;
}
#two1 {
  width: 100%;
  height: 400px;
}

/* 下拉框 */
#fist1 {
  position: absolute;
  top: 20px;
}
.el-dropdown {
  vertical-align: top;
}
.el-dropdown + .el-dropdown {
  margin-left: 15px;
}
.el-icon-arrow-down {
  font-size: 12px;
}
#three {
  background: #fff;
  color: #ddd;
  border: 1px solid #ddd;
}
</style>
