<template>
    <div>
        <div>
            <div
                    style="padding: 1rem; border-bottom: 1px solid #ddd; text-align: left"
            >
                录取信息
            </div>
            <div class="search">
                <el-form class="demo-ruleForm">
                    <el-form-item>
                        <!-- el-icon-delete -->
                        <el-button icon="el-icon-upload2" style="margin-bottom: 10px" type="primary"
                                   @click="dialogVisibleUpload = true" v-permission="'eduEnrolStudent:upLoadEdnStuInfo'"
                        >导入学生
                        </el-button>
                        <!--            <el-button icon="el-icon-upload2" type="primary"-->
                        <!--              >导入录取照片</el-button-->
                        <!--            >-->
                        <el-dropdown style="display: inline-block;margin-left: 10px" @command="handleCommand">
                            <el-button type="primary" v-permission="'eduEnrolStudent:updateStudentInfoById'">
                                设置函授站<i class="el-icon-arrow-down el-icon--right"></i>
                            </el-button>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item command="noAll">设置选中</el-dropdown-item>
                                <el-dropdown-item command="all">设置全部</el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>


                        <el-button icon="el-icon-download" type="primary" @click="download" style="margin-left: 10px"
                                   v-permission="'eduEnrolStudent:export'"
                        >导出
                        </el-button
                        >
                        <el-button
                                icon="el-icon-refresh-right"
                                type="primary"
                                @click="queryEduEnrolStudentList(1)"
                        >更新学生函授站
                        </el-button
                        >
                        <el-button type="primary" @click="queryEduEnrolStudentList"
                                   v-permission="'eduEnrolStudent:list'">查询
                        </el-button>
                    </el-form-item>
                </el-form>
                <el-form :model="search" class="demo-ruleForm" label-width="90px" inline>
                    <el-form-item label="姓名/证件号" style="margin-bottom: 1rem">
                        <el-input
                                v-model="bo.nameNo"
                                clearable
                                @input="() => (bo.nameNo = bo.nameNo.trim())"
                                placeholder="请输入内容"
                                style="width: 200px"
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="批次" style="margin-bottom: 1rem">
                        <el-select
                                clearable
                                v-model="bo.batchId"
                                placeholder="请选择"
                                class="el-form-input-width"
                                @change="queryEduEnrolStudentList"
                        >
                            <el-option
                                    v-for="item in eduGraduateWorkBatch"
                                    :key="item.id"
                                    :label="item.batchName"
                                    :value="item.id"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="选择函授站" style="margin-bottom: 1rem">
                        <el-select
                                clearable
                                @change="queryEduEnrolStudentList"
                                v-model="bo.siteId"
                                placeholder="请选择"
                                class="el-form-input-width"
                        >
                            <el-option
                                    v-for="item in site"
                                    :key="item.id"
                                    :label="item.siteName"
                                    :value="item.id"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="选择层次" style="margin-bottom: 1rem">
                        <el-select
                                clearable
                                @change="queryEduEnrolStudentList"
                                v-model="bo.stageId"
                                placeholder="请选择"
                                class="el-form-input-width"
                        >
                            <el-option
                                    v-for="item in eduStage"
                                    :key="item.id"
                                    :label="item.stageName"
                                    :value="item.id"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="选择专业" style="margin-bottom: 1rem">
                        <el-select
                                clearable
                                @change="queryEduEnrolStudentList"
                                v-model="bo.profId"
                                placeholder="请选择"
                                class="el-form-input-width"
                        >
                            <el-option
                                    v-for="item in eduProfession"
                                    :key="item.id"
                                    :label="item.profName"
                                    :value="item.id"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="指定类型" style="margin-bottom: 1rem">
                        <el-select
                            style="width: 200px"
                                clearable
                                @change="queryEduEnrolStudentList"
                                v-model="bo.assignType"
                                placeholder="请选择"
                                class="el-form-input-width"
                        >
                            <el-option
                                    v-for="item in assignTypeName"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-form>
            </div>
            <div class="table-wrap">
                <el-table
                        size="medium"
                        height="500px"
                        max-height="500px"
                        :cell-style="{'text-align':'center'}"
                        :header-cell-style="tableHeaderColor"
                        border
                        ref="multipleTable"
                        :data="tableData"
                        @selection-change="handleSelectionChange"
                >
                    <el-table-column type="selection" width="55"></el-table-column>
                    <el-table-column label="序号" width="120" type="index">
                    </el-table-column>
                    <el-table-column label="姓名" width="180" prop="name" sortable>
                    </el-table-column>
                    <el-table-column label="考生号" width="200" prop="candidateNo" sortable>
                    </el-table-column>
                    <el-table-column label="身份证号" width="200" prop="certificateNo" sortable>
                    </el-table-column>
                    <el-table-column label="函授站" width="240" prop="siteName">
                    </el-table-column>
                    <el-table-column label="层次" width="150" prop="stageName">
                    </el-table-column>
                    <el-table-column label="专业" width="150" prop="profName">
                    </el-table-column>
                    <el-table-column label="联系方式" width="150" prop="contactPhone">
                    </el-table-column>
                    <el-table-column label="证件类型" width="150" prop="certificateType">
                    </el-table-column>
                    <el-table-column label="操作" width="160" fixed="right">
                        <template slot-scope="scope">
                            <el-button @click="editor(scope.row)" type="primary" size="small"
                                       v-permission="'eduEnrolStudent:queryEduEnrolStudentById'"
                            >编辑
                            </el-button
                            >
                        </template>
                    </el-table-column>
                </el-table>
                <div style="padding: 1rem">
                    <el-pagination
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                            :current-page="currentPage4"
                            :page-sizes="[10, 20, 50, 100]"
                            :page-size="bo.pageSize"
                            layout="total, sizes, prev, pager, next, jumper"
                            :total="total"
                    >
                    </el-pagination>
                </div>
            </div>
        </div>
        <!-- 编辑 -->
        <el-dialog
                title="编辑"
                :visible.sync="dialogVisible"
                width="45%"
        >
            <el-form
                    v-if="visibleData != null"
                    :model="visibleData"
                    :rules="visibleDataRules"
                    ref="ruleForm"
                    class="demo-ruleForm"
                    label-width="180px"
            >
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="姓名：" prop="name">
                            <el-input
                                style="width: 200px"
                                    :disabled="true"
                                    v-model="visibleData.name"
                                    placeholder="请输入"
                                    class="el-form-input-width"
                                    @input="() => (visibleData.name = visibleData.name.trim())"
                            ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="性别：" prop="sex">
                            <el-select style="width: 200px" v-model="visibleData.sex" placeholder="请选择" disabled>
                                <el-option
                                        v-for="item in options"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value"
                                >
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="民族：" prop="clan">
                            <el-input
                                style="width: 200px"
                                    :disabled="true"
                                    v-model="visibleData.clan"
                                    placeholder="请输入"
                                    class="el-form-input-width"
                                    @input="() => (visibleData.clan = visibleData.clan.trim())"
                            ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="出生日期：" prop="birthday">
                            <el-date-picker
                                style="width: 200px"
                                    disabled
                                    v-model="visibleData.birthday"
                                    type="date"
                                    placeholder="选择日期时间"
                            >
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="政治面貌：" prop="politicalStatus">
                            <el-select style="width: 200px" v-model="visibleData.politicalStatus" placeholder="请选择">
                                <el-option
                                        v-for="item in politicalStatusOpt"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value"
                                >
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="文化程度：" prop="education">
                            <el-select style="width: 200px" v-model="visibleData.education" placeholder="请选择">
                                <el-option
                                        v-for="item in educationOpt"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value"
                                >
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="职业类型：" prop="jobClass">
                            <el-select style="width: 200px" v-model="visibleData.jobClass" placeholder="请选择">
                                <el-option
                                        v-for="item in jobClassOpt"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value"
                                >
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="报考函授站：" prop="siteId">
                            <el-select style="width: 200px" v-model="visibleData.siteId" placeholder="请选择" disabled>
                                <el-option
                                        v-for="item in site"
                                        :key="item.id"
                                        :label="item.siteName"
                                        :value="item.id"
                                >
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="年级：" prop="gradeId">
                            <el-select style="width: 200px" v-model="visibleData.gradeId" placeholder="请选择" disabled>
                                <el-option
                                        v-for="item in gradeIdOpt"
                                        :key="item.id"
                                        :label="item.gradeName"
                                        :value="item.id"
                                >
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="报考层次：" prop="stageId">
                            <el-select style="width: 200px" v-model="visibleData.stageId" placeholder="请选择">
                                <el-option
                                        v-for="item in eduStage"
                                        :key="item.id"
                                        :label="item.stageName"
                                        :value="item.id"
                                >
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="报考专业：" prop="profId">
                            <el-select style="width: 200px" v-model="visibleData.profId" placeholder="请选择">
                                <el-option
                                        v-for="item in eduProfession"
                                        :key="item.id"
                                        :label="item.profName"
                                        :value="item.id"
                                >
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="邮政编码：" prop="postCode">
                            <el-input
                                style="width: 200px"
                                    v-model="visibleData.postCode"
                                    placeholder="请输入"
                                    class="el-form-input-width"
                                    @input="() => (visibleData.postCode = visibleData.postCode.trim())"
                            ></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="证件类型：" prop="certificateType">
                            <el-select style="width: 200px" v-model="visibleData.certificateType" placeholder="请选择" disabled>
                                <el-option
                                        v-for="item in certificateTypeOpt"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value"
                                >
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="证件号码：" prop="certificateNo">
                            <el-input
                                style="width: 200px"
                                    :disabled="true"
                                    v-model="visibleData.certificateNo"
                                    placeholder="请输入"
                                    class="el-form-input-width"
                                    @input="() => (visibleData.certificateNo = visibleData.certificateNo.trim())"
                            ></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="联系电话：" prop="candidateNo">
                            <el-input
                                style="width: 200px"
                                    v-model="visibleData.candidateNo"
                                    placeholder="请输入"
                                    class="el-form-input-width"
                                    @input="() => (visibleData.candidateNo = visibleData.candidateNo.trim())"
                            ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="电子邮箱：" prop="email">
                            <el-input
                                style="width: 200px"
                                    v-model="visibleData.email"
                                    placeholder="请输入"
                                    class="el-form-input-width"
                                    @input="() => (visibleData.email = visibleData.email.trim())"
                            ></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="工作单位：" prop="workUnit">
                            <el-input
                                style="width: 200px"
                                    v-model="visibleData.workUnit"
                                    placeholder="请输入"
                                    class="el-form-input-width"
                                    @input="() => (visibleData.workUnit = visibleData.workUnit.trim())"
                            ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="学校名称：" prop="schoolName">
                            <el-input
                                style="width: 200px"
                                    v-model="visibleData.schoolName"
                                    placeholder="请输入"
                                    class="el-form-input-width"
                                    @input="() => (visibleData.schoolName = visibleData.schoolName.trim())"
                            ></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="入学前毕业年份：" prop="graduateYear">


                            <el-select style="width: 200px" v-model="visibleData.graduateYear" placeholder="请选择">
                                <el-option
                                        v-for="item in graduateYearOpt"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value"
                                >
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="入学前学校代码：" prop="schoolCode">
                            <el-input
                                style="width: 200px"
                                    v-model="visibleData.schoolCode"
                                    placeholder="请输入"
                                    class="el-form-input-width"
                                    @input="() => (visibleData.schoolCode = visibleData.schoolCode.trim())"
                            ></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="入学前毕业证书编号：" prop="diplomaNo">
                            <el-input
                                style="width: 200px"
                                    v-model="visibleData.diplomaNo"
                                    placeholder="请输入"
                                    class="el-form-input-width"
                                    @input="() => (visibleData.diplomaNo = visibleData.diplomaNo.trim())"
                            ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="信息来源：" prop="infoSource">
                            <el-select style="width: 200px" v-model="visibleData.infoSource" placeholder="请选择">
                                <el-option
                                        v-for="item in infoSourceOpt"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value"
                                >
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="推荐人：" prop="referrer">
                            <el-input
                                style="width: 200px"
                                    v-model="visibleData.referrer"
                                    placeholder="请输入"
                                    class="el-form-input-width"
                                    @input="() => (visibleData.referrer = visibleData.referrer.trim())"
                            ></el-input>
                        </el-form-item>
                    </el-col>
                    <!--          <el-col :span="12">-->
                    <!--            <el-form-item label="申请免试：" prop="applyFree">-->
                    <!--              <el-select v-model="visibleData.applyFree" placeholder="请选择" disabled>-->
                    <!--                <el-option-->
                    <!--                    v-for="item in optionsIs"-->
                    <!--                    :key="item.value"-->
                    <!--                    :label="item.label"-->
                    <!--                    :value="item.value"-->
                    <!--                >-->
                    <!--                </el-option>-->
                    <!--              </el-select>-->
                    <!--            </el-form-item>-->
                    <!--          </el-col>-->
                    <el-col :span="12">
                        <el-form-item label="证件地址：" prop="certAddr">
                            <el-input
                                style="width: 200px"
                                    :disabled="true"
                                    v-model="visibleData.certAddr"
                                    placeholder="请输入"
                                    class="el-form-input-width"
                                    @input="() => (visibleData.certAddr = visibleData.certAddr.trim())"
                            ></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="通讯地址：" prop="contactAddr">
                            <el-input
                                style="width: 200px"
                                    v-model="visibleData.contactAddr"
                                    placeholder="请输入"
                                    class="el-form-input-width"
                                    @input="() => (visibleData.contactAddr = visibleData.contactAddr.trim())"
                            ></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <span slot="footer" class="dialog-footer">
        <el-button @click="query()">学校代码查询</el-button>
        <el-button @click="shutDown()">关 闭</el-button>
        <el-button type="primary" @click="handleSave('ruleForm')" v-permission="'eduEnrolStudent:edit'"
        >保存后关闭</el-button
        >
      </span>
        </el-dialog>
        <!-- 学校代码查询 -->
        <el-dialog
                title="学校代码查询"
                :visible.sync="dialogVisible1"
                width="40%"
                style="text-align: left"
        >
            <el-form
                    ref="ruleForm"
                    class="demo-ruleForm"
                    label-width="160px"
            >
                <el-form-item label="院校名称/院校代码" style="margin-bottom: 0">
                    <el-input
                            v-model="siteUserInfoBo.info"
                            clearable
                            @input="() => (siteUserInfoBo.info = siteUserInfoBo.info.trim())"
                            placeholder="请输入内容"
                            style="width: 200px"
                    ></el-input>
                    <el-button icon="el-icon-search" @click="lasrQuery"></el-button>
                </el-form-item>
            </el-form>
            <div style="margin-top:1rem">
                <el-table :data="siteUserInfoData" border style="width: 100%">
                    <el-table-column prop="siteCode" label="院校代码">
                    </el-table-column>
                    <el-table-column prop="siteName" label="院校名称">
                    </el-table-column>
                </el-table>
                <div style="padding: 1rem">
                    <el-pagination
                            @size-change="handleSizeChange3"
                            @current-change="handleCurrentChange3"
                            :current-page="currentPage3"
                            :page-sizes="[10, 30, 50]"
                            :page-size="10"
                            layout="total, sizes, prev, pager, next, jumper"
                            :total="total2"
                    >
                    </el-pagination>
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible1 = false">取 消</el-button>
        <el-button type="primary" @click="handleSave('ruleForm')"
        >确 定</el-button
        >
      </span>
        </el-dialog>
        <!-- 导入 上传文件 -->
        <el-dialog title="上传文件" :visible.sync="dialogVisibleUpload" width="30%">
            <div style="margin-bottom: 20px">
                <el-button type="primary" @click="downLoadStudentMB" v-permission="'eduEnrolStudent:downLoadStudentMB'">
                    下载模板
                </el-button>
                <span style="color: red"
                >提示：导入前请先下载模板，根据模版整理好数据后上传导入.</span
                >
            </div>
            <el-upload
                    :headers="dataHeaders"
                    class="upload-demo"
                    drag
                    :action="downUrl"
                    :show-file-list="false"
                    :before-upload="beforeUpload"
                    :http-request="uploadFile"
            >
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                <div class="el-upload__tip" slot="tip">
                    只能上传excel文件，且不超过50M
                </div>
            </el-upload>
        </el-dialog>

        <el-dialog
                title="设置函授站"
                :visible.sync="dialogVisibleSite"
                width="40%"
        >
            <el-form
                    ref="ruleForm"
                    class="demo-ruleForm"
            >
                <el-form-item label="名称/代码" style="margin-bottom: 0">
                    <el-input
                            v-model="siteUserInfoBoTwo.info"
                            clearable
                            @input="() => (siteUserInfoBoTwo.info = siteUserInfoBoTwo.info.trim())"
                            placeholder="请输入内容"
                            style="width: 20vw"
                    >
                        <el-button slot="append" icon="el-icon-search" @click="handleSearch"></el-button>
                    </el-input>
                    <div style="margin-top:1rem">
                        <el-table :data="siteUserInfoDataTwo" border style="width: 100%" size="small">
                            <el-table-column>
                                <template slot-scope="scope">
                                    <el-link type="primary" :underline="false"
                                             @click="handleEdit(scope.$index, scope.row)">选择
                                    </el-link>

                                </template>
                            </el-table-column>
                            <el-table-column prop="siteName" label="名称">
                            </el-table-column>
                            <el-table-column prop="siteCode" label="编号">
                            </el-table-column>
                            <el-table-column prop="siteTypes" label="类型">
                                <!--                <template slot-scope="scope" >-->
                                <!--                  <span v-if="scope.row.siteType == 0">院校</span>-->
                                <!--                  <span v-if="scope.row.siteType == 1">函授站</span>-->
                                <!--                </template>-->
                            </el-table-column>
                        </el-table>
                        <div style="padding: 1rem">
                            <el-pagination
                                    @size-change="handleSizeChange5"
                                    @current-change="handleCurrentChange5"
                                    :current-page="currentPage5"
                                    :page-sizes="[10, 30, 50]"
                                    :page-size="10"
                                    layout="total, sizes, prev, pager, next, jumper"
                                    :total="total5"
                            >
                            </el-pagination>
                        </div>
                    </div>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                sites: {
                    siteId: null,
                    ids: ''
                },
                dialogVisibleSite: false,
                graduateYearOpt: [
                    {
                        value: 2020,
                        label: "2020",
                    }, {
                        value: 2019,
                        label: "2019",
                    }, {
                        value: 2018,
                        label: "2017",
                    }, {
                        value: 2017,
                        label: "2017",
                    }, {
                        value: 2016,
                        label: "2016",
                    }, {
                        value: 2015,
                        label: "2015",
                    }, {
                        value: 2014,
                        label: "2014",
                    }, {
                        value: 2013,
                        label: "2013",
                    }, {
                        value: 2012,
                        label: "2012",
                    },
                ],
                siteUserInfoData: null,
                siteUserInfoBo: {
                    info: "",
                    page: 1,
                    pageSize: 10
                },
                siteUserInfoDataTwo: null,
                siteUserInfoBoTwo: {
                    info: "",
                    page: 1,
                    pageSize: 10
                },
                optionsIs: [
                    {
                        value: 0,
                        label: "否",
                    }, {
                        value: 1,
                        label: "是",
                    },

                ],
                infoSourceOpt: [
                    {
                        value: 0,
                        label: "招生简章",
                    },
                    {
                        value: 1,
                        label: "报纸",
                    },
                    {
                        value: 2,
                        label: "媒体广告",
                    },
                    {
                        value: 3,
                        label: "其他",
                    },
                ],
                certificateTypeOpt: [
                    {
                        value: 0,
                        label: "身份证",
                    },
                    {
                        value: 1,
                        label: "军人证件",
                    }, {
                        value: 3,
                        label: "军人证件",
                    }],
                gradeIdOpt: [],
                jobClassOpt: [
                    {
                        value: 0,
                        label: "专业技术人员",
                    },
                    {
                        value: 1,
                        label: "生产、运输设备操作人员及有关人员",
                    },
                    {
                        value: 2,
                        label: "商业、服务业人员",
                    },
                    {
                        value: 3,
                        label: "农、林、牧、渔、水利业生产人员",
                    },
                    {
                        value: 4,
                        label: "军人",
                    },
                    {
                        value: 5,
                        label: "国家机关、党群组织、企业、事业",
                    },
                    {
                        value: 6,
                        label: "办事人员和有关人员",
                    },
                    {
                        value: 7,
                        label: "不便分类的其他从业人员",
                    },
                ],
                educationOpt: [
                    {
                        value: 0,
                        label: "高中毕业",
                    },
                    {
                        value: 1,
                        label: "同等学力",
                    },
                    {
                        value: 2,
                        label: "中专技校",
                    },
                    {
                        value: 3,
                        label: "大专毕业",
                    },
                    {
                        value: 4,
                        label: "本科毕业",
                    },
                    {
                        value: 5,
                        label: "本科以上",
                    },

                ],
                politicalStatusOpt: [
                    {
                        value: 0,
                        label: "共青团员",
                    },
                    {
                        value: 1,
                        label: "中共党员",
                    }, {
                        value: 2,
                        label: "群众",
                    }, {
                        value: 3,
                        label: "中共预备党员",
                    }, {
                        value: 4,
                        label: "民革会员",
                    }, {
                        value: 5,
                        label: "民盟盟员",
                    }, {
                        value: 6,
                        label: "民建会员",
                    }, {
                        value: 7,
                        label: "民进会员",
                    },
                    {
                        value: 8,
                        label: "农工党党员",
                    },
                    {
                        value: 9,
                        label: "致公党党员",
                    },
                    {
                        value: 10,
                        label: "九三学社社员",
                    },
                    {
                        value: 11,
                        label: "台盟盟员",
                    },
                    {
                        value: 12,
                        label: "无党派民主人士",
                    },
                ],
                visibleData: null,
                assignTypeName: [
                    {
                        value: 0,
                        label: "尚未指定函授站",
                    },
                    {
                        value: 1,
                        label: "学生选择函授站",
                    },
                    {
                        value: 2,
                        label: "学校指定函授站",
                    },
                ],
                eduProfession: [],
                eduStage: [],
                site: [],
                dataHeaders: {
                    'x-token': localStorage.loginStrage ? JSON.parse(localStorage.getItem('loginStrage'))['x-token'] : null,
                    'x-perm': localStorage.loginStrage ? JSON.parse(localStorage.getItem('loginStrage'))['x-perm'] : null
                },
                downUrl: this.DWONWRL,
                bo: {
                    nameNo: '', //学生姓名（模糊）/证件号码（模糊）
                    batchId: '',//批次id
                    siteId: '',//站点id
                    stageId: '', //层次id
                    profId: '', //专业id
                    assignType: '', //指定类型 0尚未指定函授站 1学生选择函授站 2学校指定函授站
                    page: 1,
                    pageSize: 10,
                },
                input: "",
                total: 0,
                pageNum: 1,
                pageSize: 10,
                currentPage4: 1,
                currentPage1: 1,
                currentPage2: 1,
                currentPage3: 1,
                currentPage5: 1,
                total2: 0,
                total5: 0,
                textarea: "",
                search: {
                    name: "",
                },
                teacherForm: {
                    name: "",
                },
                outerVisible: false,
                innerVisible: false,
                tableChecked: [],
                tableData: [],
                eduGraduateWorkBatch: [],
                correspondType: [
                    {
                        value: 0,
                        label: "全部",
                    },
                    {
                        value: 1,
                        label: "函授站",
                    },
                    {
                        value: 2,
                        label: "学习中心",
                    },
                    {
                        value: 3,
                        label: "函授站及学习中心",
                    },
                ],
                dialogVisible: false,
                dialogVisible1: false,
                dialogVisible2: false,
                dialogVisibleUpload: false,
                visibleDataRules: {
                    certificateNo: [{required: true, message: "请填写证件号码", trigger: "blur"}],
                    certificateType: [{required: true, message: "请选择证件类型", trigger: "blur"}],
                    postCode: [{required: true, message: "请填写邮政编码", trigger: "blur"}],
                    profId: [{required: true, message: "请选择报考专业", trigger: "blur"}],
                    stageId: [{required: true, message: "请选择报考层次", trigger: "blur"}],
                    gradeId: [{required: true, message: "请选择年级", trigger: "blur"}],
                    politicalStatus: [{required: true, message: "请选择政治面貌", trigger: "blur"}],
                    name: [{required: true, message: "请输入姓名", trigger: "blur"}],
                    fullName: [
                        {required: true, message: "请输入真实姓名", trigger: "blur"},
                    ],
                    sex: [{required: true, message: "请选择性别", trigger: "blur"}],
                    phone: [
                        {required: true, message: "请输入手机号码", trigger: "blur"},
                        {pattern: /^1[3456789]\d{9}$/, message: "手机号格式不正确"},
                    ],
                    email: [
                        {required: false, message: "请输入邮箱", trigger: "blur"},
                        {
                            pattern: /^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$/,
                            message: "邮箱格式不正确",
                        },
                    ],
                    idCard: [
                        {required: false, message: "请输入身份证号码", trigger: "blur"},
                        {
                            pattern: /^([1-6][1-9]|50)\d{4}(18|19|20)\d{2}((0[1-9])|10|11|12)(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/,
                            message: "身份证号格式不正确",
                        },
                    ],
                },
                teacherRules: {
                    politicalStatus: [{required: true, message: "请选择政治面貌", trigger: "blur"}],
                    name: [{required: true, message: "请输入姓名", trigger: "blur"}],
                    fullName: [
                        {required: true, message: "请输入真实姓名", trigger: "blur"},
                    ],
                    sex: [{required: true, message: "请选择性别", trigger: "blur"}],
                    phone: [
                        {required: true, message: "请输入手机号码", trigger: "blur"},
                        {pattern: /^1[3456789]\d{9}$/, message: "手机号格式不正确"},
                    ],
                    email: [
                        {required: false, message: "请输入邮箱", trigger: "blur"},
                        {
                            pattern: /^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$/,
                            message: "邮箱格式不正确",
                        },
                    ],
                    idCard: [
                        {required: false, message: "请输入身份证号码", trigger: "blur"},
                        {
                            pattern: /^([1-6][1-9]|50)\d{4}(18|19|20)\d{2}((0[1-9])|10|11|12)(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/,
                            message: "身份证号格式不正确",
                        },
                    ],
                },
                options: [
                    {
                        value: 0,
                        label: "男",
                    }, {
                        value: 1,
                        label: "女",
                    },
                ],
                value: "",
                pickerOptions: {
                    shortcuts: [
                        {
                            text: "今天",
                            onClick(picker) {
                                picker.$emit("pick", new Date());
                            },
                        },
                        {
                            text: "昨天",
                            onClick(picker) {
                                const date = new Date();
                                date.setTime(date.getTime() - 3600 * 1000 * 24);
                                picker.$emit("pick", date);
                            },
                        },
                        {
                            text: "一周前",
                            onClick(picker) {
                                const date = new Date();
                                date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
                                picker.$emit("pick", date);
                            },
                        },
                    ],
                },
                value1: "",
            };
        },
        methods: {
            handleEdit(index, row) {
                let formData = new FormData();
                formData.append('siteId', row.id);
                formData.append('ids', this.sites.ids);
                this.$axios_supermall.post("/hnjxjy-core/eduEnrolStudent/updateStudentInfoById", formData).then(resp => {
                    if (resp.data.code == 2000) {
                        this.$message.success("选择成功");
                        this.queryEduEnrolStudentList();
                    }
                    this.dialogVisibleSite = false;
                });
            },
            lasrQuery() {
                this.$axios_supermall.get("/hnjxjy-core/eduSite/queryAllSiteByUserId?info=" + this.siteUserInfoBo.info + "&page=" + this.siteUserInfoBo.page + "&pageSize=" + this.siteUserInfoBo.pageSize).then(resp => {
                    if (resp.data.code == 2000) {
                        let reqData = resp.data.data;
                        this.siteUserInfoData = reqData.list;
                        this.total2 = reqData.total;
                    }
                });
            },
            handleCommand(command) {
                this.sites.ids = "";
                if (command == 'all') {
                    this.tableData.forEach(item => {
                        this.sites.ids = this.sites.ids + item.id + ",";
                    });
                } else if (command == 'noAll') {
                    if (this.tableChecked.length <= 0) {
                        this.$message.error("请选择一条数据");
                        return;
                    }
                    this.tableChecked.forEach(item => {
                        this.sites.ids = this.sites.ids + item.id + ",";
                    })
                }
                this.sites.ids = this.sites.ids.substr(0, this.sites.ids.length - 1);
                this.handleSearch();
                this.dialogVisibleSite = true;
            },
            // //修改信息
            // updatEduEnrolStudent() {
            //   this.$axios_supermall.post("/hnjxjy-core/eduEnrolStudent/edit", this.visibleData).then(resp => {
            //     if (resp.data.code == 2000) {
            //       this.dialogVisible = false;
            //       this.$message.success("操作成功")
            //       this.queryEduEnrolStudentList();
            //     }
            //   });
            // },
            //修改信息
            handleSave(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        this.openLoadingView()
                        this.$axios_supermall.post("/hnjxjy-core/eduEnrolStudent/edit", this.visibleData).then(resp => {
                            if (resp.data.code == 2000) {
                                this.dialogVisible = false;
                                this.$message.success("操作成功")
                                this.queryEduEnrolStudentList();
                            }
                        });
                        this.$refs[formName].resetFields();
                    } else {
                        return false;
                    }
                });
                this.dialogVisible1 = false;
            },
            //查询专业
            queryEduProfession() {
                this.$axios_supermall.post("/hnjxjy-core/eduProfession/listGL").then(resp => {
                    if (resp.data.code == 2000) {
                        this.eduProfession = resp.data.data;
                    }
                })
            },
            //查询层次
            queryEduStage() {
                this.$axios_supermall.post("/hnjxjy-core/eduStage/listGL").then(resp => {
                    if (resp.data.code == 2000) {
                        this.eduStage = resp.data.data;
                    }
                })
            },
            //查询函授站下拉
            queryEduSite() {
              let formData = new FormData();
              formData.append("isAll","1");
              this.$axios_supermallData.post("/hnjxjy-core/eduSite/queryAllSite", formData).then(resp => {
                if (resp.data.code == 2000) {
                  this.site = resp.data.data;
                }
              })
            },
            //查询批次下拉框
            QueryEduGraduateWorkBatch() {
                // this.$axios_supermall.post("/hnjxjy-core/eduGraduateWorkBatch/list").then(resp => {
                //   if (resp.data.code == 2000) {
                //     this.eduGraduateWorkBatch = resp.data.data;
                //   }
                // })

                this.loginList = JSON.parse(localStorage.getItem("loginStrage"));
                // this.loginList = JSON.parse(localStorage.getItem("loginStrage"));
                this.openLoadingView();
                this.$axios({
                    url: "hnjxjy-core/eduEnrolBatch/list",
                    headers: this.loginList,
                    method: "POST"
                }).then((res) => {
                    if (res.data.code == 2000) {
                        this.eduGraduateWorkBatch = res.data.data.list;
                    }
                })
            },
            queryEduEnrolStudentList(type) {
                this.getPermission('eduEnrolStudent:list', () => {
                    if (type == 1) {
                        this.bo = {
                            nameNo: '', //学生姓名（模糊）/证件号码（模糊）
                            batchId: '',//批次id
                            siteId: '',//站点id
                            stageId: '', //层次id
                            profId: '', //专业id
                            assignType: '', //指定类型 0尚未指定函授站 1学生选择函授站 2学校指定函授站
                            page: 1,
                            pageSize: 10,
                        };
                    }
                    this.openLoadingView();
                    let formData = new FormData();
                    formData.append("nameNo", this.bo.nameNo);
                    formData.append("batchId", this.bo.batchId);
                    formData.append("siteId", this.bo.siteId);
                    formData.append("stageId", this.bo.stageId);
                    formData.append("profId", this.bo.profId);
                    formData.append("assignType", this.bo.assignType);
                    formData.append("page", this.bo.page);
                    formData.append("pageSize", this.bo.pageSize);
                    this.$axios_supermallData.post("/hnjxjy-core/eduEnrolStudent/list", formData).then(resp => {
                        if (resp.data.code == 2000) {
                            let reqData = resp.data.data;
                            this.tableData = reqData.list;
                            this.total = reqData.total;
                        }
                        this.loadingView.close();
                    }).catch(() => {
                        this.loadingView.close();
                    });
                });
            },
            handleSearch() {
                this.$axios_supermall.get("/hnjxjy-core/eduSite/queryAllSiteByUserId?info=" + this.siteUserInfoBoTwo.info + "&page=" + this.siteUserInfoBoTwo.page + "&pageSize=" + this.siteUserInfoBoTwo.pageSize).then(resp => {
                    if (resp.data.code == 2000) {
                        let reqData = resp.data.data;
                        this.siteUserInfoDataTwo = reqData.list;
                        this.total5 = reqData.total;
                    }
                });
            },
            addcreate() {
                this.dialogVisible1 = true;
            },
            //学校代码查询
            query() {
                this.lasrQuery();
                this.dialogVisible1 = true;
            },
            // 点击导入
            upload() {
                this.dialogVisibleUpload = true;
            },
            //设置制作教师
            production() {
                this.dialogVisible2 = true;
            },
            //设置教师
            Setting() {
                this.outerVisible = true;
            },
            //下载导入模板
            downLoadStudentMB() {
                this.openLoadingView();
                this.$axios_supermallDwon.get("/hnjxjy-core/eduEnrolStudent/downLoadStudentMB").then(resp => {
                    // 文件导出
                    if (!resp.data) {
                        this.$message.error("操作失败");
                        this.loadingView.close();
                        return
                    }
                    let url = window.URL.createObjectURL(new Blob([resp.data]));
                    let link = document.createElement('a');
                    link.style.display = 'none';
                    link.href = url;
                    link.setAttribute('download', '导入录取信息模板.xlsx');
                    document.body.appendChild(link);
                    link.click()
                    this.$message.success("操作成功");
                    this.loadingView.close();
                }).catch((err) => {
                    this.$message.error("操作失败");
                    this.loadingView.close();
                });
            },
            tableHeaderColor({row, column, rowIndex, columnIndex}) {
                return 'color:#64666A;font-size:13px;text-align:center;'
            },
            // 导入上传文件
            uploadFile(file) {
                let fileDate = file.file;
                let forms = new FormData();
                forms.append("file", fileDate);
                let objPar = JSON.parse(localStorage.getItem("loginStrage"));
                this.openLoadingView();
                this.$axios({
                    method: "post",
                    url: "hnjxjy-core/eduEnrolStudent/upLoadEdnStuInfo",
                    data: forms,
                    headers: objPar,
                }).then((res) => {
                    if (res.data.code === 2000) {
                        this.$message.success(res.data.message);
                        this.dialogVisibleUpload = false;
                        this.queryEduEnrolStudentList();
                    }
                    this.loadingView.close();
                }).catch((err) => {
                    this.$message.error("操作失败");
                    this.dialogVisibleUpload = false;
                    this.queryEduEnrolStudentList();
                    this.loadingView.close();
                });
            },
            // 导入上传文件前判断文件格式
            beforeUpload(file) {
                const isXls = file.type === "application/vnd.ms-excel";
                const isXlsx =
                    file.type ===
                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
                if (!isXls && !isXlsx) {
                    this.$message.error("上传文件只能是XLS/XLSX格式！");
                    return false;
                }
                return isXls || isXlsx;
            },
            // 点击导出
            download() {
                this.openLoadingView();
                this.$axios_supermallData({
                    url: '/hnjxjy-core/eduEnrolStudent/export',
                    method: 'get',
                    params: {
                        nameNo: this.bo.nameNo,
                        batchId: this.bo.batchId,
                        stageId: this.bo.stageId,
                        profId: this.bo.profId,
                        siteId: this.bo.siteId,
                        assignType: this.bo.assignType,
                    },
                    responseType: "blob"
                }).then(resp => {
                    // 文件导出
                    if (!resp.data) {
                        this.$message.error("操作失败");
                        this.loadingView.close();
                        return
                    }
                    let blob = new Blob([resp.data], {
                        type: "application/vnd.ms-excel",
                    });
                    // let url = window.URL.createObjectURL(blob);
                    // window.location.href = url;
                    // let url = window.URL.createObjectURL(blob);
                    // window.location.href = url;
                    let downloadElement = document.createElement('a');
                    let href = window.URL.createObjectURL(blob);
                    downloadElement.href = href;
                    downloadElement.download = '导出录取信息.xlsx';　　　　　　　　　　// xxx.xls/xxx.xlsx
                    document.body.appendChild(downloadElement);
                    downloadElement.click();
                    document.body.removeChild(downloadElement);
                    window.URL.revokeObjectURL(href);
                    this.$message.success("操作成功");
                    this.loadingView.close();
                }).catch((err) => {
                    this.$message.error("操作失败");
                    this.loadingView.close();
                });
            },
            handleSelectionChange(val) {
                this.tableChecked = val;
            },
            // 点击修改
            editor(row) {
                this.openLoadingView();
                this.$axios_supermall.get("/hnjxjy-core/eduEnrolStudent/queryEduEnrolStudentById?id=" + row.id).then(resp => {
                    if (resp.data.code == 2000) {
                        this.visibleData = resp.data.data;
                        this.dialogVisible = true;
                    }
                })

            },
          //region 查询年级下拉框列表
          queryGradeList() {
            this.$axios_supermall.post('/hnjxjy-core/eduGrade/listGL', {isAll: 1}).then(response => {
              if (response.data.code === 2000) {
                this.gradeIdOpt = response.data.data;
                this.gradeList.unshift({id: null, gradeName: "全部"});
              }
            })
          },
            handleSizeChange(val) {
                this.bo.pageSize = val;
                this.queryEduEnrolStudentList();
            },
            handleCurrentChange(val) {
                this.bo.page = val;
                this.queryEduEnrolStudentList();
            },
            handleSizeChange3(val) {
                this.siteUserInfoBo.pageSize = val;
                this.lasrQuery();
            },
            handleCurrentChange3(val) {
                this.siteUserInfoBo.page = val;
                this.lasrQuery();
            },


            handleSizeChange5(val) {
                this.siteUserInfoBoTwo.pageSize = val;
                this.handleSearch();
            },
            handleCurrentChange5(val) {
                this.siteUserInfoBoTwo.page = val;
                this.handleSearch();
            },


            handleSizeChange1(val) {
            },
            handleCurrentChange1(val) {
            },
            handleSizeChange2(val) {
            },
            handleCurrentChange2(val) {
            },
            // 点击删除
            deleteCorrespond() {
                this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                })
                    .then(() => {
                        this.$message({
                            type: "success",
                            message: "删除成功!",
                        });
                    })
                    .catch(() => {
                        this.$message({
                            type: "info",
                            message: "已取消删除",
                        });
                    });
            },
            shutDown() {
                this.dialogVisible = false;
                this.$refs.ruleForm.resetFields();
            }
        }, mounted() {
            this.queryEduEnrolStudentList();
            this.QueryEduGraduateWorkBatch();
            this.queryEduSite();
            this.queryEduStage();
            this.queryEduProfession();
            this.queryGradeList();
        }
    };
</script>
<style scoped>
    .search {
        text-align: left;
        margin-top: 1rem;
        /* margin-left: 1rem; */
        padding: 1rem;
    }

    .table-wrap {
        overflow: auto;
        /* margin-left: 1rem; */
        /* margin-bottom: 1rem; */
        padding: 1rem;
    }

    .demo-ruleForm {
        /* width: 90%; */
        height: calc(100% - 125px);
    }

    .el-form-input-width {
        width: 100%;
    }

    .rigth {
        width: 93%;
        display: inline-block;
        height: 870px;
        overflow-y: scroll;
        /* background-color: #fff; */
        background-color: rgba(164, 174, 224, 0.11);
        float: right;
    }

    .left {
        width: 7%;
        display: inline-block;
    }

    .portrait_Sprite {
        padding: 0.2rem 1rem;
        display: inline-block;
        background-color: skyblue;
        color: #fff;
        border-radius: 10%;
        font-size: 13px;
    }
</style>
