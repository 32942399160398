<template>
  <div>
    <div >
      <div
        style="padding: 1rem; border-bottom: 1px solid #ddd; text-align: left"
      >
        录取发布
      </div>
      <div class="search">
        <el-form class="demo-ruleForm">
          <el-form-item>
            <el-button type="primary" @click="selection" style="margin-bottom: 15px">录取发布</el-button>
            <el-button type="primary" @click="selections">取消发布</el-button>
            <el-button icon="el-icon-download" type="primary" @click="download">导出</el-button>
            <el-button  type="primary" @click="luqu">录取</el-button>
            <el-button  type="primary" @click="noluqu">取消录取</el-button>
            <el-button type="primary" @click="handleSearch">查询</el-button>
            <el-button type="primary" @click="reset()">重置</el-button>
          </el-form-item>
        </el-form>
        <el-form :model="search" label-width="110px" inline>
          <el-form-item label="姓名/证件号" style="margin-bottom: 1rem">
            <el-input
              v-model="search.name"
              clearable
              @input="() => (search.name = search.name.trim())"
              placeholder="请输入内容"
              style="width: 200px"
            ></el-input>
          </el-form-item>
          <el-form-item label="招生批次" style="margin-bottom: 1rem">
            <el-select
                style="width: 200px"
                clearable
                v-model="search.batchId"
                placeholder="请选择"
                class="el-form-input-width"
            >
              <el-option
                  v-for="item in eduGraduateWorkBatch"
                  :key="item.id"
                  :label="item.batchName"
                  :value="item.id"
              >
              </el-option>
            </el-select>

          </el-form-item>
          <el-form-item label="发布状态" style="margin-bottom: 1rem">
            <el-select
                style="width: 200px"
              v-model="search.publishStatus"
              placeholder="请选择"
              class="el-form-input-width"
            >
              <el-option
                v-for="item in correspondType"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="选择函授站" style="margin-bottom: 1rem">
            <el-select
                style="width: 200px"
                clearable
                v-model="search.siteId"
                placeholder="请选择"
                class="el-form-input-width"
            >
              <el-option
                  v-for="item in site"
                  :key="item.id"
                  :label="item.siteName"
                  :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="层次" style="margin-bottom:1rem">
            <el-select  style="width: 200px" v-model="search.stageId" placeholder="请选择">
              <el-option
                  v-for="item in eduStage"
                  :key="item.id"
                  :label="item.stageName"
                  :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="选择专业" style="margin-bottom:1rem">
            <el-select
                style="width: 200px"
                clearable
                v-model="search.profId"
                placeholder="请选择"
                class="el-form-input-width"
            >
              <el-option
                  v-for="item in eduProfession"
                  :key="item.id"
                  :label="item.profName"
                  :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>

        </el-form>
      </div>
      <div class="table-wrap">
        <el-table
            size="medium"
            height="500px"
            max-height="500px"
            :header-cell-style="{'text-align':'center'}"
            :cell-style="{'text-align':'center'}"
            border
            ref="multipleTable"
            :data="tableData"
            @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" width="55" > </el-table-column>
          <el-table-column label="序号" width="100" prop="id" type="index" >
          </el-table-column>
          <el-table-column label="姓名" width="140" prop="name" sortable >
          </el-table-column>
          <el-table-column
            label="证件号"
            width="200"
            prop="certificateNo"
            sortable
          >
          </el-table-column>
          <el-table-column label="函授站" width="200" prop="siteName" sortable >
          </el-table-column>
          <el-table-column label="层次" width="200" prop="stageName" >
          </el-table-column>
          <el-table-column label="专业" width="200" prop="profName" >
          </el-table-column>
          <el-table-column label="学籍状态" width="160" prop="rollStatus" >
          </el-table-column>
          <el-table-column label="录取状态" width="160" prop="isMatch" >
            <template slot-scope="scope">
              <span v-if="scope.row.isMatch==0">未录取</span>
              <span v-if="scope.row.isMatch==1">已录取</span>
            </template>
          </el-table-column>
          <el-table-column label="发布状态" width="160" prop="politicalStatus" >
            <template slot-scope="scope">
              <span>{{
                scope.row.publishStatus == 1 ? "已发布" : "未发布"
              }}</span>
            </template>
          </el-table-column>
          <el-table-column label="发布时间" width="160" prop="publishTime" >
          </el-table-column>
        </el-table>
        <div style="padding: 1rem">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="this.page"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="this.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <!-- 编辑 -->
    <!-- <el-dialog
      title="编辑"
      :visible.sync="dialogVisible"
      width="30%"
      style="text-align: left"
    >
      <el-form
        :model="teacherForm"
        :rules="teacherRules"
        ref="ruleForm"
        class="demo-ruleForm"
        label-width="120px"
      >
        <el-form-item label="课程编号：" prop="name">
          <el-input
            v-model="teacherForm.name"
            placeholder="请输入"
            class="el-form-input-width"
            @input="() => (teacherForm.name = teacherForm.name.trim())"
          ></el-input>
        </el-form-item>
        <el-form-item label="课程名称：" prop="name">
          <el-input
            v-model="teacherForm.name"
            placeholder="请输入"
            class="el-form-input-width"
            @input="() => (teacherForm.name = teacherForm.name.trim())"
          ></el-input>
        </el-form-item>
        <el-form-item label="课程类型：" prop="name">
          <el-select v-model="value" placeholder="请选择">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="handleSave('ruleForm')"
          >确 定</el-button
        >
      </span>
    </el-dialog> -->
    <!-- 新增 -->
    <!-- <el-dialog
      title="新增"
      :visible.sync="dialogVisible1"
      width="30%"
      style="text-align: left"
    >
      <el-form
        :model="teacherForm"
        :rules="teacherRules"
        ref="ruleForm"
        class="demo-ruleForm"
        label-width="120px"
      >
        <el-form-item label="课程编号：" prop="name">
          <el-input
            v-model="teacherForm.name"
            placeholder="请输入"
            class="el-form-input-width"
            @input="() => (teacherForm.name = teacherForm.name.trim())"
          ></el-input>
        </el-form-item>
        <el-form-item label="课程名称：" prop="name">
          <el-input
            v-model="teacherForm.name"
            placeholder="请输入"
            class="el-form-input-width"
            @input="() => (teacherForm.name = teacherForm.name.trim())"
          ></el-input>
        </el-form-item>
        <el-form-item label="课程类型：" prop="name">
          <el-select v-model="value" placeholder="请选择">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible1 = false">取 消</el-button>
        <el-button type="primary" @click="handleSave('ruleForm')"
          >确 定</el-button
        >
      </span>
    </el-dialog> -->
    <!-- 设置制作教师 -->
    <!-- <el-dialog
      title="设置制作教师"
      :visible.sync="dialogVisible2"
      width="50%"
      style="text-align: left"
    >
      <el-form :model="search" class="demo-ruleForm" inline>
        <el-form-item style="margin-bottom: 0">
          <el-input
            v-model="search.name"
            clearable
            @input="() => (search.name = search.name.trim())"
            placeholder="教师名字/工号"
            style="width: 300px"
          ></el-input>
          <el-button icon="el-icon-search" @click="handleSearch"></el-button>
        </el-form-item>
      </el-form>
      <div style="margin-top:1rem">
        <el-table :data="tableData" border style="width: 100%">
            <el-table-column
                type="selection"
                width="55">
                </el-table-column>
          <el-table-column prop="date" label="教师工号" >
          </el-table-column>
          <el-table-column prop="name" label="教师姓名" >
          </el-table-column>
           <el-table-column prop="name" label="性别">
          </el-table-column>
           <el-table-column prop="name" label="院系">
          </el-table-column>
           <el-table-column prop="name" label="教师类型">
          </el-table-column>
          <el-table-column prop="address" label="教师职称"> </el-table-column>
           <el-table-column label="操作" fixed="right">
            <template slot-scope="scope">
              <el-button
                @click="production(scope.row)"
                type="primary"
                size="small"
                >保存</el-button
              >
            </template>
          </el-table-column>
        </el-table>
         <div style="padding: 1rem;text-align:center">
          <el-pagination
            @size-change="handleSizeChange1"
            @current-change="handleCurrentChange1"
            :current-page="currentPage1"
            :page-sizes="[100, 200, 300, 400]"
            :page-size="100"
            layout="total, sizes, prev, pager, next, jumper"
            :total="400"
          >
          </el-pagination>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible2 = false">取 消</el-button>
        <el-button type="primary" @click="handleSave('ruleForm')"
          >确 定</el-button
        >
      </span>
    </el-dialog> -->
    <!-- 导入 上传文件 -->
    <el-dialog title="上传文件" :visible.sync="dialogVisibleUpload" width="30%">
      <div style="margin-bottom: 20px">
        <el-button type="primary">下载模板</el-button>
        <span style="color: red"
          >提示：导入前请先下载模板，根据模版整理好数据后上传导入.</span
        >
      </div>
      <el-upload
        class="upload-demo"
        drag
        action="#"
        :show-file-list="false"
        :http-request="uploadFile"
        :before-upload="beforeUpload"
      >
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        <div class="el-upload__tip" slot="tip">
          只能上传excel文件，且不超过50M
        </div>
      </el-upload>
    </el-dialog>
    <!-- 设置教师 -->
    <!-- <el-dialog title="设置教师" :visible.sync="outerVisible">
           <div style="margin-top:1rem">
        <el-table :data="tableData" border style="width: 100%">
            <el-table-column
                type="selection"
                width="55">
                </el-table-column>
          <el-table-column prop="date" label="教师工号" >
          </el-table-column>
          <el-table-column prop="name" label="教师姓名" >
          </el-table-column>
           <el-table-column prop="name" label="性别">
          </el-table-column>
           <el-table-column prop="name" label="院系">
          </el-table-column>
           <el-table-column prop="name" label="教师类型">
          </el-table-column>
          <el-table-column prop="address" label="教师职称"> </el-table-column>
        </el-table>
         <div style="padding: 1rem;text-align:center">
          <el-pagination
            @size-change="handleSizeChange2"
            @current-change="handleCurrentChange2"
            :current-page="currentPage2"
            :page-sizes="[100, 200, 300, 400]"
            :page-size="100"
            layout="total, sizes, prev, pager, next, jumper"
            :total="400"
          >
          </el-pagination>
        </div>
      </div>
        <el-dialog
        width="50%"
        title="添加教师"
        :visible.sync="innerVisible"
        append-to-body>
        <el-form :model="search" class="demo-ruleForm" inline>
        <el-form-item style="margin-bottom: 0">
          <el-input
            v-model="search.name"
            clearable
            @input="() => (search.name = search.name.trim())"
            placeholder="姓名/工号"
            style="width: 300px"
          ></el-input>
          <el-button icon="el-icon-search" @click="handleSearch"></el-button>
        </el-form-item>
      </el-form>
      <div style="margin-top:1rem">
        <el-table :data="tableData" border style="width: 100%">
            <el-table-column
                type="selection"
                width="55">
                </el-table-column>
          <el-table-column prop="date" label="教师工号" >
          </el-table-column>
          <el-table-column prop="name" label="教师姓名" >
          </el-table-column>
           <el-table-column prop="name" label="性别">
          </el-table-column>
           <el-table-column prop="name" label="院系">
          </el-table-column>
           <el-table-column prop="name" label="教师类型">
          </el-table-column>
          <el-table-column prop="address" label="教师职称"> </el-table-column>
        </el-table>
         <div style="padding: 1rem;text-align:center">
          <el-pagination
            @size-change="handleSizeChange1"
            @current-change="handleCurrentChange1"
            :current-page="currentPage1"
            :page-sizes="[100, 200, 300, 400]"
            :page-size="100"
            layout="total, sizes, prev, pager, next, jumper"
            :total="400"
          >
          </el-pagination>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="innerVisible = true">保存后关闭</el-button>
        </div>
        </el-dialog>

        <div slot="footer" class="dialog-footer">
        <el-button @click="outerVisible = false">删 除</el-button>
        <el-button type="primary" @click="innerVisible = true">添加教师</el-button>
        </div>
    </el-dialog> -->
  </div>
</template>
<script>
export default {
  data() {
    return {
      eduStage: [],
      eduProfession: [],
      site:[],
      eduGraduateWorkBatch:[],
      total: 0,
      page: 1,
      pageSize: 10,
      currentPage4: 4,
      currentPage1: 4,
      currentPage2: 4,
      textarea: "",
      search: {
        isMatch: "",
        name: "",
        batchId: "",
        siteId: "",
        profId: "",
        stageId: "",
        publishStatus:"",
      },
      teacherForm: {
        name: "",
      },
      outerVisible: false,
      innerVisible: false,
      tableChecked: [],
      tableData: [], //储存所有用户信息
      // 分页管理器中的当前页数
      currentPage: 0,
      multipleTable: [], //储存发布的数据
      // 储存获取发布状态的数据
      politicalStatus: "",
      exports: {
        //导出传参储存
        stageId: "",
        profId: "",
        publishStatus: "",
        nameNo:"",
        batchId:"",
        siteId:"",
      },
      correspondType: [
        {
          value: 0,
          label: "否",
        },
        {
          value: 1,
          label: "是",
        },
      ],
      dialogVisible: false,
      dialogVisible1: false,
      dialogVisible2: false,
      dialogVisibleUpload: false,
      teacherRules: {
        name: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        fullName: [
          { required: true, message: "请输入真实姓名", trigger: "blur" },
        ],
        sex: [{ required: true, message: "请选择性别", trigger: "blur" }],
        phone: [
          { required: true, message: "请输入手机号码", trigger: "blur" },
          { pattern: /^1[3456789]\d{9}$/, message: "手机号格式不正确" },
        ],
        email: [
          { required: false, message: "请输入邮箱", trigger: "blur" },
          {
            pattern: /^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$/,
            message: "邮箱格式不正确",
          },
        ],
        idCard: [
          { required: false, message: "请输入身份证号码", trigger: "blur" },
          {
            pattern: /^([1-6][1-9]|50)\d{4}(18|19|20)\d{2}((0[1-9])|10|11|12)(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/,
            message: "身份证号格式不正确",
          },
        ],
      },
      options: [
        {
          value: "选项1",
          label: "黄金糕",
        },
        {
          value: "选项2",
          label: "双皮奶",
        },
        {
          value: "选项3",
          label: "蚵仔煎",
        },
        {
          value: "选项4",
          label: "龙须面",
        },
        {
          value: "选项5",
          label: "北京烤鸭",
        },
      ],
      value: "",
    };
  },
  created() {
    this.getList();
    this.queryEduStage();
    this.queryEduProfession();
    this.queryEduSite();
    this.QueryEduGraduateWorkBatch();
  },
  methods: {
    // 获取用户信息
    getList() {
      this.loginList = JSON.parse(localStorage.getItem("loginStrage"));
      this.openLoadingView();
      this.$axios({
        url: "hnjxjy-core/eduEnrolStudent/listF",
        headers: this.loginList,
        method: "POST",
        params: {
          page: this.page,
          pageSize: this.pageSize
        },
      }).then((res) => {
        if (res.data.code !== 2000) {
          this.loadingView.close();
          return;
        }
        this.tableData = res.data.data.list;
        this.total = res.data.data.total;
        this.loadingView.close();
        // this.currentPage = res.data.data.pageNum;
        // this.ids = res.data.data.id;
      });
    },
    // 更改发布的状态
    selection() {
      let array = [];
      for (let i = 0; i < this.multipleTable.length; i++) {
        array.push(JSON.stringify(this.multipleTable[i].id));
      }
      let arr = array.toString();
      this.$confirm("你将对(" + array.length + ")条数据进行操作!", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.openLoadingView();
        this.$axios({
          url: "hnjxjy-core/eduEnrolStudent/editPublishStatus",
          headers: this.loginList,
          method: "POST",
          params: {
            ids: arr,
            status: 1,
          },
        }).then((res) => {
          if (res.data.code == 2000) {
            this.$message({
              type: "success",
              message: "操作成功!",
            });
            this.getList();
          }
        })
        // this.getList()
        .catch(() => {
          this.$message({
            type: "info",
            message: "操作失败",
          });
          this.loadingView.close();
        });
      });
    },
    selections() {
      let array = [];
      for (let i = 0; i < this.multipleTable.length; i++) {
        array.push(JSON.stringify(this.multipleTable[i].id));
      }
      let arr = array.toString();
      this.$confirm("你将对(" + array.length + ")条数据进行操作!", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.openLoadingView();
        this.$axios({
          url: "hnjxjy-core/eduEnrolStudent/editPublishStatus",
          headers: this.loginList,
          method: "POST",
          params: {
            ids: arr,
            status: 0,
          },
        }).then((res) => {
          if (res.data.code == 2000) {
            this.$message({
              type: "success",
              message: "操作成功!",
            });
            this.getList();
          }
        })
        // this.getList()
        .catch(() => {
          this.$message({
            type: "info",
            message: "操作失败",
          });
          this.loadingView.close();
        });
      });
    },
    queryEduStage() {
      this.$axios_supermall.post("/hnjxjy-core/eduStage/listGL").then(resp => {
        if (resp.data.code == 2000) {
          this.eduStage = resp.data.data;
        }
      })
    },
    //查询专业
    queryEduProfession() {
      this.$axios_supermall.post("/hnjxjy-core/eduProfession/listGL").then(resp => {
        if (resp.data.code == 2000) {
          this.eduProfession = resp.data.data;
        }
      })
    },
    //查询函授站下拉
    queryEduSite() {
      let formData = new FormData();
      formData.append("isAll","1");
      this.$axios_supermallData.post("/hnjxjy-core/eduSite/queryAllSite", formData).then(resp => {
        if (resp.data.code == 2000) {
          this.site = resp.data.data;
        }
      })
    },
    //查询批次下拉框
    QueryEduGraduateWorkBatch() {
      // this.$axios_supermall.post("/hnjxjy-core/eduGraduateWorkBatch/list").then(resp => {
      //   if (resp.data.code == 2000) {
      //     this.eduGraduateWorkBatch = resp.data.data;
      //   }
      // })

      this.loginList = JSON.parse(localStorage.getItem("loginStrage"));
      // this.loginList = JSON.parse(localStorage.getItem("loginStrage"));
      this.openLoadingView();
      this.$axios({
        url: "hnjxjy-core/eduEnrolBatch/list",
        headers: this.loginList,
        method: "POST"
      }).then((res) => {
        if (res.data.code == 2000) {
          this.eduGraduateWorkBatch = res.data.data.list;
        }
      })

    },
    handleSearch() {
      this.openLoadingView()
      let fromData = new FormData();
      fromData.append("nameNo",this.search.name);
      fromData.append("stageId",this.search.stageId);
      fromData.append("profId",this.search.profId);
      fromData.append("siteId",this.search.siteId);
      fromData.append("batchId",this.search.batchId);
      fromData.append("isMatch",this.search.isMatch);
      fromData.append("page",this.page);
      fromData.append("pageSize",this.pageSize);
      fromData.append("publishStatus",this.search.publishStatus);
      this.$axios_supermall.post("/hnjxjy-core/eduEnrolStudent/listF",fromData).then(resp => {
        if (resp.data.code == 2000) {
          this.tableData = resp.data.data.list;
          this.total = resp.data.data.total;
        }
      })
    },
    addcreate() {
      this.dialogVisible1 = true;
    },
    // 批量删除
    batchDelete(row) {
      if (this.tableChecked == "") {
        this.$message.error("请至少选择一项记录");
      } else {
        this.$confirm(`您确定要删除${row.length}数据吗?`, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.$message({
              type: "success",
              message: "删除成功!",
            });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消删除",
            });
          });
      }
    },
    // 点击导入
    upload() {
      this.dialogVisibleUpload = true;
    },
    //设置制作教师
    production() {
      this.dialogVisible2 = true;
    },
    //设置教师
    Setting() {
      this.outerVisible = true;
    },
    // 导入上传文件
    uploadFile() {},
    // 导入上传文件前判断文件格式
    beforeUpload(file) {
      const isXls = file.type === "application/vnd.ms-excel";
      const isXlsx =
        file.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      if (!isXls && !isXlsx) {
        this.$message.error("上传文件只能是XLS/XLSX格式！");
        return false;
      }
      return isXls || isXlsx;
    },
    // 点击导出
    download() {
      let array = [];
      for (let i = 0; i < this.multipleTable.length; i++) {
        array.push(JSON.stringify(this.multipleTable[i].id));
      }
      let arr = array.toString();
      let objPar = JSON.parse(localStorage.getItem("loginStrage"));
      this.openLoadingView();
      this.$axios({
        method: "get",
        url: "hnjxjy-core/eduEnrolStudent/exportF",
        responseType: "blob",
        headers: objPar,
        params: {
          nameNo:this.search.name,
          batchId:this.search.batchId,
          siteId:this.search.siteId,
          stageId:this.search.stageId,
          profId:this.search.profId,
          publishStatus:this.search.publishStatus,
        },
      }).then((res) => {
        let blob = new Blob([res.data], {
          type: "application/vnd.ms-excel",
        });
        let url = window.URL.createObjectURL(blob);
        window.location.href = url;
        this.$message.success("操作成功");
        this.loadingView.close();
      }).catch((err) => {
        this.$message.error("操作失败");
        this.loadingView.close();
      });
    },
    handleSelectionChange(val) {
      this.multipleTable = val;
    },
    luqu(){
      if(this.multipleTable!=null&&this.multipleTable!=""){
        this.loginList = JSON.parse(localStorage.getItem("loginStrage"));
        let array = [];
        for (let i = 0; i < this.multipleTable.length; i++) {
          array.push(this.multipleTable[i].id);
        }
        let arr = array.toString();
        this.$axios({
          url: "hnjxjy-core/eduEnrolStudent/updateEduEnrolStudentisMatch",
          headers: this.loginList,
          method: "post",
          params: {
            ids: arr,
            status:1
          },
        }).then((res) => {
          if (res.data.code == 2000) {
            this.$message({
              type: "success",
              message: "操作成功!",
            });
          }
          this.handleSearch()
        })

            .catch(() => {
              this.$message({
                type: "info",
                message: "操作失败",
              });
            });
      }else {
        this.$message({
          type: "error",
          message: "请至少选中一条数据!",
        });
      }
    },
    noluqu(){
      if(this.multipleTable!=null&&this.multipleTable!=""){
        this.loginList = JSON.parse(localStorage.getItem("loginStrage"));
        let array = [];
        for (let i = 0; i < this.multipleTable.length; i++) {
          array.push(this.multipleTable[i].id);
        }
        let arr = array.toString();
        this.$axios({
          url: "hnjxjy-core/eduEnrolStudent/updateEduEnrolStudentisMatch",
          headers: this.loginList,
          method: "post",
          params: {
            ids: arr,
            status:0
          },
        }).then((res) => {
          if (res.data.code == 2000) {
            this.$message({
              type: "success",
              message: "操作成功!",
            });
          }
          this.handleSearch()
        })

            .catch(() => {
              this.$message({
                type: "info",
                message: "操作失败",
              });
            });
      }else {
        this.$message({
          type: "error",
          message: "请至少选中一条数据!",
        });
      }
    },
    // 点击修改
    editor(row) {
      this.dialogVisible = true;
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.handleSearch();
    },
    handleCurrentChange(val) {
      this.page = val;
      this.handleSearch();
    },
    handleSizeChange1(val) {
    },
    handleCurrentChange1(val) {
    },
    handleSizeChange2(val) {
    },
    handleCurrentChange2(val) {
    },
    // 点击删除
    deleteCorrespond() {
      this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$message({
            type: "success",
            message: "删除成功!",
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    reset() {
      this.search.name = '';
      this.search.batchId = '';
      this.search.publishStatus = '';
      this.search.siteId = '';
      this.search.stageId = '';
      this.search.profId = '';
    }
  },
};
</script>
<style scoped>
.search {
  text-align: left;
  margin-top: 1rem;
  /* margin-left: 1rem; */
  padding: 1rem;
}
.table-wrap {
  overflow: auto;
  /* margin-left: 1rem; */
  /* margin-bottom: 1rem; */
  padding: 1rem;
}
.demo-ruleForm {
  /* width: 90%; */
  height: calc(100% - 125px);
}
.el-form-input-width {
  width: 100%;
}
.rigth {
  width: 93%;
  display: inline-block;
  height: 870px;
  overflow-y: scroll;
  /* background-color: #fff; */
  background-color: rgba(164, 174, 224, 0.11);
  float: right;
}
.left {
  width: 7%;
  display: inline-block;
}
.portrait_Sprite {
  padding: 0.2rem 1rem;
  display: inline-block;
  background-color: skyblue;
  color: #fff;
  border-radius: 10%;
  font-size: 13px;
}
</style>
