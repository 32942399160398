<template>
  <div >
    <section >
      <div>
        <div style="padding: 1rem; border-bottom: 1px solid #ddd; text-align: left">
          短信审核
        </div>
        <!-- 导航栏-按钮 -->
        <section class="public-global-search">
          <el-button type="primary" icon="el-icon-circle-check"
                     @click="through(1)" v-permission="'eduMsgNew:examineMsg'">
            通过并发送
          </el-button>
          <el-button type="primary" icon="el-icon-circle-close" @click="dialogFormVisible = true" v-permission="'eduMsgNew:examineMsg'">驳回</el-button>
        </section>
        <!-- 搜索框和下拉框 -->
        <section class="select">
          <!-- 创建人 -->
          <el-form class="demo-ruleForm" inline>
            <el-form-item label="创建人" style="margin-bottom: 0">
              <el-input
                  v-model="bo.siteName"
                  clearable
                  placeholder="创建人"
                  style="width: 200px"
              >
                <el-button
                    slot="append"
                    icon="el-icon-search"
                    @click="query()"
                ></el-button>
              </el-input>

            </el-form-item>
            <el-form-item label="审核状态" style="margin-bottom: 0">
              <el-select
                  clearable
                  @change="query"
                  v-model="bo.checkStatus"
                  placeholder="请选择审核状态"
                  class="el-form-input-width">
                <el-option
                    v-for="item in shenhezhuangtai"
                    :key="item.value"
                    :label="item.value"
                    :value="item.label"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="短信类型" style="margin-bottom: 0">
              <el-select
                  clearable
                  @change="query"
                  v-model="bo.msgType"
                  placeholder="请选择短信类型"
                  class="el-form-input-width"
              >
                <el-option
                    v-for="item in duanxinleixing"
                    :key="item.value"
                    :label="item.value"
                    :value="item.label"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </section>
        <!-- 表格 -->
        <div class="public-global-main_contain">
          <el-table
              size="medium"
              :header-cell-style="{'text-align':'center'}"
              :cell-style="{'text-align':'center'}"
              height="650px"
              max-height="650px"
              ref="multipleTable"
              :data="tableData"
              border
              tooltip-effect="dark"
              @selection-change="handleSelectionChange">
            <el-table-column
                type="selection">
            </el-table-column>
            <!-- 序号 -->
            <el-table-column
                width="100px"
                label="序号"
                type="index">
            </el-table-column>
            <!-- 创建人 -->
            <el-table-column
                prop="siteName"
                sortable
                label="创建人">
            </el-table-column>
            <!-- 创建时间 -->
            <el-table-column
                prop="createTime"
                sortable
                label="创建时间">
            </el-table-column>
            <!-- 短信类型 -->
            <el-table-column
                prop="msgType"
                sortable
                label="短信类型">
              <template slot-scope="scope">
                <span v-if="scope.row.msgType == 0">招生</span>
                <span v-if="scope.row.msgType == 1">学校选择学生</span>
              </template>
            </el-table-column>
            <!-- 短信内容 -->
            <el-table-column
                prop="tempContent"
                label="短信内容">
            </el-table-column>
            <!-- 号码数量 -->
            <el-table-column
                prop="phoneCount"
                label="号码数量">
            </el-table-column>
            <!-- 实发数 -->
            <el-table-column
                prop="sendNum"
                sortable
                label="实发数">
            </el-table-column>
            <!-- 短信状态-->
            <el-table-column
                prop="checkStatus"
                sortable
                label="审核状态">
              <template slot-scope="scope">
                <span v-if="scope.row.checkStatus == 0">待审核</span>
                <span v-if="scope.row.checkStatus == 1">已发送</span>
                <span v-if="scope.row.checkStatus == 2">驳回</span>
              </template>
            </el-table-column>
            <!-- 审核时间-->
            <el-table-column
                prop="reviewTime"
                sortable
                label="审核时间">
            </el-table-column>
            <!-- 审核人-->
            <el-table-column
                prop="reviewName"
                sortable
                label="审核人">
            </el-table-column>
            <!-- 驳回原因-->
            <el-table-column
                prop="reason"
                label="驳回原因">
            </el-table-column>
          </el-table>
          <el-pagination
                  @size-change="handleSizeChange"
                  @current-change="handleCurrentChange"
                  :current-page="currentPage"
                  :page-sizes="[10, 20, 50, 100]"
                  :page-size="10"
                  layout="total, sizes, prev, pager, next, jumper"
                  :total="total">
          </el-pagination>
        </div>
        <!-- 分页器 -->

        <!-- 驳回弹窗 -->
        <el-dialog title="短信驳回" :visible.sync="dialogFormVisible">
          <el-form>
            <el-form-item label="驳回理由:" label-width="130px">
              <el-input autocomplete="off" type="textarea" v-model="examineMsg.reason"></el-input>
            </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button icon="el-icon-circle-close" @click="clearStart">取 消</el-button>
            <el-button type="primary" icon="el-icon-circle-check" @click="endBh(2)">确 定</el-button>
          </div>
        </el-dialog>
      </div>
    </section>
  </div>
</template>
<script>
export default {
  data() {
    return {
      dataList: [],
      examineMsg: {
        ids: null,
        checkStatus: null,//1成功2驳回
        reason: null,//审核原因
      },
      dialogFormVisible: false,
      currentPage: 1,//当前页
      total: 0,//信息条数
      tableData: [],
      // 审核状态
      shenhezhuangtai: [
        {
          label: 0,
          value: '待审核'
        },
        {
          label: 1,
          value: '已发送'
        },
        {
          label: 2,
          value: '驳回'
        },
      ],
      // 短信类型
      duanxinleixing: [
        {
          label: 0,
          value: '招生'
        },
        {
          label: 1,
          value: '学校选择学生'
        }
      ],
      bo: {
        page: 1,
        pageSize: 10,
        siteName: '',
        checkStatus: '',//审核状态0待审核 1已发送 2驳回
        msgType: '',//0 招生 1 学校选择学生
      },
    };
  },
  mounted() {
    this.query();
  },
  methods: {
    clearStart() {
      this.examineMsg = {
        ids: null,
        checkStatus: null,//1成功2驳回
        reason: null,//审核原因
      }
      this.dialogFormVisible = false
    },
    through(type){
      this.examineMsg.checkStatus = type;
      if (this.dataList.length <= 0) {
        this.$message.warning("请选择条目");
        return;
      }
      let array = [];
      for (let i = 0; i < this.dataList.length; i++) {
        array.push(JSON.stringify(this.dataList[i].id));
      }
      this.examineMsg.ids =array.toString();
      this.$axios_supermall.post("/hnjxjy-core/eduMsgNew/examineMsg", this.examineMsg).then(resp => {
        if (resp.data.code == 2000) {
          this.$message.success("操作成功");
        }else {
          this.$message.error("操作失败");
        }
        this.query();
        this.dialogFormVisible = false;
      })
    },
    endBh(type) {
      if (this.dataList.length <= 0) {
        this.$message.warning("请选择条目");
        return;
      }
      this.examineMsg.checkStatus = type;
      if (type == 2) {
         if (this.examineMsg.reason == null)
         {
           this.$message.warning("请填写驳回内容");
           return;;
         }
        this.dialogFormVisible = true;
      }

      let array = [];
      for (let i = 0; i < this.dataList.length; i++) {
        array.push(JSON.stringify(this.dataList[i].id));
      }

      this.examineMsg.ids =array.toString();
      this.$axios_supermall.post("/hnjxjy-core/eduMsgNew/examineMsg", this.examineMsg).then(resp => {
        if (resp.data.code == 2000) {
          this.$message.success("操作成功");
        }else {
          this.$message.error("操作失败");
        }
        this.query();
        this.dialogFormVisible = false;
      })
    },
    query() {
      this.getPermission('eduMsgNew:selectEduMsgNew', () => {
        this.openLoadingView();
        this.$axios_supermall.get("/hnjxjy-core/eduMsgNew/selectEduMsgNew?page=" + this.bo.page + "&pageSize=" + this.bo.pageSize + "&siteName=" + this.bo.siteName + "&checkStatus=" + this.bo.checkStatus + "&msgType=" + this.bo.msgType).then(resp => {
          if (resp.data.code == 2000) {
            this.tableData = resp.data.data.list;
            this.total = resp.data.data.total;

          }
          this.loadingView.close();
        }).catch(() => {
          this.loadingView.close();
        })
      });
    },

    handleSizeChange(val) {
    this.bo.pageSize = val;
    this.query();
    },
    handleCurrentChange(val) {
      this.bo.page = val;
      this.query();
    },
    handleSelectionChange(val) {
      this.dataList = val;
    },

    handleSearch() {

    }
  },
};
</script>

<style scoped>
/* 主盒子 */
.contain {
  width: 100%;
}

/* mains */
.mains {
  display: flex;
}

/* 左 */
.left {
  width: 7%;
  display: inline-block;
}

/* 右 */
.right {
  box-sizing: border-box;
  width: 93%;
  display: inline-block;
  overflow-y: scroll;
  /* background-color: #fff; */
  background-color: rgba(164, 174, 224, 0.11);
  text-align: left;
}

/* 搜索框和下拉框  */
.select {
  width: 98%;
  display: flex;
  padding: 1rem;
}

/* 导航栏-按钮  */
.bottons {
  padding: 1rem;
}

.demo-ruleForm {
  /* width: 90%; */
  height: calc(100% - 125px);
}

/* 分页器居中 */
.block {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
}

@media (max-width: 750px) {
  .left {
    display: none;
  }

  .right {
    width: 100%;
  }
}
</style>
