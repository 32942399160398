<template>
  <div id="contain">
    <div >
      <div
          style="padding: 1rem; border-bottom: 1px solid #ddd; text-align: left"
      >
        学生短信
      </div>
      <div class="top_btn" style="margin-left: 1.5vw">
        <el-button type="primary" icon="el-icon-circle-plus-outline" @click="addSms" v-permission="'eduMsgNew:insertEduMsgRecordStu'">新增短信</el-button>
        <el-button type="primary" icon="el-icon-remove-outline" @click="del()" v-permission="'eduMsgNew:deleteEduMsgNew'">删除</el-button>
      </div>
      <div class="search">
        <span class="">创建人</span>
        <el-input placeholder="请输入内容" v-model="bo.siteName" style="width: 300px;">
          <el-button slot="append" icon="el-icon-search" @click="query"></el-button>
        </el-input>
      </div>
      <div class="search">
        <span class="shenhe">审核状态</span>
        <el-select v-model="bo.checkStatus" placeholder="请选择" style="width: 300px;" @change="query">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div class="main_contain">
        <span id="aaaaa">共选中 {{ tableChecked.length }} 条数据</span>
        <el-table :data="tableData" border height="580px" max-height="580px" @selection-change="handleSelectionChange"
                  :header-cell-style="{'text-align':'center'}" :cell-style="{'text-align':'center'}" size="medium">
          <el-table-column type="selection" width="55">
          </el-table-column>
          <el-table-column label="序号" type="index" width="180">

          </el-table-column>
          <el-table-column label="创建人" width="180" prop="siteName">

          </el-table-column>
          <el-table-column prop="createTime" sortable label="创建时间">
          </el-table-column>
          <el-table-column prop="msgType" sortable label="短信类型">
            <template slot-scope="scope">
              <span v-if="scope.row.msgType == 0">招生</span>
              <span v-if="scope.row.msgType == 1">学校选择学生</span>
            </template>
          </el-table-column>
          <el-table-column prop="field1" sortable label="短信内容">
          </el-table-column>
          <el-table-column prop="phoneCount" sortable label="短信数量">
          </el-table-column>
          <el-table-column prop="checkStatus" sortable label="审核状态">
            <template slot-scope="scope">
              <span v-if="scope.row.checkStatus == 0">待审核</span>
              <span v-if="scope.row.checkStatus == 1">已发送</span>
              <span v-if="scope.row.checkStatus == 2">驳回</span>
            </template>
          </el-table-column>
          <el-table-column prop="reviewTime" sortable label="审核时间">
          </el-table-column>
          <el-table-column prop="reviewName" sortable label="审核人">
          </el-table-column>
          <el-table-column prop="reason" sortable label="驳回原因">
          </el-table-column>
        </el-table>
        <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="bo.page"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="bo.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="this.total">
        </el-pagination>
      </div>
      <el-dialog class="add" title="新增" :visible.sync="dialogVisible" width="35%" center>
        <el-button type="primary" icon="el-icon-circle-close" @click="dialogVisible = false" size="mini">关闭</el-button>
        <el-button type="primary" icon="el-icon-folder-add" @click="endStuCenter" size="mini">保存后关闭</el-button>
        <el-form ref="form" :model="stuBo" label-width="80px" style="margin-top: 30px" label-position="left">
            <el-form-item label="短信模板">
              <el-select @change="tempType"  v-model="stuBo.tempId" placeholder="请选择" style="width: 100%">
                <el-option
                    v-for="item in messageTemplate"
                    :key="item.id"
                    :label="item.tempName"
                    :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="短信签名">
              <el-select v-model="stuBo.singnId" placeholder="请选择" style="width: 100%">
                <el-option
                    v-for="item in messageSignature"
                    :key="item.id"
                    :label="item.signName"
                    :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          <el-form-item label="短信内容">
            <el-input type="textarea" :rows="4" :disabled="displayMsgContent" placeholder="请输入内容" resize="none" v-model="stuBo.msgContent">
            </el-input>
          </el-form-item>
          <el-form-item label="手机号">
            <el-input type="textarea" :rows="4" resize="none" v-model="stuBo.phoneNum" :disabled="true">
            </el-input>
          </el-form-item>
        </el-form>
        <el-button type="primary" icon="el-icon-circle-close" size="mini" @click="xqStu">选取学生
        </el-button>
        <el-button type="primary" icon="el-icon-folder-add" size="mini" @click="resetStuBo">重置</el-button>
        <el-button type="primary" icon="el-icon-folder-add" size="mini" @click="dialogVisibleUpload = true">添加号码
        </el-button>
      </el-dialog>

      <el-dialog class="add" title="选择学生" :visible.sync="dialogVisible2" width="70%" center>
        <el-button type="primary" size="mini" @click="dialogVisible2 = false">关闭</el-button>
        <el-button type="primary" size="mini" @click="endOnClose">保存并关闭</el-button>
        <el-form ref="form" label-width="100px" style="margin-top: 30px" size="mini" :inline="true">
          <el-form-item label="信息">
            <el-input placeholder="学生/学号/证件号" v-model="queryStuBo.info" style="width: 10vw">
              <el-button slot="append" icon="el-icon-search" @click="quertyStuTable">
              </el-button>
            </el-input>
          </el-form-item>
          <el-form-item label="证件类型">
            <el-select v-model="queryStuBo.certificateType" placeholder="请选择" clearable @change="quertyStuTable">
              <el-option
                  v-for="item in certificateTypeOpt"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="函授站">
            <el-select v-model="queryStuBo.siteId" placeholder="请选择" clearable learable @change="quertyStuTable">
              <el-option v-for="item in siteIdOpt" :key="item.id" :label="item.siteName" :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="年级">
            <el-select v-model="queryStuBo.gradeId" placeholder="请选择" clearable style="width: 10vw" learable
                       @change="quertyStuTable">
              <el-option v-for="item in gradeIdOpt" :key="item.id" :label="item.gradeName" :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="层次">
            <el-select v-model="queryStuBo.stageId" placeholder="请选择" clearable style="width: 10vw" learable
                       @change="quertyStuTable">
              <el-option v-for="item in stageIdOpt" :key="item.id" :label="item.stageName" :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="专业">
            <el-select v-model="queryStuBo.profId" placeholder="请选择" style="width: 10vw;" clearable learable
                       @change="quertyStuTable">
              <el-option v-for="item in profIdOpt" :key="item.id" :label="item.profName" :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>

        <el-table :data="tableData2" border @selection-change="handleSelectionChange2" v-loading="isLoading"
                  element-loading-text="加载数据中........">
          <el-table-column type="selection" width="55">
          </el-table-column>
          <el-table-column type="index" label="序号" width="100" align="center">
          </el-table-column>
          <el-table-column prop="name" label="姓名" align="center">
          </el-table-column>
          <el-table-column prop="studentNo" label="学号" align="center">
          </el-table-column>
          <el-table-column prop="siteName" label="函授站" align="center">
          </el-table-column>
          <el-table-column prop="gradeName" label="年级" align="center">
          </el-table-column>
          <el-table-column prop="stageName" label="层次" align="center">
          </el-table-column>
          <el-table-column prop="contactPhone" label="手机号" align="center">
          </el-table-column>
        </el-table>
        <el-pagination @size-change="handleSizeChange2"
                       @current-change="handleCurrentChange2"
                       :page-sizes="[10, 30, 50, 100]"
                       :page-size="curretPage2" background
                       layout="total, sizes, prev, pager, next, jumper"
                       :total="total2">
        </el-pagination>
      </el-dialog>
      <el-dialog title="上传文件" :visible.sync="dialogVisibleUpload" width="30%">
        <div style="margin-bottom: 20px">
          <el-button type="primary" @click="downloadbutton">下载模板</el-button>
          <span style="color: red"
          >提示：导入前请先下载模板，根据模版整理好数据后上传导入.</span
          >
        </div>
        <el-upload
            ref="upload"
            class="upload-demo"
            drag
            action="#"
            :show-file-list="false"
            :http-request="uploadFile"
            :before-upload="beforeUpload"
        >
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
          <div class="el-upload__tip" slot="tip">
            只能上传excel文件，且不超过50M
          </div>
        </el-upload>
      </el-dialog>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      messageTemplate: [],
      messageSignature: [],
      dialogVisibleUpload: false,
      displayMsgContent: false,
      selectEnd: [],
      isLoading: false,
      total2: 0,
      curretPage2: 1,
      tableData2: [],
      profIdOpt: [],
      stageIdOpt: [],
      gradeIdOpt: [],
      siteIdOpt: [],
      certificateTypeOpt: [
        {value: 0, label: '身份证'},
      ],
      queryStuBo: {
        page: 1,
        pageSize: 10,
        info: "",//姓名/学号/证件号
        certificateType: 0,//证件类型 0身份证
        siteId: "",//站点id
        stageId: "",//层次ID
        gradeId: "",//年级ID
        profId: "",//专业id
      },
      dialogVisible2: false,
      temptype:null,
      stuBo: {
        tempId: null,
        singnId: null,
        phoneNum: '',
        msgContent: null,
        tempType: null,
      },
      tableChecked: [],
      curretPage: 1,
      total: 0,
      bo: {
        siteName: null,//创建人
        checkStatus: 0,//审核状态 0待审核 1已发送 2驳回
        page: 1,
        pageSize: 10
      },
      options: [{
        value: 0,
        label: '未审核'
      }, {
        value: 1,
        label: '已发送'
      }, {
        value: 2,
        label: '驳回'
      },],
      options2: [],
      tableData: [],
      dialogVisible: false,
      value: '',
      form: '',
      checked: '',
      value1: '',
      input3: "",
      input: '',
      editorOption: '',
      content: ''

    }
  },
  mounted() {
    this.query();
    this.queryAllSite();
    this.listGL();
    this.queryEduStage();
    this.queryPro();
  },
  methods: {
    resetStuBo(){
      this.stuBo = {
        tempId: null,
        singnId: null,
        phoneNum: '',
        msgContent: null,
      }
    },
    queryMessageTemplate(){
      this.$axios_supermall.get("/hnjxjy-core/eduMsgTemp/queryAllMsgTemp")
          .then((res) => {
            this.messageTemplate = res.data.data;
          })
          .catch((err) => {
          });
    },
    queryMessageSignature(){
      this.$axios_supermall.get("/hnjxjy-core/eduMsgNew/queryEduMsgSignList")
          .then((res) => {
            this.messageSignature = res.data.data;
          })
          .catch((err) => {
          });
    },
    addSms() {
      this.stuBo = {
        phoneNum: '',
        msgContent: null,
      };
      this.dialogVisible = true;
      setTimeout(() => {
        this.openLoadingView();
      }, 100);
      setTimeout(() => {
        this.queryMessageSignature();
        this.queryMessageTemplate();
        this.loadingView.close();
      }, 1000);
    },
    del() {
      if (this.tableChecked.length <= 0) {
        this.$message.warning("请选择条目");
        return;
      }
      this.$confirm(`您确定要删除${this.tableChecked.length}数据吗?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let ids = "";
        this.tableChecked.forEach(item => {
          ids = ids + item.id + ",";
        });
        ids = ids.substr(0, ids.length - 1).trim();
        this.openLoadingView();
        this.$axios_supermall.get("/hnjxjy-core/eduMsgNew/deleteEduMsgNew?ids=" + ids).then(resp => {
          if (resp.data.code == 2000) {
            this.$message.success("处理成功");
            this.query();
          }
          this.loadingView.close();
        });
      }).catch(() => {
        this.$message({
          type: "info",
          message: "已取消删除",
        });
      });

    },
    tempType(id){
     let list =  this.messageTemplate.filter(item =>{
        return item.id == id;
      });
     this.temptype = list[0].tempType;
      if(this.temptype==2){
        this.displayMsgContent = true
        this.stuBo.msgContent = null
      }else{
        this.displayMsgContent = false
      }
    },
    endStuCenter() {
      if (null == this.stuBo.tempId){
        return this.$message.error("请选择短信模板")
      }
      if (null == this.stuBo.singnId){
        return this.$message.error("请选择短信签名")
      }
      if(this.temptype!=2){
        if (null == this.stuBo.msgContent || this.stuBo.msgContent === ""){
          return this.$message.error("请填写短信内容")
        }
      }
      if (null == this.stuBo.phoneNum || this.stuBo.phoneNum === ""){
        return this.$message.error("手机号不可为空")
      }
      this.openLoadingView();
      let formData = new FormData();
      formData.append("tempId",this.stuBo.tempId)
      formData.append("singnId",this.stuBo.singnId)
      formData.append("phoneNum",this.stuBo.phoneNum)
      if(this.temptype!=2){
        formData.append("msgContent",this.stuBo.msgContent)
      }

      this.$axios_supermallData.post("hnjxjy-core/eduMsgNew/insertEduMsgRecordStu",formData).then(resp => {
        if (resp.data.code === 2000) {
          this.$message.success("添加成功");
          this.query();
          this.dialogVisible = false;
          this.displayMsgContent = false
          this.loadingView.close()
        }
      })
    },
    downloadbutton() {
      let objPar = JSON.parse(localStorage.getItem("loginStrage"));
      this.$axios({
        method: "GET",
        url: "hnjxjy-core/eduMsgNew/exportTemplateMsgRecord",
        responseType: "blob",
        headers: objPar,
      }).then((res) => {
        let blob = new Blob([res.data], {
          type: "application/vnd.ms-excel",
        });
        let url = window.URL.createObjectURL(blob);
        window.location.href = url;
      })
          .catch((err) => {
          });
    },
    uploadFile(file) {
      let fileDate = file.file;
      let forms = new FormData();
      forms.append("file", fileDate);
      let objPar = JSON.parse(localStorage.getItem("loginStrage"));
      this.$axios({
        method: "POST",
        url: "hnjxjy-core/eduMsgNew/phoneExcelImport",
        data: forms,
        headers: objPar,
      }).then((res) => {
        if (res.data.code == 2000) {
          this.$message.success(res.data.message);
          res.data.data.forEach(item => {
            if (this.stuBo.phoneNum==''){
              this.stuBo.phoneNum+= (item+ ',');
            }else{
              let last = this.stuBo.phoneNum.substring(this.stuBo.phoneNum.length-1)
              if (last == ','){
                this.stuBo.phoneNum+= (item+',');
              }else{
                this.stuBo.phoneNum+= (','+item+',');
              }
            }

          });
          this.stuBo.phoneNum = this.stuBo.phoneNum.substring(0,this.stuBo.phoneNum.length-1)
          // this.stuBo.phoneNum = this.stuBo.phoneNum.substr(0, phone.length - 1).trim();
          this.dialogVisibleUpload = false;
        }
      });
    },
    // 导入上传文件前判断文件格式
    beforeUpload(file) {
      const isXls = file.type === "application/vnd.ms-excel";
      const isXlsx =
          file.type ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      if (!isXls && !isXlsx) {
        this.$message.error("上传文件只能是XLS/XLSX格式！");
        return false;
      }
      return isXls || isXlsx;
    },
    endOnClose() {
      if (this.selectEnd.length <= 0) {
        this.$message.warning("请选择条目");
        return;
      }
      this.selectEnd.forEach(item => {
        if (this.stuBo.phoneNum==''){
          this.stuBo.phoneNum+= (item.contactPhone+ ',');
        }else{
          let last = this.stuBo.phoneNum.substring(this.stuBo.phoneNum.length-1)
          if (last == ','){
            this.stuBo.phoneNum+= (item.contactPhone+',');
          }else{
            this.stuBo.phoneNum+= (','+item.contactPhone+',');
          }
        }
      });
      this.stuBo.phoneNum = this.stuBo.phoneNum.substring(0,this.stuBo.phoneNum.length-1);
      this.dialogVisible2 = false;
    },
    xqStu() {
      this.dialogVisible2 = true;
      this.quertyStuTable();
    },
    handleSizeChange2(val) {
      this.queryStuBo.pageSize = val;
      this.quertyStuTable()
    },
    handleCurrentChange2(val) {
      this.queryStuBo.page = val;
      this.quertyStuTable()
    },
    quertyStuTable() {
      this.isLoading = true;
      let formData = new FormData();
      formData.append("info",this.queryStuBo.info)
      formData.append("certificateType",this.queryStuBo.certificateType)
      formData.append("siteId",this.queryStuBo.siteId)
      formData.append("stageId",this.queryStuBo.stageId)
      formData.append("gradeId",this.queryStuBo.gradeId)
      formData.append("profId",this.queryStuBo.profId)
      formData.append("page",this.queryStuBo.page)
      formData.append("pageSize",this.queryStuBo.pageSize)
      this.$axios_supermallData.post("/hnjxjy-core/eduStudentInfo/list", formData).then(resp => {
        if (resp.data.code == 2000) {
          this.total2 = resp.data.data.total;
          this.tableData2 = resp.data.data.list;
        }
        this.isLoading = false;
      }).catch(() => {
        this.isLoading = false;
      })
    },
    handleSelectionChange2(val) {
      this.selectEnd = val;
    },
    queryPro() {
      this.$axios_supermall.post("/hnjxjy-core/eduProfession/listGL").then(resp => {
        if (resp.data.code == 2000) {
          this.profIdOpt = resp.data.data;
        }
      })
    },
    queryEduStage() {
      this.$axios_supermall.post("/hnjxjy-core/eduStage/listGL").then(resp => {
        if (resp.data.code == 2000) {
          this.stageIdOpt = resp.data.data;
        }
      })
    },
    listGL() {
      this.$axios_supermall.post("/hnjxjy-core/eduGrade/listGL").then(resp => {
        if (resp.data.code == 2000) {
          this.gradeIdOpt = resp.data.data;
        }
      })
    },
    queryAllSite() {
      let formData = new FormData();
      formData.append("isAll","1");
      this.$axios_supermallData.post("/hnjxjy-core/eduSite/queryAllSite", formData).then(resp => {
        if (resp.data.code == 2000) {
          this.siteIdOpt = resp.data.data;
        }
      })
    },
    handleSelectionChange(val) {
      this.tableChecked = val;
    },
    query() {
      this.getPermission('eduMsgNew:selectEduMsgNewBySchool', () => {
        this.openLoadingView();
        this.$axios_supermall.get("/hnjxjy-core/eduMsgNew/selectEduMsgNewBySchool", {
          params:{
            page:this.bo.page,
            pageSize:this.bo.pageSize,
            siteName:this.bo.siteName,
            checkStatus:this.bo.checkStatus
          }
        }).then(resp => {
          if (resp.data.code == 2000) {
            this.tableData = resp.data.data.list;
            this.total = resp.data.data.total;
            this.curretPage = resp.data.data.page;
          }
          this.loadingView.close();
        }).catch(() => {
          this.loadingView.close();
        });
      });
    },
    handleSizeChange(val) {
      this.bo.pageSize = val;
      this.query();
    },
    handleCurrentChange(val) {
      this.bo.page = val;
      this.query();
    },

  }
}
</script>

<style scoped>
.chuangjianren {
  font-weight: bold;
  color: #555;
}

#aaaaa {
  color: lightskyblue;
}

.top_btn {
  margin: 10px 10px 10px 10px;
  text-align: left;
}

.search {
  margin: 20px 10px 30px 10px;
  text-align: left;
  display: inline-block;
}

.search span {
  margin-right: 20px;
}

.main_contain {
  margin: 20px 10px 30px 10px;
  text-align: left;
}

.yingyong {
  margin-right: 100px;
  text-align: left;
}

.right {
  float: right;
  width: 93%;
  display: inline-block;
  height: 870px;
  overflow-y: scroll;
  /* background-color: #fff; */
  background-color: rgba(164, 174, 224, 0.11);

  text-align: left;
}

.left {
  width: 7%;
  display: inline-block;
}


.inputs {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
}
</style>
