<template>
    <div>
        <div>
            <!-- 教学计划 -->
            <div style="padding: 1rem; border-bottom: 1px solid #ddd; text-align: left">
                教学计划
            </div>

            <!-- 名称-是否同步 -->
            <section class="mingcheng">
                <el-form
                        :model="bo"
                        ref="bo"
                        :inline="true"
                        class="demo-form-inline"
                        label-width="80px"
                        size="medium">

                    <!-- 添加 ---重置按钮 -->
                    <section class="tianjia">
                        <section style="margin-bottom: 20px">
                            <el-button type="primary" size="medium" @click="addPut"
                                       v-permission="'eduTeachPlanCourse:add'">添加
                            </el-button>

                            <el-button-group>
                                <el-button type="primary" size="medium" @click="importTemplate" v-permission="'upload:uploadJXJH'">导入教学计划<i
                                        class="el-icon-download"></i></el-button>
                                <el-button type="primary" size="medium" @click="download" v-permission="'eduStatPlanCourse:export'">导出教学计划<i
                                        class="el-icon-download"></i></el-button>
                            </el-button-group>
                        </section>
                        <section>
                            <el-button type="primary" size="medium" v-permission="'eduTeachPlan:list'" @click="query">
                                查询
                            </el-button>
                            <el-button type="primary" size="medium" @click="rest(bo)">重置</el-button>
                        </section>
                    </section>
                    <el-form-item label="名称" prop="planName">
                        <el-input v-model="bo.planName" style="width: 220px" placeholder="名称"></el-input>
                    </el-form-item>
                    <el-form-item label="年级" prop="gradeId">
                        <grade-select-all v-model="bo.gradeId" @input="query" ref="grades"></grade-select-all>
                    </el-form-item>
                    <el-form-item label="层次" prop="stageId">
                        <stage-select-all v-model="bo.stageId" @input="query" ref="stages"></stage-select-all>
                    </el-form-item>
                    <el-form-item label="专业" prop="profId">
                        <prof-select-all v-model="bo.profId" @input="query" ref="profs"></prof-select-all>
                    </el-form-item>
                    <el-form-item label="站点" prop="schoolId">
                        <site-select-all v-model="bo.schoolId" @input="query" ref="site"></site-select-all>
                    </el-form-item>

                </el-form>
            </section>
            <!-- 共选中0条数据 -->
            <div style="padding: 18px; text-align: left; color: #1890ff; font-size: 17px">
                共选中{{ multipleSelection.length }}条数据
            </div>
            <div style="padding: 18px; font-size: 17px">
                <el-table
                        size="medium"
                        height="500px"
                        max-height="500px"
                        :header-cell-style="{'text-align':'center'}"
                        :cell-style="{'text-align':'center'}"
                        ref="multipleTable"
                        :data="tableData"
                        border
                        tooltip-effect="dark"
                        style="width: 100%"
                        @selection-change="handleSelectionChange"
                >
                    <el-table-column type="selection" width="55"></el-table-column>
                    <el-table-column label="序号" type="index" width="60">
                    </el-table-column>
                    <el-table-column
                            prop="planName"
                            sortable
                            show-overflow-tooltip
                            label="名称"
                    >
                    </el-table-column>
                    <el-table-column
                            prop="gradeName"
                            sortable
                            show-overflow-tooltip
                            label="年级"
                    >
                    </el-table-column>
                    <el-table-column
                            prop="stageName"
                            sortable
                            show-overflow-tooltip
                            label="层级"
                    >
                    </el-table-column>
                    <el-table-column
                            prop="profName"
                            sortable
                            show-overflow-tooltip
                            label="专业"
                    >
                    </el-table-column>
                    <el-table-column
                            prop="remark"
                            sortable
                            show-overflow-tooltip
                            label="备注"
                    >
                    </el-table-column>
                    <!-- 产看课程 -->
                    <el-table-column
                            sortable
                            width="150"
                            show-overflow-tooltip
                            label="查看课程">
                        <template slot-scope="scope">
                            <el-link type="primary" :underline="false" @click="lookWork(scope.row)"
                                     v-permission="'eduTeachPlanCourse:list'">查看课程
                            </el-link>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作"
                                     align='center'

                    >
                        <template slot-scope="scope">
                            <el-button size="mini" @click="updateShow( scope.row)" v-permission="'eduTeachPlan:getOne'">
                                编辑
                            </el-button>
                            <el-button size="mini" type="danger" @click="handleDelete(scope.row)"
                                       v-permission="'eduTeachPlan:remove'">删除
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <!-- 分页 -->
                <div style="padding: 18px; font-size: 17px">
                    <div class="block">
                        <el-pagination
                                @size-change="handleSizeChange"
                                @current-change="handleCurrentChange"
                                :current-page="bo.page"
                                :page-sizes="[10, 20, 50, 100]"
                                :page-size="bo.pageSize"
                                layout="total, sizes, prev, pager, next, jumper"
                                :total="total">
                        </el-pagination>
                    </div>
                </div>
            </div>
        </div>

        <!-- 导入 上传文件 -->
        <el-dialog title="上传文件" :visible.sync="dialogVisibleUploads" width="30%">
            <div style="margin-bottom: 20px">
                <el-button type="primary" @click="downloadbutton">下载模板</el-button>
                <span style="color: red"
                >提示：导入前请先下载模板，根据模版整理好数据后上传导入.</span
                >
            </div>
            <el-upload
                    class="upload-demo"
                    drag
                    action="#"
                    :show-file-list="false"
                    :http-request="uploadFile"
                    :before-upload="beforeUpload"
            >
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                <div class="el-upload__tip" slot="tip">
                    只能上传excel文件，且不超过50M
                </div>
            </el-upload>
        </el-dialog>

        <!-- 导入 上传文件 -->
        <el-dialog title="上传文件" :visible.sync="dialogVisibleUploades" width="30%">
            <div style="margin-bottom: 20px">
                <el-button type="primary" @click="downloadbuttons" v-permission="'eduTeachPlanCourse:exportMB'">
                    下载模板
                </el-button>
                <span style="color: red">
                    提示：导入前请先下载模板，根据模版整理好数据后上传导入.
                </span>
            </div>
            <el-upload
                    class="upload-demo"
                    drag
                    action="#"
                    :show-file-list="false"
                    :http-request="uploadFiles"
                    :before-upload="beforeUpload"
            >
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                <div class="el-upload__tip" slot="tip">
                    只能上传excel文件，且不超过50M
                </div>
            </el-upload>
        </el-dialog>

        <el-dialog class="add" :title="this.titles" :visible.sync="putDialog" @close="closeDialog('ruleForm')"
                   width="25%" center
                    @opened="dialogopened">
            <el-form
                    :model="addBo" ref="addForm"
                    label-width="80px"
                    size="mini">
                <el-form-item label="年级" prop="gradeId">
                    <grade-select-all v-model="addBo.gradeId" style="width: 12vw" @input="sendGra"
                                      ref="grade"></grade-select-all>
                </el-form-item>
                <el-form-item label="层次" prop="stageId">
                    <stage-select-all v-model="addBo.stageId" style="width: 12vw" @input="sendSta"
                                      ref="stage"></stage-select-all>
                </el-form-item>
                <el-form-item label="专业" prop="profId">
                    <prof-select-all v-model="addBo.profId" style="width: 12vw" @input="sendPro"
                                     ref="prof"></prof-select-all>
                </el-form-item>
                <el-form-item label="名称" prop="planName">
                    <el-input v-model="addBo.planName" style="width: 12vw"></el-input>
                </el-form-item>
                <el-form-item label="建议名称" prop="subName">
                    <el-input v-model="addBo.subName" style="width: 12vw" disabled></el-input>
                </el-form-item>
                <el-form-item label="备注" prop="remark">
                    <el-input v-model="addBo.remark" style="width: 12vw"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button @click="end" v-permission="'eduTeachPlan:edit'">保存</el-button>
                    <el-button @click="clears">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>

      <el-dialog class="add" :title="this.titles" :visible.sync="putDialogs" @close="closeDialog('ruleForm')"
                 width="25%" center
                 >
        <el-form
            :model="addBo" ref="addForm"
            label-width="80px"
            size="mini">
          <el-form-item label="年级" prop="gradeId">
            <grade-select-all v-model="addBo.gradeId" style="width: 12vw" @input="sendGra"
                              ref="grade"></grade-select-all>
          </el-form-item>
          <el-form-item label="层次" prop="stageId">
            <stage-select-all v-model="addBo.stageId" style="width: 12vw" @input="sendSta"
                              ref="stage"></stage-select-all>
          </el-form-item>
          <el-form-item label="专业" prop="profId">
            <prof-select-all v-model="addBo.profId" style="width: 12vw" @input="sendPro"
                             ref="prof"></prof-select-all>
          </el-form-item>
          <el-form-item label="名称" prop="planName">
            <el-input v-model="addBo.planName" style="width: 12vw"></el-input>
          </el-form-item>
          <el-form-item label="建议名称" prop="subName">
            <el-input v-model="addBo.subName" style="width: 12vw" disabled></el-input>
          </el-form-item>
          <el-form-item label="备注" prop="remark">
            <el-input v-model="addBo.remark" style="width: 12vw"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button @click="end" v-permission="'eduTeachPlan:edit'">保存</el-button>
            <el-button @click="clears">取消</el-button>
          </el-form-item>
        </el-form>
      </el-dialog>

        <el-dialog class="add" :title="title" :visible.sync="putDialog2" width="80%" center>
            <div>
                <el-button type="primary" icon="el-icon-circle-close" size="small" @click="addStu"
                           v-permission="'eduTeachPlanCourse:add'">添加课程
                </el-button>
                <el-button type="primary" icon="el-icon-folder-add" size="small" @click="remove"
                           v-permission="'eduTeachPlanCourse:remove'">移除课程
                </el-button>
                <el-button v-permission="'eduTeachPlanCourse:exportExcelCourse'" type="primary" icon="el-icon-circle-close" size="small" @click="importcourse">导入课程
                </el-button>
                <el-button type="primary" icon="el-icon-folder-add" size="small" @click="isEnable(1)"
                           v-permission="'eduTeachPlanCourse:isEnable'">开启课程
                </el-button>
                <el-button type="primary" icon="el-icon-circle-close" size="small" @click="isEnable(0)"
                           v-permission="'eduTeachPlanCourse:isEnable'">关闭课程
                </el-button>
            </div>
            <div style="margin-top: 20px">
                <el-input style="width: 30vw" v-model="newBo.info">
                    <el-button slot="append" icon="el-icon-search" type="primary" @click="queryLook"></el-button>
                </el-input>
            </div>
            <div style="margin-top: 20px">
                <el-table
                        height="500"
                        size="mini"
                        ref="multipleTable"
                        :data="tableData2"
                        border
                        tooltip-effect="dark"
                        style="width: 100%"
                        @selection-change="selectStudent">
                    <el-table-column type="selection" width="55"></el-table-column>
                    <el-table-column
                            type="index"
                            label="序号">
                    </el-table-column>
                    <el-table-column
                            prop="courseName"
                            show-overflow-tooltip
                            label="课程名称"
                    >
                    </el-table-column>
                    <el-table-column
                            prop="courseCode"
                            show-overflow-tooltip
                            label="课程编号"
                    >
                    </el-table-column>
                    <el-table-column
                            prop="enabled"
                            show-overflow-tooltip
                            label="是否开课"
                    >
                        <template slot-scope="scope">
                            <span v-if="scope.row.enabled == 0">是</span>
                            <span v-if="scope.row.enabled == 1">否</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                            prop="courseType"
                            label="课程类型(可编辑)"
                    >
                        <template slot-scope="scope">
                            <el-select v-model="scope.row.courseType" @change="updateFun(scope.row)"
                                       :disabled="scope.row.enabled == 0">
                                <el-option
                                        v-for="item in typeOpt"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value">
                                </el-option>
                            </el-select>
                        </template>
                    </el-table-column>
                    <el-table-column
                            prop="period"
                            width="200"
                            label="学时(可编辑)"
                    >
                        <template slot-scope="scope">
                            <el-input-number v-model="scope.row.period" @change="updateFun(scope.row)" :min="1"
                                             :disabled="scope.row.enabled == 0"></el-input-number>
                        </template>
                    </el-table-column>
                    <el-table-column
                            prop="semester"
                            sortable
                            show-overflow-tooltip
                            label="学期(可编辑)"
                    >
                        <template slot-scope="scope">
                            <el-select v-model="scope.row.semester" @change="updateFun(scope.row)"
                                       :disabled="scope.row.enabled == 0">
                                <el-option
                                        v-for="item in semesters"
                                        :key="item.id"
                                        :label="item.maxTerm"
                                        :value="item.id">
                                </el-option>
                            </el-select>
                            <!--              <el-input v-model="scope.row.semester" @blur="updateFun(scope.row)"></el-input>-->
                        </template>
                    </el-table-column>
                    <el-table-column
                            prop="degreeFlag"
                            sortable
                            show-overflow-tooltip
                            label="是否学位课"
                    >
                        <template slot-scope="scope">
                            <el-select v-model="scope.row.degreeFlag" @change="updateFun(scope.row)"
                                       :disabled="scope.row.enabled == 0">
                                <el-option
                                        v-for="item in degreeFlag"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value">
                                </el-option>
                            </el-select>
                        </template>
                    </el-table-column>
                    <el-table-column
                            prop="testType"
                            sortable
                            show-overflow-tooltip
                            label="考试类型"
                    >
                        <template slot-scope="scope">
                            <el-select v-model="scope.row.testType" @change="updateFun(scope.row)"
                                       :disabled="scope.row.enabled == 0">
                                <el-option
                                        v-for="item in testTypeOpt"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value">
                                </el-option>
                            </el-select>
                        </template>
                    </el-table-column>
                </el-table>
                <!--        <el-pagination-->
                <!--            @size-change="handleSizeChange2"-->
                <!--            @current-change="handleCurrentChange2"-->
                <!--            :current-page="1"-->
                <!--            :page-sizes="[10, 30, 50, 100]"-->
                <!--            :page-size="1"-->
                <!--            layout="total, sizes, prev, pager, next, jumper"-->
                <!--            :total="total2">-->
                <!--        </el-pagination>-->
            </div>
        </el-dialog>

        <el-dialog class="add" :title="title" :visible.sync="putDialog3" width="80%" center>
            <div>
                <el-button type="primary" icon="el-icon-circle-close" size="mini" @click="endStu">保存</el-button>
                <el-input placeholder="名称" style="width: 12vw;margin-left: 20px" size="mini">
                    <el-button type="primary" slot="append" icon="el-icon-search" size="mini"></el-button>
                </el-input>
            </div>
            <div style="margin-top: 20px">
                <el-table
                        ref="multipleTable"
                        :data="tableDataStu"
                        border
                        tooltip-effect="dark"
                        style="width: 100%"
                        @selection-change="handleSelectionChange3"
                >
                    <el-table-column type="selection" width="55"></el-table-column>
                    <el-table-column
                            width="50"
                            sortable
                            type="index"
                            label="序号">
                    </el-table-column>
                    <el-table-column
                            prop="courseName"
                            label="课程名称">
                    </el-table-column>
                    <el-table-column
                            prop="courseCode"
                            label="课程编号">
                    </el-table-column>
                </el-table>
            </div>
        </el-dialog>
    </div>
</template>
<script>
    import GradeSelectAll from "../../components/public/grade-select-all";
    import StageSelectAll from "../../components/public/stage-select-all";
    import ProfSelectAll from "../../components/public/prof-select-all";
    import SiteSelectAll from "../../components/public/site-select-all";

    export default {
        components: {
            SiteSelectAll,
            ProfSelectAll,
            StageSelectAll,
            GradeSelectAll
        },
        data() {
            return {
                kecId: null,
                snedStu: [],
                tableDataStu: [],
                putDialog3: false,
                testTypeOpt: [
                    {value: 0, label: '考查课'},
                    {value: 1, label: '在线考试'},
                    {value: 2, label: '书面考试'},
                ],
                semesters: [],
                degreeFlag: [
                    {value: '0', label: '否'},
                    {value: '1', label: '是'},
                ],
                typeOpt: [{
                    value: 0,
                    label: '公共必修'
                }, {
                    value: 1,
                    label: '公共选修'
                }, {
                    value: 2,
                    label: '专业必修'
                }, {
                    value: 3,
                    label: '专业选修'
                },],
                newBo: {
                    info: '',
                    planId: null,
                    status: ''
                },
                total2: 0,
                putDialog2: false,
                putDialog: false,
              putDialogs: false,
                dialogVisibleUploads: false,
                dialogVisibleUploades: false,
                addBo: {
                    id: null,
                    gradeId: '',//年级ID
                    stageId: '',//层次ID
                    profId: '',//专业ID
                    planName: '',//计划名称
                    subName: '',//建议名称
                    remark: ''//备注
                },
                total: 0,
                planCourseId: 0,
                bo: {
                    page: 1,
                    pageSize: 10,
                    planName: '',
                    gradeId: '',//年级ID
                    stageId: '',//层次ID
                    profId: '',//专业ID
                    schoolId: '',//站点id
                },
                formInline: {
                    user: "",
                    region: "",
                },
                tableData: [],
                currentPage4: 1,
                pageSize: 100,
                multipleSelection: [],
                title: '',
                titles: '',
                pop: [{name: ""}, {name: ""}, {name: ""}],
                tableData2: [],
                id: null,
                grandId: null,
                selectStu: [],
                rowItem:{}
            };
        },
        mounted() {
            this.query();
        },
        methods: {
            remove() {
                if (this.selectStu.length <= 0) {
                    this.$message.warning("请选择条目");
                }

                let ids = "";
                this.selectStu.forEach(item => {
                    ids = ids + item.planCourseId + ",";
                });
                ids = ids.substr(0, ids.length - 1).trim();

                let formData = new FormData();
                formData.append("ids", ids);
                this.$axios_supermallData.post("hnjxjy-core/eduTeachPlanCourse/remove", formData).then(resp => {
                    if (resp.data.code == 2000) {
                        this.$message.success("操作成功");
                        this.queryLook();
                    }
                })
            },
            rest(form) {
                this.$refs.bo.resetFields()
                this.$refs['grades'].clearValue();
                this.$refs['stages'].clearValue();
                this.$refs['profs'].clearValue();
                this.$refs['site'].clearValue();
            },
            //保存课程
            endStu() {
                if (this.snedStu.length <= 0) {
                    this.$message.warning("请选择条目");
                }
                let ids = "";
                this.snedStu.forEach(item => {
                    ids = ids + item.id + ",";
                });
                ids = ids.substr(0, ids.length - 1).trim();
                let bo = {
                    planId: this.id,
                    ids: ids
                };
                this.$axios_supermall.post("/hnjxjy-core/eduTeachPlanCourse/insertEduPlanCourse", bo).then(resp => {
                    if (resp.data.code == 2000) {
                        this.putDialog3 = false;
                        this.queryLook();
                    }
                })

            },
            clears() {
                this.putDialogs = false
                this.putDialog = false
            },
            //开启关闭课程
            isEnable(type) {
                if (this.selectStu.length <= 0) {
                    this.$message.warning("请选择条目");
                }

                let ids = "";
                this.selectStu.forEach(item => {
                    ids = ids + item.planCourseId + ",";
                });
                ids = ids.substr(0, ids.length - 1).trim();
                let formData = new FormData();
                formData.append("ids", ids);
                formData.append("status", type);
                this.$axios_supermallData.post("/hnjxjy-core/eduTeachPlanCourse/isEnable", formData).then(resp => {
                    if (resp.data.code == 2000) {
                        this.$message.success("操作成功");
                        this.queryLook();
                    }
                });
            },
            handleSelectionChange3(val) {
                this.snedStu = val;
            },
            //添加课程查询
            addStu() {
                this.$axios_supermall.post("/hnjxjy-core/eduTeachPlanCourse/list?info=" + this.newBo.info + "&planId=" + this.id + "&status=1").then(resp => {
                    if (resp.data.code == 2000) {
                        this.tableDataStu = resp.data.data.list;
                    }
                });
                this.putDialog3 = true;
            },
            //修改课程内容
            updateFun(row) {
              if (row.courseType == null || row.semester === ""){
                return this.$message.error("请选择课程类型")
              }
              if (row.semester == null || row.semester === ""){
                return this.$message.error("请选择学期")
              }
              if (row.period == null || row.period === ""){
                return this.$message.error("请填写学时时长")
              }
              if (row.degreeFlag == null || row.degreeFlag === ""){
                return this.$message.error("请设置学位课")
              }
              if (row.testType == null || row.testType === ""){
                return this.$message.error("请选择考试类型")
              }
                this.getPermission('eduTeachPlanCourse:edit', () => {
                    let formData = new FormData();
                    formData.append("id", row.planCourseId);
                    formData.append("courseType", row.courseType);
                    formData.append("period", row.period);
                    formData.append("semester", row.semester);
                    formData.append("degreeFlag", row.degreeFlag);
                    formData.append("testType", row.testType);
                    this.$axios_supermallData.post("/hnjxjy-core/eduTeachPlanCourse/edit", formData).then(resp => {
                        if (resp.data.code == 2000) {
                            this.$message.success("修改成功");
                            this.queryLook();
                        }else {
                          this.$message.error("修改失败");
                        }
                    }).catch((err) => {
                      this.$message.error("修改失败");
                    });
                });
            },
            importcourse() {
                this.dialogVisibleUploades = true
            },
            handleCurrentChange2() {

            },
            handleSizeChange2() {

            },
            selectStudent(val) {
                this.selectStu = val;
            },
            lookWork(row) {
                this.title = "查看课程(" + row.planName + ")";
                this.id = row.id;
                this.grandId = row.gradeId;
                this.queryLook();
                this.QuerySemester();
                this.putDialog2 = true;
            },
            //查询学期
            QuerySemester() {
                this.$axios_supermall.get("/hnjxjy-core/eduTerm/queryTermByGradeId?gradeId=" + this.grandId).then(resp => {
                    if (resp.data.code == 2000) {
                        this.semesters = []
                        for (let i = resp.data.data.maxTerm; i > 0; i--) {
                            this.semesters.push({id: i, maxTerm: i});
                        }
                    }
                })
            },
            queryLook() {
                this.getPermission('eduTeachPlanCourse:list', () => {
                    this.openLoadingView();
                    this.$axios_supermall.post("/hnjxjy-core/eduTeachPlanCourse/list?info=" + this.newBo.info + "&planId=" + this.id + "&status=" + this.newBo.status).then(resp => {
                        if (resp.data.code == 2000) {
                            this.tableData2 = resp.data.data.list;
                        }
                    });
                });
            },
            updateShow(row) {
                this.putDialog = true;
                this.rowItem = row;
            },
            dialogopened(){
                this.openLoadingView();
                this.$axios_supermall.get("/hnjxjy-core/eduTeachPlan/getOne?id=" + this.rowItem.id).then(resp => {
                    if (resp.data.code == 2000 && resp.data.data!=null) {
                        this.addBo = resp.data.data;
                        this.$nextTick(() => {
                            this.$refs['grade'].selValue = this.addBo.gradeId;
                            this.$refs['stage'].selValue = this.addBo.stageId;
                            this.$refs['prof'].selValue = this.addBo.profId;
                        })
                    }
                });
                this.titles = "编辑";
            },
            end() {
                if (this.addBo.id != null) {
                    let formData = new FormData();
                    formData.append("id", this.addBo.id);
                    formData.append("gradeId", this.addBo.gradeId);
                    formData.append("stageId", this.addBo.stageId);
                    formData.append("profId", this.addBo.profId);
                    formData.append("planName", this.addBo.planName);
                    formData.append("remark", this.addBo.remark);
                    this.$axios_supermallData.post("/hnjxjy-core/eduTeachPlan/edit", formData).then(resp => {
                        if (resp.data.code == 2000) {
                            this.$message.success("操作成功");
                            this.putDialog = false;
                            this.$refs['addForm'].resetFields();
                            this.$refs['grade'].clearValue();
                            this.$refs['stage'].clearValue();
                            this.$refs['prof'].clearValue();
                            this.query()
                        }
                    });
                } else {
                    if (this.addBo.gradeId != null && this.addBo.gradeId != "" && this.addBo.profId != null && this.addBo.profId != "" && this.addBo.stageId != null && this.addBo.stageId != "" && this.addBo.planName != null && this.addBo.planName != "") {
                        this.$axios_supermall.post("/hnjxjy-core/eduTeachPlan/addEduTeachPlanService", this.addBo).then(resp => {
                            if (resp.data.code == 2000) {
                                this.$message.success("操作成功");
                                this.putDialogs = false;
                                this.resetForm('addForm');
                                this.$refs['grade'].clearValue();
                                this.$refs['stage'].clearValue();
                                this.$refs['prof'].clearValue();
                                this.query()
                            }
                        });
                    } else {
                        this.$message.error("各项不能为空");
                    }
                }
            },
            resetForm(formName) {
                this.$refs[formName].resetFields();
            },
            sendGra() {
                this.pop[0].name = this.$refs['grade'].options.find(i => i.id == this.addBo.gradeId).gradeName;
                this.forPop();
            },
            sendSta() {
                this.pop[1].name = this.$refs['stage'].options.find(i => i.id == this.addBo.gradeId).stageName;
                this.forPop();
            },
            sendPro() {
                this.pop[2].name = this.$refs['prof'].options.find(i => i.id == this.addBo.gradeId).profName;
                this.forPop();
            },
            forPop() {
                this.addBo.planName = this.pop[0].name + this.pop[1].name + this.pop[2].name;
                this.addBo.subName = this.pop[0].name + this.pop[1].name + this.pop[2].name;
            },
            addPut() {
                this.addBo = {
                    id: null,
                    gradeId: '',//年级ID
                    stageId: '',//层次ID
                    profId: '',//专业ID
                    planName: '',//计划名称
                    subName: '',//建议名称
                    remark: ''//备注
                };
                this.titles = "添加";
                this.putDialogs = true;
            },
            query() {
                this.getPermission('eduTeachPlan:list', () => {
                    this.openLoadingView();
                    this.$axios_supermall.post("/hnjxjy-core/eduTeachPlan/list?page="
                        + this.bo.page + "&pageSize="
                        + this.bo.pageSize + "&planName="
                        + this.bo.planName + "&gradeId="
                        + this.bo.gradeId + "&stageId="
                        + this.bo.stageId + "&profId="
                        + this.bo.profId + "&schoolId="
                        + this.bo.schoolId).then(resp => {
                        if (resp.data.code == 2000) {
                            this.tableData = resp.data.data.list;
                            this.total = resp.data.data.total;
                        }
                        this.loadingView.close();
                    }).catch(() => {
                        this.loadingView.close();
                    })
                });
            },
            importTemplate() {
                this.dialogVisibleUploads = true
            },
            // 导出
            download() {
              let objPar = JSON.parse(localStorage.getItem("loginStrage"));
              this.openLoadingView();
              this.$axios({
                method: "get",
                url: "hnjxjy-core/eduTeachPlan/export",
                responseType: "blob",
                headers: objPar,
              }).then((res) => {
                let blob = new Blob([res.data], {
                  type: "application/vnd.ms-excel",
                });
                let url = window.URL.createObjectURL(blob);
                window.location.href = url;
                this.$message.success("操作成功");
                this.loadingView.close();
              }).catch((err) => {
                this.$message.error("操作失败");
                this.loadingView.close();
              });
            },
            beforeUpload(file) {
                const isXls = file.type === "application/vnd.ms-excel";
                const isXlsx =
                    file.type ===
                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
                if (!isXls && !isXlsx) {
                    this.$message.error("上传文件只能是XLS/XLSX格式！");
                    return false;
                }
                return isXls || isXlsx;
            },
            uploadFile(file) {
              let fileDate = file.file;
              let forms = new FormData();
              forms.append("file", fileDate);
              let objPar = JSON.parse(localStorage.getItem("loginStrage"));
              this.openLoadingView();
              this.$axios({
                method: "post",
                url: "hnjxjy-core/eduTeachPlan/eduTeachPlanImportTemplate",
                data: forms,
                headers: objPar,
              }).then((res) => {
                if (res.data.code == 2000) {
                  this.$message.success(res.data.data);
                  this.dialogVisibleUploads = false;
                  this.query();
                } else {
                  this.$message.error(res.data.data)
                }
                this.loadingView.close();
              }).catch((err) => {
                this.$message.error("操作失败");
                this.loadingView.close();
              });
            },
            downloadbutton() {
              let objPar = JSON.parse(localStorage.getItem("loginStrage"));
              this.openLoadingView();
              this.$axios({
                method: "get",
                url: "hnjxjy-core/eduTeachPlan/downloadEduTeachPlanTemplate",
                responseType: "blob",
                headers: objPar,
                params: {
                  status: 1
                }
              }).then((res) => {
                let blob = new Blob([res.data], {
                  type: "application/vnd.ms-excel",
                });
                let downloadElement = document.createElement('a');
                let href = window.URL.createObjectURL(blob);
                downloadElement.href = href;
                downloadElement.download = '教学计划导入模板.xlsx';　　　　　　　　　　// xxx.xls/xxx.xlsx
                document.body.appendChild(downloadElement);
                downloadElement.click();
                document.body.removeChild(downloadElement);
                window.URL.revokeObjectURL(href);
                this.$message.success("操作成功");
                this.loadingView.close();
              }).catch((err) => {
                this.$message.error("操作失败");
                this.loadingView.close();
              });
            },
            uploadFiles(file) {
                let fileDate = file.file;
                let forms = new FormData();
                forms.append("file", fileDate);
                forms.append("planId",this.id);
                let objPar = JSON.parse(localStorage.getItem("loginStrage"));
                this.openLoadingView();
                this.$axios({
                    method: "post",
                    url: "hnjxjy-core/eduTeachPlanCourse/exportExcelCourse",
                    data: forms,
                    headers: objPar,
                })
                    .then((res) => {
                        if(res.data.code==2000){
                            this.$message.success(res.data.data)
                            this.dialogVisibleUploades = false;
                            this.queryLook();
                            this.loadingView.close();
                        }else{
                            this.$message.error(res.data.data)
                            this.dialogVisibleUploades = false;
                            this.loadingView.close();
                        }
                    })
                    .catch((err) => {
                        this.dialogVisibleUploades = false;
                        this.loadingView.close();
                    });
            },
            closeDialog(formName) {
                this.$refs['grade'].clearValue();
                this.$refs['stage'].clearValue();
                this.$refs['prof'].clearValue();
                this.putDialog = false;
            },
            downloadbuttons() {
                let objPar = JSON.parse(localStorage.getItem("loginStrage"));
                this.$axios({
                    method: "get",
                    url: "hnjxjy-core/eduTeachPlanCourse/exportMB",
                    responseType: "blob",
                    headers: objPar,
                    params: {
                        status: 1
                    }
                })
                    .then((res) => {
                        let blob = new Blob([res.data], {
                            type: "application/vnd.ms-excel",
                        });
                        let url = window.URL.createObjectURL(blob);
                        window.location.href = url;
                    })
                    .catch((err) => {
                    });
            },
            onSubmit() {
            },
            toggleSelection(rows) {
                if (rows) {
                    rows.forEach((row) => {
                        this.$refs.multipleTable.toggleRowSelection(row);
                    });
                } else {
                    this.$refs.multipleTable.clearSelection();
                }
            },
            handleSelectionChange(val) {
                this.multipleSelection = val;
            },
            handleEdit(index, row) {
            },
            handleDelete(row) {
                this.$confirm("确定要删除此数据吗?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                }).then(() => {
                    this.$axios_supermall.post("hnjxjy-core/eduTeachPlan/remove?id=" + row.id,).then(resp => {
                        if (resp.data.code != 2000) {
                            // this.$message({
                            //     type: "error",
                            //     message: "删除失败",
                            // });
                            return;
                        }
                        this.$message({
                            type: "success",
                            message: "删除成功",
                        });
                        this.query()
                    })
                })
            },

            handleCurrentChange(page) {
                this.bo.page = page;
                this.query();
            },

            handleSizeChange(size) {
                this.bo.pageSize = size;
                this.query();
            }
        },
    };
</script>
<style scoped>
    .rigth {
        width: 93%;
        display: inline-block;
        height: 870px;
        overflow-y: scroll;
        /* background-color: #fff; */
        background-color: rgba(164, 174, 224, 0.11);
        float: right;
    }

    .left {
        width: 7%;
        display: inline-block;
    }

    /*  添加 ---重置按钮 */
    .tianjia {
        width: 100%;
        margin-top: 20px;
        display: flex;
        justify-content: space-between;
    }

    .tianjia section:nth-child(1) {
        margin-left: 10px;
    }

    .tianjia section:nth-child(1) .el-button {
        margin-left: 10px;
        background: #1890ff;
    }

    .tianjia section:nth-child(2) {
        margin-right: 10px;
    }

    .tianjia section:nth-child(2) .el-button {
        margin-left: 10px;
        background: #1890ff;
    }

    .mingcheng {
        width: 100%;
        margin-top: 20px;
        display: flex;
        justify-content: space-between;
    }
</style>
